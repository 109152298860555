import { BaseInput } from '../..';

const InputComponent = ({ inputRef, ...rest }) => {
  return <span ref={inputRef} {...rest} />;
};

const SelectRoot = (props) => {
  const {
    startAdornment,
    children,
    innerProps,
    error,
    readOnly,
    required,
    disabled,
    fullWidth,
  } = props;

  return (
    <BaseInput
      inputComponent={InputComponent}
      inputProps={{ children, ...innerProps }}
      error={error}
      readOnly={readOnly}
      required={required}
      disabled={disabled}
      fullWidth={fullWidth ? true : false}
      startAdornment={startAdornment}
    />
  );
};

export { SelectRoot };
