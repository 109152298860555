import PropTypes from 'prop-types';
import { InputBase } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import i18n from '@shared/config/i18n';

const { t } = i18n;

const styles = {
  inputRoot: {
    overflow: 'hidden',
    flexGrow: 1,
    marginRight: '10px',
    padding: '0 10px 0 0',
    overflowY: 'auto',
    maxHeight: ({ hasAttachment }) => (hasAttachment ? '6vh' : '12vh'),
    display: 'block',
  },

  multiline: {
    overflow: 'hidden',
  },
};

export const MessageInput = withStyles(styles)((props) => {
  const { name, value, onChange } = props;
  const { inputRoot, multiline } = props.classes;

  return (
    <InputBase
      name={name}
      value={value}
      placeholder={t('EnterMessage')}
      multiline
      classes={{ root: inputRoot, input: multiline }}
      onChange={onChange}
    />
  );
});

MessageInput.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};
