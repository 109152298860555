import { useState } from 'react';
import { Grid } from '@mui/material';
import ArrowBackIosOutlinedIcon from '@mui/icons-material/ArrowBackIosOutlined';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import DatePicker from 'react-datepicker';

import { addYears, subYears, isDate } from 'date-fns';

import { InputControl, ActionIconButton } from '../../index';

const getNewValue = (date = new Date()) => ({
  date,
  year: date.getFullYear(),
});

const YearControl = (props) => {
  const { onChange, value = new Date(), name, ...rest } = props;

  const [value_, setValue] = useState(value);

  if (isDate(value_)) {
    const newValue = getNewValue(value);
    setValue(newValue);
  }

  const switchToNextYear = () => {
    const newValue = getNewValue(addYears(value_.date, 1));
    if (onChange) {
      onChange(newValue);
    }

    setValue(newValue);
  };

  const switchToPrevYear = () => {
    const newValue = getNewValue(subYears(value_.date, 1));
    if (onChange) {
      onChange(newValue);
    }

    setValue(newValue);
  };

  const handleChange = (value) => {
    const newValue = getNewValue(value);
    if (onChange) {
      onChange(newValue);
    }
    setValue(newValue);
  };

  return (
    <Grid
      container
      wrap="nowrap"
      alignItems="center"
      direction="row"
      justifyContent="space-between"
    >
      <Grid item>
        <ActionIconButton
          size="small"
          onClick={switchToPrevYear}
          style={{ margin: '0 5px' }}
        >
          <ArrowBackIosOutlinedIcon fontSize="small" />
        </ActionIconButton>
      </Grid>
      <Grid item>
        <DatePicker
          selected={value_.date}
          onChange={handleChange}
          showYearPicker
          dateFormat="yyyy"
          customInput={
            <InputControl
              disabled
              label={null}
              divider={false}
              name={name}
              value={value_.year}
              inputProps={{ style: { textAlign: 'center', minWidth: 100 } }}
              {...rest}
            />
          }
        />
      </Grid>
      <Grid item>
        <ActionIconButton
          size="small"
          onClick={switchToNextYear}
          style={{ margin: '0 5px' }}
        >
          <ArrowForwardIosOutlinedIcon fontSize="small" />
        </ActionIconButton>
      </Grid>
    </Grid>
  );
};

export { YearControl };
