import ExtraPropTypes from 'react-extra-prop-types';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import { MessageAvatar, MessageContent } from '../..';

export const Message = (props) => {
  const { message, component = 'li', children, color, ...rest } = props;
  const { isMine, contact, text } = message;
  const justify = isMine ? 'flex-end' : 'flex-start';

  const imageUrl = contact?.image || '';

  const initials = (contact?.name || '---')
    .split(' ')
    .filter((item, idx) => idx < 2)
    .map(([letter]) => letter)
    .join('')
    .toUpperCase();

  const hasMessageLongWords = text.split(' ').some((item) => item.length > 120);

  const messageStyles = {
    container: {
      [hasMessageLongWords && 'wordBreak']: 'break-all',
      background: color[50],
      order: isMine ? 1 : 2,
    },
    triangle: {
      borderBottom: `20px solid ${color[50]}`,
    },
  };

  const avatarStyles = {
    wrapper: {
      order: isMine ? 2 : 1,
    },
    avatar: {
      background: color[500],
      [isMine ? 'marginLeft' : 'marginRight']: '20px',
    },
  };

  const rootStyle = {
    paddingTop: '5px',
    paddingBottom: '5px',
    maxWidth: '100vw',
    fontSize: '14px',
  };

  return (
    <Grid
      container
      justifyContent={justify}
      wrap="nowrap"
      alignItems="flex-end"
      component={component}
      style={rootStyle}
      {...rest}
    >
      <MessageAvatar initials={initials} styles={avatarStyles} url={imageUrl} />
      <MessageContent isMine={isMine} styles={messageStyles}>
        {children}
      </MessageContent>
    </Grid>
  );
};

Message.propTypes = {
  message: PropTypes.shape({
    text: PropTypes.string.isRequired,
  }),
  children: PropTypes.arrayOf(PropTypes.node).isRequired,
  color: PropTypes.objectOf(ExtraPropTypes.color.isRequired),
};
