//@ts-nocheck
import { useEffect, useState, FC } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { FormLabel, Checkbox, FormControlLabel } from '@mui/material';
import { Add, LocationOn, SaveOutlined, Close } from '@mui/icons-material';
import { declOfNum as declOfNumber } from '@tools/declOfNum';
import { getPathOr } from '@tools/path';
import { CustomModal, ActionButton, CustomScrollbar, Divider } from '@ui/index';
import { useStyles } from './styles';
import { ISelectedBuildings, IProps, IComplex, IBuildings } from './types';

const getAllComplexes = (houses: IBuildings[]) =>
  Object.values(
    houses.reduce(
      (
        acc: Record<number, { buildings: IBuildings[]; complex: IComplex }>,
        item: IBuildings
      ) => {
        const buildingsId = item.complex.id;
        const fullComplex = acc[buildingsId];
        return {
          ...acc,
          [buildingsId]: fullComplex
            ? { ...fullComplex, buildings: [...fullComplex.buildings, item] }
            : { complex: item.complex, buildings: [item] },
        };
      },
      {}
    )
  );

const getCheckedComplexIds = (
  checkedBuildingIds: Record<number, Record<number, boolean>>
): Record<number, boolean> =>
  Object.entries(checkedBuildingIds).reduce(
    (acc, [complexId, buildings]) => ({
      ...acc,
      [complexId]: Object.values(buildings).some((e) => e),
    }),
    {}
  );

const formatOnSave = (checkedBuildingIds: Record<number, Record<number, boolean>>) =>
  Object.values(checkedBuildingIds)
    .reduce(
      (acc: Array<(string | number | boolean)[]>, item: Record<number, boolean>) => [
        ...acc,
        ...Object.entries(item),
      ],
      []
    )
    .filter((item) => item[1])
    .map((item) => ({ id: Number(item[0]) }));

const formatOnOpen = (initialBuildings: IBuildings[], houses: IBuildings[]) =>
  initialBuildings.reduce(
    (acc: Record<number, Record<number, boolean>>, { id: buildingId }) => {
      const complexId = houses?.find((item: IBuildings) => item?.id === buildingId)
        ?.complex?.id;
      if (complexId) {
        acc[complexId] = {
          ...acc[complexId],
          [buildingId]: true,
        };
      }
      return acc;
    },
    {}
  );

export const SelectBuildings: FC<IProps> = (props) => {
  const { t } = useTranslation();

  const {
    houses,
    setBuildings,
    buildings: initialBuildings,
    mode,
    error,
    hideSelect = false,
    isOpenSelect = false,
    changeVisibilityBuildingsSelect,
    changeComplex,
    onSave,
    buttonSaveText = t('Save'),
    hideIcon = false,
    saveKind = 'positive',
    cancelKind = 'negative',
  } = props;
  const classes = useStyles();

  const [isOpen, setIsOpen] = useState(false);
  const [selectedComplex, setSelectedComplex] = useState(0);
  const [checkedBuildingIds, setCheckedBuildingIds] = useState<
    Record<number, Record<number, boolean>>
  >({});

  const count = initialBuildings.length;

  useEffect(() => {
    if (isOpen) {
      setCheckedBuildingIds(formatOnOpen(initialBuildings, houses));
    }
  }, [isOpen]);

  useEffect(() => {
    setIsOpen(isOpenSelect);
  }, [isOpenSelect]);

  const isEdit = mode === 'edit';

  const allComplexes = getAllComplexes(houses);

  const selectedBuildings: ISelectedBuildings[] | [] = getPathOr(
    `${[selectedComplex]}.buildings`,
    allComplexes,
    []
  );

  const checkedComplexIds = getCheckedComplexIds(checkedBuildingIds);

  const close = () => {
    setIsOpen(false);
    setCheckedBuildingIds({});
    setSelectedComplex(0);

    if (changeVisibilityBuildingsSelect) {
      changeVisibilityBuildingsSelect(false);
    }
  };

  const save = () => {
    setBuildings(formatOnSave(checkedBuildingIds));

    if (changeComplex) {
      changeComplex(checkedComplexIds);

      if (onSave) {
        onSave();
      }
    }

    close();
  };

  const content = (
    <div className={classes.modalContainer}>
      <div className={classNames(classes.block, classes.blockBorder)}>
        <div className={classes.modalLabel}>{t('complex')}</div>
        <div className={classes.scrollContent}>
          <CustomScrollbar>
            {allComplexes.map(({ complex }, index: number) => {
              const complexId = complex.id;
              const title = complex.title;
              const currentBuildings =
                allComplexes.find((item) => item?.complex?.id === complexId)?.buildings ||
                [];

              const buildingCount = currentBuildings.reduce(
                (acc: number, item: IBuildings) => {
                  const temp = getPathOr(
                    `${[complexId]}.${[item.id]}`,
                    checkedBuildingIds,
                    0
                  );
                  return acc + (temp ? 1 : 0);
                },
                0
              );

              const fullySelected = currentBuildings.length === buildingCount;
              const checked = checkedComplexIds[complexId] || false;

              const onCheckboxChange = () => {
                setCheckedBuildingIds({
                  ...checkedBuildingIds,
                  [complexId]: {
                    ...checkedBuildingIds[complexId],
                    ...currentBuildings.reduce(
                      (acc: object, item: IBuildings) => ({
                        ...acc,
                        [item.id]: !fullySelected,
                      }),
                      {}
                    ),
                  },
                });
              };

              return (
                <div
                  className={classNames(classes.complexItem, {
                    [classes.activeComplexItem]: selectedComplex === index,
                  })}
                  key={complexId}
                  onClick={() => setSelectedComplex(index)}
                >
                  <Checkbox
                    indeterminate={!fullySelected && checked}
                    checked={checked}
                    onChange={onCheckboxChange}
                  />
                  {title}
                  {buildingCount ? (
                    <div className={classes.selectedCountBlock}>{buildingCount}</div>
                  ) : null}
                </div>
              );
            })}
          </CustomScrollbar>
        </div>
      </div>
      <div className={classes.block}>
        <div className={classes.modalLabel}>{t('house')}</div>
        <div className={classes.scrollContent}>
          <CustomScrollbar>
            {selectedBuildings.map(({ building, complex }) => {
              const title = building.title;
              const buildingId = building.id;
              const complexId = Number(complex.id);
              return (
                <div key={buildingId}>
                  <FormControlLabel
                    classes={{
                      label: classes.checkboxLabel,
                      root: classes.checkboxRoot,
                    }}
                    control={
                      <Checkbox
                        name={String(buildingId)}
                        checked={getPathOr(
                          `${[complexId]}.${[buildingId]}`,
                          checkedBuildingIds,
                          false
                        )}
                        onChange={({ target: { checked } }) => {
                          setCheckedBuildingIds({
                            ...checkedBuildingIds,
                            [complexId]: {
                              ...checkedBuildingIds[complexId],
                              [buildingId]: checked,
                            },
                          });
                        }}
                      />
                    }
                    label={title}
                  />
                </div>
              );
            })}
          </CustomScrollbar>
        </div>
      </div>
    </div>
  );

  const actions = (
    <div className={classes.buttons}>
      <ActionButton withMargin kind={saveKind} onClick={save}>
        {!hideIcon && <SaveOutlined />}
        <div className={classes.iconLabel}>{buttonSaveText}</div>
      </ActionButton>
      <ActionButton kind={cancelKind} onClick={close}>
        {!hideIcon && <Close />}
        <div className={classes.iconLabel}>{t('Cancellation')}</div>
      </ActionButton>
    </div>
  );

  const Error: FC<{}> = () =>
    error ? <div className={classes.errorText}>{error}</div> : null;

  if (hideSelect) {
    return (
      <CustomModal
        {...{ isOpen, content, actions }}
        header={t('NewsObject.ObjectSelection')}
        onClose={close}
        titleFontSize={24}
        minWidth="70vw"
      />
    );
  }

  return (
    <div>
      <FormLabel
        classes={{
          root: classes.label,
        }}
        error={Boolean(error)}
      >
        {t('objects')}
      </FormLabel>
      <div
        onClick={() => (isEdit ? setIsOpen(true) : null)}
        className={classNames(classes.defaultSelect, {
          [classes.select]: isEdit,
        })}
      >
        {count ? (
          <>
            <div className={classes.icon}>
              <LocationOn />
            </div>
            <span>
              {count}{' '}
              {declOfNumber(count, [
                t('NewsObject.Object'),
                t('NewsObject.NoObject'),
                t('NewsObject.Objects'),
              ])}{' '}
              {declOfNumber(count, [
                t('NewsObject.Selected'),
                t('NewsObject.SelectedO'),
                t('NewsObject.Chosen'),
              ])}
            </span>
          </>
        ) : (
          <>
            <div className={classes.icon}>
              <Add />
            </div>
            {t('NewsObject.SelectObjects')}
          </>
        )}
      </div>
      <Error />
      <Divider />
      <CustomModal
        {...{ isOpen, content, actions }}
        header={t('NewsObject.ObjectSelection')}
        onClose={close}
        titleFontSize={24}
        minWidth="70vw"
      />
    </div>
  );
};
