import CloseIcon from '@mui/icons-material/Close';

function CloseModalButton(props) {
  const { style, onClose, ...rest } = props;

  return (
    <CloseIcon
      onClick={onClose}
      data-test-id="close-modal-btn"
      style={{
        position: 'fixed',
        top: '15px',
        right: '15px',
        fontSize: '3rem',
        cursor: 'pointer',
        ...style,
      }}
      {...rest}
    />
  );
}

export { CloseModalButton };
