import { createEffect, createEvent, createStore } from 'effector';

interface SetToOrFromArchiveData {
  id: number | string;
  archive: boolean;
  comment?: string;
}

export const fxSetToOrFromArchive = createEffect<SetToOrFromArchiveData, void>({});

export const addVisibleArchiveBlockSignals = createEvent();
export const changeToArchiveComment = createEvent();
export const setToOrFromArchiveEvent = createEvent<SetToOrFromArchiveData>({});
export const resetToArchiveComment = createEvent();
export const resetError = createEvent();

export const $visibleArchiveBlockSignals = createStore(null).on(
  addVisibleArchiveBlockSignals,
  (_, signal_id) => signal_id
);

export const $toArchiveComment = createStore<string>('')
  .on(changeToArchiveComment, (_, comment) => comment)
  .reset(resetToArchiveComment);

export const $isArchiveLoading = createStore<boolean>(false).on(
  fxSetToOrFromArchive.pending,
  (_, status) => status
);

export const $setToOrFromArchiveError = createStore<string | null>(null)
  .on(fxSetToOrFromArchive.failData, (_, error) => error.message)
  .on(resetError, () => null);
