import { FC } from 'react';
import { Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import i18n from '@shared/config/i18n';

const { t } = i18n;

interface IProps {
  openModal: () => boolean;
  title: string;
  viewStatistics: JSX.Element;
  viewStatisticsTable: JSX.Element;
  viewStatusticsLogsTable: JSX.Element;
  dates: { date: string; count: number }[] | [];
  isPoll?: boolean;
}

const useStyles = makeStyles({
  title: {
    fontWeight: 700,
    fontSize: 24,
    marginBottom: 25,
  },
  statisticsTitle: {
    fontWeight: 500,
    fontSize: 22,
  },
  statistics: {
    marginTop: 24,
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(330px, 1fr))',
    gap: '15px calc(5px + 2%)',
  },
  detailStatistics: {
    paddingTop: 8,
    fontWeight: 500,
    fontSize: 16,
    color: '#0394E3',
    cursor: 'pointer',
  },
});

const StatisticsTemplate: FC<IProps> = ({
  openModal,
  title,
  viewStatistics,
  viewStatisticsTable,
  viewStatusticsLogsTable,
  dates,
  isPoll = false,
}) => {
  const classes = useStyles();

  return (
    <div style={{ padding: '5px 26px 10px' }}>
      {title && <Typography classes={{ root: classes.title }}>{title}</Typography>}

      <div>
        <Typography classes={{ root: classes.statisticsTitle }}>
          {isPoll ? t('PollStatistics') : t('Viewstatistics')}
        </Typography>
        <div className={classes.statistics}>{viewStatistics}</div>
      </div>

      <div style={{ marginTop: 20 }}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography classes={{ root: classes.statisticsTitle }}>
            {isPoll ? t('NumberSurveysCompletedPerWeek') : t('NumberOfViewsPerWeek')}
          </Typography>
          <Typography className={classes.detailStatistics} onClick={openModal}>
            {t('DetailedView')}
          </Typography>
        </div>

        <div style={{ height: dates.length > 0 ? 55 : 200, marginTop: 10 }}>
          {viewStatisticsTable}
        </div>
      </div>

      <div style={{ marginTop: 20, marginBottom: 5, position: 'relative' }}>
        <Typography classes={{ root: classes.statisticsTitle }}>
          {isPoll ? t('DetailedWalkthroughStatistics') : t('DetailedViewStatistics')}
        </Typography>
        <div style={{ height: 520, marginTop: 10 }}>{viewStatusticsLogsTable}</div>
      </div>
    </div>
  );
};

export { StatisticsTemplate };
