import { TableColumnVisibility as TempTableColumnVisibility } from '@devexpress/dx-react-grid-material-ui';
import i18n from '@shared/config/i18n';

const { t } = i18n;

const TableColumnVisibility = (props) => (
  <TempTableColumnVisibility messages={{ noColumns: t('NoDataToDisplay') }} {...props} />
);

export { TableColumnVisibility };
