import { api } from '@api/api2';

const updateUser = (payload: IProfileUpdateUserPayload) =>
  api.no_vers('post', 'user/update-user', payload);

const updateAuth = (payload: IProfileUpdateAuthPayload) =>
  api.no_vers('post', 'auth/update', payload);

const updateAvatar = (payload: IProfileUpdateAvatarPayload) =>
  api.no_vers('post', 'user/update-user', payload);

export const profileApi = {
  updateUser,
  updateAuth,
  updateAvatar,
};
