import makeStyles from '@mui/styles/makeStyles';
import i18n from '@shared/config/i18n';
import { CustomModal, CloseButton } from '@ui/';

const useStyles = makeStyles({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  content: {
    marginTop: 20,
  },
  field: {
    display: 'grid',
    gap: 5,
    marginBottom: 15,
  },
  fieldTitle: {
    margin: '0',
    color: '#767676',
    fontSize: 13,
    fontWeight: 500,
    margin: 0,
  },
  fieldContent: {
    fontSize: 16,
    fontWeight: 500,
    margin: 0,
  },
});

const { t } = i18n;

const ProfileInfo = ({ isOpen, onClose }) => {
  const classes = useStyles();

  const fields = [
    {
      title: t('DeveloperDetails'),
      content: (
        <span>
          {t('Unicorn')}{' '}
          <a href="https://ujin.tech/" target="_blank">
            ujin.tech
          </a>
        </span>
      ),
    },
    {
      title: t('SupportNumber'),
      content: <a href="tel:88007750519">8 800 775 05 19</a>,
    },
    {
      title: t('EmailAddress'),
      content: <a href="mailto:info@ujin.tech">info@ujin.tech</a>,
    },
  ];

  const content = (
    <div className={classes.content}>
      {fields.map((item, key) => (
        <div key={key} className={classes.field}>
          <p className={classes.fieldTitle}>{item.title}</p>
          <div className={classes.fieldContent}>{item.content}</div>
        </div>
      ))}
    </div>
  );
  return (
    <CustomModal
      content={content}
      isOpen={isOpen}
      header={
        <div className={classes.header}>
          <span className={classes.header.title}>{t('AboutTheSystem')}</span>
          <CloseButton style={{ color: 'd3d3d3' }} onClick={onClose} />
        </div>
      }
      onClose={onClose}
      titleFontSize={24}
      minWidth="528px"
    />
  );
};

export default ProfileInfo;
