import { memo } from 'react';
import { Table } from '@devexpress/dx-react-grid-material-ui';

const TableRow = (props) => {
  const {
    onRowClick = () => null,
    tableRow,
    row,
    isSelected = false,
    children,
    style,
    ...restProps
  } = props;

  const rowStyle = {
    cursor: 'pointer',
    height: '39px',
    ...(style ? style : {}),
    background: isSelected ? '#E1EBFF' : (style && style.background) || '#fff',
  };

  return (
    <Table.Row
      onClick={() => onRowClick(row, tableRow.rowId)}
      {...restProps}
      style={rowStyle}
    >
      {children}
    </Table.Row>
  );
};

export default memo(TableRow);
