import { useMemo } from 'react';
import cn from 'classnames';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => {
  return {
    container: {
      display: 'grid',
      gridTemplateColumns: ({ conf }) => conf,
      gridTemplateRows: '1fr',
      height: '100%',
      backgroundColor: '#f4f7fa',
      gridGap: '24px',
      padding: '24px',
      paddingTop: '12px',
      overflow: 'hidden',
    },
    filter: {
      gridColumn: '1 / 2',
      gridRow: '1 / 2',
      position: 'relative',
    },
    main: {
      overflow: 'hidden',
      position: 'relative',
      gridColumn: ({ isFilter, isDetail }) =>
        `${!isFilter ? 1 : 2} / ${isDetail ? 3 : 4}`,
      gridRow: '1 / 2',
    },
    detail: {
      gridColumn: '3 / 4',
      gridRow: '1 / 2',
      position: 'relative',
    },
  };
});

const FilterMainDetailLayout = (props) => {
  const {
    filter = null,
    main = <div />,
    detail = null,
    params = {},
    classes = {},
  } = props;

  const isFilter = Boolean(filter);
  const isDetail = Boolean(detail);

  const templateConfig = useMemo(() => getTemplateConfig(params), [params]);

  const cssClassName = useStyles({ isFilter, isDetail, conf: templateConfig });

  return (
    <div className={cssClassName.container}>
      {isFilter && <section className={cssClassName.filter}>{filter}</section>}
      <section className={cn(cssClassName.main, classes.main)}>{main}</section>
      {isDetail && <section className={cssClassName.detail}>{detail}</section>}
    </div>
  );
};

export { FilterMainDetailLayout };

function getTemplateConfig({
  filterWidth = '310px',
  mainWidth = 'auto',
  detailWidth = 'minmax(370px, 25%)',
}) {
  return `${filterWidth} ${mainWidth} ${detailWidth}`;
}
