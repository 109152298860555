import { styled } from '@mui/material/styles';
import { TreeView } from '@mui/lab';

export const ObjectsTree = styled(TreeView)(() => ({
  '& *[class*="focused"]': {
    background: 'white',
  },
  '& *[class*="selected"]': {
    background: '#E1EBFF !important',
  },
}));
