import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { Dumb } from '../../../../ui';

const NoSectionAccess = (props) => {
  const { t } = useTranslation();
  return (
    <Dumb>
      <Typography
        style={{ color: '#7d7d8e', fontWeight: 900, fontSize: 24 }}
        variant="body1"
      >
        {t('NoAccessSection')}
      </Typography>
    </Dumb>
  );
};

export { NoSectionAccess };
