import { memo } from 'react';
import { CustomScrollbar } from '../../ui';
import s from './FormTemplate.module.css';

const FormTemplate = memo((props) => {
  const { Header, Main, Footer, addStyle, autoHeightMax, disablePaddingBottom } = props;

  return (
    <form className={s.form} style={addStyle && addStyle.form}>
      {Header && (
        <section className={s.header} style={addStyle && addStyle.header}>
          <Header {...props} />
        </section>
      )}
      {Main && (
        <CustomScrollbar
          style={addStyle && addStyle.scroll}
          className={s.scroll}
          autoHeight
          autoHeightMax={autoHeightMax || '89vh'}
          renderThumbHorizontal={(props) => <div />}
          renderView={({ style, ...props }) => (
            <div {...props} style={{ ...style, maxHeight: 'inherit' }} />
          )}
        >
          <section
            className={s.main}
            style={{
              ...(Footer ? {} : { paddingBottom: !disablePaddingBottom && '60px' }),
              ...(addStyle && addStyle.main),
            }}
          >
            <Main {...props} />
          </section>
        </CustomScrollbar>
      )}
      {Footer && (
        <section className={s.footer} style={addStyle && addStyle.footer}>
          <Footer {...props} />
        </section>
      )}
    </form>
  );
});

export default FormTemplate;
