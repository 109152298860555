import DialogTitle from '@mui/material/DialogTitle';
import withStyles from '@mui/styles/withStyles';

const CardTitle = withStyles({
  root: {
    color: '#3B3B50',
    fontWeight: '700',
    minWidth: '25%',
    minHeight: '30%',
    fontSize: '1.2em',
    padding: 0,
    lineHeight: '28px',
  },
})(DialogTitle);

export default CardTitle;
