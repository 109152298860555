import makeRequest from '../../tools/makeRequest';
import { dialogActions, detailActions } from '../symbiotes/objects';
import { requestAC, requestFailAC, getSuccessAC } from './commonActionCreators';

const { dialogChangeVisibility } = dialogActions;

const name = 'FLATS';

export const { detailChangeMode } = detailActions;

export const request = requestAC(`${name}`);
export const getSuccess = getSuccessAC(`${name}`);
export const requestFail = requestFailAC(`${name}`);

const actions = (dispatch) => ({
  request: () => dispatch(request()),
  fail: (error) => {
    dispatch(requestFail(error));
    dispatch(dialogChangeVisibility({ isOpen: true, kind: 'error' }));
  },
});

export function fetchFlats() {
  return (dispatch, getState) => {
    const { flats } = getState().objects.filter.filters;
    const payload = { per_page: '1000', page: '1' };
    if (flats) {
      payload.filter = {
        buildings: [flats.id],
      };
    }

    return makeRequest('/v1/apartment/crm/apartments-list', payload, {
      actions: {
        ...actions(dispatch),
        success: ({ data }) => {
          dispatch(getSuccess(data));
        },
      },
      method: 'POST',
    });
  };
}

export function saveFlat({ square, id, floor, entrance_data, number, building_id }) {
  return async (dispatch) => {
    return makeRequest(
      'v1/apartment/crm/update',
      {
        apartment_id: id,
        building_id,
        apartment_number: number,
        floor,
        entrance_id: entrance_data.id,
        square,
        rooms_count: 0,
      },
      {
        actions: {
          ...actions(dispatch),
          success: () => {
            dispatch(fetchFlats());
            dispatch(detailChangeMode('view'));
          },
        },
        method: 'POST',
      }
    );
  };
}
