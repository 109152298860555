import { getCookie } from '@tools/cookie';

export const getDefaultParams = () => {
  const token = getCookie('bms_token');
  const app = getCookie('app');
  return {
    app: app || 'crm',
    token,
  };
};
