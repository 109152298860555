import { combineReducers } from 'redux';
import { handleActions } from 'redux-actions';
import {
  request,
  getSuccess,
  updateSuccess,
  signIn,
  signOut,
  requestFail,
  dialogChangeVisibility,
} from '../actions/userActionGenerator';
import { fetchingReducer, errorReducer, visibilityReducer } from './commonReducers';

const isFetching = fetchingReducer(
  [request],
  [getSuccess, requestFail, updateSuccess],
  false
);
const error = errorReducer([requestFail], [request], null);

const data = handleActions(
  {
    [signIn]: (state, action) => action.payload,
    [signOut]: () => {},
    [updateSuccess]: (state, action) => ({ ...state, ...action.payload }),
  },
  {}
);

const dialog = visibilityReducer(dialogChangeVisibility, {
  isOpen: false,
  kind: null,
});

const user = combineReducers({
  data,
  isFetching,
  error,
  dialog,
});

export default user;
