import { useState } from 'react';
import {
  FormControl,
  FormHelperText,
  FormLabel,
  OutlinedInput,
  OutlinedInputProps,
} from '@mui/material';
import { ObjectDialog } from '@features/effector-form/controls/object/components/objectDialog';
import { withControl } from '@features/effector-form/hoc/withControl';

export const ObjectControl = withControl<OutlinedInputProps>(
  ({
    label,
    value,
    setValue,
    error,
    isError,
    required,
    readOnly,
    disabled,
    ...props
  }) => {
    const [innerValue, setInnerValue] = useState();

    return (
      <FormControl>
        <FormLabel required={required}>{label}</FormLabel>

        <OutlinedInput
          {...props}
          value={value?.title || ''}
          error={isError}
          onChange={() => setInnerValue(innerValue)}
          required={required}
          readOnly={readOnly}
          disabled={disabled}
          endAdornment={<ObjectDialog setValue={setValue} />}
        />

        <FormHelperText error>{error}</FormHelperText>
      </FormControl>
    );
  }
);
