import DialogContent from '@mui/material/DialogContent';
import withStyles from '@mui/styles/withStyles';

const CardContent = withStyles({
  root: {
    color: '#65657B',
    fontSize: '.9em',
    padding: 0,
    lineHeight: '21px',
    marginBottom: '40px',
    marginTop: '24px',
    overflow: 'hidden',
  },
})(DialogContent);

export default CardContent;
