import { handleActions, combineActions } from 'redux-actions';
import { signOut } from '../actions/userActionGenerator';

const visibilityReducer = (actionFn, initialState) => {
  return handleActions(
    {
      [actionFn]: (state, action) => {
        return typeof action.payload === 'object' && action.payload !== null
          ? {
              ...action.payload,
              isOpen:
                Object.prototype.hasOwnProperty.call(action.payload, 'isOpen') &&
                action.payload.isOpen !== undefined
                  ? action.payload.isOpen
                  : !state.isOpen,
            }
          : typeof action.payload === 'boolean'
          ? action.payload
          : !state;
      },
      [signOut]: () => initialState,
    },
    initialState
  );
};

const noopReducer = (actionFn, initialState) => {
  return handleActions(
    {
      [actionFn]: (state, action) => action.payload,
      [signOut]: () => initialState,
    },
    initialState
  );
};

const fetchingReducer = (onList, offList, initialState) => {
  return handleActions(
    {
      [combineActions(...onList)]: () => true,
      [combineActions(...offList)]: () => false,
      [signOut]: () => initialState,
    },
    initialState
  );
};

const errorReducer = (errorFnList, resetFnList, initialState) => {
  return handleActions(
    {
      [combineActions(...errorFnList)]: (state, action) => action.payload,
      [combineActions(...resetFnList)]: () => null,
      [signOut]: () => initialState,
    },
    initialState
  );
};

const dataReducer = (actionFns, initialState) => {
  return handleActions(
    {
      [combineActions(actionFns.create, actionFns.update)]:
        actionFns.createReducer || actionFns.updateReducer
          ? actionFns.createReducer || actionFns.updateReducer
          : (state, action) => ({
              ...state,
              [action.payload.id]: action.payload,
            }),

      [actionFns.delete]: (state, action) => {
        const newState = { ...state };
        delete newState[action.payload];
        return newState;
      },

      [actionFns.get]: actionFns.getReducer
        ? actionFns.getReducer
        : (_, action) => {
            return actionFns.format ? actionFns.format(action.payload) : action.payload;
          },
      [signOut]: (state, action) => initialState,
      ...(actionFns.reset ? { [actionFns.reset]: (state, action) => ({}) } : {}),

      [actionFns.markForDelete]: (state, action) => {
        const newState = { ...state };
        for (const [key, value] of Object.entries(action.payload)) {
          if (Object.prototype.hasOwnProperty.call(newState, key)) {
            newState[key].del = value ? '1' : '0';
          }
        }
        return newState;
      },
    },
    initialState
  );
};

const selectReducer = (actionFn, initialState) => {
  return handleActions(
    {
      [actionFn]: (state, action) => {
        const elementInx = state.indexOf(action.payload);

        if (action.payload === null) return [];

        if (elementInx === -1) {
          return [...state, action.payload];
        }
        return state.filter((item, inx) => inx !== elementInx);
      },
      [signOut]: () => initialState,
    },
    initialState
  );
};

export {
  visibilityReducer,
  noopReducer,
  fetchingReducer,
  errorReducer,
  dataReducer,
  selectReducer,
};
