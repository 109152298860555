import { HttpRequest } from './HttpRequest';

export default class Endpoint {
  constructor(url, data, params) {
    this._url = url;
    this._data = data;
    this._params = params;
  }

  request() {
    return HttpRequest.make()
      .method('POST')
      .endpoint(this._url)
      .params(this._params)
      .data({ data: this._data });
  }

  send() {
    return this.request().send();
  }
}
