import { useState, useEffect } from 'react';
import { useMediaQuery } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { SearchButton, CloseButton, SearchInput, FoundInfo } from '../..';

const useStyles = makeStyles({
  margin: {
    margin: '0 5px',
  },
  close: {
    marginRight: 40,
  },
});

const AdaptiveSearch = ({ totalCount, searchValue, searchChanged, isAnySideOpen }) => {
  const [searchOpen, setSearch] = useState(false);
  const handleSearchClose = () => setSearch(false);
  const handleSearchOpen = () => setSearch(true);
  const isScreenLess1440 = useMediaQuery('(max-width:1440px)');

  useEffect(() => {
    if (searchValue.length > 0) {
      handleSearchOpen();
    }
  }, [searchValue]);

  const classes = useStyles();

  const getResponsiveOrFullElement = (fullElement, responsiveElement) => {
    if (isScreenLess1440 || isAnySideOpen) {
      return responsiveElement;
    }
    return fullElement;
  };

  const search = (
    <>
      <FoundInfo count={totalCount} className={classes.margin} />
      <SearchInput
        onChange={(e) => searchChanged(e.target.value)}
        handleClear={() => searchChanged('')}
        value={searchValue}
        className={classes.margin}
      />
    </>
  );

  const responsiveSearch = (
    <>
      <SearchButton
        className={classes.margin}
        onClick={handleSearchOpen}
        disabled={searchOpen}
      />
      {searchOpen && (
        <>
          <FoundInfo count={totalCount} className={classes.margin} />
          <SearchInput
            isMobile
            onChange={(e) => searchChanged(e.target.value)}
            value={searchValue}
            handleClear={() => searchChanged('')}
            className={classes.margin}
          />
          <CloseButton onClick={handleSearchClose} className={classes.close} />
        </>
      )}
    </>
  );

  return getResponsiveOrFullElement(search, responsiveSearch);
};

export { AdaptiveSearch };
