import { Components } from '@mui/material';

export const Backdrop: Components['MuiBackdrop'] = {
  styleOverrides: {
    root: {
      position: 'absolute',
      background: 'rgba(255,255,255,0.5)',
      zIndex: 10000,
    },
  },
};
