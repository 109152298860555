import { forwardRef } from 'react';
import InputMask from 'react-input-mask';
import i18n from '@shared/config/i18n';
import { InputField } from '../..';

const { t } = i18n;

const Z = /[АВЕКМНОРСТУХ]/i;
const D = /[0-9]/;
const mask = [Z, D, D, D, Z, Z, D, D, D];

function beforeMaskedStateChange({ nextState }) {
  return {
    ...nextState,
    value: nextState.value.toUpperCase(),
  };
}
function AutoPlateMaskedInput(props) {
  return (
    <InputMask
      mask={mask}
      maskPlaceholder={null}
      {...props}
      beforeMaskedStateChange={beforeMaskedStateChange}
    />
  );
}

const AutoPlateMaskedField = forwardRef((props, ref) => {
  const { field, form, ...rest } = props;

  return (
    <InputField
      field={field}
      form={form}
      helpText={t('FormatGovNumber')}
      inputComponent={AutoPlateMaskedInput}
      label={t('GovNumber')}
      placeholder="A000AA000"
      {...rest}
    />
  );
});

export { AutoPlateMaskedInput, AutoPlateMaskedField };
