import { forwardRef } from 'react';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { ActionIconButton } from '../..';

const MoreButton = forwardRef((props, ref) => {
  return (
    <ActionIconButton ref={ref} {...props}>
      <MoreHorizIcon />
    </ActionIconButton>
  );
});

export { MoreButton };
