export default class AuthenticatedRequest {
  constructor(request, credentials) {
    this._request = request;
    this.credentials = credentials;
  }

  request() {
    return this._request.request().data('auth', this.credentials.credentials());
  }

  send() {
    return this.request().send();
  }
}
