export default class Request {
  constructor(api, endpoint) {
    this.api = api;
    this.endpoint = endpoint;
  }

  request() {
    return this.endpoint.request().host(this.api.host()).params('app', this.api.app());
  }

  send() {
    return this.request().send();
  }
}
