import { Components } from '@mui/material';

export const DataGrid: Components['MuiDataGrid'] = {
  styleOverrides: {
    root: {
      cursor: 'pointer',
      border: 0,
      '& .super-app-theme--header': {
        color: '#7D7D8E',
        padding: 5,
      },

      '& .row-selection.MuiDataGrid-row.Mui-selected': {
        backgroundColor: 'transparent',
      },
      '& .row-selection.MuiDataGrid-row.Mui-selected:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.04)',
      },
      '& .row-selection.Mui-custom-opened': {
        backgroundColor: 'rgb(225, 235, 255)',
      },
      '& .row-selection.Mui-custom-opened.MuiDataGrid-row.Mui-selected': {
        backgroundColor: 'rgb(225, 235, 255)',
      },
      '& .row-selection.Mui-custom-opened:hover': {
        backgroundColor: 'rgb(225, 235, 255)',
      },

      // '& *::-webkit-scrollbar': {
      //   width: 10,
      //   height: 10,
      // },

      // '& *::-webkit-scrollbar-track': {
      //   backgroundColor: 'transparent',
      // },

      // '& *::-webkit-scrollbar-thumb': {
      //   backgroundColor: 'rgba(0, 0, 0, 0.2)',
      //   borderRadius: 6,
      // },
      '& .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within': {
        outline: 'none',
      },
      '& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-columnHeader:focus-within': {
        outline: 'none',
      },
      // '& .MuiDataGrid-columnHeader': {
      //   color: '#7D7D8E',
      //   padding: '5px',
      // },
      // '& .MuiDataGrid-row.Mui-selected, & .MuiDataGrid-row.Mui-selected:hover': {
      //   background: 'rgb(225, 235, 255)',
      // },
    },
  },
};
