import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import withStyles from '@mui/styles/withStyles';

const styles = () => {
  return {
    pollRoot: {
      background: 'rgba(255,255,255, .2)',
      padding: 10,
      borderRadius: 10,
      position: 'relative',
    },
  };
};

const Poll = (props) => {
  const { children, classes } = props;
  return (
    <>
      <Grid item className={classes.pollRoot}>
        {children}
      </Grid>
    </>
  );
};

const StyledPoll = withStyles(styles)(Poll);

Poll.propTypes = {
  isLoading: PropTypes.bool,
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
};

export { StyledPoll as Poll };
