import Checkbox from '@mui/material/Checkbox';
import withStyles from '@mui/styles/withStyles';

const styles = {
  root: {
    width: '18px',
    height: '18px',
    padding: '0',
    margin: 'auto 0',
    marginRight: '10px',
    marginLeft: '10px',
  },
};

function CustomCheckbox(props) {
  const { classes, ...restProps } = props;
  return (
    <Checkbox disableRipple type="checkbox" className={classes.root} {...restProps} />
  );
}

const StyledCheckbox = withStyles(styles)(CustomCheckbox);

export { StyledCheckbox as Checkbox };
