import {
  attach,
  combine,
  createEffect,
  createEvent,
  createStore,
  merge,
  sample,
  split,
} from 'effector';
import { pending, reset } from 'patronum';
import firebase from 'firebase/app';
import { askForRecieveNotification } from '../../../configs/firebase';
import { deleteAllCookie, getCookie, setCookie } from '../../../tools/cookie';
import { userApi } from '../api';
import { appUnmounted } from './app';

export const fxAuth = createEffect();
export const fxGetUser = createEffect();
export const fxAuthForCompany = createEffect();
export const fxGetCompanyUser = createEffect();
export const fxGetRoleData = createEffect();

export const signout = createEvent();
export const clearCookie = createEvent(deleteAllCookie);
export const authSubmit = createEvent();
export const authSubmitFor = split(authSubmit, {
  admin: ({ isCompany }) => !isCompany,
  company: ({ isCompany }) => isCompany,
});
export const loggedIn = merge([
  fxAuth.done,
  fxGetUser.done,
  fxAuthForCompany.done,
  fxGetCompanyUser.done,
]);
export const setAuthStatus = createEvent(); //boolean

const $data = createStore({});
export const $isUserLoading = pending({
  effects: [fxAuth, fxGetUser, fxAuthForCompany, fxGetCompanyUser],
});
export const $error = createStore(null);
export const $isAuthenticated = createStore(false);
export const $token = createStore(getCookie('bms_token') || null);
export const $remember_me = createStore(true);
export const $isCompany = createStore(
  JSON.parse(localStorage.getItem('isCompany')) || false
);
export const $user = $data.map((data) => data.user || {});
export const $accounts = $data.map((data) => data.accounts || []);
export const $account = $data.map((data) => (data.accounts ? data.accounts[0] : []));
export const $roleData = createStore(null);
export const $userRole = combine(
  $accounts,
  $isCompany,
  $roleData,
  ([firstAccount], isCompany, roleData) => {
    if (!isCompany) {
      return firstAccount ? { ...roleData, widgets: firstAccount.widgets } : {};
    }
    return {
      permissions: {
        'profile-section-all-restriction': true,
        'journal-user-actions-section-all-restriction': false,
        'enterprises-company-section-all-restriction': true,
        'enterprises-employee-section-all-restriction': true,
        'enterprises-passes-section-all-restriction': true,
        'enterprises-contacts-section-all-restriction': true,
        'enterprises-events-section-all-restriction': true,
        'enterprises-tickets-section-all-restriction': true,
        'enterprises-objects-section-all-restriction': true,
        'enterprises-parking-section-all-restriction': true,
        'enterprises-stuff-section-all-restriction': true,
        'enterprises-vehicles-section-all-restriction': true,
        'enterprises-news-section-all-restriction': true,
      },
      sections: {
        profile: true,
        'journal-user-actions': false,
        'enterprises-company': true,
        'enterprises-employee': true,
        'enterprises-passes': true,
        'enterprises-contacts': true,
        'enterprises-events': true,
        'enterprises-tickets': true,
        'enterprises-objects': true,
        'enterprises-parking': true,
        'enterprises-stuff': true,
        'enterprises-vehicles': true,
        'enterprises-news': true,
      },
    };
  }
);

/** INIT */

fxAuth.use(userApi.auth);
fxGetUser.use(userApi.get);
fxGetRoleData.use(userApi.getRoleData);
fxAuthForCompany.use(userApi.authForCompany);
fxGetCompanyUser.use(userApi.getCompanyUserData);

/** Запись данных после входа */
$data.on(loggedIn, (state, { result }) => result);

/** Обработка токена */
$token.on(loggedIn, (state, { result }) => result.user.token).on(signout, () => null);

/** Изменение состояния авторизации */
$isAuthenticated
  .on(fxGetRoleData.done, () => true)
  .on(setAuthStatus, (_, value) => value);

/** Обработка стора "запомнить меня" */
$remember_me.on(authSubmit, (state, { remember_me }) => remember_me);

/** Очистка куки после выхода */
sample({
  clock: signout,
  target: clearCookie,
});

/** Изменение состояния компании на основе данных аккаунта */
$isCompany.on(authSubmit, (state, { isCompany }) => isCompany);

/** После входа записываем локально состояние компании */
authSubmit.watch(({ isCompany }) => {
  localStorage.setItem('isCompany', isCompany);
});

$error.on(
  merge([fxAuth.fail, fxGetUser.fail, fxAuthForCompany.fail, fxGetCompanyUser.fail]),
  (_, { error }) => error
);

/** Удалить после миграции на сокеты ---------------------------- */

export const fxGetSocketsStatus = createEffect(userApi.isSocketsEnabled);
export const $isSocketsEnabled = createStore(false);

$isSocketsEnabled.on(
  fxGetSocketsStatus.doneData,
  (_, { is_sockets_enabled }) => is_sockets_enabled || false
);

sample({ clock: loggedIn, target: fxGetSocketsStatus });

export const fxGetTicketsStatus = createEffect(userApi.isNewTicketsEnabled);
export const $isNewTicketsEnabled = createStore(null);

$isNewTicketsEnabled
  .on(fxGetTicketsStatus.doneData, (_, { is_new_tickets_enabled = null }) =>
    is_new_tickets_enabled ? 'new' : 'old'
  )
  .reset(signout);

sample({ clock: loggedIn, target: fxGetTicketsStatus });

/** ------------------------------------------------------------- */

sample({
  clock: authSubmitFor.admin,
  target: attach({
    effect: fxAuth,
    mapParams: ({ isCompany, remember_me, ...rest }) => ({
      ...rest,
      authRequest: true,
    }),
  }),
});

sample({
  clock: authSubmitFor.company,
  target: attach({
    effect: fxAuthForCompany,
    mapParams: ({ isCompany, remember_me, ...rest }) => ({
      ...rest,
      authRequest: true,
    }),
  }),
});

sample({
  clock: [fxGetUser.fail, fxGetCompanyUser.fail],
  target: signout,
});

/** При маунте, сброс состояния "запомнить меня" */
sample({
  source: $remember_me,
  clock: appUnmounted,
  filter: (remember_me) => !remember_me,
  target: signout,
});

/** Проброс данных в localStorage и куки при логгировании */
loggedIn.watch(({ result }) => {
  const { user, provider } = result;
  if (provider) {
    setCookie('app', provider);
  }
  if (user.token) {
    setCookie('bms_token', user.token);
  }
  if (user.user_id) {
    localStorage.setItem('uid', user.user_id);
  }
  askForRecieveNotification();
});

/** Удаление токена firebase при выходе */
signout.watch(() => {
  const deleteFirebaseToken = async () => {
    await firebase.messaging().deleteToken();
  };
  deleteFirebaseToken();
  deleteAllCookie();
  localStorage.removeItem('isCompany');
});

/** Запрос новых ролей после входа. Временно отдельно, до перехода авторизации на V2  */
sample({
  clock: loggedIn,
  fn: (data) => {
    const accounts = data?.result?.accounts;

    const roleId = accounts ? accounts[0]?.role?.id : null;

    if (roleId) {
      fxGetRoleData({ role_id: roleId });
    } else {
      setAuthStatus(true);
    }
  },
});

/** Обработка данных о роли */
$roleData.on(fxGetRoleData.doneData, (_, role) => role);

/** Сброс */
reset({
  clock: signout,
  target: [
    $user,
    $data,
    $isAuthenticated,
    $remember_me,
    $isCompany,
    $isUserLoading,
    $error,
    $isSocketsEnabled,
  ],
});
