import { useGate, useUnit } from 'effector-react';
import {
  Autocomplete,
  FormControl,
  FormHelperText,
  FormLabel,
  TextField,
  CircularProgress,
} from '@mui/material';
import { Listbox } from '@features/effector-form/components/listbox';
import { RentComplexProps } from '@features/effector-form/controls/rent-complex-controls/types';
import { withControl } from '@features/effector-form/hoc/withControl';
import { RentComplexesControlGate, rentComplexesQuery } from '../model';

export const RentComplexControl = withControl<RentComplexProps>(
  ({ value, setValue, error, label, required, readOnly, disabled, city = null }) => {
    useGate(RentComplexesControlGate, { cities: city as number });

    const { data, pending } = useUnit(rentComplexesQuery);

    const val = value !== '' ? data?.find((item) => item.id === value) : null;

    return (
      <FormControl required={required} disabled={disabled}>
        <FormLabel>{label}</FormLabel>
        <Autocomplete
          loading={pending}
          value={!data ? null : val || null}
          options={data && city ? data : []}
          onChange={(_: unknown, newValue: any) => {
            setValue(newValue?.id || null);
          }}
          ListboxComponent={Listbox}
          renderInput={(params): JSX.Element => (
            <TextField
              {...params}
              InputProps={{
                ...params.InputProps,
                readOnly,
                endAdornment: readOnly ? null : (
                  <>
                    {pending && <CircularProgress size={20} color="inherit" />}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
          readOnly={readOnly}
          disabled={disabled}
        />
        <FormHelperText error>{error}</FormHelperText>
      </FormControl>
    );
  }
);
