const formatPhone = (phoneArg) => {
  let phone = '';

  if (typeof phoneArg === 'number') {
    phone = String(phoneArg);
  } else if (typeof phoneArg === 'string') {
    phone = phoneArg;
  } else return '';

  const isValid = /\d{11}/.test(phone);

  return isValid
    ? phone.replace(/(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})/g, '+$1 $2 $3 $4 $5')
    : phone;
};

export { formatPhone };
