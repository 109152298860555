import i18n from '@shared/config/i18n';
import { CloseButton } from '../../atoms';
import { Modal } from '../modal';

const { t } = i18n;

function NotificationDialog(props) {
  const {
    close,
    isOpen,
    content = t('ActionCompleted'),
    header = t('Notification'),
    ...restProps
  } = props;

  const headerElement = (
    <span style={{ display: 'flex', justifyContent: 'space-between' }}>
      <span>{header}</span>
      <CloseButton onClick={close} />
    </span>
  );

  return (
    <Modal header={headerElement} content={content} isOpen={isOpen} {...restProps} />
  );
}

export { NotificationDialog };
