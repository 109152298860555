import Divider from '@mui/material/Divider';

function CustomDivider(props) {
  const { preMargin, postMargin, margin = '20px' } = props;
  const paddingTop = preMargin || margin;
  const paddingBottom = postMargin || margin;
  return (
    <div
      style={{
        padding: `${paddingTop} 0 ${paddingBottom} 0`,
      }}
    >
      <Divider />
    </div>
  );
}

export default CustomDivider;
