import { createDomain } from 'effector';
import { nanoid } from 'nanoid';

const pushDomain = createDomain();

const push = pushDomain.createEvent();
const close = pushDomain.createEvent();

const $pushQueue = pushDomain.createStore([]);

$pushQueue
  .on(push, (state, data) => state.concat({ ...data, _id: nanoid(3) }))
  .on(close, (state, id) => state.filter((i) => i._id !== id));

export { $pushQueue, push, close, pushDomain };
