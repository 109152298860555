import FileSaver from 'file-saver';
import { Tooltip } from '@mui/material';
import DeleteForever from '@mui/icons-material/DeleteForeverOutlined';
import GetApp from '@mui/icons-material/GetApp';
import i18n from '@shared/config/i18n';
import { File } from '../file';

const { t } = i18n;

const style = {
  container: {
    position: 'relative',
    display: 'inline-block',
  },
  iconContainer: {
    position: 'absolute',
    top: 0,
    right: 0,
    zIndex: 9,
  },
  icon: {
    color: '#fff',
    background: 'rgba(0,0,0,.5)',
    margin: '5px',
    cursor: 'pointer',
  },
};

export const ActionFileWrapper = (props) => {
  const { deleteFn } = props;
  const { url, name } = props;
  const save = () => {
    FileSaver.saveAs(url, name);
  };

  return (
    <div style={style.container}>
      <div style={style.iconContainer}>
        <Tooltip title={t('Download')} placement="bottom">
          <GetApp style={style.icon} onClick={save} />
        </Tooltip>
        {deleteFn && (
          <Tooltip title={t('remove')} placement="bottom">
            <DeleteForever
              style={style.icon}
              onClick={typeof deleteFn === 'function' ? deleteFn : () => null}
            />
          </Tooltip>
        )}
      </div>
      <File {...props} />
    </div>
  );
};
