import format from 'date-fns/format';
import FileSaver from 'file-saver';
import i18n from '@shared/config/i18n';

const { t } = i18n;

export const stringToHtml = (text) => {
  const textArray = text.split('\n');
  const wrapper = document.createElement('div');
  const HTMLText = document.createElement('p');

  textArray.forEach((text) => {
    if (text) {
      const textNode = document.createTextNode(text);
      HTMLText.append(textNode);
      HTMLText.append(document.createElement('br'));
    } else {
      HTMLText.append(document.createElement('br'));
    }
  });

  wrapper.append(HTMLText);

  return wrapper.innerHTML;
};

export const saveQrPdf = (data) => {
  const blob = new Blob([data], {
    type: 'application/pdf',
  });

  const dateTimeNow = format(new Date(), 'dd.MM.yyyy-HH.mm.ss');
  FileSaver.saveAs(URL.createObjectURL(blob), `qr-codes-${dateTimeNow}.pdf`);
};

export const matchQrStatus = (flat) => {
  const qr_not_formed = {
    text: t('QRCodeNotGenerated'),
    backgroundColor: '#E7E7EC',
    textColor: '#828282',
  };
  const qr_formed = {
    text: t('QRCodeGenerated'),
    backgroundColor: '#0394E3',
    textColor: '#fff',
  };
  const flat_attached = {
    text: t('TheApartmentIsTied'),
    backgroundColor: '#1BB169',
    textColor: '#fff',
  };
  const not_defined = {
    text: t('isNotDefinedit'),
    backgroundColor: '#AAAAAA',
    textColor: '#fff',
  };

  if (!flat) return qr_not_formed;
  if (flat?.status === 'created') return qr_not_formed;
  if (flat?.status === 'printed') return qr_formed;
  if (flat?.status === 'succeeded') return flat_attached;
  return not_defined;
};
