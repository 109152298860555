import { guard, sample } from 'effector';
import { getCookieNameWithBuildType } from '@tools/cookie';
import { appMounted } from '../../common/model/app';
import { $pathname } from '../../common/model/routing';
import { sendUrl } from '../api';
import {
  fxSendSection,
  visitedEvent,
  updateSectionName,
  $prevSectionName,
} from './statistics.model';

$prevSectionName.on(updateSectionName, (_, { section }) => section);

fxSendSection.use(sendUrl);

sample({
  clock: [appMounted, $pathname],
  source: $pathname,
  target: visitedEvent,
});

guard({
  source: sample({
    clock: visitedEvent,
    source: {
      prevSection: $prevSectionName,
    },
    fn: handlePayload,
  }),
  filter: handleFilter,
  target: [fxSendSection, updateSectionName],
});

function handleFilter(params) {
  if (!params.section || params.section === 'auth') {
    return false;
  }

  const cookieMap = new Map(
    document.cookie.split(';').map((cookie) => cookie.trim().split('=', 2))
  );
  const tokenName = getCookieNameWithBuildType('bms_token');
  const token = cookieMap.get(tokenName);

  return token && !params.prevSection;
}

function handlePayload(sourceData, clockData) {
  const { prevSection } = sourceData;
  const section = clockData.split('/')[1];
  return {
    ...(prevSection !== section
      ? {
          section,
        }
      : {
          ...sourceData,
          section,
        }),
  };
}
