import { Paper, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Container = styled(Paper)(() => ({
  position: 'relative',
  display: 'grid',
  gap: 20,
  backgroundColor: '#FFFFFF',
  width: '350px',
  overflow: 'hidden',
  padding: 24,
}));

export const Title = styled(Typography)(() => ({
  fontWeight: 700,
  color: '#3B3B50',
}));

export const Form = styled('form')(() => ({
  display: 'grid',
  gap: 15,
}));

export const Checkboxes = styled('div')(() => ({
  display: 'grid',
  gap: 5,
}));
