import i18n from '@shared/config/i18n';
import Modal from '../molecules/Modal';

const { t } = i18n;

function ErrorMessage(props) {
  let { isOpen, onClose, error } = props;

  if (Array.isArray(error)) {
    error = error.map((item, index) => (
      <span key={`err-${index}`}>{typeof item === 'object' ? item.message : item}</span>
    ));
  } else if (
    error !== null &&
    typeof error === 'object' &&
    Object.prototype.hasOwnProperty.call(error, 'message')
  ) {
    error = <span> {error.message} </span>;
  } else if (typeof error === 'string') {
    error = <span> {error} </span>;
  } else {
    error = t('FatalError');
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      header={t('AnErrorHasOccurred')}
      content={error}
    />
  );
}

export default ErrorMessage;
