import { createEffect, sample } from 'effector';
import { createGate } from 'effector-react';
import { cache, createQuery, localStorageCache } from '@farfetched/core';
import { signout } from '@features/common';
import { citiesApi } from '@features/effector-form/controls/city-controls/api';
import { CitiesResponse } from '@features/effector-form/controls/city-controls/types';

export const CitiesControlGate = createGate();

const fxGetCities = createEffect<void, CitiesResponse, Error>().use(citiesApi.getCities);

export const citiesQuery = createQuery({
  effect: fxGetCities,
  mapData: ({ result }) =>
    result.items.map((city) => ({
      id: city.id,
      label: city.title,
    })),
});

cache(citiesQuery, {
  adapter: localStorageCache({ maxAge: '5m' }),
  purge: signout,
});

sample({
  clock: CitiesControlGate.state,
  target: citiesQuery.start,
});
