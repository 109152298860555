import PropTypes from 'prop-types';
import { FormHelperText, Grid } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { useFormControl, formControlState } from '../..';

const styles = () => {
  return {
    readOnly: {
      display: 'none',
    },
  };
};

const BaseInputErrorText = (props) => {
  const { classes, errors = [], ...rest } = props;

  const contextProps = useFormControl();

  const fcs = formControlState({
    props,
    ctxProps: contextProps,
    states: ['readOnly', 'error', 'required', 'disabled'],
  });

  const hasErrors = errors.length > 0;

  return !fcs.readOnly && hasErrors ? (
    <FormHelperText component="div" {...fcs} {...rest}>
      <Grid container direction="column">
        {errors.map((error, idx) => {
          return (
            <Grid key={error} item component="span">
              {error}
            </Grid>
          );
        })}
      </Grid>
    </FormHelperText>
  ) : null;
};

const StyledBaseInputErrorText = withStyles(styles)(BaseInputErrorText);

BaseInputErrorText.propTypes = {
  errors: PropTypes.arrayOf(PropTypes.string),
  readOnly: PropTypes.bool,
};

export { StyledBaseInputErrorText as BaseInputErrorText };
