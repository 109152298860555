import * as React from 'react';
import { FormControl, FormHelperText, FormLabel, TextField } from '@mui/material';
import Autocomplete, { AutocompleteProps } from '@mui/material/Autocomplete';
import { Listbox } from '@features/effector-form/components/listbox';
import { withControl } from '@features/effector-form/hoc/withControl';

export const ResidentStatusControl = withControl<
  Partial<AutocompleteProps<any, any, any, any>>
>(({ value, setValue, error, label, required, readOnly, disabled, multiple }) => {
  const options = [
    { id: 'owner', label: 'Собственник' },
    { id: 'dweller', label: 'Житель' },
    { id: 'renter', label: 'Арендатор' },
    { id: 'user', label: 'Пользователь' },
    { id: 'family_member', label: 'Член семьи' },
    { id: 'child', label: 'Ребёнок' },
  ];

  const handleChange = (event: any, newValue: any) => {
    if (multiple) {
      setValue(newValue.map((item: any) => item.id) || null);
    } else {
      setValue(newValue?.id || null);
    }
  };

  const getValue = () =>
    multiple
      ? options.filter((item) => value?.indexOf(item.id) > -1)
      : options.find((item) => item.id === value);

  return (
    <FormControl required={required} disabled={disabled}>
      {label ? <FormLabel>{label}</FormLabel> : null}

      <Autocomplete
        value={getValue()}
        options={!options ? [] : options}
        onChange={handleChange}
        ListboxComponent={Listbox}
        renderInput={(params) => (
          <TextField
            {...params}
            InputProps={{
              ...params.InputProps,
              required,
              readOnly,
              disabled,
            }}
          />
        )}
        readOnly={readOnly}
        disabled={disabled}
        multiple={multiple}
      />

      {error ? <FormHelperText error>{error}</FormHelperText> : null}
    </FormControl>
  );
});
