import { forwardRef, cloneElement } from 'react';
import PropTypes from 'prop-types';

const FieldComponent = forwardRef((props, ref) => {
  const { field = {}, form = {}, component, innerRef, ...rest } = props;

  const error = getFieldErrors(field.name, form.errors)
    ? new Array(getFieldErrors(field.name, form.errors)).flat()
    : [];

  function getFieldErrors(fieldName = '', errorObject = {}) {
    const matches = fieldName.matchAll(/\w+|\?[.*?]/g);
    const crumbs = Array.from(matches).reduce((acc, [crumb]) => acc.concat(crumb), []);

    const error = crumbs.reduce((acc, crumb) => {
      return typeof acc === 'object' &&
        acc !== null &&
        Object.prototype.hasOwnProperty.call(acc, crumb)
        ? acc[crumb]
        : [];
    }, errorObject);

    return typeof error === 'string' || Array.isArray(error)
      ? new Array(error).flat()
      : [];
  }

  const hasError = error.length > 0;

  return cloneElement(component, {
    ...field,
    error: hasError,
    errors: error,
    ...component.props,
    ...rest,
    ref: ref ?? innerRef,
  });
});

FieldComponent.propTypes = {
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  component: PropTypes.element.isRequired,
};

export { FieldComponent };
