import { createTheme } from '@mui/material/styles';
import * as locales from '@mui/material/locale';
import { enUS, ruRU } from '@mui/x-data-grid-pro';

let locale = localStorage.getItem('lang') || 'en';

if (locale === 'en') locale = 'en-US';
if (locale === 'ru') locale = 'ru-RU';

const dataGridLocales = locale === 'en-US' ? enUS : ruRU;

const theme = createTheme(
  {
    palette: {
      primary: {
        main: '#0288d1',
      },
      secondary: {
        main: '#0288d1',
      },
    },
    status: {
      button: {
        basic: {
          default: '#0394E3',
          hover: '#35A9E9',
          pressed: '#0276B6',
          disabled: '#E7E7E7',
        },
        positive: {
          default: '#1BB169',
          hover: '#49C187',
          pressed: '#168E54',
          disabled: '#E7E7E7',
        },
        negative: {
          default: '#EB5757',
          hover: '#EF7979',
          pressed: '#BC4646',
          disabled: '#E7E7E7',
        },
        active: {
          default: '#0394E3',
          hover: '#35A9E9',
          pressed: '#0276B6 ',
          disabled: '#E7E7E7',
        },
        'no-active': {
          default: '#f2f2f2',
          hover: '#f2f2f2',
          pressed: '#f2f2f2 ',
          disabled: '#f2f2f2',
        },
        dark: {
          default: '#3B3B50',
          hover: '#3B3B50',
          pressed: '#3B3B50',
          disabled: '#3B3B50',
        },
      },
      label: {
        active: '#aaa',
      },
      input: {
        text: '#65657B',
        default: '#e7e7ec',
        hover: '#35A9E9',
        active: '#0276B6',
      },
    },
    typography: {
      button: {
        textTransform: 'none',
      },
      fontFamily: [
        'Roboto',
        '-apple-system',
        'BlinkMacSystemFont',
        'Segoe UI',
        'Arial',
        'sans-serif',
      ].join(','),
      useNextVariants: true,
    },
    variant: 'outlined',
    components: {
      MuiOutlinedInput: {
        defaultProps: {
          notched: true,
        },
      },
      MuiLink: {
        defaultProps: {
          underline: 'hover',
        },
      },
      MuiDataGrid: {
        styleOverrides: {
          root: {
            border: 0,
            '& .super-app-theme--header': {
              color: '#7D7D8E',
              padding: 5,
            },

            '& .MuiDataGrid-main': {
              flex: '1 1 0px'
            },

            '& .MuiDataGrid-columnHeaders': {
              zIndex: '255',
              backgroundColor: 'white'
            },

            '& *::-webkit-scrollbar': {
              width: 10,
              height: 10,
            },

            '& *::-webkit-scrollbar-track': {
              backgroundColor: 'transparent',
            },

            '& *::-webkit-scrollbar-thumb': {
              backgroundColor: 'rgba(0, 0, 0, 0.2)',
              borderRadius: 6,
            },
            '& .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within': {
              outline: 'none',
            },
            '& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-columnHeader:focus-within':
              {
                outline: 'none',
              },
            '& .MuiDataGrid-columnHeader': {
              color: '#7D7D8E',
              padding: '5px',
            },
            '& .MuiDataGrid-row.Mui-selected, & .MuiDataGrid-row.Mui-selected:hover': {
              background: 'rgb(225, 235, 255)',
            },
          },
        },
      },
    },
  },
  dataGridLocales
);

const themeWithLocale = createTheme(theme, locales[locale]);

export { themeWithLocale as theme };
