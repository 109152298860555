import { createEffect, sample } from 'effector';
import { createGate } from 'effector-react';
import { cache, createQuery } from '@farfetched/core';
import { TicketStatusResponse } from '@features/effector-form/controls/ticketStatus/types';
import { getTicketStatuses } from './api';

export const TicketStatusControlGate = createGate();

const fxGetTicketStatuses = createEffect<void, TicketStatusResponse, Error>().use(
  getTicketStatuses
);

export const ticketStatusesQuery = createQuery({
  name: 'ticketStatuses',
  effect: fxGetTicketStatuses,
  mapData: ({ result, params }) =>
    result.statuses.map((status) => ({
      id: status.name,
      label: status.title,
    })),
});

cache(ticketStatusesQuery);

sample({
  clock: TicketStatusControlGate.status,
  target: ticketStatusesQuery.start,
});
