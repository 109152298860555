import { useEffect } from 'react';
import { connect } from 'react-redux';
import i18n from '@shared/config/i18n';
import { getEntrance } from '../../redux/actions/objects';
import DropdownInput from './DropdownInput';

const { t } = i18n;

function EntrancesSelect(props) {
  const { getEntrance, entrances, buildId, field, onChange, mode, ...restProps } = props;

  useEffect(() => {
    getEntrance(buildId);
  }, [buildId]);

  const formatedEntrances = entrances.map((entrance) => ({
    id: entrance.id,
    title: entrance.number === 0 ? '0' : entrance.number,
  }));

  const specialStyles = {
    container: (styles) =>
      mode === 'view'
        ? { ...styles, width: '100%', display: 'none' }
        : { ...styles, width: '100%' },
  };

  return (
    <DropdownInput
      label={t('rizer')}
      placeholder={t('ChooseEntrance')}
      options={formatedEntrances}
      mode={mode}
      field={field}
      onChange={(e) => onChange(field.name, e)}
      specialStyles={specialStyles}
      {...restProps}
    />
  );
}

const mapStateToProps = (state) => ({
  entrances: state.objects.entrances,
});

const mapDispatchToProps = (dispatch) => ({
  getEntrance: (id) => dispatch(getEntrance(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EntrancesSelect);
