import { useCallback } from 'react';
import { useIMask } from 'react-imask';
import { FormControl, FormHelperText, FormLabel, OutlinedInput } from '@mui/material';
import { withControl } from '@features/effector-form/hoc/withControl';

export const PhoneControl = withControl(
  ({ value, setValue, error, label, required, readOnly, disabled }) => {
    const handleAccept = useCallback(
      (newValue: string) => {
        if (newValue !== value) {
          setValue(newValue);
        }
      },
      [value]
    );

    const handleComplete = useCallback(
      (newValue: string) => {
        setValue(newValue);
      },
      [value]
    );

    const { ref } = useIMask(
      { mask: '+{7}0000000000' },
      {
        onAccept: handleAccept,
        onComplete: handleComplete,
      }
    );

    return (
      <FormControl required={required} disabled={disabled}>
        <FormLabel>{label}</FormLabel>
        <OutlinedInput
          inputRef={ref}
          value={value}
          readOnly={readOnly}
          disabled={disabled}
        />
        <FormHelperText error>{error}</FormHelperText>
      </FormControl>
    );
  }
);
