import { combineReducers } from 'redux';
import {
  request,
  requestSuccess,
  requestFail,
  changeTableKind,
  changeQrModalVisibility,
  changeReprintStatus,
  createPdf,
  createPdfSuccess,
  createPdfFail,
} from '../actions/objects';
import { reducers } from '../symbiotes/objects';
import { fetchingReducer, errorReducer, noopReducer } from './commonReducers';

const kind = noopReducer(changeTableKind, 'buildings');
const isQrModalOpen = noopReducer(changeQrModalVisibility, false);
const isReprint = noopReducer(changeReprintStatus, false);

const isFetching = fetchingReducer(
  [request, createPdf],
  [requestSuccess, requestFail, createPdfSuccess, createPdfFail],
  false
);
const error = errorReducer([requestFail, createPdfFail], [request, createPdf], null);

const objects = combineReducers({
  error,
  isFetching,
  kind,
  isQrModalOpen,
  isReprint,
  ...reducers,
});

export default objects;
