import classNames from 'classnames';
import FormControlLabel from '@mui/material/FormControlLabel';
import withStyles from '@mui/styles/withStyles';

const styles = {
  root: {
    display: 'flex',
    flexGrow: '1',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    margin: '0',
    cursor: 'pointer',
    position: 'relative',
  },

  label: {
    zIndex: 13,
    fontSize: '14px',
  },

  positive: {
    color: '#0394E3',
    display: 'flex',
    '&:hover': {
      color: '#35A9E9',
    },
  },

  default: {
    margin: '10px 0',
    color: '#65657B',
    '&:hover': {
      color: '#65657B',
    },
  },
};

const DefaultControlLabel = (props) => {
  const { control, label, classes, kind = 'default', ...restProps } = props;

  return (
    <FormControlLabel
      control={control}
      label={label}
      classes={{
        label: classNames(classes.label, classes[kind]),
        root: classNames(classes.root),
      }}
      {...restProps}
    />
  );
};

function FormControl(props) {
  const { ControlLabel = DefaultControlLabel, ...restProps } = props;
  return <ControlLabel {...restProps} />;
}

export default withStyles(styles)(FormControl);
