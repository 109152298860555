export default class ApiV2 {
  constructor(host, app) {
    this._host = host;
    this._app = app;
  }

  host() {
    return this._host;
  }

  app() {
    return this._app;
  }
}
