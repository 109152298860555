import { api } from '@api/api2';
import { RentComplexPayload } from '@features/effector-form/controls/rent-complex-controls/types';

const getRentComplexes = (payload: RentComplexPayload) =>
  api.v4('get', 'area/objects/simple/list', {
    ...payload,
    ...{ fast_filters: { is_complex: 1 } },
  });

export const rentComplexesApi = { getRentComplexes };
