import React, { forwardRef, Ref, MouseEvent } from 'react';
import classNames from 'classnames';
import { Fab } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

interface Kind {
  basic: string;
  positive: string;
  negative: string;
  active: string;
  'no-active': string;
  dark: string;
}

type KindKeys = keyof Kind;

const useStyles = makeStyles((theme: any) => {
  const { button } = theme.status;
  return {
    root: {
      zIndex: 'auto',
      width: (props: { iconSize: number | string }) => props.iconSize,
      height: (props: { iconSize: number | string }) => props.iconSize,
      minHeight: (props: { iconSize: number | string }) => props.iconSize,
      minWidth: (props: { iconSize: number | string }) => props.iconSize,
    },
    margin: {
      marginRight: '10px',
    },
    basic: {
      backgroundColor: button.basic.default,
      color: '#ffffff !important',
      borderRadius: 17,
      '&:hover': {
        backgroundColor: button.basic.hover,
      },
      '&:pressed': {
        backgroundColor: button.basic.pressed,
      },
      '&:active': {
        backgroundColor: button.basic.active,
      },
      '&:disabled': {
        backgroundColor: button.basic.disabled,
      },
    },
    positive: {
      backgroundColor: button.positive.default,
      color: '#ffffff',
      borderRadius: 17,
      '&:hover': {
        backgroundColor: button.positive.hover,
      },
      '&:pressed': {
        backgroundColor: button.positive.pressed,
      },
      '&:active': {
        backgroundColor: button.positive.active,
      },
      '&:disabled': {
        backgroundColor: button.positive.disabled,
      },
    },
    negative: {
      backgroundColor: button.negative.default,
      color: '#ffffff',
      borderRadius: 17,
      '&:hover': {
        backgroundColor: button.negative.hover,
      },
      '&:pressed': {
        backgroundColor: button.negative.pressed,
      },
      '&:active': {
        backgroundColor: button.negative.active,
      },
      '&:disabled': {
        backgroundColor: button.negative.disabled,
      },
    },
    active: {
      backgroundColor: button.active.default,
      boxShadow: 'none',
      color: '#ffffff',
      borderRadius: 17,
      '&:hover': {
        backgroundColor: button.active.hover,
      },
      '&:pressed': {
        backgroundColor: button.active.pressed,
      },
      '&:active': {
        backgroundColor: button.active.active,
      },
      '&:disabled': {
        backgroundColor: button.active.disabled,
      },
    },
    'no-active': {
      backgroundColor: button.active['no-active'],
      boxShadow: 'none',
      color: '#ffffff',
      borderRadius: 17,
      '&:hover': {
        backgroundColor: button.active['no-active'],
      },
      '&:pressed': {
        backgroundColor: button.active['no-active'],
      },
      '&:active': {
        backgroundColor: button.active['no-active'],
      },
      '&:disabled': {
        backgroundColor: button.active['no-active'],
      },
    },
    dark: {
      backgroundColor: button.dark.default,
      boxShadow: 'none',
      color: '#ffffff',
      borderRadius: 17,
      '&:hover': {
        backgroundColor: button.dark.hover,
      },
      '&:pressed': {
        backgroundColor: button.dark.pressed,
      },
      '&:active': {
        backgroundColor: button.dark.active,
      },
      '&:disabled': {
        backgroundColor: button.dark.disabled,
      },
    },
  };
});

interface Props {
  kind?: KindKeys;
  active?: boolean;
  withMargin?: boolean;
  onClick?: () => void;
  children?: JSX.Element;
  style?: object;
  type?: 'submit' | 'reset' | 'button';
  iconSize?: number | string;
  disabled?: boolean;
}

const ActionIconButton = forwardRef((props: Props, ref: Ref<HTMLButtonElement>) => {
  const {
    kind = 'basic',
    active = false,
    withMargin = false,
    children,
    onClick = () => null,
    iconSize = '34px',
    ...rest
  } = props;

  const rootClasses = useStyles({ iconSize });

  return (
    <Fab
      ref={ref}
      onClick={onClick}
      className={classNames({ [rootClasses.active]: active })}
      classes={{
        root: classNames(rootClasses[kind], rootClasses.root, {
          [rootClasses.margin]: withMargin,
        }),
      }}
      disableRipple
      {...rest}
    >
      {children}
    </Fab>
  );
});

export { ActionIconButton };
