import PropTypes from 'prop-types';
import { List } from '@mui/material';
import withStyles from '@mui/styles/withStyles';

const styles = {
  container: {
    overflowY: 'auto',
    height: '80vh',
    padding: 0,
  },
};
function FeedList({ children, classes, ...props }) {
  return (
    <List classes={{ root: classes.container }} {...props}>
      {children}
    </List>
  );
}

FeedList.propTypes = {
  children: PropTypes.node.isRequired,
};

const StyledFeedList = withStyles(styles)(FeedList);

export { StyledFeedList as FeedList };
