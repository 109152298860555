import { useCallback, memo } from 'react';
import { connect } from 'react-redux';
import {
  filterChangeVisibility,
  detailChangeVisibility,
  openCloseEntity,
  detailChangeMode,
  search,
  sortBy,
  dialogChangeVisibility,
  changeColumnsOrder,
  changeColumnWidths,
  changeTableKind,
  deleteObject,
  selectRows,
  changeQrModalVisibility,
  createQRPDF,
  changeReprintStatus,
} from '../../../../redux/actions/objects';
import { Loader, Wrapper, DeleteConfirmDialog } from '../../../../ui';
import ErrorMessage from '../../../atoms/ErrorMessage';
import { QrModal } from '../qr-modal';
import ObjectsTable from './ObjectsContentTable';
import ObjectsTabs from './ObjectsContentTabsbar';
import ObjectsToolbar from './ObjectsContentToolbar';

const ObjectsContent = memo((props) => {
  const {
    isLoading,
    errorDialogIsOpen,
    openCloseDialog,
    error,
    changeTableKind,
    tableKind,
    deleteDialogIsOpen,
    changeQrModalVisibility,
    isQrModalOpen,
    createQRPDF,
  } = props;

  const close = useCallback(() => openCloseDialog({ isOpen: false, kind: null }), []);

  return (
    <div style={{ height: '100%' }}>
      <QrModal
        isOpen={isQrModalOpen}
        changeVisibility={changeQrModalVisibility}
        createQRPDF={createQRPDF}
      />
      <Loader isLoading={isLoading} />
      <DeleteConfirmDialog
        close={close}
        isOpen={deleteDialogIsOpen}
        confirm={props.delete}
      />
      <ErrorMessage isOpen={errorDialogIsOpen} onClose={close} error={error} />
      <Wrapper style={{ width: '100%', height: '100%', overflow: 'hidden' }}>
        <div
          style={{
            padding: '0 24px',
            width: '100%',
            height: 'calc(100% - 72px - 20px - 34px - 15px)',
          }}
        >
          <ObjectsTabs changeTableKind={changeTableKind} tableKind={tableKind} />
          <ObjectsToolbar {...props} />
          <ObjectsTable {...props} />
        </div>
      </Wrapper>
    </div>
  );
});

const getData = (kind, state) => {
  switch (kind) {
    case 'buildings':
      return Object.values(state.houses.data);
    case 'flats':
      return Object.values(state.flats.data).map((flat) => {
        const main_account = flat?.bind_by_qr?.userdata_fullname || '-';
        const squareNumber = Number(flat.square || '0');

        return { ...flat, main_account, square: squareNumber };
      });
    case 'complexes':
      return Object.values(state.complexes.data);
    default:
      return null;
  }
};

const mapStateToProps = (state) => {
  return {
    data: getData(state.objects.kind, state),
    isOpen: state.objects.filter.isOpen,
    mode: state.objects.detail.mode,
    searchValue: state.objects.table.search,
    sortValue: state.objects.table.sort,
    filterValue: [
      {
        columnName: 'complexes',
        value: state.objects.filter.filters.complexes,
      },
    ],
    deleteDialogIsOpen:
      state.objects.dialog.isOpen && state.objects.dialog.kind === 'delete',
    errorDialogIsOpen:
      state.objects.dialog.isOpen && state.objects.dialog.kind === 'error',
    error: state.objects.error || state.complexes.error || state.houses.error,
    columnsOrder: state.objects.table.columnsOrder,
    columnWidths: state.objects.table.columnWidths,
    tableKind: state.objects.kind,
    isLoading:
      state.complexes.isFetching ||
      state.houses.isFetching ||
      state.flats.isFetching ||
      state.objects.isFetching,
    selection: state.objects.table.selection,
    isQrModalOpen: state.objects.isQrModalOpen,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    openCloseFilter: () => {
      dispatch(filterChangeVisibility(true));
    },
    add: () => {
      dispatch(detailChangeVisibility(true));
      dispatch(detailChangeMode('edit'));
      dispatch(openCloseEntity(null));
    },
    search: (input) => {
      dispatch(search(input));
    },
    delete: () => {
      dispatch(deleteObject());
    },
    onSort: (data) => {
      dispatch(sortBy([data[0]]));
    },
    openCloseDialog: (isOpen, kind) => {
      dispatch(dialogChangeVisibility(isOpen));
    },
    reorderTableColumn: (order) => {
      dispatch(changeColumnsOrder(order));
    },
    changeColumnWidths: (columnWidths) => {
      dispatch(changeColumnWidths(columnWidths));
    },
    changeTableKind: (kind) => {
      dispatch(detailChangeVisibility(false));
      dispatch(detailChangeMode('view'));
      dispatch(openCloseEntity(null));
      dispatch(changeTableKind(kind));
    },
    selectRows: (rows) => {
      dispatch(selectRows(rows));
    },
    changeQrModalVisibility: (isOpen) => {
      dispatch(changeQrModalVisibility(isOpen));
    },
    changeReprintStatus: (reprintStatus) => {
      dispatch(changeReprintStatus(reprintStatus));
    },
    createQRPDF: (values) => {
      dispatch(createQRPDF(values));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ObjectsContent);
