import { connect } from 'react-redux';
import i18n from '@shared/config/i18n';
import DropdownInput from './DropdownInput';

const { t } = i18n;

function ComplexInput(props) {
  const { complexes, onChange, ...restProps } = props;
  const field = props.field;

  const getOptionLabel = (option) => (option !== undefined ? option.title : '');
  const getOptionValue = (option) => (option !== undefined ? option.id : '');

  return (
    <DropdownInput
      label={t('RC')}
      placeholder={t('selectRC')}
      options={complexes}
      getOptionValue={getOptionValue}
      getOptionLabel={getOptionLabel}
      onChange={(e) => onChange(field.name, e)}
      {...restProps}
    />
  );
}

const mapStateToProps = (state) => ({
  complexes: Object.values(state.complexes.data),
});

export default connect(mapStateToProps)(ComplexInput);
