import { useEffect, useState } from 'react';
import {
  FormControl,
  FormHelperText,
  FormLabel,
  IconButton,
  OutlinedInput,
  Typography,
} from '@mui/material';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import RemoveRoundedIcon from '@mui/icons-material/RemoveRounded';
import { withControl } from '@features/effector-form/hoc/withControl';
import * as Styled from './styled';

export const CountControl = withControl(
  ({ value, setValue, error, label, required, readOnly, disabled }) => {
    const [actionsVisible, setActionsVisible] = useState(false);

    const decreaseValue = () => {
      if (value !== 0) setValue(value - 1);
    };

    const increaseValue = () => {
      setValue((value as number) + 1);
    };

    const handleMouseEnter = () => {
      setActionsVisible(true);
    };

    const handleMouseLeave = () => {
      setActionsVisible(false);
    };

    const renderDecreaseButton = () =>
      !readOnly && actionsVisible ? (
        <IconButton onClick={decreaseValue} disabled={disabled}>
          <RemoveRoundedIcon fontSize="inherit" />
        </IconButton>
      ) : (
        <span></span>
      );

    const renderIncreaseButton = () =>
      !readOnly && actionsVisible ? (
        <IconButton onClick={increaseValue} disabled={disabled}>
          <AddRoundedIcon fontSize="inherit" />
        </IconButton>
      ) : (
        <span></span>
      );

    return (
      <FormControl required={required} disabled={disabled}>
        <FormLabel>{label}</FormLabel>

        <Styled.Container onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
          {renderDecreaseButton()}

          <Typography variant="subtitle1">{value}</Typography>

          {renderIncreaseButton()}
        </Styled.Container>

        <FormHelperText error>{error}</FormHelperText>
      </FormControl>
    );
  }
);
