import { createStore, createEvent } from 'effector';
import { createGate } from 'effector-react';

export const ProfileGate = createGate();

export const profileUnmounted = ProfileGate.close;
export const profileMounted = ProfileGate.open;

export const $currentPage = createStore<ProfileCurrentPage>('personal');
export const $isEditMode = createStore<boolean>(false);
export const $error = createStore<null | Error>(null);
export const $isErrorDialogOpen = createStore<boolean>(false);

export const changeCurrentPage = createEvent<ProfileCurrentPage>();
export const changedErrorDialogVisibility = createEvent<boolean>();
export const errorOccured = createEvent<any>();
export const toggleEditMode = createEvent<boolean>();
