import { api } from '@api/api2';

const getRecords = (payload = null) => {
  return api.no_vers(
    'get',
    'signal_notifications/get-signal-notification-categories',
    payload
  );
};

const getSignals = (payload) =>
  api.no_vers('get', 'signal_notifications/get-signals', {
    activ: 1, // Нужен для бэка интеграции
    ...payload,
  });

const getSignalsTypes = (payload) =>
  api.no_vers('get', 'signal_notifications/get-signal-notification-types', payload);

const setToOrFromArchive = (payload) =>
  api.no_vers('get', 'signal_notifications/update-signal-condition-map', payload);

export const recordsApi = {
  getRecords,
  getSignals,
  getSignalsTypes,
  setToOrFromArchive,
};
