import { FC } from 'react';
import { Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

interface IProps {
  titleOne: string;
  valueOne: number | string;
  titleTwo: string;
  valueTwo: number | string;
}

const useStyles = makeStyles({
  viewStatisticsPaper: {
    width: '100%',
    background: '#F4F7FA',
    borderRadius: 16,
    padding: '18px 24px 24px 24px',
  },
  paperText: {
    fontWeight: 500,
    fontSize: 18,
  },
});

const StatisticsPaper: FC<IProps> = ({ titleOne, valueOne, titleTwo, valueTwo }) => {
  const classes = useStyles();

  return (
    <div className={classes.viewStatisticsPaper}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography classes={{ root: classes.paperText }}>{titleOne}</Typography>
        <Typography classes={{ root: classes.paperText }}>{valueOne}</Typography>
      </div>

      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          paddingTop: 20,
        }}
      >
        <Typography classes={{ root: classes.paperText }}>{titleTwo}</Typography>
        <Typography classes={{ root: classes.paperText }}>{valueTwo}</Typography>
      </div>
    </div>
  );
};

export { StatisticsPaper };
