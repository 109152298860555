import { Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import NotFound from '@img/notFound.svg';
import i18n from '@shared/config/i18n';

const { t } = i18n;

const useStyles = makeStyles({
  wrapper: {
    height: 'calc(100vh - 60px - 80px)',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
  },
  image: {
    '& text': {
      fontFamily: 'Montserrat',
      fontWeight: 900,
      fontSize: 168,
      lineHeight: '205px',
    },
  },
  text: {
    fontFamily: 'Montserrat',
    fontWeight: 700,
    fontSize: 24,
    lineHeight: '32px',
    marginTop: 14,
    color: '#252834',
  },
});

export function NotFound404() {
  const { wrapper, image, text } = useStyles();

  return (
    <div className={wrapper}>
      <NotFound className={image} />
      <Typography className={text}>{t('PageNotFound')}</Typography>
    </div>
  );
}
