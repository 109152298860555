import Select, { components } from 'react-select';
import AsyncSelect from 'react-select/async';
import CreatableSelect from 'react-select/creatable';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import ArrowDropUp from '@mui/icons-material/ArrowDropUp';
import i18n from '@shared/config/i18n';
import OutlinedInput from '../atoms/OutlinedInput';
import FormSection from './FormSection';

const { t } = i18n;

function DropdownIndicator(props) {
  const { selectProps } = props;
  const Indicator = (indProps) =>
    selectProps.menuIsOpen ? (
      <ArrowDropUp {...indProps} />
    ) : (
      <ArrowDropDown {...indProps} />
    );
  return (
    <components.DropdownIndicator {...props}>
      <Indicator style={{ cursor: 'pointer' }} />
    </components.DropdownIndicator>
  );
}

function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />;
}

function Control(props) {
  const { children, innerProps, error, selectProps } = props;

  return (
    <OutlinedInput
      inputComponent={inputComponent}
      inputProps={{
        children,
        name: selectProps.name,
        ...innerProps,
      }}
      name={selectProps.name}
      error={error || selectProps.error}
      {...selectProps.textFieldProps}
    />
  );
}

function DropdownInput(props) {
  const {
    components = {},
    label = t('EntryField'),
    placeholder,
    divider = true,
    mode,
    name,
    value,
    field = {},
    kind = 'usual',
    isMulti,
    specialStyles = {},
    ...restProps
  } = props;

  const defaultStyles = {
    placeholder: (styles) => ({ ...styles, color: 'rgba(101,101,123,.5)' }),
    select: (styles) => ({ ...styles, width: '100%' }),
    container: (styles) => ({ ...styles, width: '100%' }),
    input: (styles) => ({
      ...styles,
      color: '#65657B',
      fontWeight: '500',
      padding: '0',
      width: '100%',
    }),
    valueContainer: (styles) => ({ ...styles, height: 'auto', padding: '0' }),
    singleValue: (styles) => ({ ...styles, color: '#65657B' }),
    menu: (styles) => ({
      ...styles,
      position: 'relative',
      top: '0',
      margin: '5px',
      width: 'calc(100%-5px)',
    }),
    clearIndicator: (styles) => ({ ...styles, cursor: 'pointer' }),
  };

  const getOptionLabel = (option) =>
    option && (option.title || option.label || option.value || option.id);
  const getOptionValue = (option) =>
    option && (option.id || option.value || option.label);

  return (
    <FormSection
      label={label}
      placeholder={placeholder}
      divider={divider}
      noOptionsMessage={() =>
        kind === 'creat' ? t('StartTyping') : t('NoOptionsToChooseFrom')
      }
      components={{ Control, DropdownIndicator, ...components }}
      ControlComponent={
        kind === 'async' ? AsyncSelect : kind === 'creat' ? CreatableSelect : Select
      }
      {...(kind === 'creat' && mode === 'edit'
        ? { formatCreateLabel: (userInput) => `Создать ${userInput}` }
        : {})}
      styles={{ ...defaultStyles, ...specialStyles }}
      {...(mode !== 'view' ? { isMulti } : {})}
      getOptionValue={getOptionValue}
      getOptionLabel={getOptionLabel}
      {...restProps}
      mode={mode}
      name={name || field.name}
      value={value || field.value}
      error={restProps.form && Boolean(restProps.form.errors[name || field.name])}
    />
  );
}

export default DropdownInput;
