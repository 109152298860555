import axios from 'axios';

export class HttpRequest {
  constructor() {
    this._host = '';
    this._endpoint = '';
    this._method = 'GET';
    this._data = {};
    this._params = {};
    this._headers = {};
  }

  static make() {
    return new HttpRequest();
  }

  host(host) {
    this._host = host.replace(/\/+$/, '');
    return this;
  }

  endpoint(endpoint) {
    this._endpoint = endpoint.replace(/^\/+/, '');
    return this;
  }

  method(method) {
    this._method = method.toUpperCase();
    return this;
  }

  data(data, value = null) {
    if (value === null) {
      // Set the whole object
      this._data = data;
    } else {
      // Use data var as key
      this._data[data] = value;
    }
    return this;
  }

  params(params, value = null) {
    if (value === null) {
      this._params = params;
    } else {
      this._params[params] = value;
    }

    return this;
  }

  appendParams(params) {
    this._params = { ...this._params, ...params };
    return this;
  }

  appendData(data) {
    this._data = { ...this._data, ...data };
    return this;
  }

  header(key, value) {
    this._headers[key] = value;
    return this;
  }

  when(statement, callback, elseCallback = null) {
    if (statement) {
      callback(this);
    } else if (elseCallback !== null) {
      elseCallback(this);
    }
    return this;
  }

  async send() {
    let data = this._data;
    if (
      Object.prototype.hasOwnProperty.call(this._headers, 'Content-Type') &&
      this._headers['Content-Type'] === 'multipart/form-data'
    ) {
      data = objectToFormData(this._data);
    }

    const endpoint = this._host.length > 0 ? '/' + this._endpoint : this._endpoint;

    return await axios.request({
      method: this._method,
      url: this._host + endpoint,
      data,
      params: this._params,
      headers: this._headers,
      validateStatus: (status) => {
        return true; // axios will throw an error otherwise
      },
    });
  }
}

function objectToFormData(object, rootName, ignoreList) {
  const formData = new FormData();

  function appendFormData(data, root) {
    if (!ignore(root)) {
      const tempRoot = root || '';
      if (data instanceof File) {
        formData.append(tempRoot, data);
      } else if (Array.isArray(data)) {
        for (const [i, element] of data.entries()) {
          appendFormData(element, tempRoot + '[' + i + ']');
        }
      } else if (typeof data === 'object' && data) {
        for (const key in data) {
          if (Object.prototype.hasOwnProperty.call(data, key)) {
            if (tempRoot === '') {
              appendFormData(data[key], key);
            } else {
              appendFormData(data[key], tempRoot + '[' + key + ']');
            }
          }
        }
      } else if (data !== null && typeof data !== 'undefined') {
        formData.append(tempRoot, data);
      }
    }
  }

  function ignore(root) {
    return (
      Array.isArray(ignoreList) &&
      ignoreList.some((x) => {
        return x === root;
      })
    );
  }

  appendFormData(object, rootName);

  return formData;
}
