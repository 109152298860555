import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
  content: {
    color: '#65657B',
    '& > ul': {
      listStyleType: 'disc',
      marginLeft: '40px',
    },
  },
});

const EditorContent = (props) => {
  const { markup } = props;
  const { content } = useStyles();

  const createMarkup = () => ({ __html: markup });
  return <div className={content} dangerouslySetInnerHTML={createMarkup()} />;
};

export { EditorContent };
