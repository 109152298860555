export default class ApiToken {
  constructor(token) {
    this.token = token;
  }

  credentials() {
    return {
      type: 'api-token',
      token: this.token,
    };
  }
}
