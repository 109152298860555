import PropTypes from 'prop-types';
import Sort from '@mui/icons-material/Sort';

const SortingIcon = (props) => {
  const { direction } = props;

  if (direction === 'asc') {
    return <Sort data-test-id="sort-icon" style={{ transform: 'scale(1, -1)' }} />;
  }
  if (direction === 'desc') {
    return <Sort data-test-id="sort-icon" />;
  }
  return <Sort data-test-id="sort-icon" />;
};

SortingIcon.propTypes = {
  direction: PropTypes.oneOf(['asc', 'desc']),
};

export { SortingIcon };
