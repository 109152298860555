import PropTypes from 'prop-types';
import { Grid, Avatar } from '@mui/material';

export const MessageAvatar = (props) => {
  const { initials, url, styles } = props;

  return (
    <Grid item style={styles.wrapper}>
      <Avatar src={url} style={styles.avatar}>
        {initials}
      </Avatar>
    </Grid>
  );
};

MessageAvatar.propTypes = {
  initials: PropTypes.string,
  url: PropTypes.string,
  styles: PropTypes.shape({
    wrapper: PropTypes.object,
    avatar: PropTypes.object,
  }),
};
