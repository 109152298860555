import { createEffect, sample } from 'effector';
import { createGate } from 'effector-react';
import { cache, createQuery } from '@farfetched/core';
import { TicketPriorityResponse } from '@features/effector-form/controls/ticketPriority/types';
import { getTicketPriorities } from './api';

export const TicketPriorityControlGate = createGate();

const fxGetTicketPriorities = createEffect<void, TicketPriorityResponse, Error>().use(
  getTicketPriorities
);

export const ticketPrioritiesQuery = createQuery({
  name: 'ticketPriorities',
  effect: fxGetTicketPriorities,
  mapData: ({ result, params }) =>
    result.priorities.map((status) => ({
      id: status.name,
      label: status.title,
    })),
});

cache(ticketPrioritiesQuery);

sample({
  clock: TicketPriorityControlGate.status,
  target: ticketPrioritiesQuery.start,
});
