import { SelectControl, FieldComponent } from '../..';

const SelectField = (props) => {
  const { field, form, component = <SelectControl />, ...rest } = props;

  return (
    <FieldComponent
      field={field}
      form={form}
      component={component}
      onChange={(value) => form.setFieldValue(field.name, value)}
      {...rest}
    />
  );
};

export { SelectField };
