import { useRef } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { Close } from '@mui/icons-material';
import ImportIcon from '@mui/icons-material/VerticalAlignTop';
import makeStyles from '@mui/styles/makeStyles';
import i18n from '@shared/config/i18n';
import { Modal, InputField, ActionButton } from '../../../../ui';

const { t } = i18n;

const useStyles = makeStyles({
  modal: {
    width: 100,
    height: 100,
    background: 'red',
  },
  header: {
    marginBottom: 25,
    fontSize: 24,
    fontWeight: 700,
    display: 'flex',
  },
  header_close: {
    marginLeft: 'auto',
    cursor: 'pointer',
  },
  button: {
    marginRight: 'auto',
    '& button': {
      background: '#1BB169',
    },
  },
  buttonContent: {
    marginRight: 'auto',
    padding: '0 7px',
    '& > span': {
      marginLeft: 5,
    },
  },
  form: {
    '& > div': {
      marginBottom: 20,
    },
  },
  text: {
    '& textarea': {
      overflow: 'hidden',
    },
  },
});

const formValues = {
  title: t('Add your apartment to your account in the app.'),
  text: `${t('ToLinkAnApartment')}\n${t('InTheWindowThatOpensSelectAnOption')}\n${t(
    'TheQRCodeForAdding'
  )}
    `,
};

const QrModalHeader = ({ changeVisibility, classes }) => (
  <div className={classes.header}>
    <span>{t('CustomizingTextForPrinting')}</span>
    <div className={classes.header_close} onClick={() => changeVisibility(false)}>
      <Close />
    </div>
  </div>
);

const validationSchema = Yup.object().shape({
  title: Yup.string().required(t('thisIsRequiredField')),
  text: Yup.string().required(t('thisIsRequiredField')),
});

const QrModalContent = ({ classes, formRef, createQRPDF }) => (
  <Formik
    onSubmit={createQRPDF}
    initialValues={formValues}
    ref={formRef}
    validationSchema={validationSchema}
  >
    <Form className={classes.form}>
      <Field
        name="title"
        component={InputField}
        label={t('TheHeadlineOfMessage')}
        required
        mode="edit"
        divider={false}
      />
      <div className={classes.text}>
        <Field
          name="text"
          component={InputField}
          label={t('MessageText')}
          required
          multiline
          rows={6}
          rowsMax={6}
          mode="edit"
          divider={false}
        />
      </div>
    </Form>
  </Formik>
);

const QrModalActions = ({ classes, submit }) => (
  <div className={classes.button} onClick={submit}>
    <ActionButton>
      <div className={classes.buttonContent}>
        <ImportIcon />
        <span>{t('Download')}</span>
      </div>
    </ActionButton>
  </div>
);

export const QrModal = ({ isOpen, changeVisibility, createQRPDF }) => {
  const classes = useStyles();
  const formRef = useRef();

  const submit = () => {
    if (formRef.current) {
      formRef.current.submitForm();
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => changeVisibility(false)}
      header={<QrModalHeader changeVisibility={changeVisibility} classes={classes} />}
      content={
        <QrModalContent classes={classes} formRef={formRef} createQRPDF={createQRPDF} />
      }
      actions={<QrModalActions classes={classes} submit={submit} />}
    />
  );
};
