import { useStore } from 'effector-react';
import { Badge, MenuList, Button } from '@mui/material';
import { Notifications as NotificationsIcon } from '@mui/icons-material';
import makeStyles from '@mui/styles/makeStyles';
import {
  $notifications,
  $unreadCount,
  fxSetAllAsReadNotifications,
  $isUserLoading,
  $isNotificationsLoading,
} from '@features/common';
import i18n from '@shared/config/i18n';
import { Popover, IconButton, CustomScrollbar, Loader, Empty } from '@ui/';
import { NotificationsItem } from './organisms';

const useStyles = makeStyles((theme) => {
  const { palette, status } = theme;
  const badgeColor = status.button.negative.default;

  return {
    badge: {
      background: '#1C90FB',
      color: palette.getContrastText(badgeColor),
      right: -7,
      top: -2,
      width: 27,
      height: 20,
      minWidth: 13,
      minHeight: 13,
      fontSize: 12,
    },
    menu: {
      zIndex: 25,
      maxHeight: 340,
      padding: 0,
    },
    button: {
      borderRadius: 4,
    },
    notificationsIcon: {
      color: '#F50057',
    },
  };
});

const { t } = i18n;

const Notifications = () => {
  const unreadCount = useStore($unreadCount);
  const isUserLoading = useStore($isUserLoading);
  const isNotificationsLoading = useStore($isNotificationsLoading);
  const isLoading = isUserLoading || isNotificationsLoading;
  const notifications = useStore($notifications);
  const classes = useStyles();

  const renderContent = () => {
    if (isLoading) return <Loader isLoading fixed />;
    else if (notifications.length === 0 && !isLoading)
      return <Empty title={t('NoNotifications')} />;

    const menuItems = notifications.map((i, key) => (
      <NotificationsItem
        key={key}
        datetime={i.sent_at}
        description={i.body}
        event={i.event}
        title={i.title}
        data={i.data}
        isRead={Boolean(i.read_at)}
        id={i.id}
      />
    ));

    return (
      <>
        <MenuList classes={{ root: classes.menu }}>
          <CustomScrollbar
            style={{ height: '315px', maxHeight: '400px', width: '500px' }}
          >
            {menuItems}{' '}
          </CustomScrollbar>
        </MenuList>

        <Button
          onClick={fxSetAllAsReadNotifications}
          color="primary"
          classes={{ root: classes.button }}
          fullWidth
        >
          {t('MarkAllRead')}
        </Button>
      </>
    );
  };

  return (
    <Popover
      trigger={
        <IconButton title={t('Notifications')} size="large">
          <Badge
            classes={{ badge: classes.badge }}
            badgeContent={unreadCount}
            max={99}
            style={{ zIndex: 0 }}
          >
            <NotificationsIcon className={classes.notificationsIcon} />
          </Badge>
        </IconButton>
      }
    >
      {renderContent()}
    </Popover>
  );
};

export default Notifications;
