import set from 'date-fns/set';

export const formatDateTime = (time) => {
  const timeRegexp = new RegExp(/^(2[0-3]|[0-1]?[\d]):([0-5]?[\d])(:\d{1,2})?$/);

  if (typeof time === 'string' && time.match(timeRegexp)) {
    const [hours, minutes] = time.split(':');

    return set(new Date(), { hours, minutes });
  }
  return '';
};
