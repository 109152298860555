import { createGate } from 'effector-react';
import { createForm } from '@effector-form';
import * as Yup from 'yup';
import { authSubmit } from '@features/common';

export const AuthGate = createGate();
export const { open: authPageMounted, close: authPageUnmounted } = AuthGate;

export const authForm = createForm({
  initialValues: {
    login: '',
    password: '',
    isCompany: false,
    remember_me: true,
  },
  validationSchema: Yup.object().shape({
    login: Yup.string().required(),
    password: Yup.string().required(),
  }),
  onSubmit: authSubmit,
  resetOn: authPageUnmounted,
  editable: true,
  sid: 'authForm',
});
