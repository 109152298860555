import { PagingPanel as TempPagingPanel } from '@devexpress/dx-react-grid-material-ui';
import i18n from '@shared/config/i18n';

const { t } = i18n;

const getVisibility = ({ columns, hiddenColumnNames }) => {
  const withoutHidden = !columns && !hiddenColumnNames;
  let hasLeastOneVisibleColumn = false;

  if (!withoutHidden) {
    hasLeastOneVisibleColumn = columns.length !== hiddenColumnNames.length;
  }

  return withoutHidden || hasLeastOneVisibleColumn;
};

const PagingPanel = ({ columns, hiddenColumnNames, ...props }) => {
  const visible = getVisibility({ columns, hiddenColumnNames });

  return visible ? <TempPagingPanel {...props} /> : null;
};

PagingPanel.defaultProps = {
  pageSizes: [10, 25, 50, 100],
  messages: { rowsPerPage: t('EntriesPerPage'), info: '' },
};
export { PagingPanel };
