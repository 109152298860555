import { Components } from '@mui/material';

export const Checkbox: Components['MuiCheckbox'] = {
  defaultProps: {
    disableRipple: true,
    disableFocusRipple: true,
    disableTouchRipple: true,
  },
  styleOverrides: {
    root: {
      padding: 0,
    },
  },
};
