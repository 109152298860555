import { useMemo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useStore } from 'effector-react';
import makeStyles from '@mui/styles/makeStyles';
import {
  IntegratedFiltering,
  IntegratedSorting,
  SearchState,
  SortingState,
  FilteringState,
  PagingState,
  IntegratedPaging,
  SelectionState,
  IntegratedSelection,
  DataTypeProvider,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  Table,
  TableHeaderRow,
  TableColumnResizing,
  DragDropProvider,
  TableColumnReordering,
  TableSelection,
} from '@devexpress/dx-react-grid-material-ui';
import i18n from '@shared/config/i18n';
import { featureFlags } from '@configs/feature-flags';
import { clickRow } from '../../../../redux/actions/objects';
import { createTableBag } from '../../../../tools/factories';
import {
  TableColumnVisibility,
  SortingLabel,
  TableHeaderCell,
  TableContainer,
  PagingPanel,
} from '../../../../ui';
import TableCell from '../../../atoms/TableCell';
import TableRow from '../../../atoms/TableRowNew';
import { matchQrStatus } from '../lib/qr-code';

const { t } = i18n;

const useStyles = makeStyles({
  status: {
    display: 'inline-flex',
    background: ({ backgroundColor }) => backgroundColor,
    color: ({ textColor }) => textColor,
    padding: '4px 18px',
    borderRadius: '20px',
    fontWeight: 500,
  },
});

const Root = ({ children, ...props }) => (
  <Grid.Root {...props} style={{ height: '100%' }}>
    {children}
  </Grid.Root>
);

const CustomTableCell = ({ children, ...rest }) => (
  <TableCell {...rest} style={{ padding: 8, fontWeight: 500 }}>
    {children}
  </TableCell>
);

const buildingsColumns = [
  {
    name: 'city',
    title: t('Label.city'),
    getCellValue: (cell) => {
      return cell.building.address.city;
    },
  },
  {
    name: 'complexes',
    title: t('RC'),
    getCellValue: (cell) => cell.complex.title,
  },
  {
    name: 'alias',
    title: t('Name'),
    getCellValue: (cell) => cell?.building?.alias || '-',
  },
  {
    name: 'full_address',
    title: t('FullAddress'),
    getCellValue: (cell) => cell.building.address.fullAddress,
  },
  {
    name: 'entrances',
    title: t('Entrances'),
    getCellValue: (cell) => cell.building.entranceCount,
  },
  {
    name: 'apartments',
    title: t('Apartments'),
    getCellValue: (cell) => cell.building.apartmentCount,
  },
  {
    name: 'floor',
    title: t('Floors'),
    getCellValue: (cell) => cell.building.floor,
  },
];
const complexesColumns = [
  { name: 'title', title: t('Name') },
  {
    name: 'region',
    title: t('Region'),
    getCellValue: (cell) => cell.region && cell.region.title,
  },
];
const flatColumns = [
  {
    name: 'number',
    title: `№ ${t('Apartments')}`,
  },
  {
    name: 'entrance',
    title: `№ ${t('EntranceNo2')}`,
  },
  {
    name: 'floor',
    title: t('Floor'),
  },
  {
    name: 'square',
    title: `${t('ApartmentArea')}, м²`,
  },
  {
    name: 'title',
    title: t('Label.address'),
  },
  {
    name: 'bind_by_qr',
    title: t('AnchorStatus'),
  },
  {
    name: 'main_account',
    title: t('MainAccount'),
  },
];

const getColumn = (column) => {
  switch (column) {
    case 'buildings':
      return buildingsColumns;
    case 'flats':
      return flatColumns;
    case 'complexes':
      return complexesColumns;
    default:
      return null;
  }
};

const Row = (props) => {
  const dispatch = useDispatch();
  const entityId = useSelector((state) => state.objects.detail.open);
  const isSelected = entityId === props.row.id;

  const onRowClick = useCallback((data, id) => {
    dispatch(clickRow(id));
  }, []);

  const row = useMemo(
    () => <TableRow {...props} isSelected={isSelected} onRowClick={onRowClick} />,
    [isSelected, props.children]
  );
  return row;
};

const { pageNumChanged, pageSizeChanged, $currentPage, $pageSize } = createTableBag([]);

const StatusFormatter = ({ value }) => {
  const { text, backgroundColor, textColor } = matchQrStatus(value);

  const classes = useStyles({ backgroundColor, textColor });

  return <div className={classes.status}>{text}</div>;
};

const StatusProvider = (props) => (
  <DataTypeProvider formatterComponent={StatusFormatter} {...props} />
);

function ObjectsTable(props) {
  const {
    data = [],
    searchValue = '',
    sortValue,
    filterValue,
    onSort,
    reorderTableColumn,
    changeColumnWidths,
    columnsOrder,
    columnWidths,
    tableKind,
    selectRows,
    selection,
  } = props;
  const complexesPredicate = (value, filter, row) => {
    const isArray = Array.isArray(filter.value);
    const isEmpty = filter.value.length === 0;

    if (tableKind === 'complexes') {
      return (
        isEmpty || (isArray && filter.value.some((item) => item.title === row.title))
      );
    }

    const hasFilterMatches = isArray && filter.value.some((item) => item.title === value);

    if (isArray) {
      return isEmpty ? true : hasFilterMatches;
    }
    return IntegratedFiltering.defaultPredicate(value, filter, row);
  };

  const integratedFilteringColumnExtensions = [
    { columnName: 'complexes', predicate: complexesPredicate },
  ];

  const currentPage = useStore($currentPage);
  const pageSize = useStore($pageSize);
  const columns = getColumn(tableKind);

  const isCRUDAllowed = featureFlags.oldObjectsCRUD;

  const showMainAccountColumn = data.filter((flat) => {
    return flat?.bind_by_qr?.userdata_fullname;
  }).length;

  const hiddenColumnNames = showMainAccountColumn ? [] : ['main_account'];

  return (
    <Grid rootComponent={Root} rows={data} getRowId={(row) => row.id} columns={columns}>
      <SelectionState selection={selection} onSelectionChange={selectRows} />
      <IntegratedSelection />
      <SortingState sorting={sortValue} onSortingChange={onSort} />
      <IntegratedSorting />
      <SearchState value={searchValue} />

      <FilteringState filters={filterValue} />
      <IntegratedFiltering columnExtensions={integratedFilteringColumnExtensions} />
      <PagingState
        currentPage={currentPage}
        onCurrentPageChange={pageNumChanged}
        pageSize={pageSize}
        onPageSizeChange={pageSizeChanged}
      />
      <IntegratedPaging />
      <PagingPanel />

      <DragDropProvider />
      <Table
        messages={{ noData: t('noData') }}
        rowComponent={Row}
        cellComponent={CustomTableCell}
        containerComponent={TableContainer}
      />
      <StatusProvider for={['bind_by_qr']} />

      <TableColumnVisibility hiddenColumnNames={hiddenColumnNames} />

      <TableSelection showSelectAll showSelectionColumn={isCRUDAllowed} />

      <TableColumnResizing
        columnWidths={columnWidths}
        onColumnWidthsChange={changeColumnWidths}
      />
      <TableColumnReordering order={columnsOrder} onOrderChange={reorderTableColumn} />
      <TableHeaderRow
        cellComponent={TableHeaderCell}
        sortLabelComponent={SortingLabel}
        showSortingControls
      />
    </Grid>
  );
}

export default ObjectsTable;
