import { createEffect, createStore } from 'effector';
import { signout } from '../../common';
import { complexApi } from '../api';

const fxGetList = createEffect();

const $data = createStore([]);
const $error = createStore(null);
const $isLoading = createStore(false);

$data
  .on(fxGetList.done, (state, { result }) => {
    if (!result.items) {
      return [];
    }

    return result.items;
  })
  .reset(signout);

$error.on(fxGetList.fail, (state, { error }) => error).reset(signout);
$isLoading.on(fxGetList.pending, (state, result) => result).reset(signout);

fxGetList.use(complexApi.getList);

export { fxGetList, $data, $error, $isLoading };
