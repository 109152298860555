import { sample } from 'effector';
import { reset } from 'patronum';
import lodashSet from 'lodash/set';
import { signout } from '@features/common';
import {
  $currentObject,
  $currentObjectGuid,
  $expandedObjects,
  $objects,
  $objectsTree,
  $selectedObjects,
  collapseAllObjects,
  errorOccured,
  expandAllObjects,
  fxGetObjects,
  ObjectTreeGate,
  openObject,
  refreshObjects,
  setExpandedObjects,
  setSelectedObjects,
} from './object.model';

/** Запрос на получение объектов при маунте или обновлении */
sample({
  clock: [ObjectTreeGate.open, refreshObjects],
  fn: () => ({}),
  target: fxGetObjects,
});

/** Обработка ошибок запроса */
sample({
  clock: [fxGetObjects.fail],
  target: errorOccured,
});

/** Записываем полученные объекты как meta информацию в общий стор с объектами */
sample({
  clock: fxGetObjects.doneData,
  source: $objects,
  fn: (objects, { areaTree }) => {
    const newObjects = objects;

    areaTree.forEach((object) => {
      lodashSet(newObjects, object.guid, object);
    });

    return { ...newObjects };
  },
  target: $objects,
});

/** Запрос на дочерние уровни при клике на элемент дерева */
sample({
  clock: $currentObject,
  source: $objectsTree,
  fn: (_, currentObject) => ({ parent_guid: currentObject?.guid, level: 1 }),
  target: fxGetObjects,
});

/** Открытие объекта */
sample({
  clock: openObject,
  target: $currentObjectGuid,
});

/** Установка выделенного объекта при изменении текущего guid */
sample({
  clock: $currentObjectGuid,
  fn: (currentGuid) => (currentGuid ? [currentGuid] : []),
  target: setSelectedObjects,
});

/** Установка раскрытых объектов */
sample({
  clock: setExpandedObjects,
  target: $expandedObjects,
});

/** Установка выделенных объектов */
sample({
  clock: setSelectedObjects,
  target: $selectedObjects,
});

sample({
  clock: collapseAllObjects,
  fn: () => [],
  target: setExpandedObjects,
});

sample({
  clock: expandAllObjects,
  source: $objects,
  fn: (objects) => Object.keys(objects),
  target: setExpandedObjects,
});

/** Автораскрытие ветки по выделению объекта */
sample({
  clock: $selectedObjects,
  source: $expandedObjects,
  fn: (expandedObjects, selectedObjects) => [
    ...new Set([...expandedObjects, ...selectedObjects]),
  ],
  target: $expandedObjects,
});

reset({
  clock: [refreshObjects, signout],
  target: [$currentObjectGuid, $objects, $expandedObjects, $selectedObjects],
});

sample({
  clock: [refreshObjects, signout],
  fn: () => ({}),
  target: $objects,
});
