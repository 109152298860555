import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  profile__wrapper: {
    display: 'grid',
    gridTemplateColumns: '315px minmax(300px, 900px)',
    justifySelf: 'center',
    gap: 24,
    margin: '12px 24px 24px 24px',
  },
});
