import { api } from '@api/api2';
import { RentFloorsPayload } from '@features/effector-form/controls/rent-floor-controls/types';

const getRentFloors = (payload: RentFloorsPayload) =>
  api.v4('get', 'area/objects/simple/list', {
    ...payload,
    ...{ fast_filters: { is_floor: 1 } },
  });

export const rentFloorsApi = { getRentFloors };
