import { combineReducers } from 'redux';
import { request, getSuccess, requestFail } from '../actions/complexes_region';
import { fetchingReducer, errorReducer, dataReducer } from './commonReducers';

const isFetching = fetchingReducer([request], [getSuccess, requestFail], false);

const error = errorReducer([requestFail], [request], null);

const data = dataReducer(
  {
    create: 'NO_ACTION',
    update: 'NO_ACTION',
    delete: 'NO_ACTION',
    get: getSuccess,
  },
  []
);

const regions = combineReducers({
  isFetching,
  error,
  data,
});

export default regions;
