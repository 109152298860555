import { ListItem, Card, CardContent } from '@mui/material';
import withStyles from '@mui/styles/withStyles';

const styles = {
  root: {
    boxShadow: 'none',
    backgroundColor: 'inherit',
    padding: '0.5em 23px',
    height: '158px',
    '&:hover': {
      background: 'transparent',
    },
  },
  selected: {
    backgroundColor: '#E1EBFF !important',
  },
  content: {
    padding: '0',
    width: '100%',
  },
};

function ListElement({ children, classes, ...props }) {
  return (
    <ListItem
      button
      divider
      component={Card}
      classes={{ root: classes.root, selected: classes.selected }}
      {...props}
    >
      <CardContent classes={{ root: classes.content }}>{children}</CardContent>
    </ListItem>
  );
}

const StyledListElement = withStyles(styles)(ListElement);

export { StyledListElement as ListElement };
