import { Tab, LinearProgress } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Tabs, Progressbar } from '@ui/';

const useStyles = makeStyles({
  widget: {
    position: 'relative',
    background: '#fff',
    width: ({ size }) => size?.width || 'auto',
    height: ({ size }) => size?.height || 'auto',
    borderRadius: '15px',
    boxShadow: '0px 4px 15px #E7E7EC',
    // marginBottom: '32px',
    overflow: 'hidden',
  },
  loader: {
    position: 'absolute',
    height: 4,
    width: '100%',
    top: 0,
  },
  content: {
    padding: '2px 24px 24px 24px',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '55px',
  },
  title: {
    fontSize: '24px',
    color: '#3B3B50',
    margin: 0,
  },
  tabs: {
    marginLeft: 'auto',
    color: '#9393A3',
    fontWeight: 500,
  },
  tab_root: {
    padding: '6px 12px 2px 12px',
    minWidth: 'auto',
  },
  tab_selected: {
    color: '#0394E3',
  },
  tab_indicator: {
    background: '#0394E3',
    height: '3px',
  },
});

const WidgetTitle = ({ classes, title }) => <h1 className={classes.title}>{title}</h1>;

const WidgetTabs = ({ classes, tabs }) => {
  const { active, options, currentTab, changeTab, isNew } = tabs;

  return (
    <div className={classes.tabs}>
      {active && (
        <Tabs
          options={options}
          currentTab={currentTab}
          onChange={(e, tab) => changeTab(tab)}
          isNew={isNew}
          scroll="off"
          variant="standard"
          classes={{ indicator: classes.tab_indicator }}
          tabEl={
            <Tab
              classes={{
                selected: classes.tab_selected,
                root: classes.tab_root,
              }}
            />
          }
        />
      )}
    </div>
  );
};

const WidgetHeader = ({ classes, title, tabs, renderTool }) => {
  return (
    <div className={classes.header}>
      <WidgetTitle classes={classes} title={title} />
      {tabs && <WidgetTabs classes={classes} tabs={tabs} />}
      {renderTool ? renderTool() : null}
    </div>
  );
};

export const Widget = ({
  children,
  settings,
  renderTool = null,
  refreshRate = null,
  isLoading = null,
}) => {
  const { title, tabs = null, size } = settings;

  const classes = useStyles({ size });

  const renderLoader = () => {
    if (!refreshRate) return null;
    if (refreshRate && !isLoading)
      return (
        <div className={classes.loader}>
          <Progressbar refreshRate={refreshRate} />
        </div>
      );
    if (refreshRate && isLoading)
      return (
        <div className={classes.loader}>
          <LinearProgress />
        </div>
      );
    return null;
  };

  return (
    <div className={classes.widget}>
      {renderLoader()}

      <div className={classes.content}>
        <WidgetHeader
          classes={classes}
          title={title}
          tabs={tabs}
          renderTool={renderTool}
        />
        {children}
      </div>
    </div>
  );
};
