import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  profile__card: {
    display: 'grid',
    alignContent: 'start',
    alignSelf: 'start',
    padding: 24,
    overflow: 'hidden',
    background: 'white',
    boxShadow: 'rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px',
    borderRadius: 12,
  },
  profile__content: {
    display: 'grid',
    justifyItems: 'center',
    gap: 20,
  },
  profile__avatar: {
    width: 124,
    height: 124,
    background: 'grey',
    borderRadius: '100%',
    objectFit: 'cover',
  },
  profile__personalInfo: {
    display: 'grid',
    justifyItems: 'center',
    gap: 5,
  },
  profile__name: {
    fontWeight: 'bold',
    fontSize: 16,
  },
  profile__role: {
    fontSize: 14,
    color: 'rgb(140, 140, 140)',
  },
  profile__menu: {
    marginTop: 28,
  },
  profile__menuList: {
    padding: 0,
  },
});
