import { FC, ReactNode } from 'react';
import { Event } from 'effector';
import { Formik, Form, Field } from 'formik';
import { Typography } from '@mui/material';
import { Grid, Table, TableHeaderRow } from '@devexpress/dx-react-grid-material-ui';
import i18n from '@shared/config/i18n';
import {
  TableCell,
  TableHeaderCell,
  TableContainer,
  Modal,
  CloseButton,
  ExportButton,
  InputField,
  ActionButton,
  Loader,
} from '@ui/index';
import * as Yup from 'yup';

const { t } = i18n;

interface IProps {
  isOpen: boolean;
  onClose: () => boolean;
  getFiltredStatistic: Event<{ start_date: string; finish_date: string }>;
  filtredStatistic: { date: string; count: number }[] | null;
  isLoading: boolean;
  tableData: { date: string; views: number }[];
  columns: { name: string; title: string }[];
  exportClicked: Event<void>;
  saveDates: Event<{
    start_date: string;
    finish_date: string;
  }>;
  isPoll?: boolean;
}

const Root = ({ children, ...props }: { children?: ReactNode }) => (
  <Grid.Root {...props} style={{ height: '100%' }}>
    {children}
  </Grid.Root>
);

const StatisticsModal: FC<IProps> = (props) => {
  const {
    isOpen,
    onClose,
    getFiltredStatistic,
    filtredStatistic,
    isLoading,
    tableData,
    columns,
    exportClicked,
    saveDates,
    isPoll,
  } = props;

  const header = (
    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
      <CloseButton onClick={onClose} />
    </div>
  );

  const validationSchema = Yup.object().shape({
    start_date: Yup.string()
      .test(
        'start_date-after-end_date',
        t('StartDateBeforeEndDate'),
        startDateAfterEndDate
      )
      .nullable(),
    finish_date: Yup.string()
      .test(
        'start_date-after-end_date',
        t('EndDateAfterStartDate'),
        startDateAfterEndDate
      )
      .nullable(),
  });

  function startDateAfterEndDate(this: any) {
    const isDatesDefined =
      Boolean(this.parent.start_date) && Boolean(this.parent.finish_date);

    if (
      isDatesDefined &&
      Number(new Date(this.parent.start_date)) > Number(new Date(this.parent.finish_date))
    ) {
      return this.createError();
    }

    return true;
  }

  const todayDate = `${new Date().getFullYear()}-${String(
    new Date().getMonth() + 1
  ).padStart(2, '0')}-${String(new Date().getDate()).padStart(2, '0')}`;

  const content = (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginTop: 20,
        }}
      >
        <Typography style={{ fontWeight: 700, fontSize: 24, color: '#3B3B50' }}>
          {isPoll ? (t('PassStatistics') as string) : (t('ViewStatistics') as string)}
        </Typography>
        <ExportButton
          {...{
            columns,
            tableData,
            hiddenColumnNames: [],
          }}
          style={{ marginRight: 20 }}
          onClick={exportClicked}
        />
      </div>
      {isLoading && <Loader isLoading />}
      <Formik
        initialValues={{ start_date: '', finish_date: '' }}
        onSubmit={(values) => {
          getFiltredStatistic(values);
          saveDates(values);
        }}
        validationSchema={validationSchema}
        enableReinitialize
        render={() => (
          <Form style={{ padding: 24, paddingTop: 20 }}>
            <div style={{ display: 'flex' }}>
              <div style={{ display: 'flex' }}>
                <div style={{ marginRight: 30 }}>
                  <Field
                    name="start_date"
                    label={null}
                    divider={false}
                    type="date"
                    component={InputField}
                    inputProps={{ max: todayDate }}
                  />
                </div>
                <Field
                  name="finish_date"
                  label={null}
                  divider={false}
                  type="date"
                  component={InputField}
                  inputProps={{ max: todayDate }}
                />
              </div>
              <ActionButton style={{ marginLeft: 30, padding: '0 40px' }} type="submit">
                {t('Show')}
              </ActionButton>
            </div>
          </Form>
        )}
      />
      {filtredStatistic && (
        <div style={{ height: 400 }}>
          <Grid rootComponent={Root} rows={tableData} columns={columns}>
            <Table
              messages={{ noData: t('noData') }}
              cellComponent={TableCell}
              containerComponent={TableContainer}
            />
            <TableHeaderRow cellComponent={TableHeaderCell} />
          </Grid>
        </div>
      )}
    </>
  );

  return <Modal isOpen={isOpen} onClose={onClose} header={header} content={content} />;
};

export { StatisticsModal };
