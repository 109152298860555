import { forwardRef, Ref } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { ActionIconButton } from '../..';

const AddButton = forwardRef(
  ({ titleAccess = '', ...props }: any, ref: Ref<JSX.Element>) => {
    return (
      <ActionIconButton data-test-id="add-btn" kind="positive" ref={ref} {...props}>
        <AddIcon titleAccess={titleAccess} />
      </ActionIconButton>
    );
  }
);

export { AddButton };
