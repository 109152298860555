import { useTranslation } from 'react-i18next';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import { ActionButton } from '../..';

function VideoLibraryButton(props) {
  const { t } = useTranslation();

  return (
    <ActionButton data-test-id="save-btn" kind="positive" {...props} style={{ marginLeft: 0 }}>
      <VideoLibraryIcon />
      &nbsp;
      {t('ShowArchive')}
    </ActionButton>
  );
}

export { VideoLibraryButton };
