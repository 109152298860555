import { MouseEvent, useState } from 'react';
import {
  FormControl,
  FormHelperText,
  FormLabel,
  IconButton,
  InputAdornment,
  OutlinedInput,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { withControl } from '@features/effector-form/hoc/withControl';

export const PasswordControl = withControl(
  ({ value, setValue, error, label, required, readOnly, disabled }) => {
    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event: MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
    };

    return (
      <FormControl required={required} disabled={disabled}>
        <FormLabel>{label}</FormLabel>
        <OutlinedInput
          value={value}
          type={showPassword ? 'text' : 'password'}
          autoComplete="current-password"
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
                size="small"
                disabled={disabled}
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
          onChange={(e) => setValue(e.target.value)}
          readOnly={readOnly}
          disabled={disabled}
        />
        <FormHelperText error>{error}</FormHelperText>
      </FormControl>
    );
  }
);
