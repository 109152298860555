import { FC } from 'react';
import { Redirect } from 'react-router-dom';
import { useGate, useStore } from 'effector-react';
import { AuthGate } from '@features/authorization/model';
import { AuthForm } from '@features/authorization/organisms/authForm';
import { Header } from '@features/authorization/organisms/header';
import { $isAuthenticated, $isUserLoading } from '@features/common';
import { ThemeAdapter } from '@shared/theme/adapter';
import * as Styled from './styled';

interface Props {
  location: {
    state?: {
      from: {
        pathname: string;
      };
    };
  };
}

const AuthorizationPage: FC<Props> = ({ location, ...restProps }) => {
  useGate(AuthGate);

  const isAuthenticated = useStore($isAuthenticated);
  const isLoading = useStore($isUserLoading);

  // удаляет id сущности из роута при авторизации
  const clearEntityId = (from: { pathname: string }) => {
    const [path] = from.pathname.split('/').filter((string) => !!string);

    return { ...from, pathname: path ? `/${path}` : '/' };
  };

  if (isAuthenticated) {
    return <Redirect to={location.state ? clearEntityId(location.state.from) : '/'} />;
  }

  return (
    <ThemeAdapter>
      <Styled.Container>
        <Header />
        <Styled.Content>
          <Styled.Logo alt="Ujin" />
          <AuthForm />
        </Styled.Content>
      </Styled.Container>
    </ThemeAdapter>
  );
};

export { AuthorizationPage };
