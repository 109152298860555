import { createEffect, sample } from 'effector';
import { createGate } from 'effector-react';
import { cache, createQuery, localStorageCache } from '@farfetched/core';
import { signout } from '@features/common';
import { rentObjectsApi } from '@features/effector-form/controls/rent-objects/api';
import {
  RentObjectsPayload,
  RentObjectsResponse,
} from '@features/effector-form/controls/rent-objects/types';

export const RentObjectsControlGate = createGate<{
  propertyType: string;
  object: string;
}>();

const fxGetRentObjects = createEffect<
  RentObjectsPayload,
  RentObjectsResponse,
  Error
>().use(rentObjectsApi.getRentObjects);

export const rentObjectsQuery = createQuery({
  name: 'rentObjects',
  effect: fxGetRentObjects,
  mapData: ({ result }) =>
    result.areaList.map((obj) => ({
      id: obj.guid,
      label: obj.title,
    })),
});

cache(rentObjectsQuery, {
  adapter: localStorageCache({ maxAge: '5m' }),
  purge: signout,
});

sample({
  clock: RentObjectsControlGate.state,
  filter: ({ propertyType, object }) => Boolean(propertyType && object),
  fn: ({ propertyType, object }) => ({
    parent_guids: [object],
    area_types: [propertyType],
  }),
  target: rentObjectsQuery.start,
});
