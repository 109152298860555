const HOST = window.location.hostname;
const BUILD_TYPE = process.env.BUILD_TYPE;

export const getCookieNameWithBuildType = (name = 'bms_token') => {
  if (HOST.includes('alpha-centauri')) return `${name}_old-alpha`;
  if (HOST.includes('bms-alpha')) return `${name}_alpha`;

  return `${name}_${BUILD_TYPE}`;
};

//поиск куки по имени
export const cookieMap = (needle) => {
  const cookieArray = document.cookie.split(';');
  const mapping = new Map(cookieArray.map((cookie) => cookie.trim().split('=', 2)));

  return mapping.get(needle);
};

const getCookieDomainWithBuildType = () => {
  if (HOST === 'localhost') return HOST;

  const [zone, domaine] = HOST.split('.').reverse();
  return `${domaine}.${zone}`;
};

export function getCookie(name) {
  const cookieName = getCookieNameWithBuildType(name);
  const matches = document.cookie.match(
    new RegExp(
      '(?:^|; )' + cookieName.replace(/([.$?*|{}()[\]\\/+^])/g, '\\$1') + '=([^;]*)'
    )
  );

  return matches ? decodeURIComponent(matches[1]) : undefined;
}

export function setCookie(name, value, options = {}, isRemoving = false) {
  options.path = '/';
  options.domain = getCookieDomainWithBuildType();
  const cookieName = isRemoving ? name : getCookieNameWithBuildType(name);
  const cookieValue = encodeURIComponent(value);

  let expires = options.expires;

  if (typeof expires == 'number' && expires) {
    const date = new Date();
    date.setTime(date.getTime() + expires * 1000);
    expires = options.expires = date;
  }

  if (expires && expires.toUTCString) {
    options.expires = expires.toUTCString();
  }

  let updatedCookie = `${cookieName}=${cookieValue}`;

  for (const propName in options) {
    if (Object.prototype.hasOwnProperty.call(options, propName)) {
      updatedCookie += '; ' + propName;
      const propValue = options[propName];
      if (propValue !== true) {
        updatedCookie += '=' + propValue;
      }
    }
  }

  document.cookie = updatedCookie;
}

export function deleteCookie(name) {
  setCookie(name, '', { expires: -1 }, true);
}

export function deleteAllCookie() {
  const keys = Object.keys(
    Object.fromEntries(
      new URLSearchParams(document.cookie.replaceAll('; ', '&')).entries()
    )
  );

  keys.forEach(deleteCookie);
}
