import classNames from 'classnames';
import Typography from '@mui/material/Typography';
import withStyles from '@mui/styles/withStyles';

const styles = {
  h5: {
    fontWeight: '700',
    color: '#3B3B50',
    display: 'inline-block',
    marginBottom: '30px',
  },
};

function FormSectionHeader(props) {
  const { header, classes, variant = 'h5', ...rest } = props;
  return (
    <Typography
      variant={variant}
      classes={{ [variant]: classNames(classes[variant]) }}
      {...rest}
    >
      {header}
    </Typography>
  );
}

const StyledFormSectionHeader = withStyles(styles)(FormSectionHeader);

export { StyledFormSectionHeader as FormSectionHeader };
