import { forwardRef } from 'react';
import { Tooltip } from '@mui/material';
import ImportIcon from '@mui/icons-material/VerticalAlignTop';
import i18n from '@shared/config/i18n';
import { ActionIconButton } from '../..';

const { t } = i18n;

const ImportButton = forwardRef((props: any, ref: any) => {
  const { onClick = () => null, ...rest } = props;
  return (
    <Tooltip title={t('Import') as string}>
      <ActionIconButton ref={ref} data-test-id="import-btn" onClick={onClick} {...rest}>
        <ImportIcon />
      </ActionIconButton>
    </Tooltip>
  );
});

export { ImportButton };
