import { reset } from 'patronum';
import { signout } from '@features/common';
import {
  $isErrorDialogOpen,
  $error,
  changedErrorDialogVisibility,
  $currentPage,
  changeCurrentPage,
  errorOccured,
  profileUnmounted,
  $isEditMode,
  toggleEditMode,
} from './root.model';

$currentPage.on(changeCurrentPage, (state, type) => type);

$isErrorDialogOpen
  .on(changedErrorDialogVisibility, (_, visibility) => visibility)
  .on(errorOccured, () => true);

$error.on(errorOccured, (_, { error }) => error);

/** Изменение режима редактирования */
$isEditMode.on(toggleEditMode, (state, value) => value);

/** Сброс */
reset({
  clock: [profileUnmounted, signout],
  target: [$currentPage, $isEditMode, $error, $isErrorDialogOpen],
});

reset({
  clock: [changeCurrentPage],
  target: [$isEditMode],
});
