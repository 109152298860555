import { FilterAltOutlined } from '@mui/icons-material';
import { ActionIconButton } from '../..';

function FilterButton({ isOpen, type, badge, titleAccess = '', ...rest }: any) {
  return (
    <ActionIconButton data-test-id="filter-btn" {...rest}>
      <FilterAltOutlined titleAccess={titleAccess} />
    </ActionIconButton>
  );
}

export { FilterButton };
