import { ChangeEvent, useMemo } from 'react';
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  OutlinedInput,
  Radio,
  RadioGroup,
} from '@mui/material';
import { RadioGroupControlType } from '@features/effector-form/controls/radioGroup/types';
import { withControl } from '@features/effector-form/hoc/withControl';

export const RadioGroupControl = withControl<RadioGroupControlType>(
  ({ value, setValue, error, label, required, readOnly, disabled, options }) => {
    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
      setValue((event.target as HTMLInputElement).value);
    };

    const renderOptions = () =>
      options.map((option) => (
        <FormControlLabel value={option.id} control={<Radio />} label={option.label} />
      ));
    const renderedOptions = useMemo(() => renderOptions(), [value, options]);

    return (
      <FormControl required={required} disabled={disabled}>
        {label ? <FormLabel>{label}</FormLabel> : null}

        <RadioGroup onChange={handleChange} value={value}>
          {renderedOptions}
        </RadioGroup>

        {error ? <FormHelperText error>{error}</FormHelperText> : null}
      </FormControl>
    );
  }
);
