import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => {
  return {
    container: {
      display: 'grid',
      gridTemplateRows: '80px 1fr',
      gridTemplateColumns: (props) =>
        props.filter ? '280px 500px 1fr' : props.inverse ? '1fr 500px' : '500px 1fr',
      padding: '12px 24px 24px 24px',
      height: '100%',
      gridGap: 24,
    },
    top: {
      gridRow: '1 / 2',
      gridColumn: (props) => (!props.filter ? '1 / 3' : '1 / 4'),
    },
    left: {
      gridRow: '2 / 3',
      gridColumn: (props) =>
        props.filter
          ? ' 2 / 3'
          : props.inverse
          ? !props.right
            ? '1 / 3'
            : '2 / 3'
          : !props.right
          ? '1 / 3'
          : '1 / 2',
    },
    right: {
      gridRow: '2 / 3',
      gridColumn: (props) =>
        props.filter
          ? '3 / 4'
          : props.inverse
          ? !props.left
            ? '1 / 3'
            : '1 / 2'
          : !props.left
          ? '1 / 3'
          : '2 / 3',
    },
    filter: {
      gridRow: '2 / 3',
      gridColumn: '1 / 2',
    },
  };
});

const ScheduleLayout = (props) => {
  const { top = null, left = null, right = null, inverse = false, filter = null } = props;
  const classes = useStyles({ inverse, left, right, filter });

  return (
    <div className={classes.container}>
      <div className={classes.top}>{top}</div>
      {right && <div className={classes.right}>{right}</div>}
      {filter && <div className={classes.filter}>{filter}</div>}
      {left && <div className={classes.left}>{left}</div>}
    </div>
  );
};

export { ScheduleLayout };
