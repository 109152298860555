import { createStore } from 'effector';

/*
 * forward({
 *   from: $isLoading,
 *   to: $cursorWaiting
 * })
 *
 * */

const body = document.querySelector('body');
export const $cursorWaiting = createStore(false);

$cursorWaiting.watch((waiting) => {
  if (!body) return;

  if (waiting) {
    body.style.cursor = 'progress';
  } else {
    body.style.cursor = 'default';
  }
});
