import { ObjectShare } from '@features/effector-form/controls/objectShare/types/common';

export const stringToShare = (string: string): ObjectShare => {
  const unpackedString = string.split('/');

  return {
    numerator: Number(unpackedString[0]),
    denominator: Number(unpackedString[1]),
  };
};
