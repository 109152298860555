import { useStore } from 'effector-react';
import { Formik, Form, Field } from 'formik';
import makeStyles from '@mui/styles/makeStyles';
import { useSelect } from '@entities/SelectControl/hooks/useSelect';
import i18n from '@shared/config/i18n';
import { FilterSelect } from '@ui/';
import { $filters, filtersSubmitted } from '../models';
import FilterChip from '../atoms/filterChip';

const { t } = i18n;

const useStyles = makeStyles(() => ({
  form: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, auto)',
    justifyItems: 'start',
    justifyContent: 'start',
    alignItems: 'center',
    alignContent: 'center',
    gap: 16,
    color: '#68687e',
  },
}));

export const Filter = () => {
  const filters = useStore($filters);
  const classes = useStyles();

  const { data: complex } = useSelect({
    name: 'house',
    url: '/signal_notifications/get-complex',
    apiVersion: 'no_vers',
  });

  const { data: buildings, getListByParams } = useSelect({
    name: 'object',
    url: '/signal_notifications/get-buildings',
    apiVersion: 'no_vers',
  });

  const complexList = complex.map(({ complex_id: id, complex_title: title }) => ({
    id,
    title,
  }));

  return (
    <Formik
      initialValues={filters}
      onSubmit={filtersSubmitted}
      onReset={() => filtersSubmitted('')}
      enableReinitialize
      render={({ setFieldValue, handleSubmit }) => (
        <Form className={classes.form}>
          <Field
            list={complexList}
            name="object"
            component={FilterSelect}
            label={t('AnObject')}
            onChange={(value) => {
              setFieldValue('object', value);
              getListByParams(value === 0 ? {} : { complex_id: value });
              setFieldValue('house', '');
              setTimeout(handleSubmit, 0);
            }}
          />

          <Field
            list={buildings}
            name="house"
            component={FilterSelect}
            label={t('Label.address')}
            onChange={(value) => {
              setFieldValue('house', value);

              setTimeout(handleSubmit, 0);
            }}
          />

          <Field
            name="archive"
            id="archive"
            render={({ field }) => (
              <FilterChip
                {...field}
                onChange={() => {
                  setFieldValue(field.name, !field.value);
                  setTimeout(handleSubmit, 0);
                }}
              />
            )}
          />
        </Form>
      )}
    />
  );
};
