import MuiAvatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
  avatar: {
    width: 190,
    height: 190,
    background: '#f7f7fc',
  },
}));

function Avatar(props) {
  const { alt = 'Аватар', src, ...rest } = props;
  const classes = useStyles();

  return (
    <Grid container justifyContent="center" alignItems="center" {...rest}>
      <MuiAvatar alt={alt} src={src} className={classes.avatar} />
    </Grid>
  );
}

export { Avatar };
