export function type(value) {
  if (typeof value === 'number' && Number.isNaN(value)) {
    return 'NaN';
  }

  const regexp = /\w+/y;
  regexp.lastIndex = 8;

  const string = Object.prototype.toString.call(value);

  const result = regexp.exec(string)[0].toLowerCase();
  return result;
}

export function isNumber(value) {
  return type(value) === 'number';
}

export function isArray(value) {
  return Array.isArray(value);
}

export function isObject(value) {
  return type(value) === 'object';
}

export function isString(value) {
  return type(value) === 'string';
}

export function isNull(value) {
  return type(value) === 'null';
}

export function isBlob(value) {
  return type(value) === 'blob';
}
