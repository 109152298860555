import {
  FormControl,
  FormHelperText,
  FormLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
} from '@mui/material';
import { Search, Close } from '@mui/icons-material';
import { withControl } from '@features/effector-form/hoc/withControl';

export const SearchControl = withControl(
  ({ value, setValue, error, label, required, readOnly, disabled }) => (
    <FormControl required={required} disabled={disabled}>
      {label ? <FormLabel>{label}</FormLabel> : null}

      <OutlinedInput
        value={value}
        error={Boolean(error)}
        onChange={(e) => setValue(e.target.value)}
        readOnly={readOnly}
        disabled={disabled}
        placeholder="Поиск..."
        startAdornment={
          <InputAdornment>
            <Search />
          </InputAdornment>
        }
        endAdornment={
          value ? (
            <InputAdornment>
              <IconButton onClick={() => setValue('')}>
                <Close />
              </IconButton>
            </InputAdornment>
          ) : null
        }
      />
      {error ? <FormHelperText error>{error}</FormHelperText> : null}
    </FormControl>
  )
);
