import { useStore } from 'effector-react';
import makeStyles from '@mui/styles/makeStyles';
import { ErrorBoundary } from '@features/error';
import { Navbar } from '@features/navbar';
import { $isFixed } from '@features/navbar/models';
import { Authenticated, PushContainer } from '../../features/common';
import { Header } from '../Header';
import { Main } from '../Main';

const useStyles = makeStyles({
  msf: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    height: '100vh',
    overflow: 'hidden',
  },
  msf__content: (isFixed) => ({
    display: 'grid',
    gridTemplateRows: 'auto 1fr',
    paddingLeft: isFixed ? 300 : 75,
    gridTemplateColumns: 'auto',
  }),
});

function PageTemplate(props) {
  const isFixed = useStore($isFixed);
  const classes = useStyles(isFixed);

  return (
    <Authenticated>
      <div className={classes.msf}>
        <PushContainer />
        <Navbar />
        <div className={classes.msf__content}>
          <ErrorBoundary>
            <Header />
            <Main />
          </ErrorBoundary>
        </div>
      </div>
    </Authenticated>
  );
}

export default PageTemplate;
