import { useEffect } from 'react';
import { setCookie } from '@tools/cookie';
import { parseUrlParams } from '@tools/parseUrlParams';
import { FilterMainDetailLayout } from '@ui/templates';

import { DashboardGate, getUserInfo } from '../model/dashboard';

import { WidgetsGrid } from '../organisms/widgets-grid';

export const DashboardFullscreenPage = () => {
  useEffect(() => {
    const token = parseUrlParams('access_token');
    setCookie('bms_token', token);
    getUserInfo();
  }, []);

  const scale = Number(parseUrlParams('scale')) || 1;
  const repeat = Number(parseUrlParams('repeat')) || 4;
  const complexId = parseUrlParams('complex_id');

  const styles = {
    scale: String(scale),
    width: `calc(100% / ${scale})`,
    transformOrigin: '0px 0px',
    gridTemplateColumns: `repeat(${repeat}, 1fr)`,
  };

  return (
    <>
      <DashboardGate />
      <FilterMainDetailLayout
        main={<WidgetsGrid styles={styles} complexId={complexId} />}
        params={{ filterWidth: '400px' }}
      />
    </>
  );
};
