import { memo } from 'react';
import { connect } from 'react-redux';
import {
  filterChangeVisibility,
  filterData,
  fetchFlats,
} from '../../../../redux/actions/objects';
import { Wrapper } from '../../../../ui';
import ObjectsFilterForm from './ObjectsFilterForm';

const ObjectsFilter = memo((props) => {
  return (
    <div>
      <Wrapper>
        <ObjectsFilterForm {...props} />
      </Wrapper>
    </div>
  );
});

const mapStateToProps = (state) => ({
  filters: state.objects.filter.filters,
  tableKind: state.objects.kind,
});

const mapDispatchToProps = (dispatch) => ({
  openCloseFilter: () => {
    dispatch(filterChangeVisibility());
  },
  onFilter: (values) => {
    dispatch(filterData(values));
  },
  filterFlats: () => {
    dispatch(fetchFlats());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ObjectsFilter);
