import * as React from 'react';
import { FC } from 'react';
import { useUnit } from 'effector-react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { ObjectTree } from '@features/effector-form/controls/object/components/objectTree';
import {
  $currentObject,
  $isObjectsLoading,
} from '@features/effector-form/controls/object/model';
import { ObjectDialogProps } from '@features/effector-form/controls/object/types/common';
import * as Styled from '@features/objects/organisms/sidebar/styled';

export const ObjectDialog: FC<ObjectDialogProps> = ({ setValue }) => {
  const [isObjectsLoading, currentObject] = useUnit([$isObjectsLoading, $currentObject]);
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSelectCurrent = () => {
    setValue({
      guid: currentObject?.guid,
      type: currentObject?.type,
      title: currentObject?.title,
    });
    setOpen(false);
  };

  return (
    <div>
      <Button onClick={handleClickOpen} variant="text">
        Выбрать
      </Button>

      <Dialog open={open} onClose={handleClose} fullWidth>
        {isObjectsLoading ? <Styled.Loader /> : null}

        <DialogTitle>Выбор объекта</DialogTitle>

        <DialogContent style={{ maxHeight: 600 }}>
          <ObjectTree />
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose} variant="text" color="error">
            Отмена
          </Button>
          <Button onClick={handleSelectCurrent} disabled={!currentObject}>
            Выбрать текущий объект
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
