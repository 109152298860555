import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles({
  select: {
    cursor: 'pointer',
  },
  defaultSelect: {
    color: '#0394E3',
    display: 'flex',
    alignItems: 'flex-end',
  },
  icon: {
    marginRight: 5,
  },
  iconLabel: {
    margin: '0 5px',
  },
  label: {
    margin: '10px 0',
    color: '#65657B',
  },
  buttons: {
    display: 'flex',
  },
  button: {
    display: 'flex',
    alignItems: 'center',
  },
  modalContainer: {
    borderRadius: 8,
    border: '1px solid #E7E7EC',
    margin: '20px 0',
    height: 'calc(100% - 40px)',
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
  },
  block: {
    paddingTop: '24px',
  },
  blockBorder: {
    borderRight: '1px solid #E7E7EC',
  },
  modalLabel: {
    marginLeft: 24,
    marginBottom: 30,
    color: '#65657B',
    fontWeight: 500,
    fontSize: 16,
  },
  complexItem: {
    padding: '5px 15px',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    fontWeight: 500,
  },
  activeComplexItem: {
    background: '#E1EBFF',
  },
  checkboxLabel: {
    fontSize: 14,
    padding: '8px 0',
    fontWeight: 500,
  },
  checkboxRoot: {
    margin: '2px 0 2px 12px',
  },
  scrollContent: {
    height: '55vh',
  },
  selectedCountBlock: {
    background: '#0394E3',
    color: 'white',
    borderRadius: 14,
    padding: '4px 10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: 'auto',
  },
  errorText: {
    color: '#d32f2f',
    marginTop: 10,
    fontSize: '0.75rem',
  },
});
