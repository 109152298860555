import { memo } from 'react';
import { connect } from 'react-redux';
import {
  detailChangeVisibility,
  fetchEntrances,
  openCloseEntity,
  detailChangeMode,
  updateCreateObject,
  addEntrance,
  saveFlat,
  dialogChangeVisibility,
  fetchRequestVariants,
} from '../../../../redux/actions/objects';
import { Wrapper } from '../../../../ui';
import DetailForm from './DetailForm';

const Detail = memo((props) => {
  return (
    <Wrapper style={{ height: '100%' }}>
      <DetailForm {...props} />
    </Wrapper>
  );
});

const getEntity = (kind, state) => {
  switch (kind) {
    case 'buildings':
      return state.houses.data[state.objects.detail.open];
    case 'flats':
      return state.flats.data[state.objects.detail.open];
    case 'complexes':
      return state.complexes.data[state.objects.detail.open];
    default:
      return null;
  }
};
const mapStateToProps = (state) => ({
  openedEntity:
    state.objects.detail.open !== null ? getEntity(state.objects.kind, state) : null,
  requestVariants: state.objects.requestVariants.items,
  mode: state.objects.detail.mode,
  entity: state.objects.kind,
  detailEntrances: state.objects.detailEntrances,
  regions: Object.values(state.complexes.regions.data),
  isObjectsLoading: state.objects.isFetching,
});

const mapDispatchToProps = (dispatch) => ({
  openCloseDetail: () => {
    dispatch(detailChangeVisibility(false));
    dispatch(openCloseEntity(null));
    dispatch(detailChangeMode('view'));
  },
  loadEntrances: (id) => {
    dispatch(fetchEntrances(id));
  },
  loadRequestVariants: () => {
    dispatch(fetchRequestVariants());
  },
  openCloseDialog: () => {},
  onEdit: () => {
    dispatch(detailChangeMode('edit'));
  },
  onCancel: () => {
    dispatch(detailChangeMode('view'));
  },
  onSaveObject: (payload) => {
    dispatch(updateCreateObject(payload));
  },
  onSaveEntrances: (payload) => {
    dispatch(addEntrance(payload));
  },
  onSaveFlat: (payload) => {
    dispatch(saveFlat(payload));
  },
  onDelete: () => {
    dispatch(dialogChangeVisibility({ isOpen: true, kind: 'delete' }));
  },
});

const DetailContainer = connect(mapStateToProps, mapDispatchToProps)(Detail);

export default DetailContainer;
