import { useEffect, useState } from 'react';
import { useStore } from 'effector-react';
import { fxGetList, $list, $isLoading, $error } from '../model';

interface UseSelectProps {
  name: string;
  url: string;
  path?: string;
  payload?: { [key: string]: number };
  apiVersion?: 'no_vers' | 'v1' | 'v2' | 'v4';
}

type List = { [key: number]: number; title: string };

type ListByParam = {
  [key: string]: number | string;
};

export const useSelect = ({
  name,
  url,
  path = '',
  payload = {},
  apiVersion = 'v1',
}: UseSelectProps): {
    data: any;
    getListByParams: (params: { [key: string]: number | string }) => void;
    isLoading: boolean;
    error: string | null;
  } => {
  const options = useStore($list);
  const isLoading = useStore($isLoading);
  const error = useStore($error);

  const [params, setParams] = useState<{ [key: string]: Object }>({});

  const paths = path.length > 0 ? path.split('.') : [];

  const getListByParams = (payload: ListByParam) => {
    fxGetList({
      url,
      name,
      path: paths,
      payload: { ...params[name], ...payload },
      apiVersion,
    });
    setParams({
      ...params,
      [name]: payload,
    });
  };

  useEffect(() => {
    if (!options[name]) {
      fxGetList({ url, name, path: paths, payload, apiVersion });
      setParams({
        ...params,
        [name]: payload,
      });
    }
  }, [name]);

  return {
    data: options[name] || [],
    getListByParams,
    isLoading,
    error,
  };
};
