import { sample, merge, forward, attach, guard } from 'effector';
import { pending } from 'patronum/pending';
import { recordsApi } from '../../api';
import { $filters, filtersSubmitted } from '../filters/filters.model';
import {
  IndicatorsWidgetGate,
  mount,
  unmount,
  tick,
  startInterval,
  stopInterval,
} from '../widget/widget.model';
import {
  $rawData,
  $error,
  $signalsTypes,
  fxGetRecords,
  fxGetSignals,
  fxGetSignalsTypes,
  openLevel,
  closeLevel,
  getSignals,
  upadeSignals,
  $signals,
  $popupData,
  SIGNALS_ON_PAGE,
  $pagOffset,
  $signalsRaw,
  changePagOffset,
} from './records.model';
import { fxSetToOrFromArchive } from '../archive/archive.model';

fxGetRecords.use(recordsApi.getRecords);
fxGetSignals.use(recordsApi.getSignals);
fxGetSignalsTypes.use(recordsApi.getSignalsTypes);

const errorOccured = merge([fxGetRecords.fail, fxGetSignals.fail]);

$error
  .on(errorOccured, (_, { error }) => {
    return error.toString().replace(/Error:/, '');
  })
  .reset($rawData);

$rawData.on(fxGetRecords.doneData, preprocessRecords);

$pagOffset.on(changePagOffset, (_, page) => page).reset(closeLevel);

$signals
  .on(fxGetSignals.done, (_, { params, result }) => ({
    ...params,
    signals: result?.items || [],
  }))
  .reset(closeLevel);

$signalsRaw.on(fxGetSignals.done, (_, data) => data).reset(closeLevel);

$signalsTypes.on(
  fxGetSignalsTypes.doneData,
  (_, data) => data['signal-notification-types']
);

/** Триггерим запрос сигналов по по выбранной категории/уровню
 * при выборе категории, и после обновления категорий. */
forward({
  from: [openLevel, upadeSignals],
  to: getSignals,
});

/** Запуск интервала при маунте, запрос типов сигналов, сброс фильтров */
forward({
  from: mount,
  to: [fxGetSignalsTypes, filtersSubmitted],
});

/** Запускаю интервал после получения записей для виджетов, если не нахожусь в архиве */
sample({
  clock: fxGetRecords.done,
  source: $filters,
  filter: (filters, _) => !filters.archive,
  target: startInterval,
});

/** Останавливаю интервал после получения записей для виджетов, если нахожусь в архиве */
sample({
  clock: fxGetRecords.done,
  source: $filters,
  filter: (filters, _) => !!filters.archive,
  target: stopInterval,
});

/** Общий запрос категорий по интервалу, на маунте, при фильтрации, переводе в/из архива */
sample({
  source: [$filters, IndicatorsWidgetGate.state],
  clock: [mount, tick, filtersSubmitted, fxSetToOrFromArchive.done],
  target: attach({
    source: [$filters, IndicatorsWidgetGate.state],
    effect: fxGetRecords,
    mapParams: (_, [filters, state]) => {
      const payload = {};

      if (filters.object) {
        payload.complex_id = filters.object;
      }

      if (filters.house) {
        payload.building_id = filters.house;
      }

      /* parse link dashboard-fullscreen */
      if (state.complexId) {
        payload.complex_id = state.complexId;
      }

      payload.archive = filters.archive ? 1 : 0;

      return payload;
    },
  }),
});

/** Запрос сигналов с фильтрами, вызывать только через событие getSignals */
sample({
  clock: [getSignals, $pagOffset],
  source: getSignals,
  fn: (data) => data,
  target: attach({
    source: [$filters, $pagOffset],
    effect: fxGetSignals,
    mapParams: (signalsQuery, [filters, pagOffset]) => {
      const payload = {};

      if (filters.object) {
        payload.complex_id = filters.object;
      }

      if (filters.house) {
        payload.building_id = filters.house;
      }

      payload.archive = filters.archive ? 'only_arvhived' : 'not_archived';

      if (signalsQuery.category_id) {
        payload.category_id = signalsQuery.category_id;
      }

      if (signalsQuery.level_id) {
        payload.level_id = signalsQuery.level_id;
      }

      payload.limit = SIGNALS_ON_PAGE;
      payload.offset = pagOffset;

      return payload;
    },
  }),
});

/** Останавливаем запросы по интервалу при выходе из раздела */
forward({
  from: unmount,
  to: stopInterval,
});

/** Обновление сигналов после изменения архивного статуса сигнала */
sample({
  clock: fxSetToOrFromArchive.done,
  source: $popupData.map(({ category_id, level_id }) => ({
    category_id,
    level_id,
  })),

  filter: ({ category_id, level_id }) => !!category_id || !!level_id,
  target: getSignals,
});

function preprocessRecords(_, data) {
  if (!data.length) return [];

  return data.map((item) => ({
    category: item.category,
    levels: item.levels,
    info: item.info,
  }));
}

/** Обновление сигналов в открытом попапе */
guard({
  clock: fxGetRecords.done,
  source: $popupData.map(({ category_id, level_id }) => ({
    category_id,
    level_id,
  })),
  filter: ({ category_id, level_id }) => !!category_id || !!level_id,
  target: upadeSignals,
});
