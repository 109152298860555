import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useStore } from 'effector-react';
import { $currentPage } from '@features/profile/models';
import { Password } from '../../pages/password';
import { Personal } from '../../pages/personal';
import { useStyles } from './styles';

export const ProfileContent: FC = () => {
  const { t } = useTranslation();
  const currentPage = useStore($currentPage);
  const classes = useStyles();

  const renderPageTitle = () => {
    switch (currentPage) {
      case 'personal':
        return t('ProfilePage.PersonalData');
      case 'password':
        return t('ProfilePage.ChangePassword');
      default:
        return null;
    }
  };

  const renderContent = () => {
    switch (currentPage) {
      case 'personal':
        return <Personal />;
      case 'password':
        return <Password />;
      default:
        return null;
    }
  };

  return (
    <div className={classes.profileContent}>
      <div className={classes.profileContent__header}>
        <span className={classes.profileContent__title}>{renderPageTitle()}</span>
      </div>

      <div className={classes.profileContent__content}>{renderContent()}</div>
    </div>
  );
};
