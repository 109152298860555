import i18n from '@shared/config/i18n';
import FormSection from './FormSection';

const { t } = i18n;

export const SimpleInput = (props) => {
  const { label, ...restProps } = props;

  const sectionLabel = label || t('Value');

  return (
    <FormSection
      {...restProps}
      label={sectionLabel}
      placeholder={`${t('EnterText')} ${sectionLabel.toLowerCase()}`}
    />
  );
};
