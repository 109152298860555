import { cloneElement } from 'react';
import PropTypes from 'prop-types';

export const MessagePoll = (props) => {
  const { poll, children: element } = props;

  const data = poll
    ? {
        options: poll.options,
        title: poll.title,
        isVoted: poll.voted,
        votesNumber: poll.count,
      }
    : {};

  const pollElement = cloneElement(element, {
    ...data,
    ...element.props,
  });

  return poll ? pollElement : null;
};

MessagePoll.propTypes = {
  children: PropTypes.element.isRequired,
  poll: PropTypes.oneOf([
    PropTypes.shape({
      options: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string,
          count: PropTypes.number,
          id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
          percent: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        })
      ),
      title: PropTypes.string.isRequired,
      voted: PropTypes.bool.isRequired,
      count: PropTypes.number.isRequired,
    }),
    undefined,
  ]),
};
