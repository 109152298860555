import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import addDays from 'date-fns/addDays';
import format from 'date-fns/format';
import isDate from 'date-fns/isDate';
import startOfDay from 'date-fns/startOfDay';
import subDays from 'date-fns/subDays';
import { Grid } from '@mui/material';
import { dateFnsLocale } from '@tools/dateFnsLocale';
import ArrowBackIosOutlinedIcon from '@mui/icons-material/ArrowBackIosOutlined';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import { InputControl, ActionIconButton } from '../../index';

const getNewValue = (date = new Date()) => {
  const today = startOfDay(date, { locale: dateFnsLocale });
  const title = format(today, 'dd MMM yyyy', {
    locale: dateFnsLocale,
  });

  return {
    today,
    title,
  };
};

const DayControl = (props) => {
  const { onChange, value = new Date(), name, ...rest } = props;

  const [value_, setValue] = useState(value);

  if (isDate(value_)) {
    const newValue = getNewValue(value);
    setValue(newValue);
  }

  const switchToNextDay = () => {
    const newValue = getNewValue(addDays(value_.today, 1));
    if (onChange) {
      onChange(newValue);
    }

    setValue(newValue);
  };

  const switchToPrevDay = () => {
    const newValue = getNewValue(subDays(value_.today, 1));
    if (onChange) {
      onChange(newValue);
    }

    setValue(newValue);
  };

  const handleChange = (value) => {
    const newValue = getNewValue(value);
    if (onChange) {
      onChange(newValue);
    }
    setValue(newValue);
  };

  return (
    <Grid container wrap="nowrap" alignItems="center">
      <Grid item>
        <ActionIconButton
          size="small"
          onClick={switchToPrevDay}
          style={{ margin: '0 5px' }}
        >
          <ArrowBackIosOutlinedIcon fontSize="small" />
        </ActionIconButton>
      </Grid>
      <Grid item style={{ width: '100%' }}>
        <DatePicker
          customInput={
            <InputControl
              disabled
              label={null}
              divider={false}
              name={name}
              value={value_.title}
              inputProps={{ style: { textAlign: 'center', minWidth: 100 } }}
              {...rest}
            />
          }
          selected={value_.today}
          onChange={handleChange}
          locale={dateFnsLocale}
          dateFormat="dd MMMM yyyy"
        />
      </Grid>
      <Grid item>
        <ActionIconButton
          size="small"
          onClick={switchToNextDay}
          style={{ margin: '0 5px' }}
        >
          <ArrowForwardIosOutlinedIcon fontSize="small" />
        </ActionIconButton>
      </Grid>
    </Grid>
  );
};

export { DayControl };
