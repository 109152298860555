import { api } from '../../../api/api2';

const getList = (payload) => api.v1('get', 'notifications/list', { ...payload });
const markAsRead = (read = []) =>
  api.v1('post', 'notifications/read', { notifications: read });

const markAllAsRead = () => api.v1('post', 'notifications/read-all');

export const notificationApi = {
  getList,
  markAsRead,
  markAllAsRead,
};
