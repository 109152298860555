import Divider from '../atoms/Divider';
import FormControl from '../atoms/FormControl';
import OutlinedInput from '../atoms/OutlinedInput';
import ViewInput from '../atoms/ViewInput';

function FormSection(props) {
  let {
    divider,
    placeholder,
    label,
    mode,
    type = 'text',
    field = {},
    form = {},
    name = '',
    labelPlacement = 'top',
    ControlComponent,
    ControlLabel,
    required,
    ViewComponent = null,
    ...restProps
  } = props;

  ControlComponent =
    mode === 'view'
      ? ViewComponent !== null
        ? ViewComponent
        : ViewInput
      : ControlComponent || OutlinedInput;

  let error = false;
  let errorMessage = '';

  if (Object.keys(form).length !== 0 && Object.keys(form.errors).length > 0) {
    name = name.length === 0 ? field.name : name;

    const { errors } = form;

    error =
      Object.prototype.hasOwnProperty.call(errors, name) && errors[name].length !== 0;
    errorMessage = errors[name];
  }

  return (
    <>
      <FormControl
        label={
          required && mode === 'edit' ? (
            <span>
              {label}
              <span className="text-red"> * </span>
            </span>
          ) : (
            <span>{label}</span>
          )
        }
        labelPlacement={labelPlacement}
        ControlLabel={ControlLabel}
        control={
          <ControlComponent
            placeholder={placeholder}
            style={{
              width: '100%',
            }}
            name={name}
            error={error}
            {...field}
            inputProps={{
              type,
            }}
            {...restProps}
            mode={mode}
          />
        }
      />
      {error ? <div style={{ color: '#EB5757' }}>{errorMessage}</div> : null}
      {divider ? <Divider marginTop="19.5px" marginBottom="22px" /> : null}
    </>
  );
}

export default FormSection;
