import Dialog from '@mui/material/Dialog';
import withStyles from '@mui/styles/withStyles';

const ModalCardImage = withStyles({
  paper: {
    position: 'relative',
    borderRadius: '15px',
    boxShadow: '0px 4px 15px #6A6A6E',
    padding: '24px',
    maxWidth: '100%',
  },
})(Dialog);

export { ModalCardImage };
