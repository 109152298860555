import Chip from '@mui/material/Chip';
import Close from '@mui/icons-material/Close';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
  smallChipRoot: {
    height: '100%',
    display: 'flex',
    padding: '6px 8px',
    backgroundColor: '#E7E7EC',
    '&:focus': {
      backgroundColor: '#E7E7EC',
    },
  },
  smallChipLabel: {
    color: '#65657B',
    fontSize: 13,
    fontStyle: 'normal',
    fontFamily: 'Roboto, sans-serif',
    fontWeight: 500,
    padding: '0 5px',
    overflowWrap: 'break-word',
    whiteSpace: 'normal',
    textOverflow: 'clip',
  },
});

export const MultiSelectValue = (props) => {
  const { selectProps } = props;
  const isDisabled = selectProps.isDisabled || selectProps.mode === 'view';
  const classes = useStyles();

  const value = props.options.find((option) => option.title === props.children);

  const onClick = () => {
    if (selectProps.mode === 'view' && value?.id && selectProps?.path) {
      window.open(`${window.location.origin}/${selectProps.path}/${value.id}`);
    }
  };

  return (
    <Chip
      classes={{ root: classes.smallChipRoot, label: classes.smallChipLabel }}
      tabIndex={-1}
      label={props.children}
      onDelete={props.removeProps.onClick}
      onClick={onClick}
      deleteIcon={
        <Close
          {...props.removeProps}
          style={{
            fontSize: '1em',
            margin: '0 5px 0 0',
            display: isDisabled ? 'none' : 'block',
          }}
        />
      }
    />
  );
};
