import withStyles from '@mui/styles/withStyles';
import i18n from '@shared/config/i18n';
import { CloseButton } from '../../ui';
import FormSectionHeader from '../atoms/FormSectionHeader';
import GreedyElement from '../atoms/GreedyElement';
import Toolbar from '../atoms/Toolbar';

const { t } = i18n;

const styles = {
  margin: {
    margin: '0 5px 30px 5px ',
  },
};

function FilterToolbar(props) {
  const { classes, openCloseFilter } = props;
  return (
    <Toolbar>
      <FormSectionHeader header={t('Filter')} margin={30} />
      <GreedyElement />
      <CloseButton onClick={openCloseFilter} className={classes.margin} />
    </Toolbar>
  );
}

export default withStyles(styles, { withTheme: true })(FilterToolbar);
