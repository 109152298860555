import {
  createStore,
  createEffect,
  createEvent,
  combine,
  sample,
  restore,
} from 'effector';
import Indicator1 from '@img/indicators/indicator_1.svg';
import Indicator2 from '@img/indicators/indicator_2.svg';
import Indicator3 from '@img/indicators/indicator_3.svg';
import Indicator4 from '@img/indicators/indicator_4.svg';
import Indicator5 from '@img/indicators/indicator_5.svg';
import Indicator6 from '@img/indicators/indicator_6.svg';
import Indicator7 from '@img/indicators/indicator_7.svg';
import Indicator8 from '@img/indicators/indicator_8.svg';
// import Indicator9 from '@img/indicators/indicator_9.svg';
import Indicator10 from '@img/indicators/indicator_10.svg';
import Indicator11 from '@img/indicators/indicator_11.svg';
import Indicator12 from '@img/indicators/indicator_12.svg';
import Indicator13 from '@img/indicators/indicator_13.svg';
import Indicator14 from '@img/indicators/indicator_14.svg';
import Indicator15 from '@img/indicators/indicator_15.svg';
import i18n from '@shared/config/i18n';

const { t } = i18n;

export const SIGNALS_ON_PAGE = 10;
const SIGNALS_DEFAULT_OFFSET = 0;

/** TODO: кажется, запросы, ошибки и события открытия
 * и закрытия попапа относятся к widget.model
 */

export const fxGetRecords = createEffect();
export const fxGetSignals = createEffect();
export const fxGetSignalsTypes = createEffect();

export const $isSignalsLoading = restore(fxGetSignals.pending, false);
export const $isRecordsLoading = restore(fxGetRecords.pending, false);

export const openLevel = createEvent();
export const closeLevel = createEvent();
export const getSignals = createEvent();
export const upadeSignals = createEvent();
export const changePagOffset = createEvent();

export const $rawData = createStore([]);
export const $error = createStore(null);
export const $signalsTypes = createStore([]);
export const $signals = createStore([]);
export const $signalsRaw = createStore({});
export const $pagOffset = createStore(SIGNALS_DEFAULT_OFFSET);

export const $categoriesTemplate = createStore([
  {
    id: 77,
    title: t('Ventilation'),
    disconnectedMessage: t('IndicatorsDisconnected.VentilationDisconnected'),
    icon: (className) => <Indicator1 className={className} />,
  },
  {
    id: 82,
    title: t('Conditioning'),
    disconnectedMessage: t('IndicatorsDisconnected.ConditioningDisconnected'),
    icon: (className) => <Indicator2 className={className} />,
  },
  {
    id: 95,
    title: t('WaterSupply'),
    disconnectedMessage: t('IndicatorsDisconnected.WaterSupplyDisconnected'),
    icon: (className) => <Indicator3 className={className} />,
  },
  {
    id: 70,
    title: t('HeatSupply'),
    disconnectedMessage: t('IndicatorsDisconnected.HeatSupplyDisconnected'),
    icon: (className) => <Indicator4 className={className} />,
  },
  {
    id: 115,
    title: t('FireAlarm'),
    disconnectedMessage: t('IndicatorsDisconnected.FireAlarmDisconnected'),
    icon: (className) => <Indicator5 className={className} />,
  },
  {
    id: 97,
    title: t('PowerSupply'),
    disconnectedMessage: t('IndicatorsDisconnected.PowerSupplyDisconnected'),
    icon: (className) => <Indicator7 className={className} />,
  },
  {
    id: 90,
    title: t('WaterDisposalStormWaterInletsPipelines'),
    disconnectedMessage: t(
      'IndicatorsDisconnected.WaterDisposalStormWaterInletsPipelinesDisconnected'
    ),
    icon: (className) => <Indicator8 className={className} />,
  },
  {
    id: 100,
    title: t('Lighting'),
    disconnectedMessage: t('IndicatorsDisconnected.LightingDisconnected'),
    icon: (className) => <Indicator6 className={className} />,
  },
  {
    id: 104,
    title: t('LiftEconomy'),
    disconnectedMessage: t('IndicatorsDisconnected.LiftEconomyDisconnected'),
    icon: (className) => <Indicator10 className={className} />,
  },
  {
    id: 107,
    title: t('SmokeProtectionSystems'),
    disconnectedMessage: t('IndicatorsDisconnected.SmokeProtectionSystemsDisconnected'),
    icon: (className) => <Indicator11 className={className} />,
  },
  {
    id: 111,
    title: t('AutomaticFireExtinguishing'),
    disconnectedMessage: t(
      'IndicatorsDisconnected.AutomaticFireExtinguishingDisconnected'
    ),
    icon: (className) => <Indicator12 className={className} />,
  },
  {
    id: 119,
    title: t('ACSBarriersPasses'),
    disconnectedMessage: t('IndicatorsDisconnected.ACSBarriersPassesDisconnected'),
    icon: (className) => <Indicator13 className={className} />,
  },
  {
    id: 130,
    title: t('VideoSurveillance'),
    disconnectedMessage: t('IndicatorsDisconnected.VideoSurveillanceDisconnected'),
    icon: (className) => <Indicator14 className={className} />,
  },
  {
    id: 133,
    title: t('SmartHouse'),
    disconnectedMessage: t('IndicatorsDisconnected.SmartHouseDisconnected'),
    icon: (className) => <Indicator15 className={className} />,
  },
]);

const $levelsTemplate = createStore([
  {
    id: 147,
    title: t('information'),
    count: 0,
    viewed: true,
    levelClassName: 'card__listItem_blue',
  },
  {
    id: 146,
    title: t('Warning'),
    count: 0,
    viewed: true,
    levelClassName: 'card__listItem_orange',
  },
  {
    id: 145,
    title: t('Anxiety'),
    count: 0,
    viewed: true,
    levelClassName: 'card__listItem_red',
  },
]);

/** Мерджим стор с записями в стор с категориями,
 * заполняем пустые категории пустыми уровнями */
export const $records = combine(
  [$rawData, $categoriesTemplate, $levelsTemplate],

  ([records, categories, levels]) =>
    categories.map(({ disconnectedMessage, ...item }) => {
      const matchedRecord = records.find((record) => record.category.id === item.id);
      if (matchedRecord) {
        return {
          ...item,
          levels: matchedRecord.levels.reverse(),
          info: matchedRecord.info,
          isConnected: true,
        };
      }
      return { ...item, levels, isConnected: false, disconnectedMessage };
    })
);

/** Собираем содержимое для попапа когда появляются сигналы по выбранной категории
 * При закрытии окна сигналы очищаются в результате чего очищается и $popupData */
export const $popupData = sample({
  source: $records,
  clock: $signals,
  fn: (records, { signals, category_id, level_id }) => {
    const record = records.find(({ id }) => id === category_id);
    const level = record?.levels.find(({ id }) => id === level_id);
    return { signals, category_id, level_id, record, level };
  },
});
