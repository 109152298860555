import { components } from 'react-select';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import ArrowDropUp from '@mui/icons-material/ArrowDropUp';

function SelectIndicator(props) {
  const { selectProps } = props;

  const Indicator = (indProps) =>
    selectProps.menuIsOpen ? (
      <ArrowDropUp {...indProps} />
    ) : (
      <ArrowDropDown {...indProps} />
    );

  return (
    <components.DropdownIndicator {...props}>
      <Indicator style={{ cursor: 'pointer' }} />
    </components.DropdownIndicator>
  );
}

export { SelectIndicator };
