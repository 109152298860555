import { useState, useEffect, FC } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {
  EditorState,
  ContentState,
  convertToRaw,
  Modifier,
  DraftInlineStyleType,
} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import i18n from '@shared/config/i18n';
import { styles } from './styles';
import { IProps } from './types';

const { t } = i18n;

const toolbar = {
  options: ['inline', 'list', 'link'],
  inline: { options: ['italic', 'underline', 'strikethrough'] },
  blockType: {
    inDropdown: true,
    options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'Blockquote', 'Code'],
    className: styles.blockType,
  },
};

const CustomEditor: FC<IProps> = (props) => {
  const { text, placeholder = t('EnterContent') } = props;

  const initialEditorState = ContentState.createFromText('');

  const [editorState, setEditorState] = useState(
    EditorState.createWithContent(initialEditorState)
  );

  const setContent = (text: string) => {
    const contentBlock = htmlToDraft(text);
    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
    const editorState = EditorState.createWithContent(contentState);

    setEditorState(editorState);
  };

  useEffect(() => {
    setContent(text);
  }, []);

  useEffect(() => {
    if (!text) {
      setContent(text);
    }
  }, [text]);

  const onEditorStateChange = (editorState: EditorState) => {
    setEditorState(editorState);
  };

  return (
    <div style={styles.editorRoot}>
      <Editor
        placeholder={placeholder}
        toolbar={toolbar}
        localization={{
          locale: 'ru',
          translations: {
            'components.controls.blocktype.blocktype': t('List'),
          },
        }}
        editorState={editorState}
        editorStyle={styles.editor}
        toolbarStyle={styles.toolbar}
        onEditorStateChange={onEditorStateChange}
        handlePastedText={(text) => {
          const pastedContent = Modifier.insertText(
            editorState.getCurrentContent(),
            editorState.getSelection(),
            text.replace(/\s?style=".+"/g, '')
          );
          setEditorState(
            EditorState.push(editorState, pastedContent, 'insert-characters')
          );
          return true;
        }}
        onChange={() => {
          const rawContentState = convertToRaw(editorState.getCurrentContent());

          const rawEscapeQuptes = {
            ...rawContentState,
            blocks: rawContentState.blocks.map((block) => ({
              ...block,
              inlineStyleRanges: block.inlineStyleRanges.map((i) => ({
                ...i,
                style: i.style.replace(/"/g, '&quot;') as DraftInlineStyleType,
              })),
            })),
          };
          const html = draftToHtml(rawEscapeQuptes);
          props.onChange(html);
        }}
      />
    </div>
  );
};

export { CustomEditor as Editor };
