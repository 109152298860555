import { createEffect, sample } from 'effector';
import { createGate } from 'effector-react';
import { cache, createQuery, localStorageCache } from '@farfetched/core';
import { signout } from '@features/common';
import { rentTimeApi } from '@features/effector-form/controls/rent-time/api';
import {
  RentTimePayload,
  RentTimeResponse,
} from '@features/effector-form/controls/rent-time/types';

export const dividerForSeconds = 1000;

export const RentTimeControlGate = createGate<{
  rentalDate: Date;
  rentObject: string;
}>();

const fxGetRentTime = createEffect<RentTimePayload, RentTimeResponse[], Error>().use(
  rentTimeApi.getRentTime
);

export const rentTimeQuery = createQuery({
  name: 'rentTime',
  effect: fxGetRentTime,
  mapData: ({ result }) =>
    Array.isArray(result)
      ? result.map((data) => ({
          id: `${data.rent_start}-${data.rent_finish}`,
          label: data.slot_title,
          start: data.rent_start,
          finish: data.rent_finish,
        }))
      : [],
});

cache(rentTimeQuery, {
  adapter: localStorageCache({ maxAge: '5m' }),
  purge: signout,
});

sample({
  clock: RentTimeControlGate.state,
  filter: ({ rentalDate, rentObject }: any) =>
    Boolean(rentalDate && new Date(rentalDate as Date).toString() !== 'Invalid Date') &&
    (new Date(rentalDate as Date) as any) / dividerForSeconds > 0 &&
    rentObject,
  fn: ({ rentalDate, rentObject }) => {
    const year = rentalDate.getFullYear();
    const month = rentalDate.getMonth();
    const day = rentalDate.getDate();

    const timestampUtc = Date.UTC(year, month, day, 0, 0, 0, 0) / dividerForSeconds;

    return {
      area_id: rentObject,
      time_date: timestampUtc,
    };
  },
  target: rentTimeQuery.start,
});
