import { useEffect, useState } from 'react';
import { useStore } from 'effector-react';
import { SelectControl, SelectField } from '../../../../ui';
import { $data, $error, $isLoading, fxGetList } from '../../models';

const HouseSelect = (props) => {
  const {
    complex = [],
    include = [],
    firstAsDefault = false,
    isDisabled = false,
    params = {},
  } = props;

  const [isOpenMenu, changeStatusMenu] = useState(false);

  let options = useStore($data);
  const isLoading = useStore($isLoading);
  const error = useStore($error);

  options = complex.length
    ? options.filter((build) => complex.find(({ id }) => id === build.complex.id))
    : options;

  options = include.length > 0 ? options.filter((i) => include.includes(i.id)) : options;

  const alreadyLoaded = options.length;

  const openMenu = () => changeStatusMenu(true);
  const closeMenu = () => changeStatusMenu(false);

  useEffect(() => {
    if (!isLoading && !alreadyLoaded) {
      fxGetList(params);
    }
  }, []);

  useEffect(() => {
    if (!props.value && firstAsDefault && options.length > 0) {
      props.onChange(options[0]);
    }
  }, [options.length]);

  useEffect(() => {
    if (isDisabled) {
      closeMenu();
    }
  }, [isDisabled]);

  return (
    <SelectControl
      isLoading={isLoading}
      error={error}
      options={options}
      menuIsOpen={isOpenMenu}
      onMenuOpen={openMenu}
      onMenuClose={closeMenu}
      isDisabled={isDisabled}
      {...props}
    />
  );
};

const HouseSelectField = (props) => {
  const onChange = (value) => props.form.setFieldValue(props.field.name, value);
  const getOptionLabel = ({ building = {} }) => {
    const { title = '', alias = '' } = building;
    return alias ? `${alias} [ ${title} ]` : title;
  };

  return (
    <SelectField
      component={<HouseSelect />}
      onChange={props.onChange || onChange}
      getOptionLabel={getOptionLabel}
      {...props}
    />
  );
};

export { HouseSelect, HouseSelectField };
