import EditIcon from '@mui/icons-material/Edit';
import i18n from '@shared/config/i18n';
import { ActionIconButton } from '../..';

const { t } = i18n;

function EditButton({ isHideTitleAccess = false, ...props }) {
  return (
    <ActionIconButton data-test-id="edit-btn" {...props}>
      <EditIcon titleAccess={!isHideTitleAccess ? t('edit') : null} />
    </ActionIconButton>
  );
}

export { EditButton };
