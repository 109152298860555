import * as React from 'react';
import { FormControl, FormHelperText, FormLabel, TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { Listbox } from '@features/effector-form/components/listbox';
import { withControl } from '@features/effector-form/hoc/withControl';
import { SelectControlType } from './types';

export const SelectControl = withControl<SelectControlType>(
  ({ value, setValue, error, label, required, readOnly, disabled, options }) => {
    return (
      <FormControl required={required} disabled={disabled}>
        {label ? <FormLabel>{label}</FormLabel> : null}

        <Autocomplete
          value={!options ? null : options?.find((item) => item.id === value)}
          options={!options ? [] : options}
          onChange={(event: any, newValue: any) => {
            setValue(newValue?.id || null);
          }}
          ListboxComponent={Listbox}
          renderInput={(params): JSX.Element => (
            <TextField
              {...params}
              InputProps={{
                ...params.InputProps,
                readOnly,
                endAdornment: readOnly ? null : params.InputProps.endAdornment,
              }}
            />
          )}
          readOnly={readOnly}
          disabled={disabled}
        />

        {error ? <FormHelperText error>{error}</FormHelperText> : null}
      </FormControl>
    );
  }
);
