import { styled } from '@mui/material/styles';

export const Container = styled('div')(() => ({
  position: 'fixed',
  display: 'grid',
  gridTemplateColumns: 'auto auto',
  justifyContent: 'space-between',
  width: '100%',
  padding: 20,

  '& > *': {
    color: '#f2f2f2',
    fontSize: 14,
  },

  '& fieldset': {
    display: 'none',
  },
}));
