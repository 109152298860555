import { useLocation } from 'react-router-dom';

import { ErrorBoundary } from '@features/error';

import { HaveAccess, NoSectionAccess } from '../..';

const HaveSectionAccess = (props) => {
  const { children } = props;

  const location = useLocation();
  /** Из адреса берем только первый уровень */
  const sectionName = location.pathname.split('/')[1];

  return (
    <ErrorBoundary>
      <HaveAccess
        to={`${sectionName}-section-all-restriction`}
        ifnot={<NoSectionAccess />}
      >
        {children}
      </HaveAccess>
    </ErrorBoundary>
  );
};

export { HaveSectionAccess };
