import makeStyles from '@mui/styles/makeStyles';
import { TableHeaderRow } from '@devexpress/dx-react-grid-material-ui';

const useStyles = makeStyles({
  cellText: {
    color: '#7D7D8E',
    fontWeight: '900',
    fontFamily: 'Roboto, sans-serif',
    fontSize: '1.5em',
  },
});

function TableHeaderCell({ children, ...props }) {
  const { cellText } = useStyles();

  return (
    <TableHeaderRow.Cell {...props}>
      <span className={cellText}>{children}</span>
    </TableHeaderRow.Cell>
  );
}

export { TableHeaderCell };
