import InputBase from '@mui/material/InputBase';
import withStyles from '@mui/styles/withStyles';

const styles = {
  input: {
    height: '20px',
    width: '100%',
    color: '#65657B',
    fontWeight: '500',
    fontSize: '13px',
    display: 'flex',
    padding: '0',
  },
};

function ViewInput(props) {
  const { classes, getOptionValue, getOptionLabel, dispatch, value, ...restProps } =
    props;
  return (
    <InputBase
      readOnly
      classes={{ root: classes.input }}
      value={getOptionLabel ? getOptionLabel(value) : value}
      {...restProps}
    />
  );
}

export default withStyles(styles)(ViewInput);
