import isEqual from 'react-fast-compare';
import { combine, createDomain, is, sample, Store } from 'effector';
import { debug, reset as patronumReset } from 'patronum';
import check from 'check-types';
import lodashExtend from 'lodash/extend';
import lodashGet from 'lodash/get';
import lodashOmit from 'lodash/omit';
import lodashSet from 'lodash/set';
import * as Yup from 'yup';
import { ru } from 'yup-locales';
import {
  FormConfig,
  FormErrors,
  FormInstance,
  FormUnits,
  FormValidationParams,
} from '../types/common';

// Установка локализации
if (typeof window !== 'undefined') {
  if (localStorage.getItem('lang') === 'ru') {
    Yup.setLocale(ru);
  }
}

export const createForm = <V extends { [key: string]: any }>(
  config: FormConfig<V>
): FormInstance<V> => {
  const domain = createDomain(config.sid ? `📋${config.sid}` : '📋');
  // eslint-disable-next-line
  const isExternalInitialValues = is.store(config.initialValues);

  /** ---- СТОРЫ ---- */

  const $initialValues: FormUnits<V>['$initialValues'] = domain.createStore(
    isExternalInitialValues
      ? ({ ...config.initialValues.getState() } as V)
      : ({ ...config.initialValues } as V)
  );
  // Вариант из filled out
  // const $initialValues: FormUnits<V>['$initialValues'] = isExternalInitialValues
  //   ? (config.initialValues as Store<V>)
  //   : (domain.createStore(config.initialValues) as Store<V>);
  const $validationSchema: FormUnits<V>['$validationSchema'] = domain.createStore(
    config.validationSchema || null
  );
  const $values: FormUnits<V>['$values'] = domain.createStore(
    isExternalInitialValues ? ({} as V) : ({ ...config.initialValues } as V)
  );
  const $validationErrors: FormUnits<V>['$validationErrors'] = domain.createStore({});
  const $externalErrors: FormUnits<V>['$externalErrors'] = domain.createStore({});
  const $errors: FormUnits<V>['$errors'] = combine(
    $validationErrors,
    $externalErrors,
    (validationErrors, externalErrors) => ({
      ...externalErrors,
      ...validationErrors,
    })
  );
  const $editable = domain.createStore(config?.editable || false);
  const $valid = domain.createStore(true);
  const $dirty = domain.createStore(false);
  const $submittable = combine($editable, $dirty, (editable, dirty) => editable && dirty);

  /** ---- КОМАНДЫ ---- */

  const submit: FormUnits<V>['submit'] = domain.createEvent();
  const reset: FormUnits<V>['reset'] = domain.createEvent();
  const initCurrent: FormUnits<V>['initCurrent'] = domain.createEvent();
  const initValues: FormUnits<V>['initValues'] = domain.createEvent();
  const setEditable: FormUnits<V>['setEditable'] = domain.createEvent();
  const setValue: FormUnits<V>['setValue'] = domain.createEvent();
  const setValues: FormUnits<V>['setValues'] = domain.createEvent();
  const updateValues: FormUnits<V>['updateValues'] = domain.createEvent();
  const arrayUnshift: FormUnits<V>['arrayUnshift'] = domain.createEvent();
  const arrayPush: FormUnits<V>['arrayPush'] = domain.createEvent();
  const arrayDelete: FormUnits<V>['arrayDelete'] = domain.createEvent();
  const arrayMove: FormUnits<V>['arrayMove'] = domain.createEvent();
  const setValidationSchema: FormUnits<V>['setValidationSchema'] = domain.createEvent();
  const setError: FormUnits<V>['setError'] = domain.createEvent();
  const setErrors: FormUnits<V>['setErrors'] = domain.createEvent();
  const updateErrors: FormUnits<V>['updateErrors'] = domain.createEvent();
  const deleteError: FormUnits<V>['deleteError'] = domain.createEvent();
  const resetErrors: FormUnits<V>['resetErrors'] = domain.createEvent();

  /** ---- СОБИТИЯ ---- */
  const changed: FormUnits<V>['changed'] = domain.createEvent();
  const submitted: FormUnits<V>['submitted'] = domain.createEvent();
  const rejected: FormUnits<V>['rejected'] = domain.createEvent();

  // Промис для валидации
  const fxValidate = domain.createEffect<FormValidationParams<V>, void, FormErrors>(
    async ({ values, schema }) => {
      try {
        await schema.validate(values, { abortEarly: false });
      } catch (error) {
        if (Yup.ValidationError.isError(error)) {
          const result: Record<string, any> = {};

          error.inner.forEach((data) => {
            if (data.path) {
              result[data.path] = data.message.replace(data.path, '').trim();
            }
          });

          // eslint-disable-next-line @typescript-eslint/no-throw-literal
          throw result;
        }
      }
    }
  );

  /** ---- ЗНАЧЕНИЯ ---- */

  // Установка значения
  sample({
    clock: setValue,
    source: $values,
    filter: (_, { path }) => check.string(path),
    fn: (state, { path, value }) => ({ ...lodashSet(state, path, value) }),
    target: $values,
  });

  // Полная установка значений
  sample({
    clock: setValues,
    filter: (values) => check.object(values),
    target: $values,
  });

  // Частичная установка значений
  sample({
    clock: updateValues,
    source: $values,
    filter: (_, values) => check.object(values),
    fn: (state, values) => ({ ...lodashExtend({}, state, values) }),
    target: $values,
  });

  // Установка изначальных значений при сбросе формы
  sample({
    clock: reset,
    source: $initialValues,
    target: $values,
  });

  // Триггер ивента при любом изменении значений
  sample({
    clock: $values,
    target: changed,
  });

  /** ---- ЗНАЧЕНИЯ: МАССИВЫ ---- */

  // Добавление элемента в начало массива
  sample({
    clock: arrayUnshift,
    source: $values,
    filter: (values, { path }) =>
      check.string(path) && check.array(lodashGet(values, path)),
    fn: (values, { path, value }) => ({
      path,
      value: [value, ...lodashGet(values, path)],
    }),
    target: setValue,
  });

  // Добавление элемента в конец массива
  sample({
    clock: arrayPush,
    source: $values,
    filter: (values, { path }) =>
      check.string(path) && check.array(lodashGet(values, path)),
    fn: (values, { path, value }) => ({
      path,
      value: [...lodashGet(values, path), value],
    }),
    target: setValue,
  });

  // Удаление элемента
  sample({
    clock: arrayDelete,
    source: $values,
    filter: (values, { path, index }) =>
      check.string(path) && check.number(index) && check.array(lodashGet(values, path)),
    fn: (values, { path, index }) => {
      const array = lodashGet(values, path);

      array.splice(index, 1);

      return {
        path,
        value: array,
      };
    },
    target: setValue,
  });

  // Изменение индекса элемента
  sample({
    clock: arrayMove,
    source: $values,
    filter: (values, { path, source, target }) =>
      check.string(path) &&
      check.number(source) &&
      check.number(target) &&
      check.array(lodashGet(values, path)) &&
      source !== target,
    fn: (values, { path, source, target }) => {
      const fieldArray = lodashGet(values, path);
      const element = fieldArray[source];
      fieldArray.splice(source, 1);
      fieldArray.splice(target, 0, element);
      return { path, value: fieldArray };
    },
    target: setValue,
  });

  /** ---- ВАЛИДАЦИЯ ---- */

  // Установка схемы валидации
  sample({
    clock: setValidationSchema,
    filter: (schema) => Yup.isSchema(schema) || check.null(schema),
    target: $validationSchema,
  });

  // Валидация после ввода формы
  sample({
    clock: submit,
    source: { validationSchema: $validationSchema, values: $values },
    filter: (schema) => schema !== null,
    fn: ({ validationSchema, values }) => ({
      values,
      schema: validationSchema as Yup.Schema<Partial<V>>,
    }),
    target: fxValidate,
  });

  // Вызов результирующего ивента после успешной валидации
  sample({
    clock: fxValidate.done,
    source: $values,
    filter: $submittable,
    target: submitted,
  });

  // Сброс внешних ошибок при успешной валидации
  patronumReset({
    clock: fxValidate.done,
    target: $externalErrors,
  });

  // Сброс ошибок после успешной валидации
  sample({
    clock: fxValidate.done,
    fn: () => ({}),
    target: $validationErrors,
  });

  // Установка ошибок валидации
  sample({
    clock: fxValidate.failData,
    target: [$validationErrors, rejected],
  });

  // Прокидываем успешный ввод формы во внешний юнит
  if (config.onSubmit) {
    // @ts-ignore
    sample({
      clock: submitted,
      target: config.onSubmit,
    });
  }

  // Прокидываем неудачный ввод с ошибками во внешний юнит
  if (config.onReject) {
    // @ts-ignore
    sample({
      clock: rejected,
      target: config.onReject,
    });
  }

  if (config.resetOn) {
    // @ts-ignore
    sample({
      clock: config.resetOn,
      target: reset,
    });
  }

  /** ---- ВНЕШНИЕ ОШИБКИ ---- */

  // Установка ошибки
  sample({
    clock: setError,
    source: $externalErrors,
    filter: (_, { path, error }) => check.string(path) && check.string(error),
    fn: (state, { path, error }) => ({
      ...state,
      [path]: error,
    }),
    target: $externalErrors,
  });

  // Установка объекта с ошибками
  sample({
    clock: setErrors,
    filter: (errors) =>
      check.object(errors) && Object.values(errors).every((error) => check.string(error)),
    target: $externalErrors,
  });

  // Обновление объекта с ошибками
  sample({
    clock: updateErrors,
    source: $externalErrors,
    filter: (_, errors) =>
      check.object(errors) && Object.values(errors).every((error) => check.string(error)),
    fn: (state, errors) => lodashExtend({}, state, errors),
    target: $externalErrors,
  });

  // Удаление ошибки
  sample({
    clock: deleteError,
    source: $externalErrors,
    filter: (_, { path }) => check.string(path),
    fn: (state, { path }) => lodashOmit(state, path),
    target: $externalErrors,
  });

  // Сброс ошибок
  sample({
    clock: resetErrors,
    fn: () => ({}),
    target: $externalErrors,
  });

  /** ---- СОСТОЯНИЕ ФОРМЫ ---- */

  // Установка режима редактирования
  sample({
    clock: setEditable,
    filter: (value) => check.boolean(value),
    target: $editable,
  });

  // Установка валидности
  sample({
    source: $errors,
    fn: (errors) => Boolean(!Object.keys(errors).length),
    target: $valid,
  });

  // Установка чистоты
  sample({
    clock: changed,
    source: { values: $values, initialValues: $initialValues },
    fn: ({ values, initialValues }) => !isEqual(initialValues, values),
    target: $dirty,
  });

  /** ---- РЕИНИЦИАЛИЗАЦИЯ ---- */

  if (isExternalInitialValues) {
    // Вызываем ивент при изменении внешнего стора со значениями
    sample({
      clock: config.initialValues as Store<V>,
      target: initValues,
    });

    // Обновление начальных значений из внешнего стора со значениями
    sample({
      clock: initValues,
      filter: (values) => check.object(values),
      target: $initialValues,
    });

    // Установка значений на основе начальных значений
    sample({
      clock: $initialValues,
      target: $values,
    });
  } else {
    // Инициализация текущих значений
    sample({
      clock: initCurrent,
      source: $values,
      fn: (values) => ({ ...values }),
      target: $initialValues,
    });

    // Инициализация значениями
    sample({
      clock: initValues,
      filter: (values) => check.object(values),
      fn: (values) => ({ ...values }),
      target: $initialValues,
    });

    // Установка значений
    sample({
      clock: initValues,
      filter: (values) => check.object(values),
      target: $values,
    });
  }

  // Сброс метовых сторов при повторной инициализации
  patronumReset({
    clock: [initCurrent, initValues],
    target: [$validationErrors, $externalErrors, $editable, $valid, $dirty],
  });

  /** ---- СБРОСЫ ---- */

  // Сброс формы по команде
  patronumReset({
    clock: reset,
    target: [
      $values,
      $validationSchema,
      $validationErrors,
      $externalErrors,
      $editable,
      $valid,
      $dirty,
    ],
  });

  /** ---- ОТЛАДКА ---- */

  if (config.debug) {
    debug(domain);
  }

  return {
    $initialValues,
    $validationSchema,
    $values,
    $validationErrors,
    $externalErrors,
    $errors,
    $editable,
    $valid,
    $dirty,
    $submittable,

    editable: $editable,
    valid: $valid,
    dirty: $dirty,
    submittable: $submittable,
    initialValues: $initialValues,
    validationSchema: $validationSchema,
    values: $values,
    validationErrors: $validationErrors,
    externalErrors: $externalErrors,
    errors: $errors,

    submit,
    reset,
    initCurrent,
    initValues,
    setEditable,
    setValue,
    setValues,
    updateValues,
    arrayUnshift,
    arrayPush,
    arrayDelete,
    arrayMove,
    setValidationSchema,
    setError,
    setErrors,
    updateErrors,
    deleteError,
    resetErrors,

    changed,
    submitted,
    rejected,
  };
};
