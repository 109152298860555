import firebase from 'firebase/app';
import 'firebase/messaging';
import { sendTokenToServer } from '../api/api';

// Initialize Firebase
const config = {
  apiKey: process.env.FIREBASE_API_KEY,
  authDomain: process.env.FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.FIREBASE_DATABASE_URL,
  projectId: process.env.FIREBASE_PROJECT_ID,
  storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.FIREBASE_APP_ID,
};

export const initializeFirebase = () => {
  if (Array.isArray(firebase.apps) && firebase.apps.length === 0) {
    firebase.initializeApp(config);
  }
};

export const askForRecieveNotification = async () => {
  try {
    const messaging = firebase.messaging();

    // Deprecated
    // await messaging.requestPermission();

    await Notification.requestPermission();

    const token = await messaging.getToken();
    sendTokenToServer(token);

    return token;
  } catch (error) {
    console.error(error);
    // throw new Error(error.message);
  }
};
