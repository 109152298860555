import { sample } from 'effector';
import { recordsApi } from '../../api';
import { $filters, filtersSubmitted } from '../filters/filters.model';
import {
  fxSetToOrFromArchive,
  $isArchiveLoading,
  setToOrFromArchiveEvent,
  addVisibleArchiveBlockSignals,
  resetToArchiveComment,
} from './archive.model';

fxSetToOrFromArchive.use(recordsApi.setToOrFromArchive);

/** Отсылка данных о переносе сигнала в архив или возврате из архива только если сейчас нет такого же запроса */
sample({
  source: $isArchiveLoading,
  clock: setToOrFromArchiveEvent,
  filter: (isArchiveLoading, _) => !isArchiveLoading,
  fn: (_, data) => data,
  target: fxSetToOrFromArchive,
});

/** Стираю старый комментарий перевода сигнала в архив при открытии новой формы перевода в архив */
sample({
  clock: addVisibleArchiveBlockSignals,
  target: resetToArchiveComment,
});
