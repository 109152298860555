import { api } from '@api/api2';
import { RentBuildingsPayload } from '@features/effector-form/controls/rent-building-controls/types';

const getRentBuildings = (payload: RentBuildingsPayload) =>
  api.v4('get', 'area/objects/simple/list', {
    ...payload,
    ...{ fast_filters: { is_building: 1 } },
  });

export const rentBuildingsApi = { getRentBuildings };
