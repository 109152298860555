import { FieldComponent, SwitchControl } from '../..';

const SwitchField = (props) => {
  const { field, form, ...rest } = props;

  return (
    <FieldComponent component={<SwitchControl {...rest} />} field={field} form={form} />
  );
};

export { SwitchField };
