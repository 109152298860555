import { useMemo } from 'react';
import { useUnit } from 'effector-react';
import { getIn } from '../lib/getIn';
import { Control, ControlComponent } from '../types/control';

export function withControl<T>(Component: ControlComponent<T>): Control<T> {
  return function ({
    form,
    label = null,
    name,
    required = false,
    readOnly = false,
    disabled = false,
    ...props
  }) {
    const { values, errors, editable, setValue } = useUnit(form);
    const value = useMemo(() => getIn(values, name), [values, name]);
    const error = useMemo(() => (errors ? errors[name] : null), [errors]);
    const isError = useMemo(() => Boolean(error), [error]);

    const handleSetValue = (newValue: any) => {
      setValue({ path: name, value: newValue });
    };

    return useMemo(
      () => (
        <Component
          label={label}
          value={value}
          setValue={handleSetValue}
          error={error}
          isError={isError}
          required={required}
          disabled={disabled}
          readOnly={!editable || readOnly}
          {...(props as T)}
        />
      ),
      [
        label,
        value,
        error,
        editable,
        required,
        readOnly,
        disabled,
        Object.keys(props).length ? props : null,
      ]
    );
  };
}
