import { createEffect, createEvent, forward } from 'effector';
import { alert, warning, notification } from '@sounds/index';

declare type SoundParams = {
  type: string;
  volume?: number;
};

const audio = new Audio();

const playSound = createEvent<SoundParams>();
const fxPlaySound = createEffect<SoundParams, void, Error>(
  ({ type = 'notification', volume }) => {
    if (type === 'warning') {
      audio.volume = volume || 0.03;
      audio.src = warning;
    }

    if (type === 'alert') {
      audio.volume = volume || 0.1;
      audio.src = alert;
    }

    if (type === 'notification') {
      audio.volume = volume || 1;
      audio.src = notification;
    }

    audio.play();
  }
);

forward({
  from: playSound,
  to: fxPlaySound,
});

export { playSound };
