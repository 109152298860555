import { createEffect, sample } from 'effector';
import { createGate } from 'effector-react';
import { cache, createQuery, localStorageCache } from '@farfetched/core';
import { signout } from '@features/common';
import { rentBuildingsApi } from '@features/effector-form/controls/rent-building-controls/api';
import {
  RentBuildingsPayload,
  RentBuildingsResponse,
} from '@features/effector-form/controls/rent-building-controls/types';

export const RentBuildingsControlGate = createGate();

const fxGetRentBuildings = createEffect<
  RentBuildingsPayload,
  RentBuildingsResponse,
  Error
>().use(rentBuildingsApi.getRentBuildings);

export const rentBuildingsQuery = createQuery({
  name: 'rentBuilding',
  effect: fxGetRentBuildings,
  mapData: ({ result }) =>
    result.areaList.map((item) => ({
      id: item?.guid,
      label: item?.building?.title || '',
    })),
});

cache(rentBuildingsQuery, {
  adapter: localStorageCache({ maxAge: '5m' }),
  purge: signout,
});

sample({
  clock: RentBuildingsControlGate.state,
  filter: ({ complexes }) =>
    Array.isArray(complexes) ? complexes.length > 0 : Boolean(complexes),
  fn: ({ complexes }) => {
    if (Array.isArray(complexes)) {
      return { parent_guids: complexes };
    }

    if (typeof complexes === 'string') {
      return { parent_guids: [complexes] };
    }

    return null;
  },
  target: rentBuildingsQuery.start,
});
