import { ChangeEvent } from 'react';
import {
  FormControl,
  FormHelperText,
  FormLabel,
  OutlinedInput,
  OutlinedInputProps,
} from '@mui/material';
import { withControl } from '@features/effector-form/hoc/withControl';

export const TextControl = withControl<OutlinedInputProps>(
  ({
    label,
    value,
    setValue,
    error,
    isError,
    required,
    readOnly,
    disabled,
    ...props
  }) => {
    const handleChange = (e: ChangeEvent<HTMLInputElement>) => setValue(e.target.value);

    return (
      <FormControl>
        {label && <FormLabel required={required}>{label}</FormLabel>}

        <OutlinedInput
          {...props}
          value={value}
          error={isError}
          onChange={handleChange}
          required={required}
          readOnly={readOnly}
          disabled={disabled}
        />

        {error && <FormHelperText error>{error}</FormHelperText>}
      </FormControl>
    );
  }
);
