export default class FormDataRequest {
  constructor(request) {
    this._request = request;
  }

  request() {
    return this._request.request().header('Content-Type', 'multipart/form-data');
  }

  send() {
    return this.request().send();
  }
}
