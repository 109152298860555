import { combine, createEffect, createEvent, createStore } from 'effector';
import { createGate } from 'effector-react';
import { pending } from 'patronum/pending';
import lodashSet from 'lodash/set';
import {
  GetObjectsRequest,
  GetObjectsResponse,
  ObjectInstance,
  Objects,
  ObjectsTree,
} from '@features/effector-form/controls/object/types/common';
import { objectsApi } from '@features/objects/api';

export const ObjectTreeGate = createGate();

export const fxGetObjects = createEffect<
  GetObjectsRequest,
  GetObjectsResponse,
  Error
>().use(objectsApi.getObjects);

export const $objects = createStore<Objects>({});
export const $objectsTree = combine($objects, (objects) => {
  if (!Object.keys(objects).length) return [];

  const dataset = Object.values(objects);

  const hashTable: Record<any, any> = {};
  dataset.forEach((aData) => ({
    ...lodashSet(hashTable, aData.guid, { ...aData, children: [] }),
  }));
  const dataTree: ObjectsTree | [] = [];
  dataset.forEach((aData) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    if (aData.parent) hashTable[aData.parent].children.push(hashTable[aData.guid]);
    else {
      // @ts-ignore
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      dataTree.push(hashTable[aData.guid]);
    }
  });
  return dataTree;
});
export const $currentObjectGuid = createStore<ObjectInstance['guid'] | null>(null);
export const $currentObject = combine(
  $currentObjectGuid,
  $objects,
  (guid, objects) => (guid && objects[guid]) || null
);
export const $currentObjectType = $currentObject.map((object) => object?.type);
export const $isObjectsLoading = pending({ effects: [fxGetObjects] });
export const $expandedObjects = createStore<ObjectInstance['guid'][]>([]);
export const $selectedObjects = createStore<ObjectInstance['guid'][]>([]);

export const getObjects = createEvent();
export const openObject = createEvent<ObjectInstance['guid']>();
export const refreshObjects = createEvent();
export const setExpandedObjects = createEvent<ObjectInstance['guid'][]>();
export const setSelectedObjects = createEvent<ObjectInstance['guid'][]>();
export const collapseAllObjects = createEvent<void>();
export const expandAllObjects = createEvent<void>();

export const $error = createStore<any>(null);
export const errorOccured = createEvent<any>();
