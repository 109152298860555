import SearchIcon from '@mui/icons-material/Search';
import i18n from '@shared/config/i18n';
import OutlinedInput from '../atoms/OutlinedInput';

const { t } = i18n;

function SearchInput(props) {
  return (
    <OutlinedInput
      placeholder={t('search')}
      inputProps={{ 'data-testid': 'search-box' }}
      {...props}
      endAdornment={<SearchIcon style={{ color: '#0288d1' }} />}
      style={{
        maxWidth: '250px',
        flexGrow: '1',
        minWidth: '100px',
      }}
    />
  );
}

export default SearchInput;
