import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  profileContent: {
    position: 'relative',
    display: 'grid',
    alignItems: 'start',
    alignContent: 'start',
    height: '100%',
    overflow: 'hidden',
    background: 'white',
    boxShadow: 'rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px',
    borderRadius: 12,
  },
  profileContent__header: {
    display: 'grid',
    borderBottom: '1px solid rgb(0, 0, 0, 0.1)',
  },
  profileContent__title: {
    padding: 20,
    fontWeight: 'bold',
    fontSize: 16,
  },
  profileContent__content: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    justifySelf: 'center',
    gap: 15,
    padding: 24,
    width: '100%',
    maxWidth: 900,
  },
  profileContent__actions: {
    position: 'absolute',
    top: 15,
    right: 24,
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    gap: 5,
  },
  profileContent__saveButton: {
    padding: '0 16px 0 6px',
    boxShadow: 'none',
  },
});
