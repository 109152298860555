import { forwardRef, Ref } from 'react';
import classNames from 'classnames';
import OutlinedInput from '@mui/material/OutlinedInput';
import makeStyles from '@mui/styles/makeStyles';
import { useFormControl, formControlState } from '../..';

interface Props {
  classes?: object;
  multiline?: boolean;
  whiteBackground?: boolean;
  textAlign?: any;
  name?: string;
  value?: string;
  onChange?: void;
  inputProps?: object;
  placeholder?: string;
  style?: object;
  startAdornment?: JSX.Element | JSX.Element[];
  endAdornment?: JSX.Element | JSX.Element[];
  type?: string;
}

const useStyles = makeStyles({
  root: {
    height: '32px',
    width: '100%',

    // MUI 5 фикс бага с неизвестными отступами в 2px
    '& div': {
      marginTop: 0,
      marginBottom: 0,
    },
  },
  multiline: {
    height: 'auto',
  },
  input: {
    textAlign: ({ textAlign }: any) => textAlign || 'none',
    zIndex: 1,
    fontWeight: 500,
    fontSize: '13px',
    color: '#65657B',
    // important - костыль для преодоления мигающих селекторов
    // начиная с версии chrome 115
    display: 'flex !important',
    height: '24px',
    paddingTop: 0,
    paddingBottom: 0,
    '&[readonly]': {
      padding: 0,
    },
  },
  notchedOutline: {
    background: '#fff',
    top: -4,
    borderRadius: '15px',

    // MUI 5 баг с notched=true
    '& legend': {
      width: 0,
    },
  },
  readOnly: {
    border: 'none',
    background: 'unset',
  },
  whiteBackground: {
    background: 'white',
  },
});

const BaseInput = forwardRef((props: Props, ref: Ref<HTMLInputElement>) => {
  const {
    classes,
    multiline,
    whiteBackground = false,
    textAlign,
    name,
    value,
    onChange,
    inputProps,
    placeholder,
    style,
    startAdornment,
    endAdornment,
    type,
    ...rest
  } = props;

  const {
    root,
    input,
    notchedOutline,
    readOnly: ro,
    multiline: ml,
    whiteBackground: wb,
  } = useStyles({ textAlign });

  const contextProps = useFormControl();

  const fcs = formControlState({
    props,
    ctxProps: contextProps,
    states: ['readOnly', 'error', 'required', 'disabled'],
  });

  const notchedClassName = classNames(notchedOutline, {
    [ro]: fcs.readOnly,
    [wb]: whiteBackground,
  });

  const rootClassName = classNames(root, { [ml]: multiline });

  return (
    <OutlinedInput
      multiline={multiline}
      fullWidth={false}
      name={name}
      value={value}
      onChange={onChange}
      inputProps={inputProps}
      placeholder={placeholder}
      style={style}
      startAdornment={startAdornment}
      endAdornment={endAdornment}
      type={type}
      classes={{
        root: rootClassName,
        notchedOutline: notchedClassName,
        input,
      }}
      {...fcs}
      {...rest}
      autoComplete="off"
      inputRef={ref}
    />
  );
});

export { BaseInput };
