import PropTypes from 'prop-types';
import { CircularProgress, Grid } from '@mui/material';
import withStyles from '@mui/styles/withStyles';

const styles = {
  progressBackground__fixed: {
    background: 'rgba(255,255,255, 0)',
    width: 60,
    height: 60,
    margin: '0 auto',
  },
  progressBackground: {
    background: 'rgba(255,255,255, 0.5)',
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 11,
  },
};

function Loader(props) {
  const { isLoading, classes, fixed = false, ...rest } = props;

  return isLoading ? (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      className={fixed ? classes.progressBackground__fixed : classes.progressBackground}
    >
      <CircularProgress data-test-id="loader" {...rest} />
    </Grid>
  ) : null;
}

const StyledLoader = withStyles(styles)(Loader);

Loader.propTypes = {
  isLoading: PropTypes.bool.isRequired,
};

export { StyledLoader as Loader };
