import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Check, Close } from '@mui/icons-material';
import makeStyles from '@mui/styles/makeStyles';
import i18n from '@shared/config/i18n';
import { CustomModal, ActionButton } from '@ui/';
import { signOut } from '../../../redux/actions/userActionGenerator';
import { signout } from '../../common';

const { t } = i18n;

const useStyles = makeStyles({
  iconLabel: {
    margin: '0 5px',
  },
  buttons: {
    display: 'flex',
  },
  button: {
    display: 'flex',
    alignItems: 'center',
  },
});

const ProfileLogout = ({ isOpen, onClose, onExit }) => {
  const classes = useStyles();

  const actions = (
    <div className={classes.buttons}>
      <ActionButton
        className={classes.button}
        withMargin
        kind="positive"
        onClick={() => onExit()}
      >
        <Check />
        <div className={classes.iconLabel}>{t('yes')}</div>
      </ActionButton>
      <ActionButton className={classes.button} kind="negative" onClick={onClose}>
        <Close />
        <div className={classes.iconLabel}>{t('Cancellation')}</div>
      </ActionButton>
    </div>
  );

  return (
    <CustomModal
      content={<h3>{t('AreYouSureYouWantToExit')}</h3>}
      isOpen={isOpen}
      actions={actions}
      header={t('Logout')}
      onClose={onClose}
      titleFontSize={24}
      minWidth="528px"
    />
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    onExit: async () => {
      signout();
      dispatch(signOut());
    },
  };
};

export default connect(null, mapDispatchToProps)(withRouter(ProfileLogout));
