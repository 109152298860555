import { cloneElement } from 'react';
import MTabs from '@mui/material/Tabs';
import withStyles from '@mui/styles/withStyles';
import { Tab } from '../tab';

const styles = {
  tabsRoot: {
    borderBottom: '1px solid #e8e8e8',
    minHeight: 32,
  },

  scrollButtons: {
    width: '25px',
    height: '25px',
    alignSelf: 'center',
  },
  indicator: {},
};

export const Tabs = withStyles(styles)((props) => {
  const {
    options = [],
    currentTab,
    onChange,
    classes,
    isNew,
    tabEl: tabElement = <Tab />,
    scroll = 'auto',
    variant = 'scrollable',
    ...restProps
  } = props;

  const { tabsRoot, scrollButtons, indicator } = classes;

  const tabsList = options.map((opt) => {
    const { label, value, onCreateIsDisabled, disabled } = opt;

    return cloneElement(tabElement, {
      label,
      value,
      disabled: (isNew && onCreateIsDisabled) || disabled,
      key: `tab-${value}`,
    });
  });

  return (
    <MTabs
      value={currentTab}
      classes={{ root: tabsRoot, scrollButtons, indicator }}
      onChange={onChange}
      variant={variant}
      scrollButtons={scroll}
      {...restProps}
    >
      {tabsList}
    </MTabs>
  );
});
