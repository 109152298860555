import PropTypes from 'prop-types';
import { Grid, ListItem, ListItemText } from '@mui/material';
import withStyles from '@mui/styles/withStyles';

const styles = () => {
  return {
    item: {
      marginBottom: 5,
      minWidth: 200,
      '&:hover': {
        background: 'rgba(255,255,255, .5)',
      },
    },
    votedItem: {
      background: 'rgba(255,255,255, .5)',
    },
    itemText: {
      padding: 0,
      zIndex: 2,
    },
    votingProgress: {
      background: 'rgba(255,255,255, .7)',
      position: 'absolute',
      height: '100%',
      left: 0,
    },
  };
};

const PollOption = (props) => {
  const { isVoted, onVote, classes, title, id, count, percent } = props;
  return (
    <ListItem
      button={!isVoted}
      key={id}
      className={`${isVoted && classes.votedItem} ${classes.item}`}
      onClick={!isVoted ? () => onVote(id) : null}
    >
      <ListItemText className={classes.itemText}>
        <Grid container justifyContent="space-between" wrap="nowrap">
          <Grid item component="span">
            {title}
          </Grid>
          <Grid item component="span">
            {isVoted && count}
          </Grid>
          <Grid item component="span">
            {isVoted && percent + '%'}
          </Grid>
        </Grid>
      </ListItemText>
      {isVoted && (
        <span
          className={classes.votingProgress}
          style={{
            width: percent + '%',
          }}
        >
          {' '}
        </span>
      )}
    </ListItem>
  );
};

PollOption.propTypes = {
  title: PropTypes.string.isRequired,
  count: PropTypes.number.isRequired,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onVote: PropTypes.func.isRequired,
  isVoted: PropTypes.bool.isRequired,
};

const StyledPollOption = withStyles(styles)(PollOption);

export { StyledPollOption as PollOption };
