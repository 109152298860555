import { signout } from '@features/common';
import { getList } from '../api';
import { fxGetList, $isLoading, $error, $list } from './select.model';

fxGetList.use(getList);

$list
  .on(fxGetList.done, (state, { params: { name, path }, result }) => {
    const data = path.reduce((obj, trail) => obj[trail], result);
    return {
      ...state,
      [name]: data || [],
    };
  })
  .reset(signout);

$error.on(fxGetList.fail, (_, { error }) => error).reset(signout);
$isLoading.on(fxGetList.pending, (_, pending) => pending).reset(signout);
