import { useEffect } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import i18n from '@shared/config/i18n';
import { ActionButton as Button, File } from '../..';
import { useFileLoader } from './useFileLoader';
import { useFileReader } from './useFileReader';

const { t } = i18n;

const useStyles = makeStyles(() => ({
  input: {
    display: 'none',
  },
  label: {
    display: 'block',
    textAlign: 'center',
  },
}));

const renderDefaultButton = () => <Button component="span">{t('Downloads')}</Button>;
const renderDefaultPreview = (data) =>
  Array.isArray(data) ? (
    data.map((i, idx) => <File {...i} key={idx} />)
  ) : (
    <File {...data} />
  );

const selectFileType = (file) => {
  const imageMatcher = /^image/;
  const type = file?.meta?.type;

  const isImageType = imageMatcher.test(type);

  const newType = isImageType ? 'image' : 'file';

  if (type) {
    file.meta.newType = newType;
  }
};

const FileControl = (props) => {
  const {
    name = 'file',
    value: outerValue,
    mode = 'edit',
    onChange,
    encoding = 'base64',
    inputProps = {},
    labelProps = {},
    loadable = false,
    loadFn,
    api = 'v2',
    renderButton = renderDefaultButton,
    renderPreview = renderDefaultPreview,
  } = props;

  const classes = useStyles();
  const isMultiple = Boolean(inputProps.multiple);
  const isEditMode = mode === 'edit';

  const { read, readResult } = useFileReader({
    encoding: loadable ? (api === 'v2' ? 'file' : 'base64') : encoding,
    isMultiple,
  });

  const { upload, loadResult } = useFileLoader({ api, loadFn });
  const commit = (result) => {
    if (result) {
      onChange(result);
    }
  };

  useEffect(() => {
    const uploadOrCommit = async (readFile) => {
      if (Array.isArray(readFile)) {
        readFile.forEach((file) => selectFileType(file));
      } else {
        selectFileType(readFile);
      }

      if (loadable && readFile) {
        await upload(readFile);
      } else if (!loadable) {
        commit(readFile);
      }
    };

    uploadOrCommit(readResult);
  }, [readResult]);

  useEffect(() => {
    commit(loadResult);
  }, [loadResult]);

  const id = Math.random().toString(36).slice(2, 11);

  const button = renderButton();
  const preview =
    renderPreview &&
    renderPreview(
      isMultiple
        ? (
            (Array.isArray(loadResult) && loadResult) ||
            (Array.isArray(readResult) && readResult) ||
            (Array.isArray(outerValue) && outerValue) ||
            []
          ).map((source) => getFilePreview({ source }))
        : getFilePreview({ source: outerValue || loadResult || readResult })
    );

  return (
    <>
      {preview}
      <input
        type="file"
        className={classes.input}
        id={id}
        onChange={read}
        name={name}
        {...inputProps}
      />
      {isEditMode && button && (
        <label className={classes.label} htmlFor={id} {...labelProps}>
          {button}
        </label>
      )}
    </>
  );
};

function getFilePreview({ source }) {
  return {
    url: getFileValue({ source }),
    name: getFileName({ source }),
    mime: getFileMime({ source }),
    preview: getFileValue({ source }),
  };
}

function getFileValue({ source }) {
  return (source && source.preview) || '';
}

function getFileName({ source }) {
  return (source && source.meta && source.meta.name) || 'file';
}

function getFileMime({ source }) {
  return (source && source.meta && source.meta.type) || '';
}

export { FileControl, useFileReader };
