import i18n from '@shared/config/i18n';
import { CloseButton, Greedy, Toolbar, FormSectionHeader } from '../..';

const { t } = i18n;

function FilterToolbar(props) {
  const { closeFilter, title = t('Filter'), ...rest } = props;
  return (
    <Toolbar {...rest}>
      <FormSectionHeader style={{ margin: 0 }} header={title} />
      <Greedy />
      <CloseButton onClick={closeFilter} />
    </Toolbar>
  );
}

export { FilterToolbar };
