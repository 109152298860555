import { createEffect, sample } from 'effector';
import { createGate } from 'effector-react';
import { cache, createQuery, localStorageCache } from '@farfetched/core';
import { signout } from '@features/common';
import { buildingApi } from '@features/effector-form/controls/building-controls/api';
import {
  BuildingControlProps,
  BuildingRequest,
  BuildingResponse,
} from '@features/effector-form/controls/building-controls/types';

export const BuildingControlGate = createGate<BuildingControlProps>();

export const fxGetBuildings = createEffect<
  BuildingRequest,
  BuildingResponse,
  Error
>().use(buildingApi.getBuildings);

export const buildingQuery = createQuery({
  name: 'building',
  effect: fxGetBuildings,
  mapData: ({ result, params }) =>
    result.buildings.map((building) => ({
      id: building.building.id,
      label: building.building.title,
    })),
});

cache(buildingQuery, {
  adapter: localStorageCache({ maxAge: '5m' }),
  purge: signout,
});

sample({
  clock: BuildingControlGate.state,
  fn: (data) => ({
    complex_id: data.complex,
  }),
  target: buildingQuery.start,
});
