import * as React from 'react';
import { FormControl, FormHelperText, FormLabel, TextField } from '@mui/material';
import Autocomplete, { AutocompleteProps } from '@mui/material/Autocomplete';
import { Listbox } from '@features/effector-form/components/listbox';
import { withControl } from '@features/effector-form/hoc/withControl';

export const ObjectTypeControl = withControl<
  Partial<AutocompleteProps<any, any, any, any>>
>(({ value, setValue, error, label, required, readOnly, disabled, multiple }) => {
  const options = [
    { id: 'complex', label: 'Комплекс' },
    { id: 'building', label: 'Здание' },
    { id: 'entrance', label: 'Подъезд' },
    { id: 'floor', label: 'Этаж' },
    { id: 'elevator', label: 'Лифт' },
    { id: 'apartment', label: 'Апартаменты' },
    { id: 'room', label: 'Комната' },
    { id: 'passage', label: 'Переход' },
    { id: 'office', label: 'Офис' },
    { id: 'coworking', label: 'Коворкинг' },
    { id: 'parking_place', label: 'Парковка' },
    { id: 'parking_zone', label: 'Парковочная зона' },
    { id: 'parking_lot', label: 'Парковочное место' },
    { id: 'pantry', label: 'Кладовка' },
    { id: 'property_place', label: 'Техническое помещение' },
    { id: 'property', label: 'Помещение' },
    { id: 'workplace', label: 'Рабочее место' },
  ];

  const handleChange = (event: any, newValue: any) => {
    if (multiple) {
      setValue(newValue.map((item: any) => item.id) || null);
    } else {
      setValue(newValue?.id || null);
    }
  };

  const getValue = () =>
    multiple
      ? options.filter((item) => value?.indexOf(item.id) > -1)
      : options.find((item) => item.id === value);

  return (
    <FormControl required={required} disabled={disabled}>
      {label ? <FormLabel>{label}</FormLabel> : null}

      <Autocomplete
        value={getValue()}
        options={!options ? [] : options}
        onChange={handleChange}
        ListboxComponent={Listbox}
        renderInput={(params) => (
          <TextField
            {...params}
            InputProps={{
              ...params.InputProps,
              required,
              readOnly,
              disabled,
            }}
          />
        )}
        readOnly={readOnly}
        disabled={disabled}
        multiple={multiple}
      />

      {error ? <FormHelperText error>{error}</FormHelperText> : null}
    </FormControl>
  );
});
