import { useState, useEffect } from 'react';
import classnames from 'classnames';
import { Field, FieldArray } from 'formik';
import { Button, Collapse, IconButton } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import makeStyles from '@mui/styles/makeStyles';
import i18n from '@shared/config/i18n';
import { CloseButton, InputField, SwitchField, Divider, Loader } from '../../../../ui';
import FlatsMaskedInput from '../../../atoms/FlatsMaskedInput';

const { t } = i18n;

const useStyles = makeStyles({
  cardContainer: {
    padding: '12px 12px 6px 18px',
    width: '100%',
    background: '#EDF6FF',
    borderRadius: 16,
    marginTop: 24,
  },
  cardCollapse: {
    padding: '0 16px 16px 0',
  },
  gridContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    marginTop: 10,
  },
  labels: {
    color: '#65657B',
    fontWeight: 500,
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
  },
  labelTitle: {
    opacity: 0.7,
  },
  heading: {
    fontWeight: 'bold',
    fontSize: 24,
  },
  cardHeader: {
    display: 'flex',
    alignItems: 'flex-start',
    fontWeight: 'bold',
    fontSize: 24,
  },
  closeButton: {
    top: 9,
    right: 4,
    height: 15,
    position: 'absolute',
    display: 'block',
    zIndex: 2,
  },
  entranceBlock: {
    marginRight: 10,
  },
  inputBlock: {
    maxWidth: 100,
  },
  inputText: {
    fontWeight: 'bold',
    fontSize: 24,
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: 'transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
});

const EntrancesForm = (props) => {
  const {
    mode,
    openedEntity,
    handleReset,
    detailEntrances,
    values: { entrance_number, floors, auto_floor },
    setFieldValue,
    isObjectsLoading,
  } = props;

  const {
    cardContainer,
    cardHeader,
    heading,
    closeButton,
    gridContainer,
    labels,
    entranceBlock,
    inputBlock,
    inputText,
    expand,
    expandOpen,
    labelTitle,
    cardCollapse,
  } = useStyles();

  useEffect(() => {
    handleReset();
  }, [openedEntity && openedEntity.id]);
  const [expandedEntrances, setExpanded] = useState({});

  const handleExpandClick = (number) => {
    setExpanded({ ...expandedEntrances, [number]: !expandedEntrances[number] });
  };
  const isEditMode = mode === 'edit';
  const isEntrances = detailEntrances.length;
  const isEntranceBeingAdd = entrance_number !== null;
  if (!isEntrances && !isEditMode) {
    return <div className={heading}>{t('NoEntrances')}</div>;
  }
  const entrances = detailEntrances.map((entrance) => {
    const { number, apartments } = entrance;
    const expanded = expandedEntrances[number];
    const reducedAparts = apartments.reduce((acc, item) => {
      const temp = acc;
      const floorNumber = item.floor;
      if (acc[floorNumber]) {
        temp[floorNumber] = [...temp[floorNumber], item];
      } else {
        temp[floorNumber] = [item];
      }
      return temp;
    }, []);
    return (
      <div className={cardContainer} key={number}>
        <div className={cardHeader}>
          <span>{`${t('rizer')} ${number}`}</span>
          <IconButton
            onClick={() => handleExpandClick(number)}
            className={classnames(expand, { [expandOpen]: expanded })}
            size="large"
          >
            <ExpandMore />
          </IconButton>
        </div>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <div className={cardCollapse}>
            <div className={labels}>
              <div className={labelTitle}>{t('NumberOfFloors')}</div>
              <div className={labelTitle}>{t('NumberOfApartments')}</div>
              <div>{reducedAparts.filter((e) => e).length}</div>
              <div>{apartments.length}</div>
            </div>
            <Divider />
            <div className={labels}>
              <div className={labelTitle}>{t('Floors')}</div>
              <div className={labelTitle}>{t('ApartmentNumbers')}</div>
            </div>
            {reducedAparts.map((item, index) => (
              <div className={gridContainer} key={index}>
                <div>{`${t('Floor')} ${index}`}</div>
                <div>{item.map((i) => i.number).join(', ')}</div>
              </div>
            ))}
          </div>
        </Collapse>
      </div>
    );
  });

  const newEntrance = (
    <div className={cardContainer}>
      <div className={cardHeader}>
        <div style={{ marginRight: 15 }}>{t('rizer')}</div>
        <Field
          required
          whiteBackground
          label={null}
          divider={false}
          name="entrance_number"
          mode={mode}
          component={InputField}
          className={inputBlock}
          inputProps={{ className: inputText }}
          placeholder=""
        />
      </div>
      <div className={labels}>
        <div>{t('Floors')}</div>
        <div>{t('ApartmentNumbers')}</div>
      </div>
      <FieldArray name="floors">
        {(arrayHelpers) => (
          <>
            {floors.map((item, index, array) => (
              <div key={index}>
                <div className={gridContainer}>
                  <div className={entranceBlock}>
                    <Field
                      whiteBackground
                      required
                      label={null}
                      divider={false}
                      name={`floors[${index}].number`}
                      mode={mode}
                      component={InputField}
                      placeholder=""
                    />
                  </div>
                  <div>
                    <Field
                      name={`floors[${index}].range`}
                      required
                      render={({ field, form }) => (
                        <div style={{ position: 'relative' }}>
                          <InputField
                            field={field}
                            form={form}
                            inputComponent={FlatsMaskedInput}
                            divider={false}
                            placeholder="1-10"
                            whiteBackground
                            label={null}
                          />
                          {isEditMode && index === array.length - 1 ? (
                            <CloseButton
                              className={closeButton}
                              onClick={() => arrayHelpers.remove(index)}
                            />
                          ) : null}
                        </div>
                      )}
                    />
                  </div>
                </div>
              </div>
            ))}
            <Button
              style={{
                marginTop: floors.length > 0 ? '10px' : '0',
              }}
              color="primary"
              onClick={() => {
                if (auto_floor && floors.length) {
                  const lastFloor = floors[floors.length - 1];
                  const [first, last] = lastFloor.range.split('-');
                  const nextFirst = Number(last) + 1;
                  const nextLast = nextFirst + (Number(last) - Number(first));
                  arrayHelpers.push({
                    number: Number(lastFloor.number) + 1,
                    range: `${nextFirst}-${nextLast}`,
                  });
                } else {
                  arrayHelpers.push();
                }
              }}
            >
              {t('AddFloor')}
            </Button>
            <Field
              component={SwitchField}
              name="auto_floor"
              label={t('EnterApartmentsAutomaticallyWithFloorByFloorIndicationNumbers')}
              labelPlacement="end"
              divider={false}
            />
          </>
        )}
      </FieldArray>
    </div>
  );

  return (
    <>
      {entrances}
      {isEntranceBeingAdd && isEditMode && newEntrance}
      {!isEntranceBeingAdd && isEditMode ? (
        <Button
          style={{
            marginTop: isEntrances > 0 ? '10px' : '0',
          }}
          color="primary"
          onClick={() => {
            setFieldValue('floors', [
              {
                number: 1,
                range: '',
              },
            ]);
            setFieldValue(
              'entrance_number',
              isEntrances ? detailEntrances[isEntrances - 1].number + 1 : 1
            );
          }}
        >
          {t('AddEntrance')}
        </Button>
      ) : null}
      <Loader isLoading={isObjectsLoading} />
    </>
  );
};

export default EntrancesForm;
