import i18n from '@shared/config/i18n';
import { Modal } from '../..';
import RenderMessageContent from './renderMessageContent';

const { t } = i18n;

function parseError(error) {
  if (!error) return { isParse: false, error };
  let content;
  try {
    const content = JSON.parse(error.message);
    return { isParse: true, content };
  } catch {
    if (Array.isArray(error)) {
      content = error.map((item, index) => (
        <span key={`err-${index}`}>
          {' '}
          {typeof item === 'object' ? item.message : item}{' '}
        </span>
      ));
    } else if (
      error !== null &&
      typeof error === 'object' &&
      Object.prototype.hasOwnProperty.call(error, 'message')
    ) {
      content = <span> {error.message} </span>;
    } else if (typeof error === 'string') {
      content = <span> {error} </span>;
    } else {
      content = t('FatalError');
    }
    return { isParse: false, content };
  }
}

function ErrorMessage(props) {
  const { isOpen, onClose, error } = props;
  const { isParse, content } = parseError(error);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      header={t('AnErrorHasOccurred')}
      content={isParse ? RenderMessageContent(content) : content}
    />
  );
}

export { ErrorMessage };
