import { Children } from 'react';
import { components } from 'react-select';

function ValueContainer({ children, ...props }) {
  const newChildren = Children.map(children, (child) =>
    child && child.type === components.Input ? child : null
  );
  return <components.ValueContainer {...props}>{newChildren}</components.ValueContainer>;
}

export default ValueContainer;
