export default class ValidatedRequest {
  constructor(request) {
    this._request = request;
  }

  request() {
    return this._request;
  }

  async send() {
    let response;

    try {
      response = await this._request.send();
    } catch (error) {
      throw new Error('Неизвестная ошибка, пожалуйста попробуйте позже');
    }

    if (Object.prototype.hasOwnProperty.call(response.data.service, 'error')) {
      throw new Error(
        response.data.service.error.message ||
          'Неизвестная ошибка, пожалуйста, попробуйте позже'
      );
    }

    return response.data;
  }
}
