import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useStore } from 'effector-react';
import {
  ExitToAppOutlined,
  MoreHoriz,
  ErrorOutline,
  Person,
  HelpOutline,
} from '@mui/icons-material';
import { $sectionsData } from '@features/common';
import i18n from '@shared/config/i18n';
import { Popover, Menu, IconButton } from '@ui/';
import { ProfileInfo, ProfileLogout } from './organisms';

const { t } = i18n;

const ProfileMenu = () => {
  const [modalType, setModalType] = useState('');
  const history = useHistory();
  const sectionsData = useStore($sectionsData);
  const manual = sectionsData?.manual;

  const closeModal = () => {
    setModalType('');
  };

  const moreItems = [
    {
      title: t('navMenu.profile'),
      icon: <Person />,
      onClick: () => history.push('/profile'),
    },
    {
      title: t('Instruction'),
      icon: <HelpOutline />,
      onClick: () => window.open(manual),
    },
    {
      title: t('AboutTheSystem'),
      icon: <ErrorOutline />,
      onClick: () => setModalType('info'),
    },
    {
      title: t('Logout'),
      icon: <ExitToAppOutlined />,
      onClick: () => setModalType('logout'),
    },
  ];

  return (
    <div>
      <Popover
        trigger={
          <IconButton title={t('Additionally')} size="large">
            <MoreHoriz />
          </IconButton>
        }
      >
        <Menu items={moreItems} />
      </Popover>

      <ProfileInfo isOpen={modalType === 'info'} onClose={closeModal} />
      <ProfileLogout isOpen={modalType === 'logout'} onClose={closeModal} />
    </div>
  );
};

export default ProfileMenu;
