import i18n from '@shared/config/i18n';
import DropdownInput from './DropdownInput';

const { t } = i18n;

function join(array) {
  const separator = arguments.length > 1 ? arguments[1] : ', ';
  return array.filter((n) => n).join(separator);
}

function makeAddressString(address) {
  return join([
    address.city_with_type,
    address.settlement_with_type,
    address.street_with_type,
    join([address.house_type, address.house], ' '),
  ]);
}

function dotify(address) {
  const shortTypes = [
    'аобл',
    'респ',
    'вл',
    'г',
    'д',
    'двлд',
    'днп',
    'дор',
    'дп',
    'жт',
    'им',
    'к',
    'кв',
    'км',
    'комн',
    'кп',
    'лпх',
    'м',
    'мкр',
    'наб',
    'нп',
    'обл',
    'оф',
    'п',
    'пгт',
    'пер',
    'пл',
    'платф',
    'рзд',
    'рп',
    'с',
    'сл',
    'снт',
    'ст',
    'стр',
    'тер',
    'туп',
    'ул',
    'х',
    'ш',
  ];
  const words = address.split(' ');
  const dottedWords = words.map((word) => {
    if (shortTypes.includes(word)) {
      return `${word}.`;
    }
    return word;
  });
  return dottedWords.join(' ');
}

const loadOptions = (input) => {
  const token = '5fb595c2e7010691d3081259f6a1ba298052fd15';
  const headers = {
    'Content-Type': 'application/json; charset=UTF-8',
    Accept: 'application/json',
    Authorization: `Token ${token}`,
  };

  const body = JSON.stringify({ query: input, count: 10 });

  return fetch('https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address', {
    headers,
    body,
    method: 'POST',
  })
    .then((response) => response.json())
    .then((data) => {
      return data.suggestions
        .filter(
          (item) =>
            item.data.city_with_type ||
            item.data.settlement_with_type ||
            item.data.street_with_type
        )
        .reduce(
          (acc, item) =>
            acc.concat([
              {
                label: dotify(makeAddressString(item.data)),
                fias: item.data.fias_code ? String(item.data.fias_code) : '',
              },
            ]),
          []
        );
    })
    .catch((error) => console.error(error));
};

const getOptionLabel = (option) => (option ? option.label : '');

function AddressSelect(props) {
  const { onChange, ...restProps } = props;
  const { field } = props;

  return (
    <DropdownInput
      label={t('Label.address')}
      placeholder={t('StartTypingAddress')}
      {...(restProps.mode === 'edit' ? { isClearable: true } : {})}
      kind="async"
      getOptionLabel={getOptionLabel}
      {...(restProps.mode === 'edit'
        ? {
            loadOptions,
            inputValue:
              typeof field.value === 'string'
                ? field.value
                : field.value !== null
                ? field.value.label
                : '',
            closeMenuOnSelect: false,
            onInputChange: (value, e) =>
              e.action === 'input-change' && onChange(field.name, value),
          }
        : {})}
      value={field.value ? field.value.label : ''}
      onChange={(value) => {
        onChange(field.name, value);
      }}
      specialStyles={{
        dropdownIndicator: (styles) => ({ ...styles, display: 'none' }),
        indicatorSeparator: (styles) => ({ ...styles, display: 'none' }),
      }}
      {...restProps}
    />
  );
}

export default AddressSelect;
