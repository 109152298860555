import { useStore } from 'effector-react';
import { IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';
import makeStyles from '@mui/styles/makeStyles';
import { ActionButton, Modal } from '../../../../ui';
import { $notification, changeNotification } from '../../model';

const useStyles = makeStyles((theme) => {
  return {
    notificationPaper: {
      width: 360,
      minHeight: 280,
      minWidth: 'unset',
    },
    notificationPaperComponent: {
      width: 720,
      minHeight: 490,
    },
    iconClass: {
      margin: '30px auto',
      height: 90,
      width: 90,
    },
    buttons: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      gap: 10,
      marginTop: 20,
    },
  };
});

export const ColoredTextIconNotification = () => {
  const {
    Icon,
    color,
    text,
    isOpen,
    message = '',
    buttons = null,
    onClose = () => null,
  } = useStore($notification);
  const isMessageComponent = typeof message !== 'string';
  const classes = useStyles(isMessageComponent);

  const handleClose = () => {
    changeNotification({ isOpen: false });
    onClose();
  };

  const renderButtons = () => {
    if (!buttons) return null;

    return (
      <div className={classes.buttons}>
        {buttons.map((button, index) => (
          <ActionButton key={index} onClick={button.onClick} kind="positive">
            <span>{button.title}</span>
          </ActionButton>
        ))}
      </div>
    );
  };

  return (
    <Modal
      classes={{
        paper: isMessageComponent
          ? classes.notificationPaperComponent
          : classes.notificationPaper,
      }}
      isOpen={isOpen}
      onClose={handleClose}
      content={
        <>
          <div
            style={{
              position: 'absolute',
              top: 10,
              right: 10,
            }}
          >
            <IconButton onClick={handleClose} size="large">
              <Close />
            </IconButton>
          </div>
          {Icon && (
            <div style={{ textAlign: 'center' }}>
              <Icon className={classes.iconClass} style={{ color }} />
            </div>
          )}
          {text && (
            <div
              style={{
                color,
                textAlign: 'center',
                fontSize: 24,
                lineHeight: '28px',
                fontWeight: 'bold',
              }}
            >
              {text}
            </div>
          )}
          {message ? (
            isMessageComponent ? (
              <>{message}</>
            ) : (
              <p style={{ marginTop: 40, hyphens: 'auto' }}>{message}</p>
            )
          ) : null}
          {buttons ? (
            <div className={classes.buttons}>
              {buttons.map((button, index) => (
                <ActionButton
                  key={index}
                  onClick={() => {
                    button.onClick();
                    changeNotification({ isOpen: false });
                  }}
                  kind="positive"
                >
                  <span>{button.title}</span>
                </ActionButton>
              ))}
            </div>
          ) : null}
        </>
      }
    />
  );
};
