import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import NavItem from './navItem';

const useStyles = makeStyles({
  navList: {
    display: 'grid',
    alignContent: 'start',
    backgroundColor: 'white',
    heigth: '100%',
    zIndex: 2,
    // --- скрываем скролл ---
    overflow: 'auto',
    '-ms-overflow-style': 'none',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  // ---
});

function NavList(props) {
  const { items = [] } = props;
  const classes = useStyles();

  return (
    <nav className={classes.navList}>
      {items.map((item) => <NavItem {...item} key={`i-${item?.text}`} />)}
    </nav>
  );
}

NavList.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      link: PropTypes.string,
      text: PropTypes.string.isRequired,
      icon: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
      nest: PropTypes.bool,
    })
  ).isRequired,
};

export default NavList;
