import { createFilterBag } from '@tools/factories';

const defaultFilters = {
  object: '',
  house: '',
  archive: false,
};

export const { changedFilterVisibility, filtersSubmitted, $isFilterOpen, $filters } =
  createFilterBag(defaultFilters);
