import { styled } from '@mui/material/styles';
import TurnLeftIcon from '@mui/icons-material/TurnLeft';

export const StyledTicketTypeContainer = styled('div')(() => ({
  display: 'grid',
  gap: 5,
}));

export const StyledTicketTypeSecond = styled('div')(() => ({
  display: 'grid',
  gridTemplateColumns: 'auto 1fr',
  alignItems: 'center',
  gap: 5,
}));

export const StyledTicketTypeThird = styled('div')(() => ({
  display: 'grid',
  gridTemplateColumns: 'auto 1fr',
  alignItems: 'center',
  gap: 5,
  marginLeft: 25,
}));

export const StyledTicketTypeNestedIcon = styled(TurnLeftIcon)(() => ({
  transform: 'rotate(180deg)',
}));
