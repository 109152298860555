import {
  $isCollapsed,
  switchMenuCollapse,
  $isFixed,
  switchMenuFixed,
} from './navbar.model';

$isCollapsed
  .on(switchMenuCollapse, (_, value) => value)
  .on(switchMenuFixed, (state) => !state);
$isFixed.on(switchMenuFixed, (state) => !state);
