import { createEffect, sample } from 'effector';
import { createGate } from 'effector-react';
import { cache, createQuery, localStorageCache } from '@farfetched/core';
import { signout } from '@features/common';
import { rentStatusesApi } from '@features/effector-form/controls/rent-status-controls/api';
import { Statuses } from '@features/effector-form/controls/rent-status-controls/types';

export const RentStatusesControlGate = createGate();

const fxGetRentStatuses = createEffect<void, Statuses[], Error>().use(
  rentStatusesApi.getRentStatuses
);

export const rentStatusesQuery = createQuery({
  effect: fxGetRentStatuses,
  mapData: ({ result }) =>
    result.map((status) => ({
      id: status.id,
      label: status.title,
    })),
});

cache(rentStatusesQuery, {
  adapter: localStorageCache({ maxAge: '5m' }),
  purge: signout,
});

sample({
  clock: RentStatusesControlGate.state,
  target: rentStatusesQuery.start,
});
