import PropTypes from 'prop-types';
import { List as MuiList } from '@mui/material';
import withStyles from '@mui/styles/withStyles';

const styles = {
  container: {
    overflowY: 'auto',
    overflowX: 'hidden',
    height: '80vh',
    padding: 0,
  },
};

function List(props) {
  const { children, classes, ...rest } = props;

  return (
    <MuiList classes={{ root: classes.container }} {...rest}>
      {children}
    </MuiList>
  );
}

List.propTypes = {
  children: PropTypes.node.isRequired,
};

const StyledList = withStyles(styles)(List);

export { StyledList as List };
