import { forwardRef, FC } from 'react';
import { Paper } from '@mui/material';
import withStyles from '@mui/styles/withStyles';

const styles = {
  wrapper: {
    borderRadius: '15px',
    overflow: 'auto',
    boxShadow: '0px 4px 15px #e7e7ec',
  },
};

const Wrapper: FC<any> = forwardRef(({ children, classes, style, ...props }, ref) => (
  <Paper
    style={style}
    ref={ref}
    classes={{
      root: classes.wrapper,
    }}
    {...props}
  >
    {children}
  </Paper>
));

const StyledWrapper = withStyles(styles)(Wrapper);

export { StyledWrapper as Wrapper };
