import MaskedInput from 'react-text-mask';

const asterisk = '*';
const slash = '/';
const allSlashSymbolsRegExp = /\//g;
const caretTrap = '[]';
const space = ' ';
const g = 'g';
const anyNonSymbolRegExp = /[^\D]/;
const allWhitespaceRegExp = /\s/g;
const emptyString = '';

function shareMask(rawValue, config) {
  const tempRawValue = rawValue.replace(allWhitespaceRegExp, emptyString);

  const { placeholderChar } = config;
  const indexOfSlashSymbol = tempRawValue.indexOf(slash);

  const fromToConnector = getConnector(tempRawValue, indexOfSlashSymbol, slash);

  let fromPart = getPart(tempRawValue, indexOfSlashSymbol, 'from', placeholderChar);
  fromPart = convertToMask(fromPart);

  let toPart = getPart(tempRawValue, indexOfSlashSymbol, 'to', placeholderChar);
  toPart = convertToMask(toPart);

  const mask = fromPart.concat(fromToConnector).concat(toPart);

  return mask;
}

function getConnector(rawValue, indexOfConnection, connectionSymbol) {
  const connector = [];

  if (rawValue[indexOfConnection] === connectionSymbol) {
    connector.push(connectionSymbol);
  } else {
    connector.push(caretTrap, connectionSymbol);
  }

  connector.push(caretTrap);

  return connector;
}

function getPart(rawValue, indexOfSlashSymbol, partName) {
  let part = emptyString;

  if (indexOfSlashSymbol === -1) {
    part = partName === 'from' ? rawValue : '';
  } else {
    part =
      partName === 'from'
        ? rawValue.slice(0, indexOfSlashSymbol)
        : rawValue.slice(indexOfSlashSymbol + 1, rawValue.length);
  }

  part = part.replace(new RegExp(`[^\\d/]`, g), emptyString);

  if (part === slash) {
    return asterisk;
  }
  if (part.length === 0) {
    return space;
  }
  return part;
}

function convertToMask(string) {
  return string
    .split(emptyString)
    .map((char) => (char === space || char === slash ? char : anyNonSymbolRegExp));
}

function fromToPipe(conformedValue, config) {
  const { rawValue, placeholderChar } = config;

  let value = conformedValue;
  value = removeAllSlashSymbolsButFirst(value);

  const isEmpty = rawValue.match(new RegExp(`[^/\\s.${placeholderChar}]`)) === null;

  if (isEmpty) {
    return emptyString;
  }

  return value;
}

function removeAllSlashSymbolsButFirst(string) {
  let slashSymbolCount = 0;

  return string.replace(allSlashSymbolsRegExp, () => {
    slashSymbolCount++;

    return slashSymbolCount === 1 ? slash : emptyString;
  });
}

const ShareMaskedInput = (props) => {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput {...other} ref={inputRef} mask={{ mask: shareMask, pipe: fromToPipe }} />
  );
};

export { ShareMaskedInput };
