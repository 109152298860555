import { createEffect, createStore, guard, sample, combine } from 'effector';
import { sectionsApi } from '../api';
import { $pathname } from './routing';
import { loggedIn, signout, $isCompany } from './user';

const fxGetSections = createEffect();

const $sections = createStore({});

fxGetSections.use(sectionsApi.getSections);

guard({
  source: sample($sections, loggedIn),
  filter: (sections) =>
    (Array.isArray(sections) && !sections.length) ||
    (typeof sections === 'object' && sections !== null && !Object.keys(sections).length),
  target: fxGetSections,
});

$sections
  .on(
    // костыль для ЛК Компании, тк profile page находится по томуже урлу,
    // что и в ЛК УК. А ссылка на доку берется на основании pathname
    sample($isCompany, fxGetSections.doneData, (isCompany, data) => ({
      isCompany,
      data,
    })),
    (_, { isCompany, data }) => ({
      ...data,
      profile: isCompany ? data['enterprise-profile'] : data['profile'],
    })
    //
  )
  .reset(signout);

const $sectionsData = combine($sections, $pathname, (sections, pathname) => {
  const url = pathname.split('/')[1];
  if (!url) return null;

  return {
    description: sections[url]?.description,
    manual: sections[url]?.manual
      ? sections[url]?.manual
      : 'https://drive.google.com/file/d/1c4j-N3cpWXCvRgc4UMS-McP37NgmM6BP/view',
  };
});

export { $sections, $sectionsData };
