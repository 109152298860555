import { styled } from '@mui/material/styles';

export const Container = styled('div')(() => ({
  display: 'grid',
  gridTemplateColumns: '40px auto 40px',
  alignItems: 'center',
  alignContent: 'center',
  justifyContent: 'center',
  justifyItems: 'center',
  gap: 10,
}));
