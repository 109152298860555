import OutlinedInput from '@mui/material/OutlinedInput';
import withStyles from '@mui/styles/withStyles';

const TextField = (props) => {
  return <OutlinedInput {...props} autoComplete="off" fullwidth={0} />;
};

const StyledTextField = withStyles({
  root: {
    height: '32px',
    width: '100%',
  },
  input: {
    color: '#65657B',
    fontWeight: '500',
    fontSize: '13px',
    display: 'flex',
    height: '100%',
  },
  notchedOutline: {
    border: '1px solid #e7e7ec',
    borderRadius: '15px',
    // MUI 5 баг с notched=true
    '& legend': {
      width: 0,
    },

    '&:hover': {
      border: '1px solid #35A9E9',
    },
    '&:active': {
      border: '1px solid #0276B6',
    },
  },
})(TextField);

export default StyledTextField;
