import { useState } from 'react';

export const useHover = (closestTarget) => {
  const [anchorElement, setAnchorElement] = useState(null);
  const [hover, setHover] = useState(false);

  const handleMouseOut = (e) => {
    if (!anchorElement) return;

    let relatedTarget = e.relatedTarget;

    while (relatedTarget) {
      if (relatedTarget === anchorElement) return;

      relatedTarget = relatedTarget.parentNode;
    }

    setAnchorElement(null);
    setHover(false);
  };

  const handleMouseIn = (e) => {
    if (anchorElement) return;

    const taret = e.target.closest(closestTarget);

    if (!taret) return;

    setAnchorElement(e.currentTarget);
    setHover(true);
  };

  const close = () => {
    setAnchorElement(null);
    setHover(false);
  };

  return {
    anchorEl: anchorElement,
    hover,
    handleMouseIn,
    handleMouseOut,
    close,
  };
};
