import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  avatarControl: {
    position: 'relative',
    display: 'grid',
    width: 190,
    height: 190,
    borderRadius: '100%',
    overflow: 'hidden',
    cursor: 'pointer',
    margin: '0 auto',

    '&:hover $avatarControl__overlay': {
      opacity: 1,
    },
  },
  avatarControl__overlay: {
    position: 'absolute',
    width: 190,
    height: 190,
    display: 'grid',
    alignItems: 'center',
    alignContent: 'center',
    justifyItems: 'center',
    justifyContent: 'center',
    gap: 5,
    background: 'rgba(0, 0, 0, 0.6)',
    opacity: 0,
    transition: 'opacity 0.1s',
    transform: 'translateY(-100%)',
  },
  avatarControl__photoIcon: {
    color: 'white',
  },
  avatarControl__overlayTitle: {
    color: 'white',
  },
  input: {
    display: 'none',
  },
  label: {
    height: '100%',
    display: 'block',
    textAlign: 'center',
    marginTop: 20,
  },
});
