import styles from './ThreeColumnsLayout.module.css';

function ThreeColumnsLayout({ Left, Main, Right, ...props }) {
  const { leftIsOpen = false, rightIsOpen = false, addStyle } = props;

  return (
    <div className={styles.container} style={addStyle && addStyle.container}>
      {leftIsOpen ? (
        <section className={styles.left} style={addStyle && addStyle.left}>
          <Left />
        </section>
      ) : null}
      <section
        className={`${styles.main} ${!leftIsOpen ? styles['main--left--hide'] : ''} ${
          !rightIsOpen ? styles['main--right--hide'] : ''
        }`}
        style={addStyle && addStyle.main}
      >
        <Main />
      </section>
      {rightIsOpen ? (
        <section className={styles.right} style={addStyle && addStyle.right}>
          <Right />
        </section>
      ) : null}
    </div>
  );
}

export default ThreeColumnsLayout;
