import { Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import GeneralUnreadMark from '@img/indicators/general_mark.svg';
import { openLevel } from '../models';
import { useStore } from 'effector-react';
import { $filters } from '../models';
import { useStyles, useStylesArchive } from './card.styles';

const TitleInfo = ({ info }) => {
  const classes = useStyles();

  if (Array.isArray(info) && info.length > 0) {
    return (
      <Tooltip title={info[0]?.title || ''} arrow>
        <span className={classes.card__infoCount}>{info[0]?.count}</span>
      </Tooltip>
    );
  }

  return null;
};

export const onlyArchiveCardId = [107, 115];

const Card = ({ data }) => {
  const { t } = useTranslation();
  const { id, title, icon, levels, info, isConnected, disconnectedMessage } = data;
  const filters = useStore($filters);
  const classes = filters.archive ? useStylesArchive() : useStyles();

  if (filters.archive && !onlyArchiveCardId.includes(id)) {
    return null;
  }

  const isCardEmpty = !Boolean(levels.reduce((a, b) => a + b.count, 0));

  const handleLevelClick = (levelId) => {
    openLevel({ category_id: id, level_id: levelId });
  };

  const renderCardTitle = () =>
    title.length > 30 ? (
      <>
        <Tooltip title={title} arrow>
          <span className={classes.card__title}>{`${title.slice(0, 20)}...`}</span>
        </Tooltip>
        <TitleInfo info={info} />
      </>
    ) : (
      <>
        <span className={classes.card__title}>{title}</span>
        <TitleInfo info={info} />
      </>
    );

  const renderGeneralUnreadMark = () => {
    const isNotViewed = levels?.filter((item) => !item.viewed).length;

    return isNotViewed ? (
      <GeneralUnreadMark className={classes.card__generalUnreadMark} />
    ) : null;
  };

  const renderLevels = (
    <ul className={clsx(classes.card__list, { [classes.card_disconnected]: isCardEmpty })}>
      {levels?.map((item) => {
        const isEmpty = item.count < 1;

        return (
          <li
            key={item.id}
            className={clsx(classes.card__listItem, {
              [classes.card__listItem_blue]: !item.viewed && item.id === 147,
              [classes.card__listItem_orange]: !item.viewed && item.id === 146,
              [classes.card__listItem_red]: !item.viewed && item.id === 145,
            })}
            onClick={isEmpty ? null : () => handleLevelClick(item.id)}
          >
            <span
              className={clsx(classes.card__listTitle, {
                [classes.card__listTitle_empty]: isEmpty,
              })}
            >
              {' '}
              {item.title}
            </span>
            <span
              className={clsx(classes.card__listCount, {
                [classes.card__listCount_blue]: item.id === 147,
                [classes.card__listCount_orange]: item.id === 146,
                [classes.card__listCount_red]: item.id === 145,
                [classes.card__listCount_empty]: isEmpty,
              })}
            >
              {item.count}
            </span>
          </li>
        );
      })}
    </ul>
  );

  return (
    <div
      className={clsx(classes.card, {
        [classes.card_disabled]: isCardEmpty,
        [classes.card_disconnected]: !isConnected,
      })}
    >
      <div className={classes.card__header}>
        {icon(classes.card__icon)}
        {renderCardTitle()}
        {renderGeneralUnreadMark()}
      </div>

      {isConnected
        ? renderLevels
        : <span className={classes.card__module_disconnected}>{disconnectedMessage}</span>
      }
    </div>
  );
};

export { Card };
