import PropTypes from 'prop-types';
import { SortingIcon } from '../..';

const SortingLabel = (props) => {
  const { children, direction, onSort, column, excludeSortColumns = [] } = props;

  const isExcluded = excludeSortColumns.some(
    (excludeColumn) => excludeColumn === column.name
  );

  const iconElement = isExcluded ? null : (
    <span style={{ marginLeft: '5px' }}>
      <SortingIcon direction={direction} />
    </span>
  );

  return (
    <span
      data-test-id={isExcluded ? 'column-name' : 'sortable-column-name'}
      onClick={isExcluded ? null : onSort}
      style={{
        cursor: isExcluded ? 'default' : 'pointer',
        color: direction ? '#0288d1' : '#7D7D8E',
        fontWeight: '900',
        fontFamily: 'Roboto, sans-serif',
        fontSize: '18px',
      }}
    >
      {children}
      {iconElement}
    </span>
  );
};

SortingLabel.propTypes = {
  direction: PropTypes.oneOfType(['asc', 'desc']),
  onSort: PropTypes.func.isRequired,
  column: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
  excludeSortColumns: PropTypes.arrayOf(PropTypes.string),
};

export { SortingLabel };
