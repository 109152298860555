import { api } from '../../../api/api2';

const auth = (credentials) => api.v1('post', 'auth/crm/authenticate', credentials);

const get = () => api.v1('get', 'auth/crm/user-data');

const authForCompany = (payload = {}) =>
  api.v1('post', 'auth/rent/region/authenticate', {
    ...payload,
    app: 'pro100',
  });

const getCompanyUserData = () => api.v1('get', 'auth/user-data');

const getRoleData = (payload) => api.v4('get', '/role/get', payload);

// удалить после перехода на сокеты
const isSocketsEnabled = () => api.v1('get', 'dirty-hack/is-sockets-enabled');

// удалить после миграции на новые заявки
const isNewTicketsEnabled = () => api.v1('get', 'dirty-hack/is-new-tickets-enabled');

export const userApi = {
  auth,
  get,
  authForCompany,
  getCompanyUserData,
  getRoleData,
  isSocketsEnabled,
  isNewTicketsEnabled,
};
