import { FC } from 'react';
import {
  Pagination as MuiPagination,
  PaginationItem as MuiPaginationItem,
  Select,
  MenuItem,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import i18n from '@shared/config/i18n';

const { t } = i18n;

const useStyles = makeStyles({
  pagination: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingTop: 15,
    paddingRight: 35,
  },
  title: {
    fontSize: 12,
    marginTop: 8,
    marginRight: 15,
  },
  select: {
    fontSize: 15,
  },
  paginationStyle: {
    marginLeft: 40,
  },
  paginationItem: {
    backgroundColor: '#e6f3fb',
  },
});

interface Props {
  currentPage: number;
  changePage: (number: number) => void;
  rowCount: number;
  perPage: number;
  changePerPage: (number: number) => void;
  hideTitlePerPage?: boolean;
}

const Pagination: FC<Props> = ({
  currentPage,
  changePage,
  rowCount,
  perPage,
  changePerPage,
  hideTitlePerPage = false,
}) => {
  const classes = useStyles();

  const countPage = rowCount ? Math.ceil(rowCount / perPage) : 0;

  return (
    <div className={classes.pagination}>
      {!hideTitlePerPage && (
        <Typography classes={{ root: classes.title }}>{t('EntriesPerPage')}</Typography>
      )}
      <Select
        value={perPage}
        onChange={(_, { props }: any) => changePerPage(props.value)}
        variant="standard"
        disableUnderline
        classes={
          {
            root: classes.select,
          } as any
        }
      >
        <MenuItem value={10}>10</MenuItem>
        <MenuItem value={25}>25</MenuItem>
        <MenuItem value={50}>50</MenuItem>
        <MenuItem value={100}>100</MenuItem>
      </Select>
      <MuiPagination
        classes={{ root: classes.paginationStyle }}
        onChange={(_, page) => changePage(page)}
        page={currentPage}
        count={countPage}
        size="small"
        shape="rounded"
        renderItem={(item) => (
          <MuiPaginationItem
            {...item}
            style={{
              width: 20,
              height: 36,
              fontWeight: 500,
              backgroundColor: item.selected && ('#e6f3fb' as any),
            }}
          />
        )}
      />
    </div>
  );
};

export { Pagination };
