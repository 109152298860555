import { createEffect, sample } from 'effector';
import { createGate } from 'effector-react';
import { cache, createQuery } from '@farfetched/core';
import { GetPaymentTypesResponse } from '@features/effector-form/controls/ticketCostTypes/types';
import { getCostTypes } from './api';

export const TicketCostTypesControlGate = createGate();

const fxGetTicketCostTypes = createEffect<void, GetPaymentTypesResponse, Error>().use(
  getCostTypes
);

export const ticketCostTypesQuery = createQuery({
  name: 'ticketCostTypes',
  effect: fxGetTicketCostTypes,
  mapData: ({ result }) =>
    result.prices.map((type) => ({
      id: type.name,
      label: type.title,
    })),
});

cache(ticketCostTypesQuery);

sample({
  clock: TicketCostTypesControlGate.status,
  target: ticketCostTypesQuery.start,
});
