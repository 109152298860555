import React from 'react';
import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined';
import UnarchiveOutlinedIcon from '@mui/icons-material/UnarchiveOutlined';
import { useStore } from 'effector-react';
import { $isRecordsLoading } from '../models';

interface FilterChipProps {
  onChange: () => {};
  value: boolean;
}

const styles = {
  general: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 999,
    cursor: 'pointer',
    width: 42,
    height: 32,
    border: 0,
  },
  active: {
    backgroundColor: '#0394E3',
  },
  inactive: {
    backgroundColor: '#fff',
    border: '1px solid #E7E7EC',
  },
};

const FilterChip = (props: FilterChipProps) => {
  const isRecordsLoading = useStore($isRecordsLoading);
  const getStyles = () => ({
    ...styles.general,
    ...(props.value ? styles.active : styles.inactive),
  });

  return (
    <button
      disabled={isRecordsLoading}
      type="button"
      onClick={props.onChange}
      style={getStyles()}
    >
      {props.value ? (
        <UnarchiveOutlinedIcon sx={{ color: 'white' }} />
      ) : (
        <ArchiveOutlinedIcon />
      )}
    </button>
  );
};

export default FilterChip;
