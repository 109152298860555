import { useState } from 'react';
import { Button } from '@mui/material';
import i18n from '@shared/config/i18n';
import { BaseInputLabel, Divider, FileControl } from '../..';

const { t } = i18n;

const LogoControl = (props) => {
  const [defaultValue, setValue] = useState('');

  const { label = t('Logo'), mode = 'edit', name = '', value, onChange, ...rest } = props;

  return (
    <>
      <BaseInputLabel style={{ marginBottom: 10 }}>{label}</BaseInputLabel>
      <FileControl
        inputProps={{ accept: 'image/*' }}
        name={name}
        value={value === undefined ? defaultValue : value}
        loadable
        renderButton={() => (
          <Button component="span" color="primary">
            {value || defaultValue ? t('ReplaceLogo') : t('UploadLogo')}
          </Button>
        )}
        onChange={(value) => (onChange === undefined ? setValue(value) : onChange(value))}
        mode={mode}
        {...rest}
      />
      <Divider />
    </>
  );
};

const LogoControlField = ({ field, form, ...rest }) => (
  <LogoControl
    name={field.name}
    onChange={(value) => form.setFieldValue(field.name, value)}
    value={field.value}
    {...rest}
  />
);

export { LogoControl, LogoControlField };
