import { TypographyOptions } from '@mui/material/styles/createTypography';

const mainFontFamily = [
  'Roboto',
  '-apple-system',
  'BlinkMacSystemFont',
  'Segoe UI',
  'Arial',
  'sans-serif',
  'Apple Color Emoji',
  'Segoe UI Emoji',
  'Segoe UI Symbol',
].join(',');

export const typography: TypographyOptions = {
  fontFamily: mainFontFamily,
};
