import { sample } from 'effector';
import { $popupData } from '../records/records.model';
import { $popupOpened } from './widget.model';
import { addVisibleArchiveBlockSignals } from '../../models';

/** Показаваем/скрываем окно с сигналами только при их наличии/отсутствии */
sample({
  clock: $popupData.map(({ signals }) => Boolean(signals)),
  fn: (_, clock) => {
    addVisibleArchiveBlockSignals(null);
    return Boolean(clock);
  },
  target: $popupOpened,
});
