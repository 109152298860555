import { calcPlatformHeader, getLanguage } from '../../tools/getBaseHeaders';
export default class JsonRequest {
  constructor(request) {
    this._request = request;
  }

  request() {
    return this._request
      .request()
      .header('X-APP-TYPE', 'web')
      .header('X-APP-LANG', getLanguage())
      .header('X-APP-PLATFORM', calcPlatformHeader())
      .header('X-APP-DETAILS', null)
      .header('Content-Type', 'application/json')
      .header('Accept', 'application/json');
  }

  send() {
    return this.request().send();
  }
}
