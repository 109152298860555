import { forwardRef } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { ActionIconButton } from '../..';

const SearchButton = forwardRef((props, ref) => {
  return (
    <ActionIconButton ref={ref} {...props}>
      <SearchIcon />
    </ActionIconButton>
  );
});

export { SearchButton };
