const memorize = (
  object = JSON.parse(localStorage.getItem(localStorage.getItem('uid'))) || {},
  key,
  value
) => {
  const uid = localStorage.getItem('uid');

  const keys = key.split('.');

  if (keys.length > 1) {
    memorize((object[keys[0]] = object[keys[0]] || {}), keys.slice(1).join('.'), value);
  } else {
    object[keys[0]] = value;
  }

  localStorage.setItem(uid, JSON.stringify(object));
};

const remember = (
  object = JSON.parse(localStorage.getItem(localStorage.getItem('uid'))) || null,
  key
) => {
  const keys = key.split('.');

  if (!object || !Object.prototype.hasOwnProperty.call(object, keys[0])) return null;

  return keys.length > 1
    ? remember(object[keys[0]], keys.slice(1).join('.'))
    : object[keys[0]];
};

export { memorize, remember };
