import Grid from '@mui/material/Grid';

export const MessageDateTime = (props) => {
  const { date } = props;

  const formattedDate = typeof date === 'number' ? new Date(date * 1000) : new Date(date);

  return (
    <Grid item style={{ color: '#9393a3' }}>
      {`${formattedDate.toLocaleDateString()} в
        ${formattedDate.toLocaleTimeString([], {
          hour: '2-digit',
          minute: '2-digit',
        })}`}
    </Grid>
  );
};
