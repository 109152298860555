import { createAction } from 'redux-actions';
import { memorize } from '../../tools/userSettings';

//REQUEST ACTIONS
export const requestAC = (prefix) => createAction(`${prefix}_REQUEST`);
export const requestFailAC = (prefix) => createAction(`${prefix}_REQUEST_FAIL`);
export const requestSuccessAC = (prefix) => createAction(`${prefix}_REQUEST_SUCCESS`);
export const getSuccessAC = (prefix) => createAction(`GET_${prefix}_SUCCESS`);

//CRUD ACTIONS
export const createSuccessAC = (prefix) => createAction(`CREATE_${prefix}_SUCCESS`);
export const updateSuccessAC = (prefix) => createAction(`UPDATE_${prefix}_SUCCESS`);
export const deleteSuccessAC = (prefix) => createAction(`DELETE_${prefix}_SUCCESS`);

//VISIBILITY ACTIONS
export const changeMode = (prefix) => createAction(`${prefix}_CHANGE_MODE`);
export const changeVisibility = (prefix) => createAction(`${prefix}_CHANGE_VISIBILITY`);
export const openClose = (prefix) => createAction(`OPEN_CLOSE_${prefix}`);
export const openCloseDeleteDialog = (prefix) =>
  createAction(`OPEN_CLOSE_DELETE_${prefix}`);

//SERVICE ACTIONS
export const searchData = (prefix) => createAction(`SEARCH_${prefix}`);
export const sortAC = (prefix) => createAction(`SORT_${prefix}_BY`);
export const selectRowsAC = (prefix) => createAction(`${prefix}_ROWS_SELECTED`);
export const changePageSizeAC = (prefix) => createAction(`${prefix}_CHANGE_PAGE_SIZE`);
export const changeCurrentPageAC = (prefix) =>
  createAction(`${prefix}_CHANGE_CURRENT_PAGE`);
export const downloadFileAC = (prefix) => createAction(`${prefix}_FILE_DOWNLOAD`);
export const markDelete = (prefix) => createAction(`${prefix}_MARK_DELETE`);

export const hideTableColumns = (prefix) => {
  return createAction(`${prefix}_TABLE_HIDE_COLUMNS`, (hiddenColumnNames) => {
    memorize(undefined, `${prefix.toLowerCase()}.hiddenColumns`, hiddenColumnNames);
    return hiddenColumnNames;
  });
};

export const changeWidths = (prefix) => {
  return createAction(`${prefix}_TABLE_CHANGE_COLUMN_WIDTHS`, (columnWidths) => {
    memorize(undefined, `${prefix.toLowerCase()}.columnWidths`, columnWidths);
    return columnWidths;
  });
};

export const filterAC = (prefix) => {
  return createAction(`${prefix}_FILTER`, (filters) => {
    memorize(undefined, `${prefix.toLowerCase()}.filters`, filters);
    return filters;
  });
};

export const reorderTableColumnAC = (prefix) => {
  return createAction(`${prefix}_TABLE_COLUMN_REORDER`, (order) => {
    memorize(undefined, `${prefix.toLowerCase()}.columnsOrder`, order);
    return order;
  });
};
