import { lazy, Suspense, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { useStore } from 'effector-react';
import { Loader } from '@ui';
import { makeStyles } from '@mui/styles';
import { $isNewTicketsEnabled, $isSocketsEnabled } from '@features/common';
import { menuConfig } from '../consts/navigation-menu';
import ProfilePage from '../features/profile';
import { NotFound404 } from './pages/NotFound404';
import Objects from './pages/Objects';

/** Playgrounds */
const PlaygroundForm = lazy(() => import('@features/playgrounds/form'));

const ObjectsPage = lazy(() => import('../features/objects'));
const CallsPage = lazy(() => import('../features/calls'));
const ConnectionsPage = lazy(() => import('../features/connections'));
const NewsPage = lazy(() => import('../features/news'));
const EnterprisesNewsPage = lazy(() => import('../features/enterprises-news'));
const EquipmentPage = lazy(() => import('../features/equipment'));
const PeoplePage = lazy(() => import('../features/people'));
const UsersPage = lazy(() => import('../features/users'));
const RolesPage = lazy(() => import('../features/roles'));
const TenantsPage = lazy(() => import('../features/tenants'));
const RentObjectsPage = lazy(() => import('../features/rent-objects'));
const EnterprisesPage = lazy(() => import('../features/enterprises'));
const ParkingPage = lazy(() => import('../features/parking-slots'));
const ParkingLotsPage = lazy(() => import('../features/parking-lots'));
const CamerasPage = lazy(() => import('../features/cameras'));
const GuestsPage = lazy(() => import('../features/guests'));
const MarketCompanies = lazy(() => import('../features/market-companies'));
const MarketOffersPage = lazy(() => import('../features/market-offers'));
const CompanyPage = lazy(() => import('../features/company'));
const VotingPage = lazy(() => import('../features/voting'));
const QuarantinePage = lazy(() => import('../features/quarantine'));
const StatisticPage = lazy(() => import('../features/statistics'));
const TicketsDisplayPage = lazy(() => import('../features/tickets-display'));
const TicketPage = lazy(() => import('@features/tickets/pages/ticket'));
const TicketsPage = lazy(() => import('@features/tickets/pages/tickets'));
const OldTicketPage = lazy(() => import('../features/tickets-old/pages/ticket'));
const OldTicketsPage = lazy(() => import('../features/tickets-old/pages/tickets'));
const ReportsPage = lazy(() => import('@features/reports'));
const ScheduleSettingsPage = lazy(() => import('../features/schedule'));
const PassesPage = lazy(() => import('../features/passes'));
const AlarmsPage = lazy(() => import('../features/alarms'));
const ChatsOldPage = lazy(() => import('../features/chats-old'));
const ChatsPage = lazy(() => import('../features/socket-chats-reworked'));
const EnterprisesCompanyPage = lazy(() => import('../features/enterprise-company'));
const EnterprisesEmployeePage = lazy(() => import('../features/enterprises-employee'));
const EnterprisesPassPage = lazy(() => import('../features/enterprises-pass'));

const EnterprisesContactsPage = lazy(() => import('../features/enterprises-contacts'));

const EnterprisesObjectsPage = lazy(() => import('../features/enterprises-objects'));

const ApartmentAcceptance = lazy(() =>
  import('../features/apartment-acceptance/pages/schedule-page')
);
const AcceptanceDashboard = lazy(() =>
  import('../features/apartment-acceptance/pages/dashboard-page')
);
const EventsLogPage = lazy(() => import('../features/events-log'));
const EnterprisesParkingSlotsPage = lazy(() =>
  import('../features/enterprises-parking-slots')
);
const StuffPage = lazy(() => import('../features/stuff'));

const CountersPage = lazy(() => import('../features/counters'));
const VehiclesPage = lazy(() => import('../features/vehicles'));
const BarriersPage = lazy(() => import('../features/barriers'));
const JournalPage = lazy(() => import('../features/journal-user-actions'));
const DashboardPage = lazy(() => import('../features/dashboard'));
const DashboardClimatePage = lazy(() => import('@features/dashboard-climate'));
const ContainersPage = lazy(() => import('../features/containers'));
const AccessKeysPage = lazy(() => import('../features/access-keys'));
const EnterpriseContractingPage = lazy(() => import('../features/enterprise-contractor'));
const SecurityPage = lazy(() => import('../features/security'));
const UserAppPage = lazy(() => import('../features/user-app'));
const DoorsPage = lazy(() => import('../features/doors'));
const StatisticCommunicationPage = lazy(() =>
  import('../features/statistics-communication')
);
const TboSchedulePage = lazy(() => import('../features/tbo-schedule'));
const JobSchedulePage = lazy(() => import('../features/job-schedule'));
const WorksPage = lazy(() => import('../features/works'));
const EventServicePage = lazy(() => import('../features/event-service'));
const TicketsTypesPage = lazy(() => import('../pages/tickets-types'));
const DevicesHintsPage = lazy(() => import('../features/devices-hints'));

const EnterprisesTicketsPage = lazy(() =>
  import('@features/enterprises-tickets/pages/tickets')
);
const EnterprisesTicketPage = lazy(() =>
  import('@features/enterprises-tickets/pages/ticket')
);
const AccessGroupsPage = lazy(() => import('@features/access-groups'));
const ActivityPage = lazy(() => import('@features/activity'));
const IntercomsPage = lazy(() => import('@features/intercoms'));
const PollsPage = lazy(() => import('@features/polls'));
const UsersGroupsPage = lazy(() => import('@features/users-groups'));
const AccessLevelPage = lazy(() => import('@features/access-level'));
const SchedulePage = lazy(() => import('@features/access-schedule'));

const ComparisonsPage = lazy(() =>
  import('../features/counters-check/pages/comparisons')
);
const ComparisonPage = lazy(() =>
  import('../features/counters-check/pages/comparison-result')
);
const PlannedWorksPage = lazy(() => import('@features/planned-works'));
const PassConfigPage = lazy(() => import('@features/pass-config'));
const DashboardFullscreenPage = lazy(() => import('@features/dashboard-fullscreen'));
const QualificationsPage = lazy(() => import('@features/qualifications'));
const PlannedWorkSchedulePage = lazy(() => import('@features/planned-work-schedule'));
const WorkFlowsPage = lazy(() => import('@features/work-flows'));
const DebtorsPage = lazy(() => import('../features/debtors-list'));
const DebtorsNotifications = lazy(() => import('../features/debtors-notifications'));
const DebtorsReceipts = lazy(() => import('../features/debtors-receipts'));
const DebtorsGeneralSettings = lazy(() => import('../features/debtors-settings'));
const DebtorsAccess = lazy(() => import('../features/debtors-access'));
const AccountsPage = lazy(() => import('@features/accounts'));
const ConciergePage = lazy(() => import('@features/concierge'));
const RentPage = lazy(() => import('@features/rent'));

const useStyles = makeStyles({
  main: {
    display: 'grid',
  },
});

export function Main() {
  const [title, setTitle] = useState('');
  const socket = useStore($isSocketsEnabled);
  const tickets = useStore($isNewTicketsEnabled);
  const classes = useStyles();

  let location = useLocation();
  const { items } = menuConfig;

  const menu = items.reduce(
    (acc, { items, ...curr }) => acc.concat(items ? items : []).concat(curr),
    []
  );

  useEffect(() => {
    const pathname = location.pathname.split('/')[1];
    const { text } = menu.find((i) => pathname === i.link) || {};
    setTitle(text);
  }, [location]);

  return (
    <main className={classes.main}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{title}</title>
      </Helmet>
      <Suspense fallback={<Loader isLoading />}>
        <Switch>
          {process.env.NODE_ENV === 'development' ? (
            <Route exact path="/playground-form" component={PlaygroundForm} />
          ) : null}

          <Route exact path="/people" component={PeoplePage} />
          <Route exact path="/people/:id" component={PeoplePage} />
          <Route exact path="/users" component={UsersPage} />
          <Route path="/users/:id" component={UsersPage} />
          <Route path="/users" exact component={UsersPage} />
          <Route exact path="/roles" component={RolesPage} />
          <Route exact path="/objects-new" component={ObjectsPage} />
          <Route exact path="/objects-new/:other" component={ObjectsPage} />
          <Route exact path="/objects" component={Objects} />
          <Route exact path="/tenants" component={TenantsPage} />
          <Route exact path="/rent-objects" component={RentObjectsPage} />
          <Route exact path="/enterprises" component={EnterprisesPage} />
          <Route exact path="/parking" component={ParkingPage} />
          <Route exact path="/parking-lots" component={ParkingLotsPage} />
          <Route exact path="/containers" component={ContainersPage} />
          <Route exact path="/intercoms" component={IntercomsPage} />
          <Route exact path="/intercoms/:id" component={IntercomsPage} />
          <Route exact path="/cctv" component={CamerasPage} />
          <Route exact path="/cctv/:id" component={CamerasPage} />
          <Route exact path="/doors" component={DoorsPage} />
          <Route exact path="/barriers" component={BarriersPage} />
          <Route exact path="/guests" component={GuestsPage} />
          <Route exact path="/guests/:id" component={GuestsPage} />
          <Route exact path="/vehicles" component={VehiclesPage} />
          <Route exact path="/market-companies" component={MarketCompanies} />
          <Route exact path="/market-companies/:id" component={MarketCompanies} />
          <Route exact path="/market-offers" component={MarketOffersPage} />
          <Route exact path="/market-offers/:id" component={MarketOffersPage} />
          <Route exact path="/company" component={CompanyPage} />
          <Route exact path="/news" component={NewsPage} />
          <Route exact path="/enterprises-news" component={EnterprisesNewsPage} />
          <Route exact path="/enterprises-news/:id" component={EnterprisesNewsPage} />
          <Route exact path="/voting" component={VotingPage} />
          <Route exact path="/voting/:id" component={VotingPage} />
          <Route exact path="/quarantine" component={QuarantinePage} />
          <Route exact path="/statistics" component={StatisticPage} />
          <Route exact path="/counters" component={CountersPage} />
          <Route exact path="/tickets-display" component={TicketsDisplayPage} />

          <Route
            path="/tickets/:id"
            render={() => {
              if (tickets === 'new') return <TicketPage />;
              if (tickets === 'old') return <OldTicketPage />;
              return null;
            }}
          />
          <Route
            exact
            path="/tickets"
            render={() => {
              if (tickets === 'new') return <TicketsPage />;
              if (tickets === 'old') return <OldTicketsPage />;
              return null;
            }}
          />
          <Route path="/tickets/new" component={OldTicketPage} />
          <Route path="/reports" component={ReportsPage} />
          <Route exact path="/alarms" component={AlarmsPage} />
          <Route exact path="/appointment" component={ScheduleSettingsPage} />
          <Route exact path="/calls" component={CallsPage} />
          <Route exact path="/connections" component={ConnectionsPage} />
          <Route exact path="/passes" component={PassesPage} />
          <Route exact path="/passes/:id" component={PassesPage} />
          <Route exact path="/profile" component={ProfilePage} />
          <Route exact path="/enterprise-profile" component={ProfilePage} />
          <Route exact path="/events-log" component={EventsLogPage} />
          <Route exact path="/equipment" component={EquipmentPage} />
          <Route exact path="/access-keys" component={AccessKeysPage} />
          <Route exact path="/access-groups" component={AccessGroupsPage} />
          <Route exact path="/access-groups/:id" component={AccessGroupsPage} />
          <Route exact path="/enterprises-vehicles" component={VehiclesPage} />
          <Route exact path="/activity" component={ActivityPage} />

          <Route path="/enterprises-tickets/new" component={OldTicketPage} />
          <Route
            path="/enterprises-tickets/:id"
            render={() => {
              if (tickets === 'new') return <EnterprisesTicketPage />;
              if (tickets === 'old') return <OldTicketPage />;
              return null;
            }}
          />
          <Route
            exact
            path="/enterprises-tickets"
            render={() => {
              if (tickets === 'new') return <EnterprisesTicketsPage />;
              if (tickets === 'old') return <OldTicketsPage />;
              return null;
            }}
          />

          <Route exact path="/enterprises-events" component={EventsLogPage} />
          <Route
            exact
            path="/statistics-communication"
            component={StatisticCommunicationPage}
          />
          <Route exact path="/users-groups" component={UsersGroupsPage} />
          <Route exact path="/users-groups/:id" component={UsersGroupsPage} />
          <Route exact path="/access-level" component={AccessLevelPage} />
          <Route exact path="/access-level/:id" component={AccessLevelPage} />
          <Route exact path="/schedule" component={SchedulePage} />
          <Route exact path="/schedule/:id" component={SchedulePage} />
          <Route
            exact
            path="/enterprise-contractor"
            component={EnterpriseContractingPage}
          />
          <Route
            exact
            path="/enterprise-contractor/:id"
            component={EnterpriseContractingPage}
          />
          <Route exact path="/enterprises-company" component={EnterprisesCompanyPage} />
          <Route exact path="/enterprises-employee" component={EnterprisesEmployeePage} />
          <Route exact path="/enterprises-passes" component={EnterprisesPassPage} />
          <Route exect path="/enterprises-contacts" component={EnterprisesContactsPage} />
          <Route exect path="/enterprises-objects" component={EnterprisesObjectsPage} />
          <Route path="/apartment-acceptance/:id" component={ApartmentAcceptance} />
          <Route exact path="/apartment-acceptance" component={AcceptanceDashboard} />
          <Route
            exact
            path="/enterprises-parking"
            component={EnterprisesParkingSlotsPage}
          />
          <Route path="/enterprises-stuff" component={StuffPage} />
          <Route exact path="/journal-user-actions" component={JournalPage} />
          <Route path="/messages" component={socket ? ChatsPage : ChatsOldPage} />
          <Route path="/polls" component={PollsPage} />
          <Route path="/stuff" component={StuffPage} />
          <Route path="/security" component={SecurityPage} />
          <Route path="/user-app" component={UserAppPage} />
          <Route path="/tbo-schedule" component={TboSchedulePage} />
          <Route path="/job-schedule" component={JobSchedulePage} />
          <Route exact path="/" render={() => <Redirect to="/profile" />} />
          <Route path="/works" component={WorksPage} />
          <Route path="/works/:id" component={WorksPage} />
          <Route exact path="/dashboard" component={DashboardPage} />
          <Route exact path="/dashboard-climate" component={DashboardClimatePage} />
          <Route exact path="/event-service" component={EventServicePage} />
          <Route exact path="/tickets-types" component={TicketsTypesPage} />
          <Route exact path="/tickets-types/:id" component={TicketsTypesPage} />
          <Route exact path="/devices-hints" component={DevicesHintsPage} />
          <Route exact path="/counters-check" component={ComparisonsPage} />
          <Route path="/counters-check/:id" component={ComparisonPage} />
          <Route exact path="/planned-works" component={PlannedWorksPage} />
          <Route exact path="/planned-works/:id" component={PlannedWorksPage} />
          <Route exact path="/pass-config" component={PassConfigPage} />
          <Route exact path="/qualifications" component={QualificationsPage} />
          <Route exact path="/qualifications/:id" component={QualificationsPage} />
          <Route
            exact
            path="/planned-work-schedule"
            component={PlannedWorkSchedulePage}
          />
          <Route exact path="/work-flows" component={WorkFlowsPage} />
          <Route exact path="/work-flows/:id" component={WorkFlowsPage} />
          <Route exact path="/debtors-list" component={DebtorsPage} />
          <Route exact path="/debtors-list/:id" component={DebtorsPage} />
          <Route exact path="/debtors-notifications" component={DebtorsNotifications} />
          <Route exact path="/debtors-receipts" component={DebtorsReceipts} />
          <Route exact path="/debtors-settings" component={DebtorsGeneralSettings} />
          <Route exact path="/debtors-access" component={DebtorsAccess} />
          <Route exact path="/accounts" component={AccountsPage} />
          <Route exact path="/accounts/:id" component={AccountsPage} />
          <Route exact path="/concierge" component={ConciergePage} />
          <Route exact path="/rent" component={RentPage} />
          <Route exact path="/rent/:id" component={RentPage} />
          <Route component={NotFound404} />
        </Switch>
      </Suspense>
    </main>
  );
}
