import { Grid, Divider, Typography } from '@mui/material';

const MessagesDateDivider = (props) => {
  const { date } = props;

  return (
    <Grid
      component="li"
      container
      wrap="nowrap"
      alignItems="center"
      style={{
        position: 'sticky',
        zIndex: 10,
        margin: '24px 0 10px 0',
        background: '#fff',
        top: 0,
      }}
    >
      <Grid item style={{ flexGrow: 1 }}>
        <Divider />
      </Grid>
      <Grid item>
        <Typography>{date}</Typography>
      </Grid>
      <Grid item style={{ flexGrow: 1 }}>
        <Divider />
      </Grid>
    </Grid>
  );
};

export { MessagesDateDivider };
