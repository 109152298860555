import { getCrumbs } from './getCrumbs';

export const hasPath = (path, object) => {
  if (!path || !object) {
    return false;
  }

  let value = object;
  const crumbs = getCrumbs(path);
  let idx = 0;

  while (idx < crumbs.length) {
    if (typeof value !== 'object') return false;

    if (value === null) return false;

    value = value[crumbs[idx]];
    idx += 1;
  }

  return true;
};
