import { Components } from '@mui/material';

export const Autocomplete: Components['MuiAutocomplete'] = {
  styleOverrides: {
    root: {
      '& *': {
        cursor: 'pointer',
      },
    },
    inputRoot: {
      padding: '2.5px 6px 2.5px 4px',
    },
  },
};
