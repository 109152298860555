import ExtraPropTypes from 'react-extra-prop-types';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';

export const MessageAuthor = (props) => {
  const { author, color, contact } = props;

  const renderAddress = () => {
    const address = contact?.meta?.address?.number || '';
    const flatNumber = address?.index || '';
    const flatPrefix = address?.prefix || '';

    if (flatPrefix.length && flatNumber.length) return `, ${flatPrefix} ${flatNumber}`;
  };

  return (
    <Grid item style={{ color }}>
      {author}
      {renderAddress()}
    </Grid>
  );
};

MessageAuthor.propTypes = {
  author: PropTypes.string.isRequired,
  color: ExtraPropTypes.color.isRequired,
};
