import 'react-datepicker/dist/react-datepicker.css';
import { createRoot } from 'react-dom/client';
import App from './components/App';
import { initializeFirebase } from './configs/firebase';
import './index.scss';
import './reboot.min.css';
import './shared/config/i18n';

const root = createRoot(document.getElementById('root')!);
root.render(<App />);

initializeFirebase();

if (module.hot) module.hot.accept();
