import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import CreatableSelect from 'react-select/creatable';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import i18n from '@shared/config/i18n';
import {
  FormControl,
  SelectRoot,
  FormControlContext,
  BaseInput,
  BaseInputLabel,
  BaseInputHelperText,
  BaseInputErrorText,
  Divider,
  SelectIndicator as DropdownIndicator,
  MultiSelectContainer,
  MultiSelectValue,
  MultiSelectValueContainer,
} from '../..';

const { t } = i18n;

const styles = (theme) => {
  return {
    item: {},
    fullWidth: {
      width: '100%',
    },
    labelStart: {
      marginRight: 20,
    },
    labelEnd: {
      marginLeft: 20,
    },

    labelTop: {
      marginBottom: 10,
    },
    labelBottom: {
      marginTop: 10,
    },
  };
};

const selectStyles = (notAbsolute) => ({
  placeholder: (styles) => ({ ...styles, color: 'rgba(101,101,123,.5)' }),
  select: (styles) => ({ ...styles, width: '100%' }),
  container: (styles, select) => {
    return {
      ...styles,
      width: '100%',
      display: select.selectProps.mode === 'view' ? 'none' : 'block',
    };
  },
  input: (styles) => ({
    ...styles,
    color: '#65657B',
    fontWeight: '500',
    padding: '0',
    width: '100%',
  }),
  valueContainer: (styles) => ({ ...styles, height: 'auto', padding: '0' }),
  singleValue: (styles) => ({ ...styles, color: '#65657B' }),
  menu: (styles) => ({
    ...styles,
    position: notAbsolute ? 'static' : 'absolute',
    margin: '5px',
    width: 'calc(100% - 10px)',
    zIndex: 999,
  }),
  clearIndicator: (styles) => ({ ...styles, cursor: 'pointer' }),
});

const SelectControl = (props) => {
  const {
    components,
    label,
    type,
    helpText,
    name,
    classes,
    fullWidth,
    placeholder,
    labelPlacement: lp,
    isClearable,
    errors,
    divider,
    isMulti,
    options,
    getOptionLabel,
    getOptionValue,
    formatGroupLabel,
    startAdornment,
    isLoading,
    kind = 'select',
    loadOptions,
    cacheOptions,
    isDisabled = false,
    filterOption,
    isOptionDisabled,
    menuPlacement = 'auto',
    notAbsolute,
    maxOptions,
    ...rest
  } = props;

  const inputId = `${name}-input`;
  const { item, fullWidth: fw, labelStart, labelEnd, labelTop, labelBottom } = classes;

  const mapDirectionToLabelPlacement = {
    bottom: 'column-reverse',
    end: 'row-reverse',
    start: 'row',
    top: 'column',
  };

  const direction = mapDirectionToLabelPlacement[lp];
  const rootClassName = classNames(item, { [fw]: fullWidth });
  const labelClassName = classNames({
    [labelStart]: lp === 'start',
    [labelEnd]: lp === 'end',
    [labelTop]: lp === 'top',
    [labelBottom]: lp === 'bottom',
  });
  const hasHelpText = Boolean(helpText);

  const InputControl = (props) => {
    if (props.isDisabled && props.isMulti && props.hasValue) {
      return null;
    }
    return (
      <FormControlContext.Consumer>
        {(contextProps) => (
          <SelectRoot
            {...contextProps}
            fullWidth={fullWidth ? true : false}
            startAdornment={startAdornment}
            {...props}
          />
        )}
      </FormControlContext.Consumer>
    );
  };

  const isViewMode = props.mode === 'view';

  const SelectComponent =
    kind === 'select' ? Select : kind === 'async' ? AsyncSelect : CreatableSelect;

  const multiComponents = isMulti
    ? {
        SelectContainer: MultiSelectContainer,
        ValueContainer: MultiSelectValueContainer,
        MultiValue: MultiSelectValue,
      }
    : {};

  const isArrayOfIds =
    Array.isArray(props.value) &&
    props.value.length > 0 &&
    typeof props.value[0] !== 'object';

  const isId = typeof props.value === 'string' || typeof props.value === 'number';

  const replaceIdToOption = (id) =>
    options.find((i) => String(getOptionValue(i)) === String(id)) || '';

  const calculatedValue =
    (isArrayOfIds && props.value.map(replaceIdToOption)) ||
    (isId && replaceIdToOption(props.value)) ||
    props.value ||
    '';

  const isMaxOptions =
    maxOptions !== undefined && isMulti && props.value?.length === maxOptions;

  const defaultFilterOption = (option, value) => {
    if (value.length > 0 && Boolean(option?.label)) {
      const pattern = new RegExp(value.replace(/[\\]/g, '').toUpperCase());

      if (option.label.toUpperCase().search(pattern) !== -1) {
        return true;
      }

      return false;
    }

    return true;
  };

  const filterValue = filterOption || defaultFilterOption;

  const control =
    (isViewMode || props.readOnly) && !isMulti ? (
      <BaseInput
        name={name}
        fullWidth={fullWidth ? true : false}
        placeholder={placeholder}
        type={type}
        id={inputId}
        startAdornment={startAdornment}
        {...rest}
        value={typeof calculatedValue === 'object' ? getOptionLabel(calculatedValue) : ''}
      />
    ) : (
      <SelectComponent
        styles={selectStyles(notAbsolute)}
        name={name}
        isMulti={isMulti}
        isClearable={isClearable}
        options={isMaxOptions ? [] : options}
        placeholder={placeholder}
        noOptionsMessage={() =>
          kind === 'creatable'
            ? t('StartTyping')
            : isMaxOptions
            ? `${t('CanNotChooseMore')} ${maxOptions}`
            : t('NoOptionsToChooseFrom')
        }
        loadingMessage={() => t('Loading')}
        inputId={inputId}
        openMenuOnFocus
        getOptionLabel={getOptionLabel}
        getOptionValue={getOptionValue}
        formatGroupLabel={formatGroupLabel}
        components={{
          Control: InputControl,
          DropdownIndicator,
          ...multiComponents,
          ...components,
        }}
        isLoading={isLoading}
        loadOptions={loadOptions}
        cacheOptions={cacheOptions}
        isDisabled={isDisabled}
        filterOption={filterValue}
        isOptionDisabled={isOptionDisabled}
        menuPlacement={menuPlacement}
        {...rest}
        value={calculatedValue}
      />
    );

  return (
    <FormControl {...rest}>
      <Grid
        container
        direction={direction}
        alignItems="baseline"
        justifyContent="space-between"
        wrap="nowrap"
      >
        {label && (
          <Grid item classes={{ item: rootClassName }} style={{ width: 'auto' }}>
            <BaseInputLabel
              classes={{
                root: labelClassName,
              }}
              htmlFor={inputId}
            >
              {label}
            </BaseInputLabel>
          </Grid>
        )}

        <Grid item classes={{ item: rootClassName }}>
          <Grid container direction="column">
            <Grid item style={{ width: 'inherit' }}>
              {control}
              <Grid item>
                <BaseInputHelperText>{hasHelpText ? helpText : null}</BaseInputHelperText>
              </Grid>

              <Grid item>
                <BaseInputErrorText errors={errors} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Divider isVisible={divider} />
    </FormControl>
  );
};

const StyledInputControl = withStyles(styles)(SelectControl);

SelectControl.propTypes = {
  name: PropTypes.string.isRequired,
  divider: PropTypes.bool,
  fullWidth: PropTypes.bool,
  isMulti: PropTypes.bool,
  isClearable: PropTypes.bool,
  options: PropTypes.array,
  type: PropTypes.string,
  value: PropTypes.any,
  label: PropTypes.node,
  helpText: PropTypes.node,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  labelPlacement: PropTypes.oneOf(['start', 'end', 'top', 'bottom']),
  errors: PropTypes.arrayOf(PropTypes.string),
  getOptionValue: PropTypes.func,
  getOptionLabel: PropTypes.func,
  isLoading: PropTypes.bool,
  onInputChange: PropTypes.func,
  onMenuScrollToBottom: PropTypes.func,
  menuPlacement: PropTypes.string,
  filterOption: PropTypes.func,
  isOptionDisabled: PropTypes.func,
  menuIsOpen: PropTypes.bool,
  onMenuOpen: PropTypes.func,
  onMenuClose: PropTypes.func,
  components: PropTypes.object,
  formatOptionLabel: PropTypes.func,
  addParams: PropTypes.array,
};

SelectControl.defaultProps = {
  name: Math.random().toString(36).slice(2, 11),
  divider: true,
  fullWidth: true,
  options: [],
  placeholder: t('FillField'),
  type: 'text',
  isMulti: false,
  isClearable: true,
  helpText: '',
  label: 'Some label',
  labelPlacement: 'top',
  errors: [],
  getOptionValue: (option) =>
    option && (option.id || option.value || option.name || option.label || 0),
  getOptionLabel: (option) =>
    option &&
    (option.title || option.label || option.value || option.fullname || option.name || 0),
  isLoading: false,
};

export { StyledInputControl as SelectControl };
