import {
  ModalCardImage,
  ModalCardTitle,
  ModalCardContent,
  ModalCardActions,
  CloseModalButton,
} from '../../atoms';

function ModalImage(props) {
  const { isOpen, header, content, actions, ...rest } = props;
  const { onClose } = rest;

  return isOpen ? (
    <ModalCardImage open={isOpen} {...rest}>
      <CloseModalButton onClose={onClose} />
      <ModalCardTitle disableTypography>{header}</ModalCardTitle>
      {content && <ModalCardContent>{content}</ModalCardContent>}
      {actions && <ModalCardActions>{actions}</ModalCardActions>}
    </ModalCardImage>
  ) : null;
}

function DialogImageTitle(props) {
  const { title } = props;
  return <p style={{ fontSize: '1.5em', marginBottom: 15 }}>{title}</p>;
}

function DialogImageContent(props) {
  const { src, alt } = props;

  return (
    <div
      style={{
        height: 500,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <img src={src} alt={alt} style={{ maxWidth: '100%', maxHeight: '100%' }} />
    </div>
  );
}

function DialogImage(props) {
  const { src, title, changeVisibility, isOpen } = props;

  const handleCloseDialog = () => changeVisibility(false);

  return (
    <ModalImage
      isOpen={isOpen}
      onClose={handleCloseDialog}
      header={<DialogImageTitle title={title} />}
      content={<DialogImageContent src={src} alt={title} />}
    />
  );
}

export { DialogImage };
