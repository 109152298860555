import { api } from '@api/api2';

const CLIENT_TOKEN = process.env.CLIENT_TOKEN;

const getFromStorage = (payload) =>
  api.v1(
    'post',
    'app/storage/get',
    { ...payload, client_token: CLIENT_TOKEN },
    {
      headers: { 'Content-Type': 'application/json' },
    }
  );

const saveInStorage = (payload) =>
  api.v1(
    'post',
    'app/storage/update',
    { ...payload, client_token: CLIENT_TOKEN },
    {
      headers: { 'Content-Type': 'application/json' },
    }
  );

export const storageApi = { getFromStorage, saveInStorage };
