import classNames from 'classnames';
import { Field, FieldArray } from 'formik';
import FormControlLabel from '@mui/material/FormControlLabel';
import withStyles from '@mui/styles/withStyles';
import Checkbox from '@components/atoms/Checkbox';
import FormControl from '@components/atoms/FormControl';
import FormGroup from '@components/molecules/FormGroup';
import i18n from '@shared/config/i18n';

const { t } = i18n;

const ViewControl = ({ values, ...props }) => (
  <span
    style={{
      color: '#65657B',
      fontSize: '13px',
      fontWeight: '500',
      height: '28px',
    }}
  >
    {' '}
    {values.length ? values.join(', ') : t('NotChosen')}{' '}
  </span>
);

const labelStyles = {
  root: {
    display: 'flex',
    flexGrow: '1',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    margin: '0',
    cursor: 'pointer',
    position: 'relative',
  },

  label: {
    margin: '8px 0',
  },

  positive: {
    color: '#0394E3',
    display: 'flex',
    '&:hover': {
      color: '#35A9E9',
    },
  },

  default: {
    color: '#65657B',
    '&:hover': {
      color: '#65657B',
    },
  },
};

const ControlLabel = withStyles(labelStyles)((props) => {
  const { control, label, classes, ...restProps } = props;

  return (
    <FormControlLabel
      control={control}
      label={label}
      classes={{
        label: classNames(classes.default, classes.label),
        root: classNames(classes.root),
      }}
      {...restProps}
    />
  );
});

function CheckboxesGroup(props) {
  const { data, label, getValue, getLable, mode, field, form } = props;
  const values = form.values;
  const topLevelName = field.name;

  return (
    <FormGroup
      label={label}
      GroupContent={() =>
        mode === 'edit' ? (
          <FieldArray
            name={topLevelName}
            render={({ push, remove }) =>
              data.map((item, index, array) => {
                const value = getValue(item);
                const label = getLable(item);
                const checked = values[topLevelName].includes(value);
                return (
                  <Field
                    key={value}
                    name={`${topLevelName}[${index}]`}
                    render={({ field }) => {
                      return (
                        <FormControl
                          labelPlacement="end"
                          ControlLabel={ControlLabel}
                          style={{
                            marginBottom: index !== array.length - 1 ? '16px' : '0',
                          }}
                          label={label}
                          control={
                            <Checkbox
                              inputProps={{
                                'data-test-label': label,
                                'data-test-id': topLevelName,
                              }}
                              checked={checked}
                              value={value}
                              onChange={(e) => {
                                if (!checked) {
                                  push(value);
                                } else {
                                  remove(values[topLevelName].indexOf(value));
                                }
                              }}
                              name={`${field.name}`}
                            />
                          }
                        />
                      );
                    }}
                  />
                );
              })
            }
          />
        ) : (
          <ViewControl
            values={data
              .filter((item) => values[topLevelName].includes(getValue(item)))
              .map((item) => {
                return getLable(item);
              })}
          />
        )
      }
    />
  );
}

export { CheckboxesGroup };
