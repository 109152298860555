import { Toolbar } from '@mui/material';
import withStyles from '@mui/styles/withStyles';

const styles = {
  root: {
    display: 'flex',
    alignItems: 'center',
    minHeight: 'auto',
    width: '100%',
    justifyContent: 'space-between',
  },
};

function CustomToolbar({
  children,
  variant = 'dense',
  disableGutters = true,
  classes,
  ...props
}) {
  return (
    <Toolbar
      disableGutters={disableGutters}
      variant={variant}
      classes={{ root: classes.root }}
      {...props}
    >
      {children}
    </Toolbar>
  );
}

export default withStyles(styles, { withTheme: true })(CustomToolbar);
