import { ChangeEvent } from 'react';
import { Checkbox, FormControlLabel } from '@mui/material';
import { withControl } from '@features/effector-form/hoc/withControl';

export const CheckboxControl = withControl(
  ({ value, setValue, label, readOnly, disabled }) => {
    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
      setValue(event.target.checked);
    };

    return (
      <FormControlLabel
        control={<Checkbox checked={Boolean(value)} onChange={handleChange} />}
        label={label}
        disabled={readOnly || disabled}
      />
    );
  }
);
