import VpnKeyIcon from '@mui/icons-material/VpnKey';
import i18n from '@shared/config/i18n';
import { ActionIconButton, ActionButton } from '../..';

const { t } = i18n;

function SendButton({ isMultipleUsers, isHideTitleAccess = false, ...props }) {
  const Component = isMultipleUsers ? ActionButton : ActionIconButton;
  return (
    <Component kind="positive" {...props}>
      <VpnKeyIcon
        titleAccess={
          !isHideTitleAccess
            ? `${t('sendAuthenticationPair')} ${
                isMultipleUsers ? t('selectedUsers') : ''
              }`
            : null
        }
      />
      {isMultipleUsers ? <div style={{ marginLeft: 5 }}>Сбросить</div> : ''}
    </Component>
  );
}

export { SendButton };
