import { createEffect, sample } from 'effector';
import { createGate } from 'effector-react';
import { cache, createQuery } from '@farfetched/core';
import { GetPaymentTypesResponse } from '@features/effector-form/controls/ticketPrepaymentTypes/types';
import { getPrepaymentTypes } from './api';

export const TicketPrepaymentTypesControlGate = createGate();

const fxGetTicketPrepaymentTypes = createEffect<
  void,
  GetPaymentTypesResponse,
  Error
>().use(getPrepaymentTypes);

export const ticketPrepaymentTypesQuery = createQuery({
  name: 'ticketPrepaymentType',
  effect: fxGetTicketPrepaymentTypes,
  mapData: ({ result }) =>
    result.prepayments.map((type) => ({
      id: type.name,
      label: type.title,
    })),
});

cache(ticketPrepaymentTypesQuery);

sample({
  clock: TicketPrepaymentTypesControlGate.status,
  target: ticketPrepaymentTypesQuery.start,
});
