import PropTypes from 'prop-types';
import { Typography } from '@mui/material';

export const PollHeader = (props) => {
  const { title } = props;

  return <Typography variant="h6">{title}</Typography>;
};

PollHeader.propTypes = {
  title: PropTypes.string.isRequired,
};
