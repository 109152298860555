import { Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import i18n from '@shared/config/i18n';

const { t } = i18n;

const useStyles = makeStyles(() => {
  return {
    info: {
      color: 'rgb(147, 147, 147)',
      fontSize: 18,
      fontWeight: 400,
      whiteSpace: 'nowrap',
    },
  };
});

const FoundInfo = (props) => {
  const { countTitle = t('Found'), count = 0, ...rest } = props;
  const classes = useStyles();

  return (
    <Typography classes={{ root: classes.info }} {...rest}>
      {countTitle + count}
    </Typography>
  );
};

export { FoundInfo };
