import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import add from 'date-fns/add';
import endOfWeek from 'date-fns/endOfWeek';
import format from 'date-fns/format';
import isDate from 'date-fns/isDate';
import { dateFnsLocale } from '@tools/dateFnsLocale';
import startOfWeek from 'date-fns/startOfWeek';
import sub from 'date-fns/sub';
import { Grid } from '@mui/material';
import ArrowBackIosOutlinedIcon from '@mui/icons-material/ArrowBackIosOutlined';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import { InputControl, ActionIconButton } from '../../index';

const getNewValue = (date = new Date()) => {
  const start = startOfWeek(date, { locale: dateFnsLocale });
  const end = endOfWeek(date, { locale: dateFnsLocale });

  const formattedStart = format(start, 'd', { locale: dateFnsLocale });
  const formattedEnd = format(end, 'd MMM y', {
    locale: dateFnsLocale,
  });
  const numberWeekOfYear = format(date, 'w', { locale: dateFnsLocale });
  const year = format(date, 'y', { locale: dateFnsLocale });

  return {
    year,
    week: numberWeekOfYear,
    weekStart: start,
    weekEnd: end,
    selectedDate: date,
    title: `${formattedStart} - ${formattedEnd}`,
  };
};

const WeekControl = (props) => {
  const { onChange, value = new Date(), name, ...rest } = props;

  const [value_, setValue] = useState(value);

  if (isDate(value_)) {
    if (onChange) {
      onChange(getNewValue(value));
    }

    setValue(getNewValue(value));
  }

  const switchToNextWeek = () => {
    const newValue = getNewValue(add(value_.weekStart, { weeks: 1 }));
    if (onChange) {
      onChange(newValue);
    }

    setValue(newValue);
  };

  const switchToPrevWeek = () => {
    const newValue = getNewValue(sub(value_.weekStart, { weeks: 1 }));
    if (onChange) {
      onChange(newValue);
    }

    setValue(newValue);
  };

  const handleChange = (value) => {
    const newValue = getNewValue(value);
    if (onChange) {
      onChange(newValue);
    }

    setValue(newValue);
  };

  return (
    <Grid container wrap="nowrap" alignItems="center">
      <Grid item>
        <ActionIconButton
          size="small"
          onClick={switchToPrevWeek}
          style={{ margin: '0 5px' }}
        >
          <ArrowBackIosOutlinedIcon fontSize="small" />
        </ActionIconButton>
      </Grid>
      <Grid item style={{ width: '100%' }}>
        <DatePicker
          onChange={handleChange}
          locale={dateFnsLocale}
          selected={value_.selectedDate}
          value={value_.title}
          customInput={
            <InputControl
              disabled
              label={null}
              divider={false}
              name={name}
              value={value_.title}
              inputProps={{ style: { textAlign: 'center', minWidth: 100 } }}
              readOnly
              {...rest}
            />
          }
        />
      </Grid>
      <Grid item>
        <ActionIconButton
          size="small"
          onClick={switchToNextWeek}
          style={{ margin: '0 5px' }}
        >
          <ArrowForwardIosOutlinedIcon fontSize="small" />
        </ActionIconButton>
      </Grid>
    </Grid>
  );
};

export { WeekControl };
