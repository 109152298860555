import { useState, useEffect } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import i18n from '@shared/config/i18n';
import { formatPhone } from '@tools/formatPhone';
import { isNull as isNewEntity } from '@tools/type';
import { formatDateTime } from '../lib/format-date-time';
import { matchQrStatus } from '../lib/qr-code';
import BuildingsForm from './BuildingsForm';
import ComplexesForm from './ComplexesForm';
import FlatsForm from './FlatsForm';

const { t } = i18n;

const objectsTabs = [
  {
    value: 'info',
    onCreateIsDisabled: false,
    label: t('information'),
  },
  {
    value: 'entrances',
    onCreateIsDisabled: true,
    label: t('Entrances'),
  },
];

const getLocaleDate = (number) => new Date(2021, 0, number).toLocaleDateString();
const toInputFormat = (dateString) =>
  dateString.replace(/(\d{2})\.(\d{2})\.(\d{4})/g, '$3-$2-$1');

const getDayOfMonthForInput = (number) => toInputFormat(getLocaleDate(number));

function ObjectsDetailForm({
  onCancel,
  onSaveObject,
  onSaveEntrances,
  onSaveFlat,
  ...props
}) {
  const { openedEntity, entity, loadRequestVariants } = props;
  const isNew = isNewEntity(openedEntity);

  const objectId = !isNew && (openedEntity.id || openedEntity.building.id);

  useEffect(() => {
    loadRequestVariants();
  }, [objectId]);

  const [currentObjectsTab, changeObjectsTab] = useState(objectsTabs[0].value);

  const formatHouseAddress = (openedEntity) => {
    const hasAddress =
      openedEntity && openedEntity.building && openedEntity.building.address;

    if (hasAddress) {
      const { city = '', street = '', houseNumber = '' } = openedEntity.building.address;

      return { city, street, number: houseNumber };
    }

    return { city: '', street: '', number: '' };
  };

  let initialValues = {
    handle_address: false,
    complex: !isNew
      ? entity === 'buildings'
        ? openedEntity.complex
        : openedEntity.title
      : '',
    ownershipsList: '',
    alias: !isNew ? openedEntity.building?.alias : '',
    address: !isNew
      ? {
          label:
            (openedEntity.building && openedEntity.building.address.fullAddress) || '',
        }
      : '',
    ...formatHouseAddress(openedEntity),
    fias: !isNew
      ? openedEntity.building && openedEntity.building.fias
        ? openedEntity.building.fias
        : t('isNotSpecified')
      : '',
    start_work_time:
      openedEntity && openedEntity.building && openedEntity.building.start_work_time
        ? formatDateTime(openedEntity.building.start_work_time)
        : '',
    end_work_time:
      openedEntity && openedEntity.building && openedEntity.building.end_work_time
        ? formatDateTime(openedEntity.building.end_work_time)
        : '',
    region: !isNew ? openedEntity.region || '' : '',
    sell: {
      enabled: !isNew
        ? openedEntity.building && Boolean(openedEntity.building.sell_enabled)
        : false,
      emails:
        !isNew &&
        openedEntity.building &&
        Array.isArray(openedEntity.building.sell_emails)
          ? openedEntity.building.sell_emails
          : [],
    },
    security_number:
      (!isNew &&
        openedEntity.building &&
        formatPhone(openedEntity.building.security_number)) ||
      '',
    resident_request_variant: !isNew
      ? openedEntity.building?.resident_request_variant
      : 'resident-request-by-bms',
    buildings_properties_rent_available: !isNew
      ? openedEntity.building &&
        Boolean(openedEntity.building.buildings_properties_rent_available)
      : false,
    meters:
      !isNew && openedEntity.building
        ? {
            mode: openedEntity.building.meters.mode,
            'manual-update':
              openedEntity.building.meters.mode === 'manual'
                ? {
                    from_day_of_month: getDayOfMonthForInput(
                      openedEntity.building.meters['manual-update'].from_day_of_month
                    ),
                    until_day_of_month: getDayOfMonthForInput(
                      openedEntity.building.meters['manual-update'].until_day_of_month
                    ),
                    push_day_of_month:
                      openedEntity.building.meters['manual-update'].push_day_of_month &&
                      openedEntity.building.meters['manual-update'].push_day_of_month !==
                        'Invalid date'
                        ? getDayOfMonthForInput(
                            openedEntity.building.meters['manual-update']
                              .push_day_of_month
                          )
                        : '',
                    notification_message: openedEntity.building.meters['manual-update']
                      .notification_message
                      ? openedEntity.building.meters['manual-update'].notification_message
                      : '',
                  }
                : {
                    from_day_of_month: '',
                    until_day_of_month: '',
                    push_day_of_month: '',
                    notification_message: '',
                  },
          }
        : {
            'manual-update': {
              from_day_of_month: '',
              until_day_of_month: '',
              push_day_of_month: '',
              notification_message: '',
            },
            mode: 'automatic',
          },
    entrance_number: null,
    floors: [],
    auto_floor: true,
    apartment_plan_default: openedEntity?.building?.apartment_plan_default,
    guest_scud_pass_limit: openedEntity?.building?.guest_scud_pass_limit || 0,
    paidTicketsAllowed:
      entity === 'buildings'
        ? typeof openedEntity?.building?.paid_tickets_allowed === 'string'
          ? openedEntity.building.paid_tickets_allowed === 'true'
            ? openedEntity.building.paid_tickets_allowed
            : Boolean(Number.parseInt(openedEntity.building.paid_tickets_allowed), 10)
          : Boolean(openedEntity?.building?.paid_tickets_allowed)
        : typeof openedEntity?.paid_tickets_enabled === 'string'
        ? Boolean(Number.parseInt(openedEntity.paid_tickets_enabled, 10))
        : Boolean(openedEntity?.paid_tickets_enabled),
  };

  const flatsInitialValues = {
    ...openedEntity,
    bind_by_qr: openedEntity?.bind_by_qr?.userdata_fullname || '',
    status_qr: matchQrStatus(openedEntity?.bind_by_qr).text,
  };

  const buildingValidationSchema = Yup.object().shape({
    complex: Yup.mixed().required(t('thisIsRequiredField')),
    handle_address: Yup.boolean(),
    address: Yup.mixed().when('handle_address', {
      is: false,
      then: Yup.mixed().nullable().required(t('thisIsRequiredField')),
      otherwise: Yup.mixed(),
    }),
    city: Yup.string().when('handle_address', {
      is: true,
      then: Yup.string().required(t('thisIsRequiredField')),
      otherwise: Yup.string(),
    }),
    street: Yup.string().when('handle_address', {
      is: true,
      then: Yup.string().required(t('thisIsRequiredField')),
      otherwise: Yup.string(),
    }),
    number: Yup.string().when('handle_address', {
      is: true,
      then: Yup.string().required(t('thisIsRequiredField')),
      otherwise: Yup.string(),
    }),
    ownershipsList: Yup.mixed().test('fileType', t('OnlyExcelFilesAllowed'), (value) =>
      value ? /.xlsx?/.test(value.name) : true
    ),
    resident_request_variant: Yup.string().required(t('thisIsRequiredField')),
    guest_scud_pass_limit: Yup.number().min(0, t('ValueCannotBeNegative')).nullable(),
  });

  const complexValidationSchema = Yup.object().shape({
    complex: Yup.string().required(t('thisIsRequiredField')),
    region: Yup.mixed().required(t('thisIsRequiredField')),
  });

  const entrancesValidationSchema = Yup.object().shape({
    entrance_number: Yup.number()
      .required(t('thisIsRequiredField'))
      .typeError(t('TheFieldMustBeNumber')),
    floors: Yup.array().of(
      Yup.object().shape({
        number: Yup.number()
          .required(t('thisIsRequiredField'))
          .typeError(t('TheFieldMustBeNumber')),
        range: Yup.string()
          .required(t('thisIsRequiredField'))
          .test(
            'entrances_incorrect',
            (object) =>
              `${t('FillField')} '${t('rizer')} ${
                1 + Number(object.path.match(/\d+/))
              }' ${t('NumberNumberFormat')}`,
            (values) => /\d{1,}-\d{1,}/.test(values)
          ),
      })
    ),
  });

  const flatsValidationSchema = Yup.object().shape({
    floor: Yup.number()
      .typeError(t('TheFieldMustBeNumber'))
      .required(t('thisIsRequiredField')),
    square: Yup.number()
      .typeError(t('AreaMustNumberAndSeparatedByDot'))
      .required(t('thisIsRequiredField')),
  });
  let validationSchema;
  let render = (formicProps) => (
    <BuildingsForm
      {...{ currentObjectsTab, changeObjectsTab, objectsTabs }}
      {...formicProps}
      {...props}
    />
  );
  let onSave = onSaveObject;

  if (currentObjectsTab === 'entrances') {
    validationSchema = entrancesValidationSchema;
    onSave = onSaveEntrances;
  } else if (entity === 'buildings') {
    validationSchema = buildingValidationSchema;
  } else if (entity === 'complexes') {
    validationSchema = complexValidationSchema;
    render = (formicProps) => <ComplexesForm {...formicProps} {...props} />;
  } else if (entity === 'flats') {
    render = (formicProps) => <FlatsForm {...formicProps} {...props} />;
    onSave = onSaveFlat;
    initialValues = flatsInitialValues;
    validationSchema = flatsValidationSchema;
  }

  return (
    <Formik
      render={render}
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize
      onReset={(values, { resetForm }) => {
        resetForm(initialValues);
        onCancel();
      }}
      onSubmit={(values, { resetForm }) => {
        onSave(values);

        if (currentObjectsTab === 'entrances') {
          resetForm(initialValues);
        }
      }}
    />
  );
}

export default ObjectsDetailForm;
