import makeRequest from '../../tools/makeRequest';
import { dialogActions } from '../symbiotes/objects';
import {
  requestAC,
  requestFailAC,
  getSuccessAC,
  updateSuccessAC,
  createSuccessAC,
  deleteSuccessAC,
} from './commonActionCreators';

const { dialogChangeVisibility } = dialogActions;

const name = 'COMPLEXES';

export const request = requestAC(name);
export const getSuccess = getSuccessAC(name);
export const createSuccess = createSuccessAC(name);
export const updateSuccess = updateSuccessAC(name);
export const deleteSuccess = deleteSuccessAC(name);
export const requestFail = requestFailAC(name);

const actions = (dispatch) => ({
  request: () => dispatch(request()),
  fail: (error) => {
    dispatch(requestFail(error));
    dispatch(dialogChangeVisibility({ isOpen: true, kind: 'error' }));
  },
});

export const fetchComplexes = () => (dispatch, getState) => {
  const isLoaded = Object.values(getState().complexes.data).length > 0;

  return isLoaded
    ? getState().complexes.data
    : makeRequest(
        '/v1/complex/list/',
        {},
        {
          actions: {
            ...actions(dispatch),
            success: (response) => {
              dispatch(getSuccess(response.data.items));
            },
          },
          method: 'GET',
        }
      );
};

export const createComplex = (data) => (dispatch, getState) => {
  return makeRequest('/v1/complex/create/', data, {
    actions: {
      ...actions(dispatch),
      success: (response) => {
        dispatch(createSuccess(response.data));
      },
    },
  });
};

export const updateComplex = (data) => (dispatch, getState) => {
  return makeRequest('/v1/complex/edit/', data, {
    actions: {
      ...actions(dispatch),
      success: (response) => {
        dispatch(createSuccess(response.data));
      },
    },
  });
};

export const deleteComplex = (data) => (dispatch, getState) => {
  return makeRequest('/v1/complex/delete/', data, {
    actions: {
      ...actions(dispatch),
      success: (response) => {
        dispatch(deleteSuccess(data.id));
      },
    },
  });
};
