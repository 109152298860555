import { restore, sample, createDomain } from 'effector';
import { history } from './history';

const routingDomain = createDomain();

const historyPush = routingDomain.createEffect(history.push);
const searchPush = routingDomain.createEvent();
const updateLocation = routingDomain.createEvent();
const updateAction = routingDomain.createEvent();

history.listen((location, action) => {
  updateLocation(location);
  updateAction(action);
});

const $history = restore(updateLocation, history.location);
const $lastAction = restore(updateAction, null);
const $pathname = $history.map(({ pathname }) => pathname);
const $historySearch = $history
  .map(({ search = '' }) => search)
  .map((search) => new URLSearchParams(search));

sample({
  clock: searchPush,
  source: [$pathname, $historySearch],
  fn: ([path, search], [name, param]) => {
    if (param) {
      search.set(name, param);
    } else {
      search.delete(name);
    }
    return `${path}?${search.toString()}`;
  },
  target: historyPush,
});

export {
  $history,
  $lastAction,
  $pathname,
  $historySearch,
  historyPush,
  searchPush,
  routingDomain,
  updateLocation,
};
