import { Backdrop as MuiBackdrop, LinearProgress, styled } from '@mui/material';

export const Container = styled('div')(() => ({
  position: 'relative',
  display: 'grid',
  gap: 15,
  padding: '0 0 18px 0',
  background: '#FFFFFF',
  boxShadow: '0px 10px 15px #E7E7EC',
  borderRadius: 15,
  overflow: 'hidden',
}));

export const Loader = styled(LinearProgress)(() => ({
  position: 'absolute',
  width: '100%',
  top: 0,
}));

export const Backdrop = styled(MuiBackdrop)(() => ({
  position: 'absolute',
  backgroundColor: 'rgba(255, 255, 255, 0.8)',
  zIndex: 1000,
}));

export const Header = styled('div')(() => ({
  display: 'grid',
  gridTemplateColumns: '1fr auto',
  alignItems: 'center',
  alignContent: 'center',
  padding: '18px 24px 0 24px',
}));

export const Title = styled('span')(() => ({
  fontSize: 18,
  fontWeight: 'bold',
}));

export const Actions = styled('div')(() => ({
  display: 'grid',
  gridTemplateColumns: 'auto auto auto',
  alignItems: 'center',
  gap: 5,
}));
