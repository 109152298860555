import axios from 'axios';
import qs from 'qs';
import { getCookieNameWithBuildType } from '@tools/cookie';

export const API_URL = process.env.API_URL;

const request = axios.create({
  baseURL: `${API_URL}`,
});

export const get = (url, body, download = false) => {
  const cookieMap = new Map(
    document.cookie.split(';').map((cookie) => cookie.trim().split('=', 2))
  );

  const tokenName = getCookieNameWithBuildType('bms_token');

  const token = cookieMap.get(tokenName);
  if (download) {
    window.location.href = `${API_URL}/${url}/?${qs.stringify({
      ...body,
      token,
    })}`;
  }
  return request.get(`${url}/`, {
    params: {
      ...body,
      token,
      app: 'crm',
    },
  });
};

export const sendTokenToServer = (tokenbrowser) =>
  get('push/regbrowser', { tokenbrowser, device_type: 'web', app: 'crm' });
