import CloseIcon from '@mui/icons-material/Close';
import i18n from '@shared/config/i18n';
import { ActionIconButton } from '../..';

const { t } = i18n;

function CancelButton({ isHideTitleAccess = false, ...props }) {
  return (
    <ActionIconButton data-test-id="cancel-btn" kind="negative" {...props}>
      <CloseIcon titleAccess={!isHideTitleAccess ? t('cancel') : null} />
    </ActionIconButton>
  );
}

export { CancelButton };
