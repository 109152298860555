import { useEffect } from 'react';
import { connect } from 'react-redux';
import { HaveSectionAccess } from '../../../features/common';
import fetchObjects from '../../../redux/actions/objects';
import ThreeColumnsLayout from '../../templates/ThreeColumnsLayout';
import ObjectsContent from './content/ObjectsContent';
import ObjectsFilter from './filter/ObjectsFilter';
import Detail from './forms/Detail';

function ObjectsPage({ loadObjects, houses, ...props }) {
  useEffect(() => {
    loadObjects();
  }, [houses.length]);

  return (
    <ThreeColumnsLayout
      Left={ObjectsFilter}
      Main={ObjectsContent}
      Right={Detail}
      {...props}
    />
  );
}

const mapStateToProps = (state) => ({
  rightIsOpen: state.objects.detail.isOpen,
  leftIsOpen: state.objects.filter.isOpen,
  houses: Object.values(state.houses.data),
});

const mapDispatchToProps = (dispatch) => ({
  loadObjects: () => {
    dispatch(fetchObjects());
  },
});

const ConnectedObjectsPage = connect(mapStateToProps, mapDispatchToProps)(ObjectsPage);

const RestrictedObjectsPage = (props) => (
  <HaveSectionAccess>
    <ConnectedObjectsPage {...props} />
  </HaveSectionAccess>
);

export default RestrictedObjectsPage;
