import { createEvent, createStore } from 'effector';
import { createGate } from 'effector-react';
import { interval } from 'patronum';

export const IndicatorsWidgetGate = createGate();
export const { open: mount, close: unmount } = IndicatorsWidgetGate;

export const startInterval = createEvent();
export const stopInterval = createEvent();
export const intervalValue = 30000;
export const { tick, isRunning } = interval({
  timeout: intervalValue,
  start: startInterval,
  stop: stopInterval,
});

export const $popupOpened = createStore(false);
