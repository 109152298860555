import { Field } from 'formik';
import i18n from '@shared/config/i18n';
import { isNull as isNewEntity } from '@tools/type';
import { CustomScrollbar } from '@ui';
import { featureFlags } from '@configs/feature-flags';
import DetailToolbar from '../../../molecules/DetailToolbar';
import EntrancesSelect from '../../../molecules/EntrancesSelect';
import { SimpleInput } from '../../../molecules/SimpleInput';

const { t } = i18n;
const isCRUDAllowed = featureFlags.oldObjectsCRUD;

const FlatsForm = (props) => {
  const {
    openCloseDetail,
    mode,
    handleSubmit,
    handleReset,
    onEdit,
    openedEntity,
    setFieldValue,
  } = props;

  const buildId = openedEntity.building_id;

  const isNew = isNewEntity(openedEntity);

  return (
    <form
      style={{
        height: '100%',
        position: 'relative',
      }}
    >
      <DetailToolbar
        style={{ padding: '24px' }}
        openCloseDetail={openCloseDetail}
        mode={mode}
        hidden={{ delete: true, send: true, edit: !isCRUDAllowed }}
        onEdit={onEdit}
        onSave={handleSubmit}
        onCancel={isNew ? openCloseDetail : handleReset}
      />
      <div style={{ height: 'calc(100% - 82px)', padding: '0 6px 24px 24px' }}>
        <CustomScrollbar autoHide>
          <div style={{ paddingRight: 18 }}>
            <Field
              label={`№ ${t('Apartments')}`}
              required
              component={SimpleInput}
              name="number"
              mode="view"
              divider
            />
            <Field
              label={t('rizer')}
              required
              component={EntrancesSelect}
              name="entrance_data"
              buildId={buildId}
              mode={mode}
              onChange={setFieldValue}
              divider
            />
            <Field
              label={t('Floor')}
              required
              component={SimpleInput}
              name="floor"
              mode={mode}
              divider
            />
            <Field
              label={`${t('ApartmentArea')}, м²`}
              required
              component={SimpleInput}
              name="square"
              mode={mode}
              divider
            />
            <Field
              label={t('Name')}
              required
              component={SimpleInput}
              name="title"
              mode="view"
              divider
            />
            <Field
              label={t('AnchorStatus')}
              required
              component={SimpleInput}
              name="status_qr"
              mode="view"
              divider
            />
            {openedEntity?.bind_by_qr?.userdata_fullname && (
              <Field
                label={t('InTheMobileApplicationApartmentIsLinkedToAccount')}
                required
                component={SimpleInput}
                name="bind_by_qr"
                mode="view"
                divider
              />
            )}
          </div>
        </CustomScrollbar>
      </div>
    </form>
  );
};

export default FlatsForm;
