import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Tooltip, IconButton } from '@mui/material';
import { FiberManualRecord } from '@mui/icons-material';
import makeStyles from '@mui/styles/makeStyles';
import { Template, Plugin, Getter } from '@devexpress/dx-react-core';
import { Table } from '@devexpress/dx-react-grid-material-ui';
import { TableCell } from '../..';

const useStyles = makeStyles({
  popper: {
    fontSize: 14,
  },
  iconRoot: {
    fontSize: 12,
  },
  buttonActive: {
    '& svg': {
      opacity: 1,
    },
  },
  buttonNoActive: {
    '& svg': {
      opacity: 0,
    },
    '&:hover': {
      '& svg': {
        opacity: 0.4,
      },
    },
  },
});

const TABLE_TOGGLE_TYPE = Symbol('toggle');

const tableColumnsWithToggle =
  (hasActive) =>
  ({ tableColumns }) => {
    const newTableColumns = [
      {
        key: TABLE_TOGGLE_TYPE.toString(),
        type: TABLE_TOGGLE_TYPE,
        width: 60,
      },
      ...tableColumns,
    ];

    return hasActive ? newTableColumns : tableColumns;
  };

const TableToggle = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { active, onToggle } = props;

  return (
    <Plugin name="TableToggle" dependencies={[{ name: 'Table' }]}>
      <Getter name="tableColumns" computed={tableColumnsWithToggle(active.length > 0)} />
      <Template
        name="tableCell"
        predicate={({ tableRow, tableColumn }) =>
          tableColumn.type === TABLE_TOGGLE_TYPE && tableRow.type === Table.ROW_TYPE
        }
      >
        {({ tableRow }) => {
          const rowId = tableRow.row.id;
          const isActive = active.includes(rowId);
          const buttonClassName = classNames(classes.buttonActive, {
            [classes.buttonNoActive]: !isActive,
          });

          return isActive ? (
            <TableCell>
              <Tooltip
                classes={{ popper: classes.popper }}
                arrow
                title={isActive ? t('MarkAsRead') : t('MarkUnread')}
              >
                <IconButton
                  className={buttonClassName}
                  onClick={(e) => {
                    e.stopPropagation();
                    onToggle(rowId);
                  }}
                  size="medium"
                >
                  <FiberManualRecord color="primary" className={classes.iconRoot} />
                </IconButton>
              </Tooltip>
            </TableCell>
          ) : (
            <TableCell />
          );
        }}
      </Template>
    </Plugin>
  );
};

TableToggle.COLUMN_TYPE = TABLE_TOGGLE_TYPE;

export { TableToggle };
