import { forwardRef, MouseEvent, Ref } from 'react';
import classNames from 'classnames';
import { Fab } from '@mui/material';
import withStyles from '@mui/styles/withStyles';

interface IKind {
  basic: string;
  positive: string;
  negative: string;
  active: string;
  dark: string;
  'negative-outlined': string;
}

type KindKeys = keyof IKind;

interface IClasses extends IKind {
  root: string;
  margin: string;
  label: string;
  labelWithIcon: string;
  activeLabel: string;
}

interface IProps {
  kind?: KindKeys;
  active?: boolean;
  withMargin?: boolean;
  onClick?: () => void;
  icon?: JSX.Element;
  children?: any;
  classes: IClasses;
  style?: object;
  type?: 'submit' | 'reset' | 'button';
  disabled?: boolean;
  className?: any;
}

const styles = (theme: any) => {
  const { button, label } = theme.status;

  return {
    root: {
      zIndex: 'auto',
      // В MUI 5 лейбл оборачивается в span, высота ломает стили
      // height: '30px',
      padding: '0 10px',
      color: theme.palette.common.white,

      // Костыль для кнопок с иконкой и текстом
      '& svg': {
        marginRight: 10,
      },
    },
    margin: {
      marginRight: '10px',
    },
    basic: {
      backgroundColor: button.basic.default,
      '&:hover': {
        backgroundColor: button.basic.hover,
      },
      '&:pressed': {
        backgroundColor: button.basic.pressed,
      },
      '&:active': {
        backgroundColor: button.basic.active,
      },
      '&:disabled': {
        backgroundColor: button.basic.disabled,
      },
    },
    positive: {
      backgroundColor: button.positive.default,
      '&:hover': {
        backgroundColor: button.positive.hover,
      },
      '&:pressed': {
        backgroundColor: button.positive.pressed,
      },
      '&:active': {
        backgroundColor: button.positive.active,
      },
      '&:disabled': {
        backgroundColor: button.positive.disabled,
      },
    },
    negative: {
      backgroundColor: button.negative.default,
      '&:hover': {
        backgroundColor: button.negative.hover,
      },
      '&:pressed': {
        backgroundColor: button.negative.pressed,
      },
      '&:active': {
        backgroundColor: button.negative.active,
      },
      '&:disabled': {
        backgroundColor: button.negative.disabled,
      },
    },
    'negative-outlined': {
      backgroundColor: theme.palette.common.white,
      border: `2px solid ${button.negative.default}`,
      color: `${button.negative.default} !important`,
      '&:hover': {
        backgroundColor: theme.palette.common.white,
        borderColor: button.negative.hover,
      },
      '&:pressed': {
        backgroundColor: theme.palette.common.white,
        borderColor: button.negative.pressed,
      },
      '&:active': {
        backgroundColor: theme.palette.common.white,
        borderColor: button.negative.active,
      },
      '&:disabled': {
        backgroundColor: theme.palette.common.white,
        borderColor: button.negative.disabled,
      },
    },
    active: {
      backgroundColor: button.active.default,
      boxShadow: 'none',
      '&:hover': {
        backgroundColor: button.active.hover,
      },
      '&:pressed': {
        backgroundColor: button.active.pressed,
      },
      '&:active': {
        backgroundColor: button.active.active,
      },
      '&:disabled': {
        backgroundColor: button.active.disabled,
      },
    },

    dark: {
      backgroundColor: button.dark.default,
      boxShadow: 'none',
      '&:hover': {
        backgroundColor: button.dark.hover,
      },
      '&:pressed': {
        backgroundColor: button.dark.pressed,
      },
      '&:active': {
        backgroundColor: button.dark.active,
      },
      '&:disabled': {
        backgroundColor: button.dark.disabled,
      },
    },
    label: {
      fontFamily: 'Roboto, sans-serif',
      fontSize: '1em',
    },
    labelWithIcon: {
      justifyContent: 'space-between',
    },
    activeLabel: {
      color: label.active,
    },
  };
};

const ActionButton = forwardRef((props: IProps, ref: Ref<HTMLButtonElement>) => {
  const {
    kind = 'basic',
    active = false,
    withMargin = false,
    onClick = () => null,
    icon,
    children,
    classes,
    ...rest
  } = props;

  return (
    <Fab
      ref={ref}
      classes={{
        root: classNames(classes[kind], classes.root, {
          [classes.active]: active,
          [classes.margin]: withMargin,
        }),
        // label: classNames(classes.label, {
        //   [classes.labelWithIcon]: icon,
        //   [classes.activeLabel]: active,
        // }),
      }}
      variant="extended"
      disableRipple
      size="small"
      onClick={(e) => {
        onClick(e);
      }}
      {...rest}
    >
      {icon}
      {children}
    </Fab>
  );
});

const StyledActionButton = withStyles(styles)(ActionButton);

export { StyledActionButton as ActionButton };
