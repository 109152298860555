import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { Business, Home, MeetingRoom } from '@mui/icons-material';
import withStyles from '@mui/styles/withStyles';
import i18n from '@shared/config/i18n';

const { t } = i18n;

const styles = {
  root: {
    flexGrow: '1',
    marginBottom: '20px',
  },
  margin: {
    margin: '0 10px',
  },
  tabsRoot: {
    borderBottom: '1px solid #e8e8e8',
  },
  tabRoot: {
    fontWeight: '700',
    fontSize: '14px',
    textTransform: 'uppercase',
  },
};

function TabsBar(props) {
  const { tableKind, changeTableKind, classes } = props;

  return (
    <div className={classes.root}>
      <Tabs
        value={tableKind}
        classes={{ root: classes.tabsRoot }}
        onChange={(e, value) => changeTableKind(value)}
        variant="fullWidth"
      >
        <Tab
          disableRipple
          label={t('objects')}
          classes={{ root: classes.tabRoot }}
          value="complexes"
          icon={<Business />}
          iconPosition="start"
        />
        <Tab
          disableRipple
          label={t('Buildings')}
          classes={{ root: classes.tabRoot }}
          value="buildings"
          icon={<Home />}
          iconPosition="start"
        />
        <Tab
          disableRipple
          label={t('Apartments')}
          classes={{ root: classes.tabRoot }}
          value="flats"
          icon={<MeetingRoom />}
          iconPosition="start"
        />
      </Tabs>
    </div>
  );
}

export default withStyles(styles, { withTheme: true })(TabsBar);
