import { useHistory } from 'react-router-dom';
import { useStore } from 'effector-react';
import { Grid, Link, Tooltip } from '@mui/material';
import { $user } from '@features/common';
import { useStyles } from './styles';

export const ProfileLink = () => {
  const { user_fullname, user_email, user_id } = useStore($user);
  const history = useHistory();
  const { text } = useStyles();

  const userIdText = `USER_ID: ${user_id}`;
  const profileText = user_fullname || user_email || userIdText;

  return (
    <Tooltip title={userIdText}>
      <Grid container alignItems="center">
        <Link
          className={text}
          onClick={() => history.push('/profile')}
          component="button"
          variant="body1"
        >
          {profileText}
        </Link>
      </Grid>
    </Tooltip>
  );
};
