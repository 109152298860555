import { useStore } from 'effector-react';

import makeStyles from '@mui/styles/makeStyles';

import { CustomScrollbar } from '@ui/index';
import { WidgetIndicators } from '@features/widgets/widget-indicators';
import { $userRole } from '@features/common';

const useStyles = makeStyles({
  wrap: {
    display: 'grid',
    gap: 24,
    alignItems: 'start',
    alignContent: 'start',
  },
  containerOneColumn: {
    display: 'grid',
    gap: 24,
    alignItems: 'start',
    alignContent: 'start',
  },
  containerTwoColumns: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: 24,
    alignItems: 'start',
    alignContent: 'start',
  },
  column: {
    display: 'grid',
    gap: 24,
    alignItems: 'start',
    alignContent: 'start',
  },
});

export const WidgetsGrid = ({ styles = {}, complexId = '' }) => {
  const { widgets } = useStore($userRole);
  const classes = useStyles();

  return (
    <CustomScrollbar trackHorizontalStyle={null} style={{ height: '100vh' }}>
      <div className={classes.wrap}>
        {widgets?.indicators && (
          <div className={classes.containerOneColumn}>
            <WidgetIndicators styles={styles} isHideFilters complexId={complexId} />
          </div>
        )}
      </div>
    </CustomScrollbar>
  );
};
