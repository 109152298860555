import { forwardRef, memo } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import i18n from '@shared/config/i18n';
import {
  FormControl,
  BaseInput,
  BaseInputLabel,
  BaseInputHelperText,
  BaseInputErrorText,
  Divider,
} from '../..';

const { t } = i18n;

const useStyles = makeStyles({
  item: {},
  fullWidth: {
    width: '100%',
  },
  labelStart: {
    marginRight: 20,
  },
  labelEnd: {
    marginLeft: 20,
  },
  labelTop: {
    marginBottom: 10,
  },
  labelBottom: {
    marginTop: 10,
  },
  inputContainer: { display: 'flex', alignItems: 'center' },
});

const mapDirectionToLabelPlacement = {
  bottom: 'column-reverse',
  end: 'row-reverse',
  start: 'row',
  top: 'column',
};

const InputControl = memo(
  forwardRef((props, ref) => {
    const {
      divider,
      fullWidth,
      labelPlacement: lp,
      name,
      placeholder,
      helpText,
      label,
      errors,
      type,
      rowsMax,
      multiline,
      inputProps,
      inputRef,
      inputButton,
      ...rest
    } = props;

    const classes = useStyles();

    const getName = (string) =>
      typeof string === 'string' || Math.random().toString(36).slice(2, 11);

    const inputId = `${getName(name)}-input`;
    const { item, fullWidth: fw, inputContainer } = classes;

    const direction = mapDirectionToLabelPlacement[lp];
    const rootClassName = classNames(item, { [fw]: fullWidth });

    const hasHelpText = Boolean(helpText);

    const hasInputButton = Boolean(inputButton) && props.mode === 'edit';

    return (
      <FormControl {...rest}>
        <Grid
          container
          direction={direction}
          alignItems="baseline"
          justifyContent="space-between"
          wrap="nowrap"
        >
          <InputLabel
            lp={lp}
            rootClassName={rootClassName}
            label={label}
            inputId={inputId}
          />

          <Grid item classes={{ item: rootClassName }}>
            <Grid container direction="column">
              <Grid item className={inputContainer}>
                <BaseInput
                  fullWidth={!!fullWidth}
                  placeholder={placeholder}
                  name={name}
                  id={inputId}
                  type={type}
                  multiline={multiline}
                  maxRows={rowsMax}
                  inputProps={inputProps}
                  inputRef={inputRef}
                  {...rest}
                  ref={ref}
                />
                {hasInputButton ? inputButton : null}
              </Grid>
              <Grid item>
                <BaseInputHelperText>{hasHelpText ? helpText : null}</BaseInputHelperText>
              </Grid>

              <Grid item>
                <BaseInputErrorText errors={errors} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Divider isVisible={divider} />
      </FormControl>
    );
  }),
  (prev, next) =>
    prev.value === next.value &&
    prev.label === next.label &&
    prev.mode === next.mode &&
    prev.required === next.required &&
    prev.readOnly === next.readOnly &&
    prev.error === next.error &&
    prev.disabled === next.disabled &&
    JSON.stringify(prev.errors) === JSON.stringify(next.errors) &&
    prev.helpText === next.helpText &&
    prev.state === next.state
);

function InputLabel(props) {
  const { inputId, label, lp, rootClassName } = props;

  const { labelStart, labelEnd, labelTop, labelBottom } = useStyles();

  const labelClassName = classNames({
    [labelStart]: lp === 'start',
    [labelEnd]: lp === 'end',
    [labelTop]: lp === 'top',
    [labelBottom]: lp === 'bottom',
  });

  if (!label) {
    return null;
  }

  return (
    <Grid item classes={{ item: rootClassName }} style={{ width: 'auto' }}>
      <BaseInputLabel
        classes={{
          root: labelClassName,
        }}
        htmlFor={inputId}
      >
        {label}
      </BaseInputLabel>
    </Grid>
  );
}

InputControl.propTypes = {
  name: PropTypes.string,
  divider: PropTypes.bool,
  fullWidth: PropTypes.bool,
  type: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.node,
  helpText: PropTypes.node,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  labelPlacement: PropTypes.oneOf(['start', 'end', 'top', 'bottom']),
  errors: PropTypes.arrayOf(PropTypes.string),
  multiline: PropTypes.bool,
  rowsMax: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

InputControl.defaultProps = {
  divider: true,
  fullWidth: true,
  placeholder: t('FillField'),
  type: 'text',
  helpText: '',
  label: 'Some label',
  labelPlacement: 'top',
  multiline: false,
  rowsMax: 1,
};

export { InputControl };
