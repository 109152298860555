import { api } from '@api/api2';

export const getList = ({ url, payload, apiVersion }) => {
  if (url.includes('http')) {
    return fetch(url)
      .then((response) => (response.ok ? response.json() : Promise.reject(response)))
      .catch((error) => error.message);
  }

  return api[apiVersion || 'v1']('get', url, payload);
};
