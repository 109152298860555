import { FC } from 'react';
import { useGate, useStore } from 'effector-react';
import { ColoredTextIconNotification } from '@features/colored-text-icon-notification';
import { HaveSectionAccess } from '@features/common';
import { ErrorMessage, Loader } from '@ui/index';
import {
  $error,
  $isErrorDialogOpen,
  $isFormLoading,
  changedErrorDialogVisibility,
  ProfileGate,
} from '../models';
import { ProfileCard } from '../organisms/profile-card';
import { ProfileContent } from '../organisms/profile-content';
import { useStyles } from './styles';

const ProfilePage: FC = () => {
  useGate(ProfileGate);

  const error = useStore($error);
  const isErrorDialogOpen = useStore($isErrorDialogOpen);
  const isFormLoading = useStore($isFormLoading);
  const classes = useStyles();

  const onCloseErrorMessage = () => changedErrorDialogVisibility(false);

  return (
    <>
      <ColoredTextIconNotification />

      <Loader isLoading={isFormLoading} />

      <ErrorMessage
        isOpen={isErrorDialogOpen}
        onClose={onCloseErrorMessage}
        error={error}
      />

      <div className={classes.profile__wrapper}>
        <ProfileCard />

        <ProfileContent />
      </div>
    </>
  );
};

export const RestrictedProfilePage: FC = () => (
  <HaveSectionAccess>
    <ProfilePage />
  </HaveSectionAccess>
);
