import { memo } from 'react';
import { Table } from '@devexpress/dx-react-grid-material-ui';

const TableCell = (props) => {
  const { dispatch, NestedComponent = null, row, value, ...restProps } = props;

  const customStyle = restProps?.style || {};

  const style = {
    padding: '8px 8px 8px 24px',
    fontSize: '16px',
    fontFamily: 'Roboto, sans-serif',
    color: '#767676',
    ...customStyle,
  };

  return NestedComponent === null ? (
    <Table.Cell
      {...restProps}
      data-testid={`cell ${restProps.column.name}`}
      style={style}
      value={value}
    />
  ) : (
    <Table.Cell {...restProps} style={style}>
      <NestedComponent value={value} />
    </Table.Cell>
  );
};

export default memo(TableCell);
