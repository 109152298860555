import ru from 'date-fns/locale/ru';
import enUS from 'date-fns/locale/en-US';

const userLocale = localStorage.getItem('lang');

const getDateFnsLocale = () => {
  if (userLocale === 'en') return enUS;
  return ru;
};

export const dateFnsLocale = getDateFnsLocale();
