import classNames from 'classnames';
import makeStyles from '@mui/styles/makeStyles';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import IconButton from '@mui/material/IconButton';
import { useTranslation } from 'react-i18next';

import { BaseInput } from '../..';

const useStyles = makeStyles({
  searchInput: {
    maxWidth: (props) => (props.isMobile ? '100%' : '250px'),
    flexGrow: '1',
    minWidth: '100px',
  },
  iconButton: {
    padding: 0,
  },
  icon: {
    zIndex: 1,
  },
  searchIcon: {
    color: '#0288d1',
    '&:hover': {
      cursor: 'default',
    },
  },
  clearIcon: {
    color: '#999999',
    '&:hover': {
      cursor: 'pointer',
    },
  },
});

const IconContainer = ({ handleClear, isEmpty, setIconHovered = () => {} }) => {
  const classes = useStyles();

  return (
    <IconButton
      onClick={handleClear}
      onMouseEnter={() => setIconHovered(true)}
      onMouseLeave={() => setIconHovered(false)}
      className={classes.iconButton}
      size="large"
    >
      {!isEmpty ? (
        <ClearIcon className={classNames(classes.icon, classes.clearIcon)} />
      ) : (
        <SearchIcon className={classNames(classes.icon, classes.searchIcon)} />
      )}
    </IconButton>
  );
};

const SearchInput = ({ isMobile = false, handleClear, ...props }) => {
  const classes = useStyles({ isMobile });
  const { t } = useTranslation();
  const isEmpty = props.value.length === 0;

  return (
    <BaseInput
      placeholder={t('search')}
      {...props}
      endAdornment={<IconContainer handleClear={handleClear} isEmpty={isEmpty} />}
      className={classes.searchInput}
      style={{ ...props.style }}
    />
  );
};

export { SearchInput };
