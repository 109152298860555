import FormControl from '@mui/material/FormControl';
import InputBase from '@mui/material/InputBase';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import NativeSelect from '@mui/material/NativeSelect';
import Select from '@mui/material/Select';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import i18n from '@shared/config/i18n';

const { t } = i18n;

const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
    borderRadius: 100,
  },
  input: {
    width: 80,
    position: 'relative',
    backgroundColor: 'none',
    border: 'none',
    borderRadius: 100,
    // padding: '7px 12px 7px 6px',
    padding: '0 12px 0 6px',
    color: '#65657B',
    fontSize: 14,
    fontWeight: 500,
    '&:focus': {
      borderRadius: '0 100px 100px 0',
    },
  },
}))(InputBase);

const useStyles = makeStyles((theme) => ({
  field: {
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    alignItems: 'center',
    justifyItems: 'start',
    justifyContent: 'start',
    gap: 6,
    margin: 0,
    border: '1px solid #E7E7EC',
    borderRadius: 100,
    paddingLeft: 12,
    height: 32,
  },
  field_title: {
    color: '#9393A3',
    fontWeight: 500,
    paddingTop: 2,
    paddingBottom: 1,
  },
}));

export const FilterSelect = ({
  form,
  field: { value, name },
  onChange,
  label,
  list,
  noDefault = false,
  ...props
}) => {
  const classes = useStyles();

  return (
    <FormControl className={classes.field}>
      <span className={classes.field_title}>{label}</span>
      <NativeSelect
        value={value}
        onChange={(e) => onChange(Number(e.target.value))}
        input={<BootstrapInput />}
      >
        {!noDefault && (
          <option aria-label="None" value="">
            {t('all')}
          </option>
        )}

        {list.map((item) => (
          <option key={item.id} value={item.id}>
            {item.title}
          </option>
        ))}
      </NativeSelect>
    </FormControl>
  );
};
