/**
 * Здесь подтягиваются фича флаги из конфигурвции конкретной сборки.
 * Непосредственно флаги объявлены в файле .env-cmd в корне проекта.
 * Чтобы передать флаги в runtime необходимо распарсить каждый флаг в
 * файле конфигурации webpack/webpack.common.js.
 */

const oldObjectsCRUD = JSON.parse(process.env.OLD_OBJECTS_CRUD) ?? false;
const oldRentsCRUD = JSON.parse(process.env.OLD_RENTS_CRUD) ?? false;
const oldParkingsCRUD = JSON.parse(process.env.OLD_PARKINGS_CRUD) ?? false;

export const featureFlags = {
  oldObjectsCRUD,
  oldRentsCRUD,
  oldParkingsCRUD,
};
