import { useStore } from 'effector-react';
import { getPathOr } from '../../../../tools/path';
import { $userRole } from '../../model';

const HaveAccess = (props) => {
  const { children, to, ifnot = null } = props;
  const role = useStore($userRole);

  const rules = role.permissions || { permissions: {} };

  const hasAccessBySection = getPathOr(
    to.replace('-section-all-restriction', ''),
    role.sections,
    false
  );

  const hasAccessByPermission = getPathOr(to, rules, false);

  return <>{hasAccessBySection && hasAccessByPermission ? children : ifnot} </>;
};

export { HaveAccess };
