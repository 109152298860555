import ru from 'date-fns/locale/ru';
import { FormControl, FormHelperText, FormLabel } from '@mui/material';
import { TimeField, LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns';
import { withControl } from '@features/effector-form/hoc/withControl';

export const TimeControl = withControl(
  ({ value, setValue, error, label, required, readOnly, disabled }) => (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ru}>
      <FormControl required={required} disabled={disabled}>
        <FormLabel>{label}</FormLabel>
        <TimeField
          value={value}
          onChange={(newValue) => setValue(newValue)}
          InputProps={{
            readOnly,
            disabled,
          }}
          readOnly={readOnly}
          disabled={disabled}
        />
        <FormHelperText error>{error}</FormHelperText>
      </FormControl>
    </LocalizationProvider>
  )
);
