import { memo } from 'react';
import { useLocation } from 'react-router-dom';
import { useStore } from 'effector-react';
import clsx from 'clsx';

import { Tooltip, CircularProgress, Pagination } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import i18n from '@shared/config/i18n';
import { PaginationItem } from '@mui/lab';

import TelegramIcon from '@img/telegram';
import PencilIcon from '@img/pencil';
import { CustomModal, CloseButton, CustomScrollbar, Empty, ErrorMsg as ErrorMessage } from '@ui/';
import { convertTimestampToLocal } from '@tools/formatDateWithZone';
import { ArchiveIcon, ArchiveEdit } from './archive';
import {
  $filters,
  $visibleArchiveBlockSignals,
  $signalsRaw,
  changePagOffset,
  SIGNALS_ON_PAGE,

  $isSignalsLoading,
  $signalsTypes,
  closeLevel,
  $popupData,
  $popupOpened,
} from '../models';
import { onlyArchiveCardId } from './card';
import { $setToOrFromArchiveError, resetError } from '../models/archive/archive.model';

const { t } = i18n;

const colors = {
  red: { default: '#EB5757', light: '#FDEEEE' },
  orange: { default: '#FF8A00', light: '#fff3e5' },
  blue: { default: '#0394E3', light: '#e5f4fc' },
  green: { default: '#36b97b' },
  telegram: {
    default: '#28a7e9',
  },
};

const useStyles = makeStyles({
  header: {
    display: 'grid',
    gap: 24,
    padding: '0 10px',
  },
  header__content: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: 12,
    width: '100%',
    paddingTop: 5,
  },
  header__title: {
    flexGrow: 10,
    fontSize: 24,
  },
  header__attention: {
    fontSize: 12,
    padding: '10px 16px',
    borderRadius: 12,
    fontWeight: 400,
  },
  header__attention_red: {
    backgroundColor: colors.red.light,
    color: colors.red.default,
  },
  header__attention_orange: {
    backgroundColor: colors.orange.light,
    color: colors.orange.default,
  },
  header__attention_blue: {
    backgroundColor: colors.blue.light,
    color: colors.blue.default,
  },
  content: {
    paddingTop: 15,
  },
  content__list: {
    display: 'grid',
    alignItems: 'start',
    gap: 12,
    margin: 0,
    padding: '0 10px',
  },
  list__item: {
    display: 'grid',
    lineHeight: 1,
    gap: 5,
    padding: '15px 20px',
    fontWeight: 'bold',
    fontSize: 12,
    background: 'white',
    boxShadow: '0px 12px 24px -8px rgba(49, 49, 49, 0.1);',
    border: '1px solid whitesmoke',
    borderRadius: 12,
  },
  item__iconWrap: {
    display: 'grid',
    alignItems: 'center',
    justifyItems: 'center',
    width: 40,
    height: 40,
    borderRadius: '100%',
  },
  item__iconWrap_red: {
    backgroundColor: colors.red.light,
    '& *': {
      stroke: colors.red.default,
    },
  },
  item__iconWrap_orange: {
    backgroundColor: colors.orange.light,
    '& *': {
      stroke: colors.orange.default,
    },
  },
  item__iconWrap_blue: {
    backgroundColor: colors.blue.light,
    '& *': {
      stroke: colors.blue.default,
    },
  },
  item__icon: {
    padding: 3,
  },
  item__content: {
    display: 'grid',
    gridTemplateColumns: '1fr auto',
    gap: 16,
    paddingBottom: 16,
    borderBottom: '1px solid whitesmoke',
  },
  item__deviceInfo: {
    display: 'grid',
    gridTemplateColumns: '40px 1fr',
    gap: 16,
    cursor: 'pointer',
  },
  item__titles: {
    display: 'grid',
    gap: 8,
    alignContent: 'start',
  },
  item__type: {
    fontSize: 12,
    fontWeight: 500,
  },
  item__type_red: {
    color: colors.red.default,
  },
  item__type_orange: {
    color: colors.orange.default,
  },
  item__type_blue: {
    color: colors.blue.default,
  },
  item__name: {
    fontSize: 15,
    fontWeight: 600,
  },
  item__info: {
    fontSize: 12,
    fontWeight: 400,
  },
  item__address: {
    fontSize: 14,
    fontWeight: 400,
    color: '#6C6C7C',
  },
  item__footer: {
    display: 'grid',
    gridTemplateColumns: '1fr auto',
    paddingTop: 10,
  },
  item__archiveText: {
    background: '#F4F7FA',
    borderRadius: 8,
    padding: 16,
    color: 'color: #6C6C7C',
    fontSize: 14,
    lineHeight: '22px',
    fontWeight: 400,
    gridColumnStart: 1,
    gridColumnEnd: 3,
    display: 'flex',
  },
  item__actions: {
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    gap: 10,
    justifyContent: 'start',
    alignContent: 'center',
    alignItems: 'center',
  },
  item__actionsTelegram: {
    width: 15,
    height: 15,
    cursor: 'pointer',
    '& *': {
      stroke: colors.telegram.default,
    },
  },
  item__actionsPencil: {
    width: 15,
    height: 15,
    cursor: 'pointer',
    '& *': {
      stroke: colors.green.default,
    },
  },
  item__value: {
    fontWeight: 500,
  },
});

const Popup = () => {
  const { level = {}, record = {}, signals = [] } = useStore($popupData);
  const signalsTypes = useStore($signalsTypes);
  const isSignalsLoading = useStore($isSignalsLoading);
  const popupOpened = useStore($popupOpened);
  const classes = useStyles();
  const filters = useStore($filters);
  const setToOrFromArchiveError = useStore($setToOrFromArchiveError);

  const handleDeviceClick = (url) => {
    window.open(`/equipment/?url=${url}`, '_blank');
  };

  const handleTicketClick = (id) => {
    window.open(`/tickets/${id}`, '_blank');
  };

  const handleTelegramClick = (url) => {
    window.open(url, '_blank');
  };

  const getRecordColor = () => {
    switch (level.id) {
      case 145:
        return 'red';
      case 146:
        return 'orange';
      case 147:
      default:
        return 'blue';
    }
  };

  const recordColor = getRecordColor();

  const renderDate = (date) => {
    if (date === 0) return t('DateNotSpecified');

    return convertTimestampToLocal(date, 'yyyy-MM-dd HH:MM');
  };

  const renderActions = (signal) =>
    signal.signal.actions.map((action) => {
      const actionTypeData = signalsTypes.find(
        (item) => +item.id === action.signal_notification_type_id
      );

      // Без обертки div не работает тултип
      switch (+actionTypeData.id) {
        case 183:
          return (
            <Tooltip title={renderDate(action.send_at)} placement="bottom">
              <div>
                <TelegramIcon
                  className={classes.item__actionsTelegram}
                  onClick={() => handleTelegramClick(action.url)}
                />
              </div>
            </Tooltip>
          );

        case 148:
          return (
            <Tooltip title={renderDate(action.send_at)} placement="bottom">
              <div>
                <PencilIcon
                  className={classes.item__actionsPencil}
                  onClick={() => handleTicketClick(action.params.ticket_id)}
                />
              </div>
            </Tooltip>
          );

        default:
          return null;
      }
    });

  const SignalCardFooter = memo(
    ({ signal, isArchiveAccess, visibleArchiveBlockSignals }) => {
      const signal_id = signal.signal?.signal_id;
      const location = useLocation();

      return (
        <div className={classes.item__footer}>
          {visibleArchiveBlockSignals !== signal_id && (
            <>
              <div className={classes.item__actions}>
                {!filters.archive && renderActions(signal)}
                {isArchiveAccess && <ArchiveIcon signal={signal} />}
              </div>

              {/* Current value will be hidden on dashboard page */}
              {!location.pathname.includes('dashboard') ?
                (
                  <span className={classes.item__value}>
                    {`${t('CurrentValue')}: ${signal.signal.current_signal_value_float}`}
                  </span>
                ) : null}
            </>
          )}

          {visibleArchiveBlockSignals === signal_id && <ArchiveEdit signal={signal} />}
        </div>
      );
    });

  const SignalCard = memo(({ signal }) => {
    const signal_id = signal.signal?.signal_id;
    const isArchiveAccess = onlyArchiveCardId.includes(signal.signal.category_id);
    const visibleArchiveBlockSignals = useStore($visibleArchiveBlockSignals);
    const omitBorder =
      visibleArchiveBlockSignals !== signal_id && !isArchiveAccess && !filters.archive;

    console.log(visibleArchiveBlockSignals, signal_id, isArchiveAccess, filters.archive);

    if (!signal_id) return null;

    return (
      <li className={classes.list__item} key={signal.signal.activ}>
        <div
          className={classes.item__content}
          style={{ borderBottom: omitBorder ? 'none' : '' }}
        >
          <div
            className={classes.item__deviceInfo}
            onClick={() => handleDeviceClick(signal.device.url)}
            aria-hidden="true"
          >
            <div
              className={clsx(classes.item__iconWrap, {
                [classes.item__iconWrap_red]: recordColor === 'red',
                [classes.item__iconWrap_orange]: recordColor === 'orange',
                [classes.item__iconWrap_blue]: recordColor === 'blue',
              })}
            >
              {record.icon(classes.item__icon)}
            </div>

            <div className={classes.item__titles}>
              {signal.notification.title && (
                <span
                  className={clsx(classes.item__type, {
                    [classes.item__type_red]: recordColor === 'red',
                    [classes.item__type_orange]: recordColor === 'orange',
                    [classes.item__type_blue]: recordColor === 'blue',
                  })}
                >
                  {signal.notification.title}
                </span>
              )}

              <span className={classes.item__name}>
                {`${signal.map_signals_condition.title}`}
              </span>

              <span className={classes.item__address}>
                {`${signal.device.complex.title}, ${signal.device.building.title}`}
              </span>
            </div>
          </div>

          {}

          <div className={classes.item__info}>{renderDate(signal.signal.activ_at)}</div>

          {filters.archive && !!signal.map_signals_condition.comment && (
            <div className={classes.item__archiveText}>
              {signal.map_signals_condition.comment}
            </div>
          )}
        </div>

        <SignalCardFooter
          signal={signal}
          isArchiveAccess={isArchiveAccess}
          visibleArchiveBlockSignals={visibleArchiveBlockSignals}
        />
      </li>
    );
  });

  const renderSignals = () => {
    if (!signals?.length) return <Empty title={t('NoRecords')} />;

    return (
      <CustomScrollbar
        style={{ width: '100%' }}
        autoHide
        autoHeight
        autoHeightMin={0}
        autoHeightMax={312}
      >
        <ul className={classes.content__list}>
          {signals.map((signal) => (
            <SignalCard signal={signal} key={signal.signal.signal_id} />
          ))}
        </ul>
      </CustomScrollbar>
    );
  };

  const PaginationData = memo(() => {
    const signalsRaw = useStore($signalsRaw);
    const meta = signalsRaw.result?.meta;

    const count = Math.ceil(meta.total / meta.limit);

    if (count === 1) return null;

    const pageProp = Math.floor(meta.offset / SIGNALS_ON_PAGE) + 1;

    return (
      <Pagination
        style={{
          padding: 24,
          display: 'flex',
          justifyContent: 'center',
          flexWrap: 'nowrap',
        }}
        onChange={(_, page) => changePagOffset((page - 1) * SIGNALS_ON_PAGE)}
        page={pageProp}
        count={count}
        size="small"
        shape="rounded"
        renderItem={(item) => (
          <PaginationItem
            {...item}
            style={{
              width: 20,
              height: 36,
              fontWeight: 600,
            }}
          />
        )}
      />
    );
  });

  const content = (
    <div className={classes.content}>
      {renderSignals()}

      <PaginationData />

      <ErrorMessage
        isOpen={setToOrFromArchiveError}
        onClose={resetError}
        error={setToOrFromArchiveError}
      />
    </div>
  );

  return (
    <CustomModal
      isOpen={Boolean(popupOpened)}
      header={(
        <div className={classes.header}>
          <div className={classes.header__content}>
            <span className={classes.header__title}>{record.title}</span>
            {isSignalsLoading ? <CircularProgress size={24} /> : null}
            <CloseButton style={{ color: 'd3d3d3' }} onClick={closeLevel} />
          </div>
          {!signals?.length ? null : (
            <div
              className={clsx(classes.header__attention, {
                [classes.header__attention_red]: recordColor === 'red',
                [classes.header__attention_orange]: recordColor === 'orange',
                [classes.header__attention_blue]: recordColor === 'blue',
              })}
            >
              {!filters.archive
                ? `${t('PayAttentionToTheFollowingIndicators')}:`
                : `${t('SignalsMovedArchive')}:`}
            </div>
          )}
        </div>
      )}
      content={content}
      onClose={closeLevel}
      minWidth={650}
      maxWidth={650}
    />
  );
};

export { Popup };
