export function mergeColumnWidths(defaultConf, receivedConf) {
  if (Array.isArray(receivedConf)) {
    const widths = receivedConf.reduce((acc, i) => acc.set(i.columnName, i), new Map());

    return defaultConf.reduce(
      (acc, i) =>
        widths.has(i.columnName) ? acc.concat(widths.get(i.columnName)) : acc.concat(i),
      []
    );
  }

  return defaultConf;
}
