import { combineReducers } from 'redux';
import cameras from './cameras';
import complexes from './complexesReducer';
import flats from './flatsReducer';
import houses from './housesReducer';
import objects from './objects';
import rizers from './rizersReducer';
import user from './userReducer';

const rootReducer = combineReducers({
  complexes,
  houses,
  flats,
  user,
  objects,
  cameras,
  rizers,
});

export default rootReducer;
