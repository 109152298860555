import makeRequest from '../../tools/makeRequest';
import { requestAC, requestFailAC, getSuccessAC } from './commonActionCreators';

const name = 'COMPLEXES_REGION';

export const request = requestAC(`${name}`);
export const getSuccess = getSuccessAC(`${name}`);
export const requestFail = requestFailAC(`${name}`);

const actions = (dispatch) => ({
  request: () => dispatch(request()),
  fail: (error) => {
    dispatch(requestFail(error));
  },
});

export const fetchComplexesRegion = () => (dispatch, getState) => {
  const isLoaded = Object.values(getState().complexes.data).length > 0;

  return isLoaded
    ? getState().complexes.data
    : makeRequest(
        '/v1/regions/list/',
        {},
        {
          actions: {
            ...actions(dispatch),
            success: (response) => {
              dispatch(getSuccess(response.data.items));
            },
          },
          method: 'GET',
        }
      );
};
