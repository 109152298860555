import makeRequest from '../../tools/makeRequest';
import { dialogActions } from '../symbiotes/objects';
import {
  requestAC,
  requestFailAC,
  getSuccessAC,
  updateSuccessAC,
  createSuccessAC,
  deleteSuccessAC,
} from './commonActionCreators';

const { dialogChangeVisibility } = dialogActions;

const name = 'HOUSES';

export const request = requestAC(name);
export const getSuccess = getSuccessAC(name);
export const createSuccess = createSuccessAC(name);
export const updateSuccess = updateSuccessAC(name);
export const deleteSuccess = deleteSuccessAC(name);
export const requestFail = requestFailAC(name);

const actions = (dispatch) => ({
  request: () => dispatch(request()),
  fail: (error) => {
    dispatch(requestFail(error));
    dispatch(dialogChangeVisibility({ isOpen: true, kind: 'error' }));
  },
});

export function fetchHouses(fromState = true) {
  return (dispatch, getState) => {
    const isLoad = Object.values(getState().houses.data).length > 0;

    return isLoad && fromState
      ? getState().houses.data
      : makeRequest(
          '/v1/buildings/get-list-crm/',
          { per_page: '1000', page: '1' },
          {
            actions: {
              ...actions(dispatch),
              success: ({ data }) => {
                dispatch(getSuccess(data));
              },
            },
            method: 'GET',
          }
        );
  };
}

export const createBuilding = (payload) => (dispatch, getState) =>
  makeRequest('/v1/buildings/create/', payload, {
    actions: {
      ...actions(dispatch),
      success: ({ data }) => {
        dispatch(createSuccess(data));
      },
    },
  });

export const updateBuilding = (payload) => (dispatch, getState) => {
  return makeRequest('/v1/buildings/edit/', payload, {
    actions: {
      ...actions(dispatch),
      success: ({ data }) => {
        dispatch(updateSuccess(data));
      },
    },
  });
};

export const deleteBuilding = (data) => (dispatch, getState) => {
  return makeRequest('/v1/buildings/delete/', data, {
    actions: {
      ...actions(dispatch),
      success: (response) => {
        dispatch(deleteSuccess(data.id));
      },
    },
  });
};
