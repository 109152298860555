export const parseUrlParams = (params) => {
  const urlParams = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });

  if (Array.isArray(params)) {
    return params.map((param) => ({
      value: urlParams[param],
    }));
  } else return urlParams[params];
};
