import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import withStyles from '@mui/styles/withStyles';

const styles = {
  container: {
    maxHeight: ({ hasAttachment }) => (hasAttachment ? '12vh' : '18vh'),
    padding: '16px',
    alignItems: 'flex-end',
  },
};

export const MessageBox = withStyles(styles)((props) => {
  const { container } = props.classes;
  const { children } = props;

  return (
    <Grid
      component="form"
      container
      classes={{ container }}
      justifyContent="space-between"
      wrap="nowrap"
      alignItems="center"
    >
      {children}
    </Grid>
  );
});

MessageBox.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element),
};
