import { SettingsOutlined } from '@mui/icons-material';
import { ActionIconButton } from '../..';

function GroupButton(props) {
  return (
    <ActionIconButton {...props}>
      <SettingsOutlined />
    </ActionIconButton>
  );
}

export { GroupButton };
