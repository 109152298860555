import { useEffect } from 'react';
import ReactDatePicker from 'react-datepicker';
import format from 'date-fns/format';
import parse from 'date-fns/parse';
import { Field, FieldArray } from 'formik';
import {
  Button,
  IconButton,
  Tab,
  Grid,
  RadioGroup,
  Radio,
  FormControlLabel,
  FormLabel,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import makeStyles from '@mui/styles/makeStyles';
import i18n from '@shared/config/i18n';
import { dateFnsLocale } from '@tools/dateFnsLocale';
import {
  SwitchField,
  InputField,
  EmailMaskedInput,
  CustomScrollbar,
  Tabs,
  FileControl,
  ActionButton,
  BaseInputLabel,
  PlanImage,
  PhoneMaskedInput,
} from '@ui/index';
import { featureFlags } from '@configs/feature-flags';
import { isNull as isNewEntity } from '../../../../tools/type';
import CheckboxControl from '../../../atoms/CheckboxControl';
import Divider from '../../../atoms/Divider';
import AddressSelect from '../../../molecules/AsyncAddressSelect';
import ComplexInput from '../../../molecules/ComplexInput';
import DetailToolbar from '../../../molecules/DetailToolbar';
import FormGroup from '../../../molecules/FormGroup';
import EntrancesForm from './EntrancesForm';

const { t } = i18n;

const isCRUDAllowed = featureFlags.oldObjectsCRUD;

const set31Day = (date, isParse = false) => {
  const res = new Date(2021, 0, 1);
  if (date) {
    const tempDate = isParse ? parse(date, 'yyyy-MM-dd', res) : date;
    res.setDate(tempDate.getDate());
  }
  return res;
};

const getDay = (date, mode) =>
  mode === 'edit' ? date : date && `${Number(date.split('-')[2])} числа`;

const useStyles = makeStyles(() => ({
  selectDay: {
    '& .react-datepicker__header, & .react-datepicker__navigation': {
      display: 'none',
    },
    '& .react-datepicker__day--outside-month': {
      visibility: 'hidden',
    },
  },
  imagePaper: {
    width: 224,
    height: 192,
    backgroundColor: '#F4F7FA',
    borderRadius: 16,
    marginBottom: 15,
    position: 'relative',
    margin: '20px auto 15px auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  planImage: {
    padding: 15,
    width: '100%',
    height: 150,
    objectFit: 'contain',
    cursor: 'pointer',
  },
  radioLabel: {
    fontSize: '13px',
    color: '#65657B',
    lineHeight: '15px',
    fontWeight: 500,
  },
  formLabel: {
    fontSize: '14px',
    padding: '15px 0',
    color: '#9494A3',
  },
}));

const BuildingsForm = (props) => {
  const {
    openCloseDetail,
    loadEntrances,
    mode,
    handleSubmit,
    handleReset,
    onEdit,
    onDelete,
    openedEntity,
    requestVariants,
    values,
    setFieldValue,
    detailEntrances,
    isObjectsLoading,
    changeObjectsTab,
    currentObjectsTab,
    objectsTabs,
  } = props;

  const isNew = isNewEntity(openedEntity);
  const isEditMode = mode === 'edit';
  const classes = useStyles();
  const objectId = !isNew && openedEntity.building.id;
  const apartmentPlanUrl =
    values.apartment_plan_default?.url || values.apartment_plan_default?.preview || null;

  useEffect(() => {
    if (objectId) {
      loadEntrances(objectId);
    }
  }, [objectId]);

  return (
    <form
      style={{
        height: '100%',
        position: 'relative',
      }}
    >
      <DetailToolbar
        style={{ padding: 24 }}
        openCloseDetail={openCloseDetail}
        mode={mode}
        hidden={{ delete: !isCRUDAllowed, edit: !isCRUDAllowed, send: true }}
        onEdit={onEdit}
        onSave={handleSubmit}
        onCancel={isNew ? openCloseDetail : handleReset}
        onDelete={onDelete}
      />
      <div style={{ height: 'calc(100% - 82px)', padding: '0 6px 24px 24px' }}>
        <CustomScrollbar autoHide>
          <div>
            <Tabs
              options={objectsTabs}
              currentTab={currentObjectsTab}
              onChange={(e, tab) => changeObjectsTab(tab)}
              isNew={isNew}
              tabEl={<Tab style={{ minWidth: '50%' }} />}
            />

            {currentObjectsTab === 'info' && (
              <div style={{ paddingRight: 18 }}>
                <Field
                  name="apartment_plan_default"
                  render={({ field, form }) => (
                    <Grid classes={{ root: classes.imagePaper }} item>
                      <BaseInputLabel
                        style={{
                          margin: '20px 20px 10px 20px',
                          textAlign: 'center',
                        }}
                      >
                        {t('PhotoOfTheBuilding')}
                      </BaseInputLabel>
                      {apartmentPlanUrl && (
                        <PlanImage
                          src={apartmentPlanUrl}
                          className={classes.planImage}
                          title={t('PhotoOfTheBuilding')}
                        />
                      )}
                      <FileControl
                        inputProps={{ accept: 'image/*' }}
                        loadable
                        mode={mode}
                        value={field.value}
                        renderPreview={() => null}
                        renderButton={() => (
                          <ActionButton
                            component="span"
                            style={{
                              width: '75%',
                              position: 'absolute',
                              bottom: 25,
                              left: 0,
                              right: 0,
                              marginLeft: 'auto',
                              marginRight: 'auto',
                            }}
                          >
                            {field.value ? t('Replace') : t('PictureControlUpload')}
                          </ActionButton>
                        )}
                        onChange={(value) => form.setFieldValue(field.name, value)}
                      />
                    </Grid>
                  )}
                />
                <Field
                  component={ComplexInput}
                  name="complex"
                  required
                  onChange={setFieldValue}
                  value={values.complex}
                  mode={mode}
                />
                <Field
                  name="alias"
                  label={t('Name')}
                  component={InputField}
                  mode={mode}
                />
                {!values.handle_address ? (
                  <Field
                    component={AddressSelect}
                    name="address"
                    divider={!isEditMode}
                    required
                    onChange={(name, value) => {
                      setFieldValue(name, value);
                      setFieldValue('fias', value && value.fias ? value.fias : '');
                    }}
                    value={values.address}
                    mode={mode}
                  />
                ) : (
                  <>
                    <Field
                      required
                      name="city"
                      label={t('Label.city')}
                      mode={mode}
                      component={InputField}
                    />
                    <Field
                      required
                      name="street"
                      label={t('Label.street')}
                      mode={mode}
                      component={InputField}
                    />
                    <Field
                      required
                      name="number"
                      label={t('HouseNumber')}
                      mode={mode}
                      divider={!isEditMode}
                      component={InputField}
                    />
                  </>
                )}
                {isEditMode ? (
                  <Field
                    component={SwitchField}
                    name="handle_address"
                    label={t('EnterAddressManually')}
                    labelPlacement="start"
                  />
                ) : null}
                <Field
                  name="fias"
                  label={t('FIAS')}
                  mode={mode}
                  value={
                    isEditMode && values.fias === t('isNotSpecified').toLowerCase()
                      ? ''
                      : values.fias
                  }
                  component={InputField}
                />
                <Field
                  name="security_number"
                  render={({ field, form }) => (
                    <InputField
                      field={field}
                      form={form}
                      inputComponent={PhoneMaskedInput}
                      label={t('SecurityPhone')}
                      placeholder={t('Label.phone')}
                      mode={mode}
                    />
                  )}
                />
                <Field
                  name="sell"
                  render={({ field, form }) => (
                    <>
                      <Field
                        name={`${field.name}.enabled`}
                        component={SwitchField}
                        label={t('AcceptanceApplicationsForSaleApartmentsFromOwners')}
                        labelPlacement="start"
                        disabled={!isEditMode}
                      />
                      <FieldArray
                        name={`${field.name}.emails`}
                        render={({ push, remove }) => {
                          const emails =
                            form.values.sell && Array.isArray(form.values.sell.emails)
                              ? form.values.sell.emails.map((i, idx, array) => {
                                const isLast = idx === array.length - 1;

                                return (
                                  <div
                                    style={{
                                      display: 'flex',
                                      alignItems: 'center',
                                    }}
                                    key={idx}
                                  >
                                    <div
                                      style={{
                                        width: isLast ? '80%' : '100%',
                                      }}
                                    >
                                      <Field
                                        name={`${field.name}.emails[${idx}]`}
                                        render={({ field: subField }) => (
                                          <InputField
                                            field={subField}
                                            form={form}
                                            inputComponent={EmailMaskedInput}
                                            label={`${t('ContactEmail')} ${idx + 1}`}
                                            mode={mode}
                                          />
                                        )}
                                      />
                                    </div>
                                    {isEditMode && isLast ? (
                                      <div
                                        style={{
                                          width: '20%',
                                          textAlign: 'center',
                                        }}
                                      >
                                        <IconButton
                                          onClick={() => remove(idx)}
                                          size="large"
                                        >
                                          <Close />
                                        </IconButton>
                                      </div>
                                    ) : null}
                                  </div>
                                );
                              })
                              : [];

                          return form.values.sell && form.values.sell.enabled ? (
                            <>
                              {emails}
                              {isEditMode ? (
                                <div style={{ textAlign: 'center' }}>
                                  <Button color="primary" onClick={() => push('')}>
                                    {t('AddContactEmail')}
                                  </Button>
                                  <Divider />
                                </div>
                              ) : null}
                            </>
                          ) : null;
                        }}
                      />
                    </>
                  )}
                />

                <Field
                  name="resident_request_variant"
                  mode={mode}
                  render={({ field }) => (
                    <>
                      <FormLabel classes={{ root: classes.formLabel }}>
                        {t('VerificationApplicationsForConnectionToPremises')}
                      </FormLabel>

                      {requestVariants ? (
                        <RadioGroup
                          name={field.name}
                          value={field.value}
                          onChange={field.onChange}
                        >
                          <Grid container>
                            {Object.keys(requestVariants).map((i, idx) => (
                              <Grid item key={idx}>
                                <FormControlLabel
                                  classes={{
                                    label: classes.radioLabel,
                                  }}
                                  value={i}
                                  control={<Radio size="small" />}
                                  label={requestVariants[i]}
                                  disabled={!isEditMode}
                                />
                              </Grid>
                            ))}
                          </Grid>
                        </RadioGroup>
                      ) : (
                        <p>{t('NoVerificationOptionsAvailable')}</p>
                      )}
                    </>
                  )}
                />

                <Divider />

                <Field
                  component={SwitchField}
                  name="buildings_properties_rent_available"
                  label={t('PossibilityRentingPremises')}
                  labelPlacement="start"
                  disabled={!isEditMode}
                />

                <Field
                  component={InputField}
                  name="guest_scud_pass_limit"
                  type="number"
                  mode={mode}
                  label={t('individualDurationGuestPass')}
                  placeholder={t('EnterExpirationDateGuestPass')}
                />

                <Field
                  name="meters"
                  mode={mode}
                  render={(fieldProps) => {
                    const parentFieldName = fieldProps.field.name;
                    const name = `${parentFieldName}.mode`;
                    const isManual = values[parentFieldName].mode === 'manual';

                    return (
                      <>
                        <Field
                          name={name}
                          render={(nestFieldProps) => {
                            const { field, form } = nestFieldProps;
                            const { value, name } = field;
                            const { setFieldValue } = form;
                            const checked = value === 'manual';
                            const disabled = !isEditMode;

                            const onChange = (e) =>
                              setFieldValue(
                                name,
                                e.target.checked ? 'manual' : 'automatic'
                              );

                            return (
                              <CheckboxControl
                                checked={checked}
                                disabled={disabled}
                                value={value}
                                onChange={onChange}
                                label={t('SubmitCountersManually')}
                              />
                            );
                          }}
                        />
                        {isManual && (
                          <Field
                            name={`${parentFieldName}.manual-update`}
                            mode={mode}
                            render={({ field, form }) => {
                              const fromName = `${field.name}.from_day_of_month`;
                              const untilName = `${field.name}.until_day_of_month`;
                              const pushName = `${field.name}.push_day_of_month`;
                              const notificationName = `${field.name}.notification_message`;
                              const {
                                from_day_of_month,
                                until_day_of_month,
                                push_day_of_month,
                                notification_message,
                              } = field.value;
                              return (
                                <>
                                  <ReactDatePicker
                                    customInput={
                                      <InputField
                                        field={field}
                                        form={form}
                                        label={t('ServeWith')}
                                      />
                                    }
                                    readOnly={mode === 'view'}
                                    placeholderText={
                                      mode === 'view' ? t('NotSet') : t('ChooseADay')
                                    }
                                    name={fromName}
                                    selected={
                                      from_day_of_month
                                        ? set31Day(from_day_of_month, true)
                                        : null
                                    }
                                    onChange={(value) => {
                                      form.setFieldValue(
                                        fromName,
                                        format(set31Day(value), 'yyyy-MM-dd')
                                      );
                                    }}
                                    calendarClassName={classes.selectDay}
                                    value={getDay(from_day_of_month)}
                                    popperPlacement="top"
                                  />
                                  <ReactDatePicker
                                    customInput={
                                      <InputField
                                        field={field}
                                        form={form}
                                        label={t('ServeUntil')}
                                      />
                                    }
                                    readOnly={mode === 'view'}
                                    placeholderText={
                                      mode === 'view' ? t('NotSet') : t('ChooseADay')
                                    }
                                    name={untilName}
                                    selected={
                                      until_day_of_month
                                        ? set31Day(until_day_of_month, true)
                                        : null
                                    }
                                    onChange={(value) => {
                                      form.setFieldValue(
                                        untilName,
                                        format(set31Day(value), 'yyyy-MM-dd')
                                      );
                                    }}
                                    calendarClassName={classes.selectDay}
                                    value={getDay(until_day_of_month)}
                                    popperPlacement="top"
                                  />

                                  <ReactDatePicker
                                    customInput={
                                      <InputField
                                        field={field}
                                        form={form}
                                        label={t('NotifyResidents')}
                                      />
                                    }
                                    readOnly={mode === 'view'}
                                    placeholderText={
                                      mode === 'view' ? t('NotSet') : t('ChooseADay')
                                    }
                                    name={push_day_of_month}
                                    selected={
                                      push_day_of_month
                                        ? set31Day(push_day_of_month, true)
                                        : null
                                    }
                                    onChange={(value) => {
                                      form.setFieldValue(
                                        pushName,
                                        format(set31Day(value), 'yyyy-MM-dd')
                                      );
                                    }}
                                    calendarClassName={classes.selectDay}
                                    value={getDay(push_day_of_month)}
                                    popperPlacement="top"
                                  />

                                  <InputField
                                    field={field}
                                    form={form}
                                    label={t('NotificationMessage')}
                                    readOnly={mode === 'view'}
                                    name={notificationName}
                                    value={
                                      isEditMode &&
                                      notification_message ===
                                        t('isNotSpecified').toLowerCase()
                                        ? ''
                                        : notification_message
                                    }
                                  />
                                </>
                              );
                            }}
                          />
                        )}
                        <Field
                          name="start_work_time"
                          render={({ field, form }) => (
                            <ReactDatePicker
                              customInput={
                                <InputField
                                  field={field}
                                  form={form}
                                  label={t('WorkingHoursFromBuildingsForm')}
                                />
                              }
                              readOnly={mode === 'view'}
                              placeholderText={
                                mode === 'view' ? t('NotSet') : t('chooseTime')
                              }
                              name={field.name}
                              selected={field.value}
                              onChange={(value) => form.setFieldValue(field.name, value)}
                              showTimeSelect
                              showTimeSelectOnly
                              dateFormat="HH:mm"
                              timeIntervals={15}
                              locale={dateFnsLocale}
                              timeCaption={t('Label.time')}
                              popperPlacement="top"
                            />
                          )}
                        />
                        <Field
                          name="end_work_time"
                          render={({ field, form }) => (
                            <ReactDatePicker
                              customInput={
                                <InputField
                                  field={field}
                                  form={form}
                                  label={t('WorkingHoursToBuildingsForm')}
                                />
                              }
                              readOnly={mode === 'view'}
                              placeholderText={
                                mode === 'view' ? t('NotSet') : t('chooseTime')
                              }
                              name={field.name}
                              selected={field.value}
                              onChange={(value) => form.setFieldValue(field.name, value)}
                              showTimeSelect
                              showTimeSelectOnly
                              dateFormat="HH:mm"
                              timeIntervals={15}
                              locale={dateFnsLocale}
                              timeCaption={t('Label.time')}
                              popperPlacement="top"
                            />
                          )}
                        />
                        <Field
                          component={SwitchField}
                          name="paidTicketsAllowed"
                          label={t('ConnectPaidTickets')}
                          labelPlacement="start"
                          disabled={!isEditMode}
                        />
                      </>
                    );
                  }}
                />
              </div>
            )}
            {currentObjectsTab === 'entrances' && (
              <div style={{ paddingRight: 18 }}>
                <FormGroup
                  divider={false}
                  GroupContent={EntrancesForm}
                  contentProps={{
                    mode,
                    openedEntity,
                    handleReset,
                    detailEntrances,
                    values,
                    setFieldValue,
                    isObjectsLoading,
                  }}
                />
              </div>
            )}
          </div>
        </CustomScrollbar>
      </div>
    </form>
  );
};

export default BuildingsForm;
