import styled from '@emotion/styled';

export const GroupContainer = styled.div(() => ({
  display: 'grid',
  gap: 15,
}));

export const GroupHeader = styled.div(() => ({
  display: 'grid',
  gridTemplateColumns: '1fr auto',
  alignItems: 'center',
}));

export const GroupContent = styled.div(({ theme }) => ({
  width: '100%',
  display: 'grid',
  gap: 10,
  padding: 15,
  border: `1px solid ${theme.palette.grey.main}`,
  borderRadius: 10,
}));
