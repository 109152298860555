import { DialogActions } from '@mui/material';
import ModalCard from '../atoms/ModalCard';
import ModalCardContent from '../atoms/ModalCardContent';
import ModalCardTitle from '../atoms/ModalCardTitle';

function ModalCardActions({ children }) {
  return <DialogActions disableSpacing>{children}</DialogActions>;
}

function CustomModal({ isOpen, header, content, actions, ...restProps }) {
  return (
    <div>
      <ModalCard open={isOpen} {...restProps}>
        <ModalCardTitle disableTypography>{header}</ModalCardTitle>
        {content && <ModalCardContent>{content}</ModalCardContent>}
        {actions && <ModalCardActions>{actions}</ModalCardActions>}
      </ModalCard>
    </div>
  );
}

export default CustomModal;
