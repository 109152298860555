import { Components } from '@mui/material';

export const Switch: Components['MuiSwitch'] = {
  styleOverrides: {
    root: {
      width: 32,
      height: 20,
      padding: 0,
      '& .MuiSwitch-switchBase': {
        '&.Mui-checked': {
          transform: 'translateX(11px)',
          color: '#fff',
        },
      },
    },
    switchBase: {
      height: 20,
      width: 20,
      padding: 0,
      color: '#fff',
      '&.Mui-checked + .MuiSwitch-track': {
        opacity: 1,
      },
      '& + .MuiSwitch-track': {
        borderRadius: 50,
      },
    },
    thumb: {
      flexShrink: 0,
      width: '14px',
      height: '14px',
    },
  },
};
