import { createEffect, sample } from 'effector';
import { createGate } from 'effector-react';
import { cache, createQuery, localStorageCache } from '@farfetched/core';
import { signout } from '@features/common';
import { propertyTypesApi } from '@features/effector-form/controls/property-type-control/api';
import { PropertyType } from '@features/effector-form/controls/property-type-control/types';

export const PropertyTypesControlGate = createGate();

const fxGetPropertyTypes = createEffect<void, PropertyType[], Error>().use(
  propertyTypesApi.getPropertyTypes
);

export const propertyTypesQuery = createQuery({
  effect: fxGetPropertyTypes,
  mapData: ({ result }) =>
    result.map((type) => ({
      id: type.id,
      label: type.title,
    })),
});

cache(propertyTypesQuery, {
  adapter: localStorageCache({ maxAge: '5m' }),
  purge: signout,
});

sample({
  clock: PropertyTypesControlGate.state,
  target: propertyTypesQuery.start,
});
