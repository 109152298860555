import MaskedInput from 'react-text-mask';

const phoneMask = [
  '+',
  '7',
  ' ',
  /\d/,
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
];

function PhoneMaskedInput(props) {
  return (
    <MaskedInput
      {...props}
      keepCharPositions
      placeholder="+7 000 000 00 00"
      mask={phoneMask}
      pipe={pipe}
    />
  );
}

export { PhoneMaskedInput };

function pipe(string, { rawValue, placeholder }) {
  if (rawValue === '7') return { value: placeholder };
  if (rawValue === '8') return { value: placeholder };

  return { value: string };
}
