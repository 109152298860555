import makeStyles from '@mui/styles/makeStyles';
import { Table } from '@devexpress/dx-react-grid-material-ui';
import { CustomScrollbar } from '../../index';

const useStyles = makeStyles({
  container: {
    height: '100%',
    '& > *': {
      height: 'inherit',
    },
  },
});

function TableContainer(props) {
  const { children } = props;

  const { container } = useStyles();

  return (
    <Table.Container className={container}>
      <CustomScrollbar>{children}</CustomScrollbar>
    </Table.Container>
  );
}

export { TableContainer };
