import { useEffect, useState } from 'react';
import { LinearProgress } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

export const Progressbar = ({ refreshRate }) => {
  const useStyles = makeStyles({
    progressbar__container: {
      height: 4,
      width: '100%',
      background: 'transparent',
    },
    progressbar__line: {
      height: 4,
      width: '100%',
      background: '#0394E3',
      animationName: `$decrease`,
      animationDuration: `${refreshRate}ms`,
      animationDelay: '0ms',
    },
    '@keyframes decrease': {
      '0%': {
        width: '100%',
      },
      '100%': {
        width: '0',
      },
    },
  });
  const classes = useStyles();

  return (
    <div className={classes.progressbar__container}>
      <div className={classes.progressbar__line} />
    </div>
  );
};
