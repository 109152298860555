import qs from 'qs';
import { createAction } from 'redux-actions';
import { push } from '../../features/common';
import { getCookie } from '../../tools/cookie';
import {
  requestAC,
  requestFailAC,
  getSuccessAC,
  updateSuccessAC,
  changeVisibility,
} from './commonActionCreators';

const name = 'USER';
const API_URL = process.env.API_URL;

export const signIn = createAction(`${name}__SIGN__IN`);
export const signOut = createAction(`${name}__SIGN__OUT`);

export const request = requestAC(`${name}`);
export const getSuccess = getSuccessAC(`${name}`);
export const updateSuccess = updateSuccessAC(`${name}`);
export const requestFail = requestFailAC(`${name}`);
export const dialogChangeVisibility = changeVisibility(`${name}_DIALOG`);

export function updateUser(payload) {
  return (dispatch) => {
    const { user_mail, user_phone, user_fullname, user_avatar, oldpass, newpass } =
      payload;

    const authInfo = {
      oldpass,
      newpass,
    };

    const userInfo = {
      user_mail,
      user_phone,
      user_fullname,
      user_avatar,
      user_avatar_inputname: 'user_avatar',
    };

    const headers = {
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
    };

    const token = getCookie('bms_token');

    dispatch(request());

    const updateInfo = fetch(`${API_URL}/user/update-user/?token=${token}`, {
      headers,
      body: qs.stringify(userInfo),
      method: 'POST',
    })
      .then((response) => response.json())
      .then((json) => (json.error ? json.message : true));

    const updateAuth =
      oldpass !== '' && newpass !== ''
        ? fetch(`${API_URL}/auth/update/?token=${token}`, {
            headers,
            body: qs.stringify(authInfo),
            method: 'POST',
          })
            .then((response) => response.json())
            .then((json) => (json.error ? json.message : true))
        : new Promise((res) => res(true));

    return Promise.all([updateInfo, updateAuth])
      .then((results) => {
        const errors = results.filter((result) => result !== true);

        if (errors.length > 0) {
          dispatch(requestFail(errors));
          dispatch(dialogChangeVisibility({ isOpen: true, kind: 'error' }));
        } else {
          dispatch(updateSuccess(userInfo));
          push({
            header: 'Данные успешно обновлены',
          });
        }
      })
      .catch(
        (error) =>
          dispatch(requestFail(error)) &&
          dispatch(dialogChangeVisibility({ isOpen: true, kind: 'error' }))
      );
  };
}
