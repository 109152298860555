import { useGate, useStore } from 'effector-react';
import i18n from '@shared/config/i18n';
import { Widget } from '@ui/';
import {
  IndicatorsWidgetGate,
  intervalValue,
  isRunning,
  $isRecordsLoading,
} from '../models';
import { Filter } from './filter';
import { Records } from './records';
import { Popup } from './popup';
import { $filters } from '../models/filters/filters.model';

const { t } = i18n;

export const WidgetIndicators = ({
  size = {},
  data,
  styles = {},
  isHideFilters = false,
  complexId = '',
}) => {
  useGate(IndicatorsWidgetGate, { ...data, complexId });
  const isRecordsLoading = useStore($isRecordsLoading);
  const filters = useStore($filters);
  const isRunningData = useStore(isRunning);

  const title = filters.archive
    ? `${t('ObjectIndicatorsAndTheirDeviations')} (${t('OnlyArchive')})`
    : t('ObjectIndicatorsAndTheirDeviations');

  const settings = {
    title,
    size,
  };

  return (
    <Widget
      settings={settings}
      renderTool={() => !isHideFilters && <Filter />}
      refreshRate={
        isRunningData ||
        typeof filters.archive === 'undefined' ||
        filters.archive === false
          ? intervalValue
          : 0
      }
      isLoading={isRecordsLoading}
    >
      <Records styles={styles} />
      <Popup />
    </Widget>
  );
};
