import { combineReducers } from 'redux';
import { request, getSuccess, requestFail } from '../actions/flatsActionGenerator';
import { fetchingReducer, errorReducer, dataReducer } from './commonReducers';

const isFetching = fetchingReducer([request], [getSuccess, requestFail], false);
const error = errorReducer([requestFail], [request], null);

const defaultData = {
  create: 'NO_FLAT_ACTION',
  update: 'NO_FLAT_ACTION',
  delete: 'NO_FLAT_ACTION',
  get: getSuccess,
};

const data = dataReducer(
  {
    ...defaultData,
    format: (flats) =>
      flats.apartments
        .map((e) => {
          const entranceId = e.entrance === null ? 0 : e.entrance.id;
          const entrance = e.entrance === null ? 0 : e.entrance.number;
          const entranceNumber = entrance === 0 ? '0' : entrance;

          return {
            ...e,
            entrance_id: entranceId,
            entrance,
            entrance_data: { id: entranceId, title: entranceNumber },
          };
        })
        .reduce((acc, cur) => ({ ...acc, [cur.id]: cur }), {}),
  },
  {}
);

const houses = combineReducers({
  isFetching,
  error,
  data,
});

export default houses;
