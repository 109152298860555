import { isArray } from './type';

const pick = (props = [], object = {}) => {
  if (!isArray(props) || typeof object !== 'object') {
    console.error(new Error('Args incorrect'));
    return [];
  }

  const result = {};
  for (const prop in object) {
    if (props.includes(prop) && object[prop]) {
      result[prop] = object[prop];
    }
  }
  return result;
};

export { pick };
