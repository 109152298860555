import { FC } from 'react';
import { CircularProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';

interface IContentLoader {
  isLoading: boolean;
}

const useStyles = makeStyles({
  loader__wrapper: {
    display: 'grid',
    alignItems: 'center',
    justifyItems: 'center',
    width: '100%',
    height: '100%',
    background: 'rgba(255,255, 255, 0.5)',
  },
});

export const ContentLoader: FC<IContentLoader> = ({ isLoading }) => {
  const classes = useStyles();

  return isLoading ? (
    <div className={classes.loader__wrapper}>
      <CircularProgress />
    </div>
  ) : null;
};
