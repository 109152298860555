import { forwardRef, Ref } from 'react';
import { Toolbar as MuiToolbar } from '@mui/material';
import withStyles from '@mui/styles/withStyles';

const styles = {
  root: {
    display: 'flex',
    alignItems: 'center',
    minHeight: 'auto',
    width: '100%',
    justifyContent: 'space-between',
  },
};

interface IProps {
  children: any;
  variant?: 'regular' | 'dense';
  disableGutters?: boolean;
  classes: {
    root: string;
  };
  className?: string;
}

const Toolbar = forwardRef((props: IProps, ref: Ref<HTMLDivElement>) => {
  const { children, variant = 'dense', disableGutters = true, classes, ...rest } = props;

  return (
    <MuiToolbar
      disableGutters={disableGutters}
      variant={variant}
      classes={{ root: classes.root }}
      ref={ref}
      {...rest}
    >
      {children}
    </MuiToolbar>
  );
});

const StyledToolbar = withStyles(styles)(Toolbar);

export { StyledToolbar as Toolbar };
