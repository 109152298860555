import { getCookie } from '../../tools/cookie';
import getFormData from '../../tools/getFormatData';
import { requestAC, getSuccessAC, requestFailAC } from './commonActionCreators';

const name = 'RIZERS';
const API_URL = process.env.API_URL;

export const request = requestAC(`${name}`);
export const getSuccess = getSuccessAC(`${name}`);
export const requestFail = requestFailAC(`${name}`);

export const fetchRizerByBuilding = (buildingId) => {
  return async (dispatch, getState) => {
    if (!getState().rizers.data[buildingId]) {
      try {
        const fd = new FormData();
        getFormData(fd, {
          building_id: buildingId,
          token: getCookie('bms_token'),
        });

        dispatch(request());

        const req = fetch(`${API_URL}/admin/get-rizer/`, {
          method: 'POST',
          body: fd,
        });

        const res = await req;
        const json = await res.json();

        if (!res.ok) {
          json.error = 1;
          json.message = res.statusText;
        }

        if (json.error) {
          dispatch(requestFail(json.message));
        } else {
          const data = json.data;
          return dispatch(getSuccess(data));
        }
      } catch (error) {
        dispatch(requestFail(error));
      }
    } else {
      return getState().rizers.data[buildingId];
    }

    return null;
  };
};
