import { FC } from 'react';
import { useStore } from 'effector-react';
import { Field, Formik } from 'formik';
import { $user } from '@features/common';
import { updateAvatar } from '@features/profile/models';
import { AvatarControl } from '@ui/index';

export const ProfileAvatar: FC = () => {
  const user = useStore($user);

  const defaultValues = {
    user_avatar: user.user_avatar,
  };

  return (
    <Formik
      initialValues={defaultValues}
      enableReinitialize
      onSubmit={(values: any) => {
        updateAvatar(values);
      }}
      render={({ handleSubmit, setFieldValue }) => (
        <Field
          name="user_avatar"
          render={({ field }: any) => (
            <AvatarControl
              encoding="base64"
              mode="edit"
              onChange={(value: any) => {
                setFieldValue(field.name, value);
                setTimeout(() => {
                  handleSubmit();
                }, 1);
              }}
              isModifyResult={false}
              value={field.value}
            />
          )}
        />
      )}
    />
  );
};
