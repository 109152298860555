import { createEffect, sample } from 'effector';
import { createGate } from 'effector-react';
import { cache, createQuery } from '@farfetched/core';
import {
  ApartmentGate,
  ApartmentRequest,
  ApartmentResponse,
} from '@features/effector-form/controls/apartment/types';
import { getApartments } from './api';

export const ApartmentControlGate = createGate<ApartmentGate>();

const fxGetApartments = createEffect<ApartmentRequest, ApartmentResponse, Error>().use(
  getApartments
);

export const apartmentsQuery = createQuery({
  name: 'apartments',
  effect: fxGetApartments,
  mapData: ({ result, params }) =>
    result.items.map(({ apartment }) => ({
      id: apartment.id,
      label: apartment.title,
    })),
});

cache(apartmentsQuery);

sample({
  clock: ApartmentControlGate.state,
  filter: (data) => Boolean(data),
  fn: (data) => ({
    complex_id: data.complex,
    building_id: data.building,
  }),
  target: apartmentsQuery.start,
});
