import { Component } from 'react';
import { ErrorMessage } from './errorMessage';

// Подробнее https://ru.reactjs.org/docs/error-boundaries.html
class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Обновить состояние с тем, чтобы следующий рендер показал запасной UI.
    return { hasError: true };
  }

  // Можно также сохранить информацию об ошибке в соответствующую службу журнала ошибок
  // componentDidCatch(error, errorInfo) {
  //   logErrorToMyService(error, errorInfo);
  // }

  render() {
    if (this.state.hasError) {
      // Можно отрендерить запасной UI произвольного вида
      return <ErrorMessage />;
    }

    return this.props.children;
  }
}

export { ErrorBoundary };
