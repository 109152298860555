import { createEffect, sample } from 'effector';
import { createGate } from 'effector-react';
import { cache, createQuery, localStorageCache } from '@farfetched/core';
import { signout } from '@features/common';
import { complexApi } from '@features/effector-form/controls/complex/api';
import { ComplexResponse } from '@features/effector-form/controls/complex/types';

export const ComplexControlGate = createGate();

const fxGetComplexes = createEffect<void, ComplexResponse, Error>().use(
  complexApi.getComplexes
);

export const complexQuery = createQuery({
  name: 'complex',
  effect: fxGetComplexes,
  mapData: ({ result, params }) =>
    result.items.map((complex) => ({
      id: complex.id,
      label: complex.title,
    })),
});

cache(complexQuery, {
  adapter: localStorageCache({ maxAge: '5m' }),
  purge: signout,
});

sample({
  clock: ComplexControlGate.open,
  target: complexQuery.start,
});
