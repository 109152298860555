import { createEffect, createEvent } from 'effector';
import { pending } from 'patronum';
import { fxGetUser } from '@features/common';

export const fxUpdateUser = createEffect<
  IProfileUpdateUserPayload,
  IProfileUpdateUserResponse,
  Error
>();
export const fxUpdateAuth = createEffect<IProfileUpdateAuthPayload, any, Error>();
export const fxUpdateAvatar = createEffect<IProfileUpdateAvatarPayload, any, Error>();

export const $isFormLoading = pending({
  effects: [fxUpdateUser, fxUpdateAuth, fxUpdateAvatar, fxGetUser],
});

export const updateUser = createEvent<IProfileUpdateUserPayload>();
export const updateAuth = createEvent<IProfileUpdateAuthPayload>();
export const updateAvatar = createEvent<IProfileUpdateAvatarPayload>();
