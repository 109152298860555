import { Typography } from '@mui/material';
import { green } from '@mui/material/colors';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
  resultLabel: {
    fontWeight: 'bold',
    fontSize: 18,
    lineHeight: '21px',
    margin: '0 12px',
    whiteSpace: 'nowrap',
  },
});

export const VoteProgress = (props) => {
  const { title = 0, progress = 10, color = green } = props;
  const { resultLabel } = useStyles();

  return (
    <div
      style={{
        width: '100%',
        border: `1px solid ${color[300]}`,
        borderRadius: '20px',
        height: 34,
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        background: `linear-gradient(to right, ${color[50]} ${progress}%, #fff ${progress}%)`,
        color: '#65657B',
      }}
    >
      <Typography
        style={{ color: color[300] }}
        classes={{ root: resultLabel }}
        component="span"
        align="left"
      >
        {title}
      </Typography>
      <Typography classes={{ root: resultLabel }} component="span" align="right">
        {progress}%
      </Typography>
    </div>
  );
};
