import { useState } from 'react';
import { DialogImage } from '@ui';

const PlanImage = (props) => {
  const { src, className, title } = props;

  const [isPlanImageOpen, changePlanImageVisibility] = useState(false);

  return (
    <>
      <img
        src={src}
        className={className}
        alt={title}
        onClick={() => changePlanImageVisibility(true)}
      />
      <DialogImage
        src={src}
        title={title}
        isOpen={isPlanImageOpen}
        changeVisibility={changePlanImageVisibility}
      />
    </>
  );
};

export { PlanImage };
