import { Tooltip } from '@mui/material';
import i18n from '@shared/config/i18n';
import { ActionIconButton } from '../../index';

const { t } = i18n;

export const CommonButton = ({
  title = t('Button'),
  clickHandler = () => {},
  children,
  ...rest
}) => (
  <Tooltip title={title}>
    <span>
      <ActionIconButton onClick={clickHandler} {...rest}>
        {children}
      </ActionIconButton>
    </span>
  </Tooltip>
);
