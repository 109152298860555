import { forward } from 'effector';
import { fxGetUser } from '@features/common';
import { fxGetList as fxGetComplexes } from '@features/complex-select/model';
import { fxGetList as fxGetHouses } from '@features/house-select/models';
import { getUserInfo } from './dashboard.model';

// Запрашиваем вспомогающие сторы для списков в фильтрах
forward({
  from: getUserInfo,
  to: [fxGetUser, fxGetComplexes, fxGetHouses],
});
