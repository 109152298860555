import makeRequest from '../../tools/makeRequest';
import { dialogActions } from '../symbiotes/cameras';
import {
  requestAC,
  requestFailAC,
  getSuccessAC,
  createSuccessAC,
  updateSuccessAC,
  deleteSuccessAC,
} from './commonActionCreators';

export const { dialogChangeVisibility } = dialogActions;

const name = 'CAMERAS';

export const request = requestAC(`${name}`);
export const getSuccess = getSuccessAC(`${name}`);
export const createSuccess = createSuccessAC(`${name}`);
export const updateSuccess = updateSuccessAC(`${name}`);
export const deleteSuccess = deleteSuccessAC(`${name}`);
export const requestFail = requestFailAC(`${name}`);

const actions = (dispatch) => ({
  request: () => dispatch(request()),
  fail: (error) => {
    const regexp = /invalid/i;
    const errorTranslate = regexp.test(error) ? 'Введенные данные некорректны!' : error;

    dispatch(requestFail(errorTranslate));
    dispatch(dialogChangeVisibility({ isOpen: true, kind: 'error' }));
  },
});

export const fetchCameras = () => (dispatch, getState) => {
  const isCamerasLoaded = Object.values(getState().cameras.data).length > 0;

  return isCamerasLoaded
    ? getState().cameras.data
    : makeRequest(
        '/v1/cameras/get-by-company/',
        {},
        {
          actions: {
            ...actions(dispatch),
            success: (response) => {
              dispatch(getSuccess(response.data));
            },
          },
        }
      );
};
