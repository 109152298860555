import { useIMask } from 'react-imask';
import { FormControl, FormHelperText, FormLabel, OutlinedInput } from '@mui/material';
import { withControl } from '@features/effector-form/hoc/withControl';

type PropsEmail = {
  fullWidth?: boolean;
  placeholder?: string;
};

export const EmailControl = withControl<PropsEmail>(
  ({ value, setValue, error, label, required, readOnly, disabled, fullWidth = false, placeholder = '' }) => {
    const { ref } = useIMask(
      {
        mask: 'W@W.W',
        blocks: {
          W: {
            mask: /^\w+$/,
          },
        },
      },
      {
        onAccept: (data) => {
          if (data !== value) {
            setValue(data);
          }
        },
      }
    );

    return (
      <FormControl required={required} disabled={disabled} fullWidth={fullWidth}>
        <FormLabel>{label}</FormLabel>

        <OutlinedInput
          inputRef={ref}
          value={value}
          type="email"
          readOnly={readOnly}
          disabled={disabled}
          fullWidth={fullWidth}
          placeholder={placeholder}
        />

        <FormHelperText error>{error}</FormHelperText>
      </FormControl>
    );
  }
);
