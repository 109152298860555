import makeStyles from '@mui/styles/makeStyles';

const styles = makeStyles({
  numberContainer: {
    display: 'flex',
    backgroundColor: 'white',
    borderRadius: 4,
  },
  plateContainer: {
    display: 'flex',
    backgroundColor: 'rgb(240, 240, 240)',
    borderRadius: 4,
    width: 'max-content',
    boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, .2)',
  },
  numberText: {
    padding: '3px 16px',
  },
  brandText: {
    padding: (props) => (props.brand ? '3px 16px' : 0),
  },
});

export const VehiclePlate = ({ number, brand = '' }) => {
  const { numberContainer, plateContainer, numberText, brandText } = styles({
    brand,
  });

  return (
    <div className={plateContainer}>
      <div className={numberContainer}>
        <span className={numberText}>{number}</span>
      </div>
      {Boolean(brand) && <span className={brandText}>{brand}</span>}
    </div>
  );
};
