import { Components } from '@mui/material';

export const Button: Components['MuiButton'] = {
  defaultProps: {
    disableElevation: true,
  },
  styleOverrides: {
    root: {
      textTransform: 'none',
    },
  },
};
