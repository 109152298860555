import { useEffect } from 'react';
import { useList } from 'effector-react';
import { Paper, Grid, Typography, IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';
import makeStyles from '@mui/styles/makeStyles';
import { $pushQueue, close } from '../../model';

const useStyles = makeStyles({
  container: {
    width: '370px',
    position: 'fixed',
    pointerEvents: 'none',
    zIndex: 9999,
    right: 10,
    bottom: 40,
  },
  paper: {
    position: 'relative',
    width: 'inherit',
    padding: 18,
    boxShadow: '0px 16px 15px rgba(0, 0, 0, 0.1)',
    borderRadius: 15,
    pointerEvents: 'auto',
    marginTop: 10,
  },
  iconBlock: {
    width: 24,
    height: 24,
  },
  closeBlock: {
    marginLeft: 'auto',
  },
  headerText: {
    lineHeight: '21px',
    fontSize: 18,
    fontWeight: 900,
  },
  contentText: {
    lineHeight: '19px',
    fontSize: 16,
    color: '#767676',
    fontWeight: 'bold',
  },
});

const Push = (props) => {
  const {
    Icon,
    header = '',
    content = '',
    color = '#0394E3',
    close = () => null,
    timeout = 5000,
  } = props;

  const { paper, iconBlock, headerText, contentText, closeBlock } = useStyles();

  useEffect(() => {
    setTimeout(close, timeout);
  }, []);

  return (
    <Paper style={{ border: `2px solid ${color}` }} classes={{ root: paper }}>
      <Grid
        container
        justifyContent="space-between"
        alignItems="flex-start"
        wrap="nowrap"
        spacing={4}
      >
        {Icon && (
          <Grid classes={{ root: iconBlock }} item>
            <Icon style={{ color }} />
          </Grid>
        )}
        <Grid item>
          <Typography style={{ color }} classes={{ root: headerText }}>
            {header}
          </Typography>
          <Typography classes={{ root: contentText }}>{content}</Typography>
        </Grid>
        <Grid classes={{ root: closeBlock }} item>
          <IconButton size="small" onClick={close}>
            <Close />
          </IconButton>
        </Grid>
      </Grid>
    </Paper>
  );
};

export const PushContainer = (props) => {
  const { container } = useStyles();
  return (
    <div className={container}>
      {useList($pushQueue, (item) => (
        <Push {...item} close={() => close(item._id)} />
      ))}
    </div>
  );
};
