import { Popper as MuiPopper, Paper } from '@mui/material';

export function Popper(props) {
  const { header, children, open, handleClose, anchorEl, ...rest } = props;

  return (
    <MuiPopper
      disablePortal
      open={open}
      onClose={handleClose}
      anchorEl={anchorEl}
      placement="bottom-start"
      style={{ zIndex: 9999 }}
      {...rest}
    >
      <Paper elevation={5} style={{ padding: 24, borderRadius: 8 }}>
        <h3
          style={{
            fontSize: 18,
            lineHeight: '24px',
            fontWeight: 500,
            color: '#3B3B50',
            letterSpacing: '.15px',
            marginBottom: 20,
          }}
        >
          {header}
        </h3>
        {children}
      </Paper>
    </MuiPopper>
  );
}
