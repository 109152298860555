import { createEffect, sample } from 'effector';
import { createGate } from 'effector-react';
import { cache, createQuery, localStorageCache } from '@farfetched/core';
import { signout } from '@features/common';
import { enterpriseApi } from '@features/effector-form/controls/enterprise/api';
import { EnterpriseResponse } from '@features/effector-form/controls/enterprise/types';

export const EnterpriseControlGate = createGate();

const fxGetEnterprises = createEffect<void, EnterpriseResponse, Error>().use(
  enterpriseApi.getEnterprises
);

export const enterpriseQuery = createQuery({
  name: 'enterprise',
  effect: fxGetEnterprises,
  mapData: ({ result, params }) =>
    result.enterprises.map((enterprice) => ({
      id: enterprice.id,
      label: enterprice.title,
    })),
});

cache(enterpriseQuery, {
  adapter: localStorageCache({ maxAge: '5m' }),
  purge: signout,
});

sample({
  clock: EnterpriseControlGate.open,
  target: enterpriseQuery.start,
});
