import PropTypes from 'prop-types';
import { Divider, Grid } from '@mui/material';

export const AttachedFilesPanel = (props) => {
  const { isOpen, children } = props;

  return isOpen ? (
    <>
      <Divider />
      <Grid style={{ overflow: 'auto', maxHeight: 220 }}>{children}</Grid>
    </>
  ) : null;
};

AttachedFilesPanel.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  children: PropTypes.element,
};
