import { useTranslation } from 'react-i18next';
import { useStore } from 'effector-react';
import { InfoOutlined } from '@mui/icons-material';
import makeStyles from '@mui/styles/makeStyles';
import { Popper, useHover } from '../../../../ui';
import { $sectionsData } from '../../model';

const useStyles = makeStyles({
  sdb: {
    position: 'relative',
    marginLeft: 5,
  },
  sdb__icon: {
    color: '#7f7f7f',
    cursor: 'pointer',
    fontSize: 20,
    opacity: 0.5,
  },
});

const SectionDescriptionButton = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const sectionsData = useStore($sectionsData);
  const { anchorEl, hover, handleMouseIn, handleMouseOut } = useHover('span');

  const description = sectionsData?.description;

  return description ? (
    <span className={classes.sdb} onMouseOver={handleMouseIn} onMouseOut={handleMouseOut}>
      <Popper
        style={{ minWidth: 300, zIndex: 2 }}
        open={hover}
        header={t('SectionDescription')}
        anchorEl={anchorEl}
      >
        {description}
      </Popper>

      <InfoOutlined classes={{ root: classes.sdb__icon }} />
    </span>
  ) : null;
};

export { SectionDescriptionButton };
