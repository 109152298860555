import { createStore, createEvent } from 'effector';
import { signout } from '@features/common';

function createFilterBag(defaultFilters: any) {
  const changedFilterVisibility = createEvent<boolean>();
  const filtersSubmitted = createEvent<any>();

  const $isFilterOpen = createStore<boolean>(false);
  const $filters = createStore<any>(defaultFilters);

  $isFilterOpen.on(changedFilterVisibility, (_, visibility) => visibility).reset(signout);

  $filters.on(filtersSubmitted, (_, filters) => filters || defaultFilters).reset(signout);

  return {
    changedFilterVisibility,
    filtersSubmitted,
    $isFilterOpen,
    $filters,
  };
}

export { createFilterBag };
