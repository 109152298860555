import { Formik, Field } from 'formik';
import i18n from '@shared/config/i18n';
import { HouseSelectField } from '../../../../features/house-select';
import SelectContainer from '../../../atoms/MultiSelectContainer';
import MultiSelectValue from '../../../atoms/MultiSelectValue';
import ValueContainer from '../../../atoms/MultiSelectValueContainer';
import ComplexInput from '../../../molecules/ComplexInput';
import FilterFooter from '../../../molecules/FilterFooter';
import FilterToolbar from '../../../molecules/FilterToolbar';
import FormTemplate from '../../../templates/FormTemplate';

const { t } = i18n;

const resetValues = {
  complexes: [],
  flats: '',
};

const Header = (props) => {
  const { openCloseFilter } = props;
  return <FilterToolbar openCloseFilter={openCloseFilter} />;
};

const Main = (props) => {
  const { setFieldValue, name } = props;

  return (
    <>
      {name === 'flats' ? (
        <Field
          name="flats"
          component={HouseSelectField}
          onChange={(value) => {
            setFieldValue('flats', value);
          }}
          label={t('Label.building')}
          placeholder={t('selectHouse')}
          notAbsolute
        />
      ) : (
        <Field
          component={ComplexInput}
          name="complexes"
          isMulti
          components={{
            SelectContainer,
            ValueContainer,
            MultiValue: MultiSelectValue,
          }}
          onChange={setFieldValue}
        />
      )}
    </>
  );
};

const Footer = (props) => {
  const { handleSubmit, resetForm, values } = props;

  return (
    <FilterFooter
      onSubmit={handleSubmit}
      onReset={(e) => {
        e.preventDefault();
        resetForm(resetValues);
        handleSubmit(values);
      }}
      disabled={
        Object.values(values).filter((value) => {
          if (!value) {
            return false;
          }

          if (typeof value === 'number') {
            return value > 0;
          }

          if (Object.values(value).length > 0) {
            return true;
          }

          return value.length > 0;
        }).length === 0
      }
    />
  );
};

function UsersFilterForm(props) {
  const { onFilter, filterFlats, tableKind } = props;
  const isFlats = tableKind === 'flats';
  const name = isFlats ? 'flats' : 'complexes';
  return (
    <Formik
      onSubmit={(values) => {
        onFilter(values);
        if (isFlats) {
          filterFlats();
        }
      }}
      initialValues={resetValues}
    >
      {(formikProps) => {
        return (
          <FormTemplate
            Header={Header}
            Main={Main}
            Footer={Footer}
            {...props}
            {...formikProps}
            name={name}
          />
        );
      }}
    </Formik>
  );
}

export default UsersFilterForm;
