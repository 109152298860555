import classNames from 'classnames';
import { InputLabel } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { useFormControl, formControlState } from '../..';

const styles = (theme) => {
  return {
    root: {
      cursor: 'pointer',
      whiteSpace: 'nowrap',
      color: '#9494A3',
      fontSize: 14,
      lineHeight: '16px',
    },
    readOnly: {
      color: 'rgba(0, 0, 0, 0.54) !important',
      cursor: 'auto',
      "& > *[class*='asterisk']": {
        display: 'none',
      },
    },
    required: {
      "& > *[class*='asterisk']": {
        color: theme.status.button.negative.default,
      },
    },
  };
};

const BaseInputLabel = (props) => {
  const { classes, ...rest } = props;
  const { root, required, readOnly: ro } = classes;

  const contextProps = useFormControl();

  const fcs = formControlState({
    props,
    ctxProps: contextProps,
    states: ['readOnly', 'error', 'required', 'disabled'],
  });

  const rootClassName = classNames(root, { [ro]: fcs.readOnly });

  return <InputLabel classes={{ root: rootClassName, required }} {...fcs} {...rest} />;
};

const StyledBaseInputLabel = withStyles(styles)(BaseInputLabel);

export { StyledBaseInputLabel as BaseInputLabel };
