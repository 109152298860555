import UnarchiveOutlinedIcon from '@mui/icons-material/UnarchiveOutlined';
import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined';
import { useStore } from 'effector-react';
import { $filters, setToOrFromArchiveEvent } from '../models';
import { Field, Form, Formik } from 'formik';
import { InputField } from '@ui/molecules';
import { ActionButton } from '@ui/atoms';
import { addVisibleArchiveBlockSignals } from '../models';
import i18n from '@shared/config/i18n';
import {
  $isRecordsLoading,
  $toArchiveComment,
  changeToArchiveComment,
  $isArchiveLoading,
} from '../models';

const { t } = i18n;

const styles = {
  icon: {
    width: 17,
    height: 17,
    border: 0,
    cursor: 'pointer',
  },
  archiveEdit: {
    display: 'flex',
    alignItems: 'center',
    flexFlow: 'row nowrap',
    width: '100%',
    padding: 0,
    justifyContent: 'stretch',
  },
  inputDiv: {
    flex: '1 0 auto',
    marginRight: 16,
    padding: 0,
    fontWeight: 600,
    fontSize: 15,
    lineHeight: '20px',
  },
  input: {
    padding: '18px 5px',
  },
  submitButton: {
    marginRight: 10,
  },
};

const ArchiveIcon = (props) => {
  const signal_id = props.signal.signal.signal_id;
  const filters = useStore($filters);

  if (!filters.archive)
    return (
      <UnarchiveOutlinedIcon
        onClick={() => addVisibleArchiveBlockSignals(signal_id)}
        style={styles.icon}
      />
    );

  return (
    <ArchiveOutlinedIcon
      onClick={() => addVisibleArchiveBlockSignals(signal_id)}
      style={styles.icon}
    />
  );
};

const SetToArchive = (props) => {
  const { signal } = props;
  const isRecordsLoading = useStore($isRecordsLoading);
  const isArchiveLoading = useStore($isArchiveLoading);
  const toArchiveComment = useStore($toArchiveComment);
  const edit = { comment: toArchiveComment };

  const onSubmit = (data) => {
    setToOrFromArchiveEvent({
      comment: data.comment ?? '',
      id: signal.map_signals_condition.id,
      archive: 1,
    });
  };

  const onReset = () => {
    addVisibleArchiveBlockSignals(null);
  };

  return (
    <Formik
      initialValues={edit}
      onSubmit={onSubmit}
      onReset={onReset}
      render={({ handleSubmit }) => (
        <Form style={styles.archiveEdit}>
          <div style={styles.inputDiv}>
            <Field
              name="comment"
              render={({ field, form }) => (
                <InputField
                  style={styles.input}
                  field={field}
                  form={form}
                  label=""
                  divider={false}
                  placeholder={t('Label.comment')}
                  onKeyUp={(event) => {
                    changeToArchiveComment(event.target.value);
                  }}
                />
              )}
            />
          </div>
          <ActionButton
            disabled={isRecordsLoading || isArchiveLoading}
            style={styles.submitButton}
            kind="positive"
            onClick={(_) => handleSubmit()}
          >
            {t('ToArchive')}
          </ActionButton>
          <ActionButton kind="negative" onClick={onReset}>
            {t('cancel')}
          </ActionButton>
        </Form>
      )}
    />
  );
};

const GetFromArchive = (props) => {
  const { signal } = props;
  const isRecordsLoading = useStore($isRecordsLoading);
  const isArchiveLoading = useStore($isArchiveLoading);

  const onSubmit = () => {
    setToOrFromArchiveEvent({
      id: signal.map_signals_condition.id,
      archive: 0,
    });
  };

  const onReset = () => {
    addVisibleArchiveBlockSignals(null);
  };

  return (
    <div style={styles.archiveEdit}>
      <div style={styles.inputDiv}>{t('ReturnSignalDashboard')}</div>
      <ActionButton
        disabled={isRecordsLoading || isArchiveLoading}
        style={styles.submitButton}
        kind="positive"
        onClick={onSubmit}
      >
        {t('FromArchive')}
      </ActionButton>
      <ActionButton kind="negative" onClick={onReset}>
        {t('cancel')}
      </ActionButton>
    </div>
  );
};

const ArchiveEdit = (props) => {
  const filters = useStore($filters);

  if (!filters.archive) {
    return <SetToArchive {...props} />;
  }

  return <GetFromArchive {...props} />;
};

export { ArchiveIcon, ArchiveEdit };
