import { forwardRef } from 'react';
import { InputControl, FieldComponent } from '../..';

const InputField: React.FC<any> = forwardRef((props, ref) => {
  const { field, form, component = <InputControl />, ...rest } = props;

  return <FieldComponent field={field} form={form} component={component} {...rest} />;
});

export { InputField };
