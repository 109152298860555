import { $isCompany } from '@features/common/model/user';
import { getCookie } from '@tools/cookie';

const calcPlatformHeader = () => {
  if (!getCookie('bms_token')) return null;

  return $isCompany.stateRef.current
    ? 'base_enterprise_app'
    : 'base_operating_company_app';
};

const getLanguage = () => {
  const language = localStorage.getItem('lang');

  return language && language === 'en' ? 'en-us' : 'ru-ru';
};

const getBaseHeaders = () => ({
  'X-APP-TYPE': 'web',
  'X-APP-PLATFORM': calcPlatformHeader(),
  'X-APP-DETAILS': null,
  'X-APP-LANG': getLanguage(),
});

export { calcPlatformHeader, getLanguage, getBaseHeaders };
