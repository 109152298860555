//  the function of word declension after numerals

export function declOfNum(number, text_forms) {
  const tempNumber = Math.abs(number) % 100;
  const number1 = tempNumber % 10;

  if (tempNumber > 10 && tempNumber < 20) {
    // example: "5 книг"
    return text_forms[2];
  }
  if (number1 > 1 && number1 < 5) {
    // example: "2 книги"
    return text_forms[1];
  }
  if (number1 === 1) {
    // example: "1 книга"
    return text_forms[0];
  }
  return text_forms[2];
}
