import { combineReducers } from 'redux';
import {
  request,
  getSuccess,
  createSuccess,
  updateSuccess,
  deleteSuccess,
  requestFail,
} from '../actions/housesActionGenerator';
import { fetchingReducer, errorReducer, dataReducer } from './commonReducers';

const isFetching = fetchingReducer(
  [request],
  [getSuccess, createSuccess, updateSuccess, deleteSuccess, requestFail],
  false
);
const error = errorReducer([requestFail], [request], null);

const format = (buildings) => {
  return buildings.reduce((acc, item) => ({ ...acc, [item.id]: { ...item } }), {});
};

const data = dataReducer(
  {
    create: createSuccess,
    update: updateSuccess,
    delete: deleteSuccess,
    get: getSuccess,
    format,
    getReducer: (state, { payload }) => ({
      ...state,
      ...format(payload.buildings),
    }),
  },
  {}
);

const houses = combineReducers({
  isFetching,
  error,
  data,
});

export default houses;
