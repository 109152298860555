import PropTypes from 'prop-types';
import { AttachFile } from '@mui/icons-material';

export const ChatFileInput = (props) => {
  const { onLoad, formats, multiple = true } = props;

  const mapFormatsToConverter = {
    file: (file) => {
      return { format: 'file', url: file };
    },
    base64: (file) =>
      new Promise((res) => {
        const reader = new FileReader();
        reader.addEventListener('load', (event) => {
          res({
            format: 'base64',
            name: file.name,
            url: event.target.result,
          });
        });
        reader.readAsDataURL(file);
      }),
  };

  return (
    <label htmlFor="attachments" style={{ margin: 0 }}>
      <input
        type="file"
        style={{ display: 'none' }}
        id="attachments"
        onChange={(e) => {
          const images = Array.from(e.target.files).filter((file) =>
            file.type.startsWith('image/')
          );

          const promises = images.flatMap((file) => {
            return formats.map((name) => mapFormatsToConverter[name](file));
          });

          Promise.all(promises)
            .then(onLoad)
            .catch((error) => console.log(error));
        }}
        multiple={multiple}
      />
      <AttachFile
        className="txt-gray"
        style={{
          marginRight: '16px',
          transform: 'rotate(45deg)',
          cursor: 'pointer',
          color: 'rgb(117, 117, 117)',
        }}
      />
    </label>
  );
};

ChatFileInput.propTypes = {
  onLoad: PropTypes.func.isRequired,
};
