import Dialog from '@mui/material/Dialog';
import withStyles from '@mui/styles/withStyles';

const Card = withStyles({
  paper: {
    borderRadius: '15px',
    boxShadow: '0px 4px 15px #6A6A6E',
    padding: '24px',
    width: '27.55vw',
  },
})(Dialog);

export default Card;
