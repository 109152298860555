import { Checkbox, Tooltip } from '@mui/material';
import { VisibilityOffOutlined } from '@mui/icons-material';
import i18n from '@shared/config/i18n';

const { t } = i18n;

const MessageVisibilityToggle = (props) => {
  const { field } = props;
  const { value, ...rest } = field;

  return (
    <Tooltip
      title={value ? t('OpenConversation') : t('HideCorrespondenceFromTheApplicant')}
    >
      <div>
        <Checkbox
          style={{ padding: 0, marginRight: 16 }}
          icon={<VisibilityOffOutlined />}
          checkedIcon={<VisibilityOffOutlined color="primary" />}
          checked={props.field.value}
          disableRipple
          {...rest}
        />
      </div>
    </Tooltip>
  );
};

export { MessageVisibilityToggle };
