import { createDialogSymbiotes } from './_create';

const name = 'CAMERAS';

const { actions: dialogActions, reducer: dialog } = createDialogSymbiotes(name);

export { dialogActions };

export const reducers = {
  dialog,
};
