import { useEffect } from 'react';
import { Route, Redirect, useLocation } from 'react-router-dom';
import { useStore } from 'effector-react';
import { Loader } from '../../../../ui';
import {
  fxGetUser,
  $isAuthenticated,
  $token,
  $isCompany,
  fxGetCompanyUser,
} from '../../model/user';

export const Authenticated = (props) => {
  const { children } = props;
  const isAuthenticated = useStore($isAuthenticated);
  const token = useStore($token);
  const isCompany = useStore($isCompany);
  const location = useLocation();

  useEffect(() => {
    if (!isAuthenticated && token) {
      if (isCompany) {
        fxGetCompanyUser();
      } else {
        fxGetUser();
      }
    }
  }, [token]);

  if (isAuthenticated) {
    return (
      <Route exact={true} path={location.pathname}>
        {children}
      </Route>
    );
  }

  if (token) {
    return (
      <Route exact={true} path={location.pathname}>
        <Loader isLoading />
      </Route>
    );
  }

  return (
    <Redirect
      to={{
        pathname: '/auth',
        state: { from: location },
      }}
    />
  );
};
