export default (props) => (
  <svg
    width="13"
    height="14"
    viewBox="0 0 13 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.706 7.47088V11.3533C10.706 12.0683 10.1269 12.6474 9.41189 12.6474H2.29414C1.57913 12.6474 1 12.0683 1 11.3533V4.23554C1 3.52053 1.57913 2.94141 2.29414 2.94141H4.88241"
      stroke="#6C6C7C"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M4.23535 8.76466L6.27362 8.51295C6.41662 8.49548 6.54991 8.43013 6.65215 8.32854L11.5899 3.39076C12.1367 2.84398 12.1367 1.9575 11.5899 1.41008C11.0432 0.863307 10.1567 0.863307 9.60925 1.41008L4.71742 6.30192C4.61842 6.40092 4.55436 6.52904 4.5343 6.66816L4.23535 8.76466Z"
      stroke="#6C6C7C"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
