import makeStyles from '@mui/styles/makeStyles';

const useStylesArchive = makeStyles({
  card: {
    background: '#F4F7FA',
    borderRadius: 16,
    overflow: 'hidden',
    userSelect: 'none',
  },
  card_disabled: {
    pointerEvents: 'none',
  },
  card__header: {
    display: 'flex',
    gap: 8,
    alignItems: 'center',
    padding: 17,
  },
  card__title: {
    fontSize: 16,
    fontWeight: 'bold',
    color: '#3B3B50',
    flex: '1 1 0',
  },
  card__generalUnreadMark: {
    flex: '0 0 22px',
  },
  card__list: {
    display: 'grid',
    alignContent: 'start',
    margin: 0,
  },
  card__listItem: {
    display: 'grid',
    gridTemplateColumns: '1fr auto',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: 10,
    padding: '14px 16px 14px 26px',
    borderTop: '1px solid #E7E7EC',
    cursor: 'pointer',

    '&:hover': {
      background: '#edf2f7',
    },
  },
  card__listItem_blue: {
    background: 'transparent',
  },
  card__listItem_orange: {
    background: 'transparent',
  },
  card__listItem_red: {
    background: 'transparent',
  },
  card__listTitle: {
    fontSize: 14,
    color: '#3B3B50',
    opacity: 0.75,
  },
  card__listTitle_empty: {
    color: '#7D7D8E',
  },
  card__listCount: {
    height: 25,
    width: 26,
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    padding: 0,
    borderRadius: 2,
    fontSize: 16,
    lineHeight: 1,
    fontWeight: 500,
    color: 'white',
  },
  card__listCount_empty: {
    color: '#AAAAAA !important',
    background: '#E7E7EC !important',
  },
  card__listCount_blue: {
    background: '#7D7D8E',
  },
  card__listCount_orange: {
    background: '#7D7D8E',
  },
  card__listCount_red: {
    background: '#7D7D8E',
  },
  card__listUnreadMark: {
    height: 24,
    alignItems: 'center',
    justifyItems: 'center',
    lineHeight: 0.72,
    fontSize: 34,
    fontWeight: 1000,
    color: 'white',
  },
  card__listUnreadMark_blue: {
    color: 'transparent',
  },
  card__listUnreadMark_orange: {
    color: 'transparent',
  },
  card__listUnreadMark_red: {
    color: 'transparent',
  },
});

const useStyles = makeStyles({
  card: {
    background: '#F4F7FA',
    borderRadius: 16,
    overflow: 'hidden',
    userSelect: 'none',
  },
  card_disabled: {
    pointerEvents: 'none',
  },
  card__header: {
    display: 'flex',
    gap: 8,
    alignItems: 'center',
    padding: 17,
  },
  card__title: {
    fontSize: 16,
    fontWeight: 'bold',
    color: '#3B3B50',
    flex: '1 1 0',
  },
  card__generalUnreadMark: {
    flex: '0 0 22px',
  },
  card__list: {
    display: 'grid',
    alignContent: 'start',
    margin: 0,
  },
  card__listItem: {
    display: 'grid',
    gridTemplateColumns: '1fr auto',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: 10,
    padding: '14px 16px 14px 26px',
    borderTop: '1px solid #E7E7EC',
    cursor: 'pointer',

    '&:hover': {
      background: '#edf2f7',
    },
  },
  card__listItem_blue: {
    background: 'rgba(3, 148, 227, 0.12)',
  },
  card__listItem_orange: {
    background: 'rgba(255, 138, 0, 0.12)',
  },
  card__listItem_red: {
    background: 'rgba(235, 87, 87, 0.12)',
  },
  card__listTitle: {
    fontSize: 14,
    color: '#3B3B50',
    opacity: 0.75,
  },
  card__listTitle_empty: {
    color: '#7D7D8E',
  },
  card__listCount: {
    height: 25,
    minWidth: 26,
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    padding: '0 5px',
    borderRadius: 2,
    fontSize: 16,
    lineHeight: 1,
    fontWeight: 500,
    color: 'white',
  },
  card__listCount_empty: {
    color: '#AAAAAA !important',
    background: '#E7E7EC !important',
  },
  card__listCount_blue: {
    background: '#0394E3',
  },
  card__listCount_orange: {
    background: '#FF8A00',
  },
  card__listCount_red: {
    background: '#EB5757',
  },
  card__listUnreadMark: {
    height: 24,
    alignItems: 'center',
    justifyItems: 'center',
    lineHeight: 0.72,
    fontSize: 34,
    fontWeight: 1000,
    color: 'white',
  },
  card__listUnreadMark_blue: {
    color: '#0394E3',
  },
  card__listUnreadMark_orange: {
    color: '#FF8A00',
  },
  card__listUnreadMark_red: {
    color: '#EB5757',
  },
  card__infoCount: {
    height: 20,
    minWidth: 20,
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    padding: '0 5px',
    borderRadius: '50%',
    fontSize: 14,
    fontWeight: 500,
    color: 'white',
    background: '#EB5757',
  },
  card__module_disconnected: {
    display: 'block',
    padding: 24,
    fontSize: 12,
    color: '#7D7D8E',
  },
  card_disconnected: {
    opacity: 0.3
  },
  card_disconnected__txt: {
    color: '#FF0100'
  }
});

export { useStyles, useStylesArchive };
