import { useEffect } from 'react';
import ReactFileReader from 'react-file-reader';
import { useTranslation } from 'react-i18next';
import { useStore } from 'effector-react';
import { AddAPhotoOutlined } from '@mui/icons-material';
import { Avatar } from '../..';
import { getCookie } from '../../../tools/cookie';
import { useFileReader } from '../file-control';
import { $file, uploadImage, unMount } from './models';
import { useStyles } from './styles';

const AvatarControl = (props) => {
  const { t } = useTranslation();

  const {
    name = 'avatar',
    value,
    mode,
    onChange,
    encoding = 'base64',
    isModifyResult = true,
    actionTitle = t('UpdatePhoto'),
  } = props;

  const file = useStore($file);
  const isEditMode = mode === 'edit';
  const { readResult, reset } = useFileReader({
    encoding,
    isMultiple: false,
  });

  const innerValue =
    (Boolean(readResult) && Boolean(readResult.preview) && readResult.preview) || value;
  const classes = useStyles();

  useEffect(() => {
    if (readResult && isModifyResult) {
      onChange(readResult);
    }
  }, [readResult]);

  useEffect(() => {
    if (file.url && !isModifyResult) {
      onChange(file.url);
    }
    return () => {
      reset();
      unMount();
    };
  }, [file.url]);

  const handleImageFiles = (files) => {
    uploadImage({
      data: { type: 'image', file: files[0] },
      auth: { token: getCookie('bms_token'), type: 'api-token' },
    });
  };

  return (
    <div className={classes.avatarControl}>
      <Avatar src={innerValue} alt={name} />

      {isEditMode ? (
        <ReactFileReader
          fileTypes={['image/jpeg', 'image/png']}
          multipleFiles={true}
          handleFiles={handleImageFiles}
        >
          <div className={classes.avatarControl__overlay}>
            <AddAPhotoOutlined className={classes.avatarControl__photoIcon} />
            <span className={classes.avatarControl__overlayTitle}>
              {innerValue ? actionTitle : t('UploadPht')}
            </span>
          </div>
        </ReactFileReader>
      ) : null}
    </div>
  );
};

export { AvatarControl };
