import { Tooltip } from '@mui/material';
import { Print, Settings } from '@mui/icons-material';
import ImportIcon from '@mui/icons-material/VerticalAlignTop';
import withStyles from '@mui/styles/withStyles';
import { featureFlags } from '@configs/feature-flags';
import { useTranslation } from 'react-i18next';
import { changeReprintStatus } from '../../../../redux/actions/objects';
import { FilterButton, AddButton, QrCodeButton, CommonButton } from '../../../../ui';
import GreedyElement from '../../../atoms/GreedyElement';
import Toolbar from '../../../atoms/Toolbar';
import SearchInput from '../../../molecules/SearchInput';

const styles = {
  margin: {
    margin: '0 10px',
  },
  toolbar: {
    marginBottom: 15,
  },
  buttons: {
    '& span': {
      marginRight: 10,
    },
    '& span:last-of-type': {
      marginRight: 0,
    },
  },
};

const FlatsButtons = ({
  tab,
  classes,
  changeQrModalVisibility,
  changeReprintStatus,
  isButtonDisabled,
}) => {
  if (tab !== 'flats') return null;

  const { t } = useTranslation();

  return (
    <div className={classes.buttons}>
      <QrCodeButton
        disabled={isButtonDisabled}
        clickHandler={() => {
          changeQrModalVisibility(true);
          changeReprintStatus(false);
        }}
      />

      <CommonButton
        disabled={isButtonDisabled}
        title={t('DownloadPreviouslyGeneratedQrCodes')}
        clickHandler={() => {
          changeQrModalVisibility(true);
          changeReprintStatus(true);
        }}
      >
        <ImportIcon />
      </CommonButton>
    </div>
  );
};

const isCRUDAllowed = featureFlags.oldObjectsCRUD;

function ObjectsContentToolbar(props) {
  const {
    classes,
    isOpen,
    mode,
    openCloseFilter,
    add,
    searchValue,
    search,
    tableKind,
    changeQrModalVisibility,
    changeReprintStatus,
    selection,
  } = props;

  return (
    <Toolbar className={classes.toolbar}>
      <FilterButton
        onClick={openCloseFilter}
        disabled={isOpen}
        className={classes.margin}
      />
      <SearchInput
        className={classes.margin}
        value={searchValue}
        onChange={(e) => search(e.target.value)}
      />
      <GreedyElement />
      {isCRUDAllowed && tableKind === 'buildings' && (
        <AddButton onClick={add} disabled={mode === 'edit'} className={classes.margin} />
      )}
      {!isCRUDAllowed ? null : (
        <FlatsButtons
          tab={tableKind}
          classes={classes}
          changeQrModalVisibility={changeQrModalVisibility}
          changeReprintStatus={changeReprintStatus}
          isButtonDisabled={!selection.length}
        />
      )}
    </Toolbar>
  );
}

export default withStyles(styles, { withTheme: true })(ObjectsContentToolbar);
