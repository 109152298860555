import { useTranslation } from 'react-i18next';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  error: {
    display: 'grid',
    justifyItems: 'center',
    alignContent: 'center',
    gap: 15,
    width: '100%',
    height: 'calc(100vh - 200px) !important',
  },
  error__icon: {
    color: '#EB5757',
    fontSize: 48,
  },
  error__content: {
    display: 'grid',
    justifyItems: 'center',
  },
  error__title: {
    color: 'rgb(101, 101, 123)',
    fontWeight: 'bold',
  },
});

const ErrorMessage = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <div className={classes.error}>
      <CancelOutlinedIcon className={classes.error__icon} />
      <div className={classes.error__content}>
        <span className={classes.error__title}>{t('SomethingWentWrong')}</span>
        <span className={classes.error__title}>
          {t('TryRefreshingThePageOrComeBackLater')}
        </span>
      </div>
    </div>
  );
};

export { ErrorMessage };
