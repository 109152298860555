import { FC } from 'react';
import { Typography } from '@mui/material';
import { Storage } from '@mui/icons-material';
import makeStyles from '@mui/styles/makeStyles';
import i18n from '@shared/config/i18n';

const { t } = i18n;

const useStyles = makeStyles({
  empty: {
    display: 'grid',
    gap: 5,
    justifyContent: 'center',
    justifyItems: 'center',
    alignItems: 'center',
    alignContent: 'center',
    width: '100%',
    height: '100%',
    padding: '30px 10px',
  },
  emptyIcon: {
    color: '#65657B',
    fontSize: 28,
  },
  emptyText: {
    color: '#65657B',
    fontWeight: 500,
  },
});

interface EmptyProps {
  title: string;
}

export const Empty: FC<EmptyProps> = ({ title = '' }) => {
  const classes = useStyles();

  const renderTitle = () => (
    <span className={classes.emptyText}>{title || t('NoData')}</span>
  );

  return (
    <div className={classes.empty}>
      <Storage classes={{ root: classes.emptyIcon }} />
      {renderTitle()}
    </div>
  );
};
