import classNames from 'classnames';
import withStyles from '@mui/styles/withStyles';
import {
  CancelButton,
  CloseButton,
  DeleteButton,
  EditButton,
  ExportButton,
  SaveButton,
  SendButton,
} from '../../ui';
import GreedyElement from '../atoms/GreedyElement';
import Toolbar from '../atoms/Toolbar';

const styles = {
  margin: {
    margin: '0 5px',
  },
};

function DetailToolbar(props) {
  const {
    classes,
    mode,
    openCloseDetail,
    onSave,
    onCancel,
    onSend,
    onEdit,
    onDelete,
    onExport,
    hidden = {},
    ...restProps
  } = props;

  return (
    <Toolbar {...restProps}>
      {hidden.edit ? null : (
        <EditButton
          className={classes.margin}
          onClick={onEdit}
          disabled={mode === 'edit'}
        />
      )}
      {!onExport ? null : (
        <ExportButton
          className={classes.margin}
          onClick={onExport}
          disabled={mode === 'edit'}
        />
      )}
      {hidden.delete ? null : (
        <DeleteButton
          disabled={mode === 'edit'}
          className={classes.margin}
          onClick={onDelete}
        />
      )}
      {!hidden.send && <SendButton className={classes.margin} onClick={onSend} />}

      <GreedyElement />

      {mode === 'edit' ? (
        <div>
          <SaveButton className={classes.margin} onClick={onSave} />
          <CancelButton className={classes.margin} onClick={onCancel} />
        </div>
      ) : null}
      {!hidden.close && mode !== 'edit' ? (
        <CloseButton className={classNames(classes.margin)} onClick={openCloseDetail} />
      ) : null}
    </Toolbar>
  );
}

export default withStyles(styles, { withTheme: true })(DetailToolbar);
