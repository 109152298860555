import { memo } from 'react';
import { useStore } from 'effector-react';
import makeStyles from '@mui/styles/makeStyles';
import { $records, $error } from '../models';
import { Card } from './card';

const useStyles = makeStyles({
  records: {
    display: 'grid',
    gridTemplateColumns: 'repeat(5, 1fr)',
    alignContent: 'start',
    gap: 16,
    minHeight: 750,

    '@media screen and (max-width: 2400px)': {
      gridTemplateColumns: 'repeat(6, 1fr)',
    },

    '@media screen and (max-width: 2048px)': {
      gridTemplateColumns: 'repeat(6, 1fr)',
    },

    '@media screen and (max-width: 1600px)': {
      gridTemplateColumns: 'repeat(5, 1fr)',
    },

    '@media screen and (max-width: 1300px)': {
      gridTemplateColumns: 'repeat(4, 1fr)',
    },

    '@media screen and (max-width: 1024px)': {
      gridTemplateColumns: 'repeat(3, 1fr)',
    },

    '@media screen and (max-width: 960px)': {
      gridTemplateColumns: 'repeat(2, 1fr)',
    },
  },
});

const Records = memo(({ styles = {} }) => {
  const records = useStore($records);
  const error = useStore($error);
  const classes = useStyles();

  if (error) {
    return (
      <div>
        <p>{error}</p>
      </div>
    );
  }
  return (
    <div className={classes.records} style={styles}>
      {records.map((item) => (
        <Card key={item.id} data={item} />
      ))}
    </div>
  );
});

export { Records };
