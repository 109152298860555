import { createStore, createEvent, combine, restore } from 'effector';
import { throttle } from 'patronum/throttle';
import { Sorting } from '@devexpress/dx-react-grid';
import { signout } from '@features/common';

export interface IColumns {
  name: string;
  title: string;
}

// возможно интерфейс с массивом order нужно доработать, когда он передается
export interface IConfig {
  order?: string[];
  widths?: IWidths[] | [];
  pageSize?: number;
  hiddenColumns?: string[];
}

export interface IWidths {
  columnName: string;
  width: number | string;
}

export interface ISort {
  columnName: string;
  direction: 'asc' | 'desc';
}

function createTableBag(columns: IColumns[] | [] = [], customConfig: IConfig) {
  const config = getConfig(columns, customConfig);
  const pageNumberChanged = createEvent<number>();
  const pageSizeChanged = createEvent<number>();
  const columnOrderChanged = createEvent<string[]>();
  const columnWidthsChanged = createEvent<IWidths[]>();
  const sortChanged = createEvent<Array<Sorting>>();
  const hiddenColumnChanged = createEvent<string[]>();
  const searchChanged = createEvent<string>();

  const $currentPage = createStore<number>(config.currentPage);
  const $pageSize = createStore<number>(config.pageSize);
  const $columnOrder = createStore<string[]>(config.order);
  const $columnWidths = createStore<IWidths[]>(config.widths);
  const $sorting = createStore<Array<Sorting>>(config.sorting);
  const $hiddenColumnNames = createStore<string[]>(config.hiddenColumns);
  const $search = createStore<string>(config.search);

  const throttledSearch = throttle({
    source: searchChanged,
    timeout: 1000,
  });

  const $tableParams = combine(
    $currentPage,
    $pageSize,
    $sorting,
    restore(throttledSearch, ''),
    (page, per_page, sorting, search) => ({
      page: page + 1,
      per_page,
      sorting: sorting.map((i) => ({ name: i.columnName, order: i.direction })),
      search,
    })
  );

  $currentPage
    .on(pageNumberChanged, (_, number) => number)
    .on(pageSizeChanged, () => 0)
    .reset([signout, searchChanged]);

  $pageSize.on(pageSizeChanged, (_, qty) => qty).reset([signout]);

  $columnOrder.on(columnOrderChanged, (_, order) => order).reset(signout);

  $columnWidths.on(columnWidthsChanged, (_, widths) => widths).reset(signout);

  $sorting.on(sortChanged, (_, payload) => payload).reset(signout);

  $hiddenColumnNames.on(hiddenColumnChanged, (_, names) => names).reset(signout);

  $search.on(searchChanged, (_, searchText) => searchText).reset(signout);

  return {
    pageNumChanged: pageNumberChanged,
    pageSizeChanged,
    columnOrderChanged,
    columnWidthsChanged,
    sortChanged,
    hiddenColumnChanged,
    searchChanged,

    $currentPage,
    $pageSize,
    $columnOrder,
    $columnWidths,
    $sorting,
    $hiddenColumnNames,
    $search,
    $tableParams,
  };
}

function getConfig(columns: IColumns[], config: IConfig = { order: [], widths: [] }) {
  return {
    search: '',
    currentPage: 0,
    pageSize: 10,
    sorting: [],
    hiddenColumns: [],
    ...config,
    order: columns.map((col) => col.name).concat(config.order || []),
    widths: columns.map((col) => {
      const match = config.widths && config.widths.find((i) => i.columnName === col.name);
      return match || { columnName: col.name, width: 200 };
    }),
  };
}

export { createTableBag };
