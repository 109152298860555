import { cloneElement } from 'react';
import PropTypes from 'prop-types';
import { List } from '@mui/material';

export const PollOptionList = (props) => {
  const { options, children: element } = props;

  const optionsList = options.map((item) =>
    cloneElement(element, { key: item.id, ...item, ...element.props })
  );

  return <List>{optionsList}</List>;
};

PollOptionList.propTypes = {
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  children: PropTypes.element.isRequired,
};
