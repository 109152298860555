import { combineReducers } from 'redux';
import {
  request,
  getSuccess,
  createSuccess,
  updateSuccess,
  deleteSuccess,
  requestFail,
} from '../actions/cameras';
import { reducers } from '../symbiotes/cameras';
import { fetchingReducer, errorReducer, dataReducer } from './commonReducers';

const isFetching = fetchingReducer(
  [request],
  [createSuccess, updateSuccess, deleteSuccess, getSuccess, requestFail],
  false
);
const error = errorReducer([requestFail], [request], null);

const data = dataReducer(
  {
    create: createSuccess,
    update: updateSuccess,
    delete: deleteSuccess,
    get: getSuccess,
    format: (data) =>
      data.cameras.reduce((acc, item) => {
        return { ...acc, [item.camera.id]: item };
      }, {}),
    createReducer: (state, action) => ({
      ...state,
      [action.payload.camera.id]: action.payload,
    }),
  },
  {}
);

const cameras = combineReducers({
  isFetching,
  error,
  data,
  ...reducers,
});

export default cameras;
