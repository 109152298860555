import classNames from 'classnames';
import Typography from '@mui/material/Typography';
import withStyles from '@mui/styles/withStyles';

const styles = {
  h5: {
    fontWeight: '700',
    color: '#3B3B50',
    display: 'inline-block',
  },
};

function FormSectionHeader(props) {
  const { header, classes, addStyles = { marginBottom: '30px' }, variant = 'h5' } = props;
  return (
    <Typography
      variant={variant}
      style={{
        ...addStyles,
      }}
      classes={{ [variant]: classNames(classes[variant]) }}
    >
      {' '}
      {header}
    </Typography>
  );
}

export default withStyles(styles)(FormSectionHeader);
