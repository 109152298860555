import { sample } from 'effector';
import { fileSave } from '../api';
import { fxUploadImage, uploadImage, unMount, $file } from './upload.model';

fxUploadImage.use(fileSave);

$file.on(fxUploadImage.done, (_, { result }) => result.file).reset(unMount);

sample({
  clock: uploadImage,
  target: fxUploadImage,
});
