import { createEffect, sample } from 'effector';
import { createGate } from 'effector-react';
import { cache, createQuery, localStorageCache } from '@farfetched/core';
import { signout } from '@features/common';
import { rentFloorsApi } from '@features/effector-form/controls/rent-floor-controls/api';
import {
  RentFloorsPayload,
  RentFloorsResponse,
} from '@features/effector-form/controls/rent-floor-controls/types';

export const RentFloorsControlGate = createGate();

const fxGetRentFloors = createEffect<RentFloorsPayload, RentFloorsResponse, Error>().use(
  rentFloorsApi.getRentFloors
);

export const rentFloorsQuery = createQuery({
  name: 'rentFloors',
  effect: fxGetRentFloors,
  mapData: ({ result }) =>
    result.areaList.map((floor) => ({
      id: floor.guid,
      label: floor.title,
    })),
});

cache(rentFloorsQuery, {
  adapter: localStorageCache({ maxAge: '5m' }),
  purge: signout,
});

sample({
  clock: RentFloorsControlGate.state,
  filter: ({ buildings }) =>
    Array.isArray(buildings) ? buildings.length > 0 : Boolean(buildings),
  fn: ({ buildings }) => {
    if (Array.isArray(buildings)) {
      return { parent_guids: buildings };
    }

    if (typeof buildings === 'string') {
      return { parent_guids: [buildings] };
    }

    return null;
  },
  target: rentFloorsQuery.start,
});
