import toPath from 'lodash/toPath';

/** Извлекает значение из вложенного объекта */

export function getIn(obj: any, key: string | string[], def?: any, p = 0) {
  let result = { ...obj };
  let iterator = p;
  const path = toPath(key);

  while (result && iterator < path.length) {
    result = result[path[iterator++]];
  }

  // Проверка не находится ли путь в конце
  if (iterator !== path.length && !result) {
    return def;
  }

  return result === undefined ? def : result;
}
