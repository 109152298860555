import { getCrumbs } from './getCrumbs';
import { hasPath } from './hasPath';

export const getPathOr = (path, object, fallback = {}) => {
  const pathExists = hasPath(path, object);

  if (!path || !object) {
    return fallback;
  }

  if (pathExists) {
    let value = object;
    const crumbs = getCrumbs(path);
    let idx = 0;

    while (idx < crumbs.length) {
      if (typeof value !== 'object') return fallback;

      value = value[crumbs[idx]];
      idx += 1;
    }

    return value !== undefined ? value : fallback;
  }

  return fallback;
};
