import { Components } from '@mui/material';

export const Link: Components['MuiLink'] = {
  defaultProps: {
    underline: 'none',
  },
  styleOverrides: {
    root: {
      fontWeight: 700,
      display: 'inline-flex',
      alignItems: 'center',
      '&.MuiTypography-body1 > svg': {
        marginTop: 2,
      },
      '& svg:last-child': {
        marginLeft: 2,
      },
    },
  },
  variants: [
    {
      props: {
        color: 'primary',
      },
      style: {},
    },
  ],
};
