function getFormData(data, existFormData, previousKey) {
  const formData = !existFormData ? new FormData() : existFormData;

  if (data instanceof File) {
    return formData.append(previousKey, data);
  }

  if (data instanceof Object) {
    Object.keys(data).forEach((key) => {
      const value = data[key];
      let tempKey = key;

      if (previousKey) {
        tempKey = `${previousKey}[${tempKey}]`;
      }

      if (value instanceof File) {
        formData.append(tempKey, value);
      } else if (value instanceof Object && !Array.isArray(value)) {
        getFormData(value, formData, tempKey);
      } else if (Array.isArray(value)) {
        value.forEach((value_, index) => {
          if (typeof value_ === 'object') {
            getFormData(value_, formData, `${tempKey}[${index}]`);
          } else {
            formData.append(`${tempKey}[]`, value_);
          }
        });
      } else {
        formData.append(tempKey, value);
      }
    });

    return formData;
  }

  return null;
}

export { getFormData as newGetFormData };
