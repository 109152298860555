import { Field } from 'formik';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@mui/material';
import { CustomScrollbar, SwitchField, DeleteButton, EditButton } from '@ui/index';
import { featureFlags } from '@configs/feature-flags';

import { SimpleInput } from '../../../molecules/SimpleInput';
import DropdownInput from '../../../molecules/DropdownInput';
import DetailToolbar from '../../../molecules/DetailToolbar';
import { isNull as isNewEntity } from '../../../../tools/type';

const isCRUDAllowed = featureFlags.oldObjectsCRUD;

const ComplexesForm = (props) => {
  const {
    openCloseDetail,
    mode,
    handleSubmit,
    handleReset,
    onEdit,
    openedEntity,
    setFieldValue,
    regions,
    onDelete,
  } = props;
  const { t } = useTranslation();

  const isNew = isNewEntity(openedEntity);

  return (
    <form
      style={{
        height: '100%',
        position: 'relative',
      }}
    >
      <DetailToolbar
        style={{ padding: '24px' }}
        openCloseDetail={openCloseDetail}
        mode={mode}
        hidden={{ delete: !isCRUDAllowed, send: true, edit: !isCRUDAllowed }}
        onEdit={onEdit}
        onSave={handleSubmit}
        onCancel={isNew ? openCloseDetail : handleReset}
        onDelete={onDelete}
      />
      <div style={{ height: 'calc(100% - 82px)', padding: '0 6px 24px 24px' }}>
        <CustomScrollbar autoHide>
          <div style={{ paddingRight: 18 }}>
            <Field
              label={t('Name')}
              required
              component={SimpleInput}
              name="complex"
              mode={mode}
              divider
            />
            <Field
              label={t('Region')}
              required
              placeholder={t('ChooseRegion')}
              options={regions}
              component={DropdownInput}
              name="region"
              onChange={(value) => setFieldValue('region', value)}
              mode={mode}
            />
            <Field
              component={SwitchField}
              name="paidTicketsAllowed"
              label={t('ConnectPaidTickets')}
              labelPlacement="start"
              disabled={mode === 'view'}
            />
          </div>
        </CustomScrollbar>
      </div>
    </form>
  );
};

export default ComplexesForm;
