import { useEffect, cloneElement } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import format from 'date-fns/format';
import { dateFnsLocale} from '@tools/dateFnsLocale';

import { MessagesDateDivider, CustomScrollbar } from '../..';

export const MessagesList = (props) => {
  const { messages, children: element, colors } = props;

  const namedMonthDY = (unixtimestamp) =>
    format(new Date(unixtimestamp * 1000), 'dd.MMMM.yyyy', { locale: dateFnsLocale });

  const getDate = ({ created_at }) => namedMonthDY(created_at);

  useEffect(() => {
    const list = document.querySelector('#list');

    list.scroll({ top: list.scrollHeight, behavior: 'smooth' });
  }, [messages.length]);

  const messagesContent = messages.map((item, index, array) => {
    const authorHasColor = colors[item.author];

    const messageElement = cloneElement(element, {
      message: item,
      color: colors[!authorHasColor || item.hidden ? 'hidden' : item.autor],
    });

    const currentDate = getDate(item);
    const prevDate = array[index - 1] && getDate(array[index - 1]);
    const showDate = currentDate !== prevDate;

    const dateDividerElement = showDate ? (
      <MessagesDateDivider date={currentDate} />
    ) : null;

    return (
      <div key={`msg-${item.id}`}>
        {dateDividerElement}
        {messageElement}
      </div>
    );
  });

  return (
    <Grid
      component="div"
      style={{
        flex: '1 1',
        padding: '24px 12px 0 24px',
        overflowY: 'auto',
        height: 'calc(100% - 24px)',
      }}
    >
      <CustomScrollbar
        autoHide
        renderView={(props) => (
          <ul
            {...props}
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              overflow: 'scroll',
              marginRight: -20,
              marginBottom: -20,
              paddingRight: 24,
            }}
            id="list"
            className="view"
          />
        )}
      >
        {messagesContent}
      </CustomScrollbar>
    </Grid>
  );
};

MessagesList.propTypes = {
  messages: PropTypes.arrayOf(PropTypes.object).isRequired,
  children: PropTypes.element.isRequired,
};
