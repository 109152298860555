import { useState } from 'react';
import classNames from 'classnames';
import Collapse from '@mui/material/Collapse';
import FormLabel from '@mui/material/FormLabel';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import withStyles from '@mui/styles/withStyles';
import Divider from '../atoms/Divider';

const styles = {
  control: {
    marginBottom: '16px',
    fontWeight: '400',
    fontSize: '.7em',
    color: '#65657B',
    display: 'flex',
    alignItems: 'center',
  },
  controlDense: {
    marginBottom: '0',
    alignItems: 'center',
  },
  legend: {
    marginBottom: '16px',
    color: '#65657B',
    fontSize: '14px',
    lineHeight: '21px',
    display: 'flex',
  },
  legendDense: {
    marginBottom: '0',
  },
};

function ControlsGroup(props) {
  const [groupIsOpen, changeVisibility] = useState(false);

  let GroupWrapper = 'div';
  const {
    label,
    divider = true,
    wrapperProps = {},
    classes,
    GroupContent = null,
    contentProps = {},
    collapse = false,
  } = props;

  if (collapse) GroupWrapper = Collapse;

  return GroupContent !== null ? (
    <>
      <FormLabel
        className={
          collapse && !groupIsOpen
            ? classNames(classes.legend, classes.legendDense)
            : classes.legend
        }
        component="legend"
      >
        {collapse ? (
          groupIsOpen ? (
            <ExpandLess
              onClick={() => changeVisibility(false)}
              style={{ marginRight: '10px', cursor: 'pointer' }}
            />
          ) : (
            <ExpandMore
              onClick={() => changeVisibility(true)}
              style={{ marginRight: '10px', cursor: 'pointer' }}
            />
          )
        ) : null}
        {label}
      </FormLabel>
      <GroupWrapper
        {...(collapse ? { in: groupIsOpen, timeout: 'auto', unmountOnExit: true } : {})}
        {...wrapperProps}
      >
        <GroupContent {...contentProps} />
      </GroupWrapper>
      {divider ? <Divider /> : null}
    </>
  ) : null;
}

export default withStyles(styles)(ControlsGroup);
