import { split } from 'effector';
import { playSound } from '@features/common/model/sounds';
import { fxGetRecords } from '../records/records.model';
import { $unreadedLevels } from './notifications.model';

/**
 * Получаем типы уровне (адишники) по которым есть непросмотренные события
 */
$unreadedLevels.on(fxGetRecords.doneData, (_, data) => {
  if (!Array.isArray(data) || !data.length) return new Set();

  return data
    .reduce((acc, { levels = [] }) => [...acc, ...levels], [])
    .filter(({ viewed = true }) => !viewed)
    .reduce((acc, { id }) => acc.add(id), new Set());
});

/**
 * Смотрим какие уровни с новыми уведомлениями присутствуют и проигрываем
 * соответсвующие уведомление (с наибольшим приоритетом: от 145 к 147)
 */
split({
  source: $unreadedLevels,
  match: {
    warning: (source) => source.has(145),
    alert: (source) => source.has(146),
    notify: (source) => source.has(147),
  },
  cases: {
    warning: playSound.prepend(() => ({ type: 'warning' })),
    alert: playSound.prepend(() => ({ type: 'alert' })),
    notify: playSound.prepend(() => ({ type: 'notification' })),
  },
});
