import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MenuItem, Select } from '@mui/material';
import i18n from '@shared/config/i18n';
import * as Styled from './styled';

export const Header = () => {
  const { t } = useTranslation();

  const [language, setLanguage] = useState(localStorage.getItem('lang') || 'en');

  const handleLanguage = (event: any) => {
    const { value } = event.target;
    i18n.changeLanguage(value);
    localStorage.setItem('lang', value);
    setLanguage(value);
    window.location.reload();
  };

  return (
    <Styled.Container>
      <a
        href="https://files.mysmartflat.ru/crm/ujin.pdf"
        target="_blank"
        rel="noopener noreferrer"
      >
        {t('DownloadPresentation')}
      </a>
      <Select
        id="select-standard-label-language"
        value={language}
        onChange={handleLanguage}
        label="Age"
      >
        <MenuItem value="en">en</MenuItem>
        <MenuItem value="ru">ru</MenuItem>
      </Select>
    </Styled.Container>
  );
};
