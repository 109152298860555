import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  objectsItem_group: {
    borderLeft: '1px dashed rgba(0, 0, 0, 0.1)',
  },
  // Костыль со стилями есть еще в стилях у дерева
  objectsItem_selected: {
    background: '#E1EBFF !important',

    '& *': {
      color: '#3B3B50',
      fontWeight: '500',
    },
  },
  objectsItem_arrow: {
    color: '#0394E3 !important',

    '& *': {
      color: '#0394E3 !important',
    },
  },
  objectsItem__icon: {
    fontSize: 20,
  },
  objectsItem__title: {
    display: 'grid',
    gridTemplateColumns: 'auto 1fr auto',
    alignItems: 'center',
    gap: 12.5,
    padding: '12px 5px 12px 0',
    fontSize: 14,
    color: '#7D7D8E',
  },
  objectsItem__titleText: {},
});
