import { ModalCard, ModalCardTitle, ModalCardContent, ModalCardActions } from '../..';

function Modal(props) {
  const { isOpen, header, content, actions, ...rest } = props;

  return isOpen ? (
    <ModalCard open={isOpen} {...rest}>
      <ModalCardTitle>{header}</ModalCardTitle>
      {content && <ModalCardContent>{content}</ModalCardContent>}
      {actions && <ModalCardActions>{actions}</ModalCardActions>}
    </ModalCard>
  ) : null;
}

export { Modal };
