import DeleteIcon from '@mui/icons-material/Delete';
import i18n from '@shared/config/i18n';
import { ActionIconButton } from '../..';

const { t } = i18n;

function DeleteButton({ isHideTitleAccess = false, ...props }) {
  return (
    <ActionIconButton data-test-id="delete-btn" kind="negative" {...props}>
      <DeleteIcon titleAccess={!isHideTitleAccess ? t('remove') : null} />
    </ActionIconButton>
  );
}

export { DeleteButton };
