import { SelectControl, SelectField } from '@ui/index';
import i18n from '@shared/config/i18n';

const { t } = i18n;

export const timeZones = [
  { id: 'Asia/Anadyr', title: t('Asia/Anadyr') },
  { id: 'Asia/Barnaul', title: t('Asia/Barnaul') },
  { id: 'Asia/Chita', title: t('Asia/Chita') },
  { id: 'Asia/Irkutsk', title: t('Asia/Irkutsk') },
  { id: 'Asia/Kamchatka', title: t('Asia/Kamchatka') },
  { id: 'Asia/Khandyga', title: t('Asia/Khandyga') },
  { id: 'Asia/Krasnoyarsk', title: t('Asia/Krasnoyarsk') },
  { id: 'Asia/Magadan', title: t('Asia/Magadan') },
  { id: 'Asia/Novokuznetsk', title: t('Asia/Novokuznetsk') },
  { id: 'Asia/Novosibirsk', title: t('Asia/Novosibirsk') },
  { id: 'Asia/Omsk', title: t('Asia/Omsk') },
  { id: 'Asia/Sakhalin', title: t('Asia/Sakhalin') },
  { id: 'Asia/Srednekolymsk', title: t('Asia/Srednekolymsk') },
  { id: 'Asia/Tomsk', title: t('Asia/Tomsk') },
  { id: 'Asia/Ust-Nera', title: t('Asia/Ust-Nera') },
  { id: 'Asia/Vladivostok', title: t('Asia/Vladivostok') },
  { id: 'Asia/Yakutsk', title: t('Asia/Yakutsk') },
  { id: 'Asia/Yekaterinburg', title: t('Asia/Yekaterinburg') },
  { id: 'Europe/Astrakhan', title: t('Europe/Astrakhan') },
  { id: 'Europe/Kaliningrad', title: t('Europe/Kaliningrad') },
  { id: 'Europe/Kirov', title: t('Europe/Kirov') },
  { id: 'Europe/Moscow', title: t('Europe/Moscow') },
  { id: 'Europe/Samara', title: t('Europe/Samara') },
  { id: 'Europe/Saratov', title: t('Europe/Saratov') },
  { id: 'Europe/Ulyanovsk', title: t('Europe/Ulyanovsk') },
  { id: 'Europe/Volgograd', title: t('Europe/Volgograd') },
];

const TimeZoneSelect = (props) => <SelectControl options={timeZones} {...props} />;

const TimeZoneSelectField = (props) => {
  const onChange = (value) => props.form.setFieldValue(props.field.name, value);

  return <SelectField component={<TimeZoneSelect />} onChange={onChange} {...props} />;
};

export { TimeZoneSelect, TimeZoneSelectField };
