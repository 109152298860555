import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Store } from 'effector';
import { useUnit } from 'effector-react';
import { Control } from '@effector-form';
import { Alert, Backdrop, Button, CircularProgress } from '@mui/material';
import { authForm } from '@features/authorization/model';
import { $error, $isUserLoading } from '@features/common';
import * as Styled from './styled';

export const AuthForm: FC = () => {
  const { t } = useTranslation();
  const [error, isLoading] = useUnit([
    $error as Store<null | { message: string }>,
    $isUserLoading,
  ]);

  const renderError = () =>
    error ? (
      <Alert severity="error" icon={false}>
        {error?.message}
      </Alert>
    ) : null;

  const { submittable, submit } = useUnit(authForm);

  return (
    <Styled.Container>
      <Backdrop open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>

      {renderError()}

      <Styled.Form>
        <Control.Text form={authForm} name="login" label={t('LoginOrEmail')} />
        <Control.Password form={authForm} name="password" label={t('Label.Password')} />

        <Styled.Checkboxes>
          <Control.Checkbox form={authForm} name="remember_me" label={t('RememberMe')} />
          <Control.Checkbox form={authForm} name="isCompany" label={t('LoginForCompanies')} />
        </Styled.Checkboxes>

        <Button variant="contained" size="large" color="primary" onClick={() => submit()}>
          {t('Enter')}
        </Button>
      </Styled.Form>
    </Styled.Container>
  );
};
