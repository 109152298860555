export function LabeledContent(props) {
  const { label, children, labelPlacement = 'top', ...rest } = props;

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: labelPlacement === 'top' ? 'column' : 'row',
        alignItems: labelPlacement === 'start' && 'center',
      }}
      {...rest}
    >
      <div
        style={{
          marginBottom: labelPlacement === 'top' ? 10 : 0,
          marginRight: labelPlacement === 'top' ? 0 : 20,
        }}
      >
        <span style={{ color: '#9494A3', fontSize: 14, lineHeight: '16px' }}>
          {label}
        </span>
      </div>
      <div style={{ flexGrow: 1, color: '#65657B', fontWeight: 500, fontSize: 13 }}>
        {children}
      </div>
    </div>
  );
}
