import {
  Paper,
  Popper,
  ClickAwayListener,
  MenuList,
  MenuItem,
  ListItemText,
  ListItemIcon,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: 12,
    marginRight: 2,
    borderRadius: 4,
    boxShadow: '0px 4px 15px #E7E7EC',
    padding: 5,
    overflow: 'hidden',
    minWidth: 184,
  },
  menuList: {
    padding: '0',
  },
  menuItem: {
    borderRadius: 4,
    padding: '5.5px 10px',
  },
  listItemIcon: {
    minWidth: 'unset !important',
    marginRight: 10,
    color: 'rgba(37, 40, 52, 0.6)',
  },
  listItemText: {
    fontSize: 14,
    // fontWeight: 300,
    color: 'rgba(0, 0, 0, 0.6)',
  },
}));

export const Menu = ({ items, handleClose = null }) => {
  const classes = useStyles();

  const renderItems = () =>
    items.map((item, idx) => (
      <MenuItem key={idx} onClick={item.onClick} classes={{ root: classes.menuItem }}>
        {item.icon && (
          <ListItemIcon classes={{ root: classes.listItemIcon }}>
            {item.icon}
          </ListItemIcon>
        )}
        <ListItemText primary={item.title} classes={{ primary: classes.listItemText }} />
      </MenuItem>
    ));

  return (
    <MenuList
      autoFocusItem={open}
      id="menu-list-grow"
      classes={{ root: classes.menuList }}
    >
      {renderItems()}
    </MenuList>
  );
};
