import { DialogActions } from '@mui/material';

function ModalCardActions(props) {
  const { children, classes } = props;
  return (
    <DialogActions disableSpacing classes={{ classes }}>
      {children}
    </DialogActions>
  );
}

export { ModalCardActions };
