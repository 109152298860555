import { createSymbiote } from 'redux-symbiote';
import {
  createTableSymbiotes,
  createDetailSymbiotes,
  createFilterSymbiotes,
  createDialogSymbiotes,
} from './_create';

const name = 'OBJECTS';

const initialColumnWidths = [
  { columnName: 'city', width: 200 },
  { columnName: 'complexes', width: 280 },
  { columnName: 'full_address', width: 300 },
  { columnName: 'alias', width: 300 },
  { columnName: 'title', width: 200 },
  { columnName: 'region', width: 200 },
  { columnName: 'number', width: 200 },
  { columnName: 'entrance', width: 200 },
  { columnName: 'square', width: 200 },
  { columnName: 'floor', width: 150 },
  { columnName: 'entrances', width: 150 },
  { columnName: 'apartments', width: 150 },
  { columnName: 'bind_by_qr', width: 250 },
  { columnName: 'main_account', width: 250 },
];

const orderInitial = [
  'city',
  'complexes',
  'alias',
  'full_address',
  'title',
  'region',
  'number',
  'entrance',
  'square',
  'floor',
  'entrances',
  'apartments',
  'bind_by_qr',
  'main_account',
];

const { actions: tableActions, reducer: table } = createTableSymbiotes(name, {
  columnsOrder: orderInitial,
  columnWidths: initialColumnWidths,
});

const { actions: detailActions, reducer: detail } = createDetailSymbiotes(name);

const filtersInitial = {
  complexes: [],
  flats: '',
};

const { actions: filterActions, reducer: filter } = createFilterSymbiotes(name, {
  filters: filtersInitial,
});

const { actions: dialogActions, reducer: dialog } = createDialogSymbiotes(name);

const detailEntrancesInitialState = [];

const detailEntrancesSymbiotes = {
  setDetailEntrances: (state, data) => data,
};

const { actions: detailEntrancesActions, reducer: detailEntrances } = createSymbiote(
  detailEntrancesInitialState,
  detailEntrancesSymbiotes
);

const requestVariantsInitialState = [];

const requestVariantsSymbiotes = {
  setRequestVariants: (state, data) => data,
};

const { actions: requestVariantsActions, reducer: requestVariants } = createSymbiote(
  requestVariantsInitialState,
  requestVariantsSymbiotes
);

const entrancesInitialState = [];

const entrancesSymbiotes = {
  setEntrances: (state, data) => data,
};

const { actions: entrancesActions, reducer: entrances } = createSymbiote(
  entrancesInitialState,
  entrancesSymbiotes
);

export {
  tableActions,
  detailActions,
  filterActions,
  dialogActions,
  detailEntrancesActions,
  requestVariantsActions,
  entrancesActions,
};

export const reducers = {
  detail,
  filter,
  dialog,
  table,
  detailEntrances,
  requestVariants,
  entrances,
};
