import { combineReducers } from 'redux';
import {
  request,
  getSuccess,
  createSuccess,
  updateSuccess,
  deleteSuccess,
  requestFail,
} from '../actions/complexesActionGenerator';
import { fetchingReducer, errorReducer, dataReducer } from './commonReducers';
import regions from './complexes_region';

const isFetching = fetchingReducer(
  [request],
  [getSuccess, createSuccess, deleteSuccess, requestFail],
  false
);
const error = errorReducer([requestFail], [request], null);

const data = dataReducer(
  {
    create: createSuccess,
    update: updateSuccess,
    delete: deleteSuccess,
    get: getSuccess,
    format: (data) => data.reduce((acc, item) => ({ ...acc, [item.id]: item }), {}),
  },
  {}
);

const complexes = combineReducers({
  isFetching,
  error,
  data,
  regions,
});

export default complexes;
