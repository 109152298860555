export const styles = {
  editor: {
    paddingLeft: '1em',
    color: '#65657b',
    cursor: 'auto',
  },
  toolbar: {
    position: 'sticky',
    top: '0',
    zIndex: '10',
    marginTop: 20,
    width: 'fit-content',
  },
  blockType: {
    minWidth: '150px',
  },
  editorRoot: {
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    margin: '0',
    cursor: 'pointer',
    position: 'relative' as 'relative',
    paddingLeft: 0,
  },
};
