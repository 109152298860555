import { useState, useCallback } from 'react';
import { push } from '@features/common';
import i18n from '@shared/config/i18n';
import { pick } from '../../../tools/pick';

const { t } = i18n;

const mapEncodingToMethod = {
  base64: 'readAsDataURL',
  binary: 'readAsBinaryString',
  arraybuffer: 'readAsArrayBuffer',
  text: 'readAsText',
};

const formatResult = ({ file, preview, meta }) => ({ file, preview, meta });

export const useFileReader = ({ encoding = 'base64', isMultiple = false }) => {
  const [error, setError] = useState(null);
  const [readResult, setResult] = useState('');

  const read = useCallback((event) => {
    const name = event.target.name;
    const files = event.target.files;
    const validFiles = validateFiles(files);

    const targetElement = event.target;

    if (!validFiles.length) return;

    const promises =
      encoding === 'file'
        ? loadRawFiles({ files: validFiles, formatResult })
        : loadEncodedFiles({ files: validFiles, encoding, formatResult });

    Promise.all(promises)
      .then((results) =>
        setResult(
          isMultiple
            ? appendNameToResult(name, results)
            : appendNameToResult(name, results[0])
        )
      )
      .catch((error) => setError(error))
      .finally(() => {
        targetElement.value = '';
      });
  }, []);

  const reset = () => {
    setResult('');
  };

  return { read, error, readResult, reset };
};

function loadRawFiles({ files = [], formatResult }) {
  return files.map((file) =>
    loadPreview(file).then((preview) =>
      formatResult({
        preview,
        file,
        meta: pick(['name', 'lastModifiedDate', 'size', 'type'], file),
      })
    )
  );
}

function loadEncodedFiles({ files = [], encoding = 'base64', formatResult }) {
  return files.map((file) => {
    const previewPromise = loadPreview(file);
    const filePromise = loadFile(file, encoding);

    return Promise.all([filePromise, previewPromise]).then(([{ file, meta }, preview]) =>
      formatResult({ file, meta, preview })
    );
  });
}

function loadPreview(file) {
  const previewReader = new FileReader();
  previewReader.readAsDataURL(file);

  return new Promise((res, rej) => {
    previewReader.addEventListener('load', () => res(previewReader.result));
    previewReader.addEventListener('error', () => rej(previewReader.error));
  });
}

function loadFile(file, encoding = 'base64') {
  const fileReader = new FileReader();
  fileReader[getMethodByEncoding(encoding)](file);

  return new Promise((res, rej) => {
    fileReader.addEventListener('load', () =>
      res({
        file: fileReader.result,
        meta: pick(['name', 'lastModifiedDate', 'size', 'type'], file),
      })
    );
    fileReader.addEventListener('error', () => rej(fileReader.error));
  });
}

function getMethodByEncoding(encodingName) {
  return mapEncodingToMethod[encodingName] || 'readAsDataURL';
}

function appendNameToResult(name, result) {
  return Array.isArray(result)
    ? result.map((i) => ({ ...i, name }))
    : { ...result, name };
}

function validateFiles(files) {
  const filteredByType = Array.from(files).filter((i) => i instanceof Blob);

  return filteredByType.map((file) => {
    // Проверка на 50мб
    if (file.size > 52428800) {
      push({
        header: t('FileUploadError'),
        content: `${t('FileSize')} ${file.name} ${t('Exceeds50MB')}.`,
        color: 'red',
      });
      return;
    }

    return file;
  });
}
