import { Button, ButtonGroup } from '@mui/material';
import i18n from '@shared/config/i18n';

const { t } = i18n;

export const FastDateSetter = ({ onChange, value, ...props }) => {
  const defineToday = () => new Date();

  const addDayToDate = (date) => new Date(new Date().setDate(date.getDate() + 1));

  return (
    <ButtonGroup
      style={{ paddingBottom: 18 }}
      variant="text"
      size="large"
      fullWidth
      {...props}
    >
      <Button color="primary" onClick={() => onChange(defineToday())}>
        {t('TODAY')}
      </Button>
      <Button color="primary" onClick={() => onChange(addDayToDate(defineToday()))}>
        {t('TOMORROW')}
      </Button>
      <Button color="primary" onClick={() => onChange(addDayToDate(value))}>
        {t('+1DAY')}
      </Button>
    </ButtonGroup>
  );
};
