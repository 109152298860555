import { Components } from '@mui/material';

export const IconButton: Components['MuiIconButton'] = {
  defaultProps: {},
  styleOverrides: {
    root: {
      borderRadius: 4,
    },
  },
};
