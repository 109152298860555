import classnames from 'classnames';
import { Typography } from '@mui/material';
import { Done, QueryBuilder } from '@mui/icons-material';
import makeStyles from '@mui/styles/makeStyles';
import i18n from '@shared/config/i18n';

const { t } = i18n;

const useStatusStyles = makeStyles({
  root: {
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
    color: '#65657B',
  },
  statusTitle: {
    fontSize: 14,
    lineHeight: '16px',
  },
  statusIcon: {
    marginRight: 10,
  },
  verified: {
    color: '#1BB169',
  },
});

export const ItemStatus = (props) => {
  const { is_verified, verifiedText = t('verified') } = props;
  const { root, statusTitle, statusIcon, verified } = useStatusStyles();

  return is_verified ? (
    <div className={classnames(root, { [verified]: is_verified })}>
      <Done className={statusIcon} />
      <Typography className={statusTitle}>{verifiedText}</Typography>
    </div>
  ) : (
    <div className={classnames(root, { [verified]: is_verified })}>
      <QueryBuilder className={statusIcon} />
      <Typography className={statusTitle}>{t('AwaitingVerification')}</Typography>
    </div>
  );
};
