import { Typography } from '@mui/material';
import { Add as AddIcon } from '@mui/icons-material';
import makeStyles from '@mui/styles/makeStyles';
import i18n from '@shared/config/i18n';
import { ActionButton, Dumb } from '../..';

const { t } = i18n;

const useStyles = makeStyles(() => ({
  text: {
    fontWeight: 900,
    color: '#9EA1AB',
    fontSize: 24,
    lineHeight: '44px',
    fontFamily: 'Montserrat',
    marginBottom: 25,
  },
}));

export function NoDataPage({ text, onAdd }) {
  const classes = useStyles();
  return (
    <Dumb>
      <Typography classes={{ root: classes.text }} variant="h3">
        {text}
      </Typography>
      <ActionButton
        icon={<AddIcon fontSize="small" />}
        kind="positive"
        onClick={onAdd}
        style={{ padding: '0 24px' }}
      >
        {t('create')}
      </ActionButton>
    </Dumb>
  );
}
