import Grid from '@mui/material/Grid';
import { Close, InsertDriveFile } from '@mui/icons-material';
import makeStyles from '@mui/styles/makeStyles';
import i18n from '@shared/config/i18n';

const { t } = i18n;

const useStyles = makeStyles({
  attachment: {
    margin: '5px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
    paddingTop: '20px',
    '& img': {
      borderRadius: 8,
      objectFit: 'cover !important',
      width: 150,
      height: 150,
    },
    '&:hover': {
      opacity: '.8',
    },
  },
  close: {
    position: 'absolute',
    top: 0,
    right: 0,
    cursor: 'pointer',
  },
});

export const File = (props) => {
  const {
    url,
    name,
    mime,
    preview,
    rootComponent = 'div',
    openImageDialog = () => {},
    imgStyles = {},
    removeAttachment = null,
    type,
  } = props;

  const fileUrl = url || '';
  const fileName = name || t('File');
  const fileMime = mime || type || '';

  const classes = useStyles();

  const hasRemovable = typeof removeAttachment === 'function';
  const remove = () => {
    if (hasRemovable) {
      removeAttachment();
    }
  };

  const isImage = /image/.test(fileMime);

  return fileUrl || preview ? (
    <Grid component={rootComponent} className={classes.attachment}>
      {hasRemovable && (
        <div className={classes.close} onClick={remove}>
          <Close />
        </div>
      )}
      {isImage ? (
        <img
          src={fileUrl || preview}
          style={{
            width: '150px',
            height: '150px',
            objectFit: 'contain',
            ...imgStyles,
          }}
          alt={fileName}
          onClick={openImageDialog}
        />
      ) : (
        <InsertDriveFile style={{ width: 150, height: 150 }} />
      )}
      <Grid container justifyContent="center" style={{ width: '150px' }}>
        {fileName.length > 13 ? `${fileName.slice(0, 13)}...` : fileName}
      </Grid>
    </Grid>
  ) : null;
};
