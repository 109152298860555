import { Components } from '@mui/material';

export const FormControlLabel: Components['MuiFormControlLabel'] = {
  styleOverrides: {
    root: {
      marginLeft: 0,
      marginRight: 0,
      marginBottom: 0,
      gap: 15,
    },
  },
};
