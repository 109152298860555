import { Table } from '@devexpress/dx-react-grid-material-ui';

export { useHover } from './useHover';

const TableCell = (props) => {
  const { value, additionalStyle = {}, ...restProps } = props;

  const style = {
    padding: '0 8px',
    height: 'inherit',
    fontSize: '16px',
    fontFamily: 'Roboto, sans-serif',
    color: '#767676',
    ...additionalStyle,
  };

  return <Table.Cell {...restProps} style={style} value={value} />;
};

export { TableCell };
