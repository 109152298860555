import { useState } from 'react';
import PropTypes from 'prop-types';
import { Collapse } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {
  KeyboardArrowDown as ArrowDown,
  KeyboardArrowUp as ArrowUp,
} from '@mui/icons-material';
import { NavLink, useLocation } from 'react-router-dom';
import { useStore } from 'effector-react';
import classNames from 'classnames';
import { $isCollapsed } from '../models';

const useStyles = makeStyles({
  navItem: (isCollapsed) => ({
    textDecoration: 'none !important',
    position: 'relative',
    cursor: 'pointer',
    padding: '0 13.5px',

    '& *': {
      userSelect: 'none',
    },

    '&.active': {
      '& $navItem__header': {
        background: 'rgba(28, 144, 251, 0.1) !important',
      },

      '& $navItem__iconWrap': {
        color: '#1C90FB !important',
      },

      '& $navItem__title': {
        color: isCollapsed ? 'rgba(37, 40, 52, 0.6)' : '#1C90FB',
      },

      '& $navItem__nestedItems': {
        background: isCollapsed
          ? 'white !important'
          : 'rgba(28, 144, 251, 0.1) !important',
      },
    },

    '&:hover': {
      '& $navItem__header': {
        background: 'rgba(0, 0, 0, 0.03)',
      },

      '& $navItem__iconWrap': {
        color: '#252834',
      },

      '& $navItem__title': {
        visibility: 'visible',
      },

      '& $navItem__nestedItems': {
        visibility: 'visible !important',
        background: isCollapsed ? 'white' : 'rgba(0, 0, 0, 0.03)',
      },
    },
  }),
  navItem__header: (isCollapsed) => ({
    display: 'grid',
    gridTemplateColumns: isCollapsed ? 'auto' : 'auto 1fr auto',
    alignItems: 'center',
    alignContent: 'center',
    justifyItems: 'start',
    justifyContent: 'start',
    borderRadius: 4,
  }),
  navItem__iconWrap: () => ({
    display: 'grid',
    alignItems: 'center',
    justifyItems: 'center',
    width: 48,
    height: 48,
    borderRadius: 4,
    color: 'rgba(37, 40, 52, 0.6)',

    // Костыль для размера иконки
    '& *': {
      fontSize: 24,
    },
  }),
  navItem__icon: {
    color: 'inherit',
  },
  navItem__title: (isCollapsed) => ({
    display: isCollapsed ? 'none' : 'block',
    fontSize: 16,
    fontWeight: 500,
    whiteSpace: 'nowrap',
    color: 'rgba(0, 0, 0, 0.6)',
  }),
  navItem__arrow: (isCollapsed) => ({
    margin: '0px 14px',
    color: 'rgba(0, 0, 0, 0.26)',
    display: isCollapsed ? 'none' : 'block',
  }),
  navItem__nestedItems: (isCollapsed) => {
    if (isCollapsed)
      return {
        position: 'absolute',
        right: 0,
        top: 0,
        display: 'grid',
        background: 'white',
        boxShadow:
          '0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12)',
        borderRadius: 3,
        zIndex: 1000,
        maxWidth: 272,
        visibility: 'hidden',
        transform: 'translateX(100%)',

        '&:hover': {
          visibility: 'visible',
        },
      };
    else
      return {
        display: 'grid',
      };
  },
  navItem__nestedLink: () => ({
    textDecoration: 'none !important',
    color: 'rgba(0, 0, 0, 0.6)',

    '& *': {
      fontSize: 14,
      fontWeight: '500',
    },

    '&.active *': {
      // fontSize: 16,
      // fontWeight: 'normal',
      background: 'rgba(28, 144, 251, 0.1)',
      color: '#1C90FB',
    },

    '&:hover': {
      background: 'rgba(0, 0, 0, 0.03)',
      color: 'rgba(37, 40, 52, 0.87)',
    },
  }),
  navItem__nestedItem: (isCollapsed) => ({
    padding: isCollapsed ? '14px 16px' : '14px 16px 14px 48px',
    whiteSpace: isCollapsed ? 'nowrap' : 'unset',
  }),
});

const NavItemDefault = (props) => {
  const { text, link, icon } = props;
  const isCollapsed = useStore($isCollapsed);
  const Icon = icon;
  const classes = useStyles(isCollapsed);

  return (
    <NavLink to={`/${link}`} className={classes.navItem}>
      <div className={classes.navItem__header}>
        <div className={classes.navItem__iconWrap}>
          <Icon className={classes.navItem__icon} />
        </div>

        <div className={classes.navItem__title}>{text}</div>
      </div>
    </NavLink>
  );
};

const NavItemNested = (props) => {
  const { icon, text, items = [] } = props;
  const isCollapsed = useStore($isCollapsed);
  const [isOpen, setOpen] = useState(false);
  const location = useLocation();
  const classes = useStyles(isCollapsed);

  const Icon = icon;
  const Arrow = isOpen ? ArrowUp : ArrowDown;

  const hasCurrentLocation = items.find(
    (i) => location.pathname.split('/')[1] === i.link
  );

  const renderContent = () => {
    if (items.length)
      return (
        <ul className={classes.navItem__nestedItems}>
          {items.map((item, key) => (
            <NavLink
              key={key}
              to={`/${item.link}`}
              className={classes.navItem__nestedLink}
            >
              <li className={classes.navItem__nestedItem}>{item.text}</li>
            </NavLink>
          ))}
        </ul>
      );
    else return null;
  };

  return (
    <div
      className={classNames(classes.navItem, {
        ['active']: hasCurrentLocation,
      })}
    >
      <div className={classes.navItem__header} onClick={() => setOpen(!isOpen)}>
        <div className={classes.navItem__iconWrap}>
          <Icon className={classes.navItem__icon} />
        </div>

        <>
          <div className={classes.navItem__title}>{text}</div>

          <Arrow className={classes.navItem__arrow} />
        </>
      </div>

      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        {renderContent()}
      </Collapse>
    </div>
  );
};

const NavItem = (props) => {
  const { nest = false } = props;

  return nest ? <NavItemNested {...props} /> : <NavItemDefault {...props} />;
};

NavItem.propTypes = {
  link: PropTypes.string,
  text: PropTypes.string.isRequired,
  icon: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  nest: PropTypes.bool,
};

export default NavItem;
