import { useState } from 'react';
import { api } from '../../../api/api2';

export const useFileLoader = ({ api = 'v2', loadFn }) => {
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState('');

  const mapApiToUploadFn = {
    no_vers: api0UploadFile,
    v2: api2UploadFile,
  };

  const uploadFile = typeof loadFn === 'function' ? loadFn : mapApiToUploadFn[api];

  const upload = (readFile) => {
    const isMultiple = Array.isArray(readFile);
    setLoading(true);

    let uploadPromise;

    if (isMultiple) {
      uploadPromise = Promise.all(readFile.map((i) => uploadFile(i)));
    } else {
      uploadPromise = uploadFile(readFile);
    }

    uploadPromise
      .then(setData)
      .catch(setError)
      .finally(() => setLoading(false));
  };

  return { isLoading, error, loadResult: data, upload };
};

function api0UploadFile(file) {
  const payload = {
    file_base64: file.file,
    file_name: file.meta.name,
  };

  if (file?.meta?.type.includes('image')) {
    payload.image_only = 1;
  }

  return api.no_vers('post', 'file/upload_file', payload).then(formatFile);

  function formatFile({ file_base64, file_id }) {
    return {
      id: file_id,
      preview: file_base64,
      meta: file.meta,
    };
  }
}

function api2UploadFile(file) {
  const payload = {
    type: getType(file),
    file: getFile(file),
  };

  return api
    .v2('/system/files/save', payload, {}, false)
    .then((response) => formatFile(response.file));

  function getType(readFile) {
    let [type] =
      readFile && typeof readFile.meta.type === 'string'
        ? readFile.meta.type.split('/')
        : ['file'];

    if (type !== 'image' && type !== 'video') {
      type = 'file';
    }

    return type;
  }

  function getFile(readFile) {
    return readFile.file;
  }

  function formatFile({ id, name, meta, mime_type, url, bytes }) {
    return {
      id,
      preview: url,
      meta: { ...meta, name, size: bytes, type: mime_type },
    };
  }
}
