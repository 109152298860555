import { createEffect, sample } from 'effector';
import { createGate } from 'effector-react';
import { cache, createQuery } from '@farfetched/core';
import { TicketOriginResponse } from '@features/effector-form/controls/ticketOrigin/types';
import { getTicketOrigins } from './api';

export const TicketOriginControlGate = createGate();

const fxGetTicketOrigin = createEffect<void, TicketOriginResponse, Error>().use(
  getTicketOrigins
);

export const ticketOriginsQuery = createQuery({
  name: 'ticketOrigin',
  effect: fxGetTicketOrigin,
  mapData: ({ result, params }) =>
    result.origins.map((originData) => ({
      id: originData.name,
      label: originData.title,
    })),
});

cache(ticketOriginsQuery);

sample({
  clock: TicketOriginControlGate.status,
  target: ticketOriginsQuery.start,
});
