import ru from 'date-fns/locale/ru';
import { FormControl, FormHelperText, FormLabel } from '@mui/material';
import { CalendarToday } from '@mui/icons-material';
import { DateField, LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns';
import { withControl } from '@features/effector-form/hoc/withControl';
import { theme } from '@shared/theme';

export const DateControl = withControl(
  ({ value, setValue, error, label, required, readOnly, disabled }) => (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ru}>
      <FormControl required={required} disabled={disabled}>
        <FormLabel>{label}</FormLabel>
        <DateField
          value={value}
          onChange={(newValue) => setValue(newValue)}
          InputProps={{
            readOnly,
            disabled,
            endAdornment: <CalendarToday sx={{ color: theme.palette.grey[700] }} />,
          }}
          readOnly={readOnly}
          disabled={disabled}
        />
        <FormHelperText error>{error}</FormHelperText>
      </FormControl>
    </LocalizationProvider>
  )
);
