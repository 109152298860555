import { createEffect, createStore } from 'effector';
import { signout } from '../../common';
import { houseApi } from '../api';

const fxGetList = createEffect();

const $data = createStore([]);
const $error = createStore(null);
const $isLoading = createStore(false);

fxGetList.use(houseApi.getList);

$data.on(fxGetList.done, (state, { result }) => formatResponse(result)).reset(signout);

$error.on(fxGetList.fail, (state, { error }) => error).reset(signout);
$isLoading.on(fxGetList.pending, (state, pending) => pending).reset(signout);

function formatResponse(response = []) {
  return response.buildings.reduce(
    (acc, house) =>
      house && house.building
        ? acc.concat({ id: house.id, title: house.building.title, ...house })
        : acc,
    []
  );
}

export { $data, $error, $isLoading, fxGetList };
