import { Toolbar } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import withStyles from '@mui/styles/withStyles';
import i18n from '@shared/config/i18n';
import { ActionButton, CloseButton, Modal } from '../..';

const { t } = i18n;

const styles = {
  root: {
    display: 'flex',
    alignItems: 'center',
    minHeight: 'auto',
    justifyContent: 'flex-end',
    width: '100%',
  },
  margin: {
    margin: '0 10px',
  },
};

function CustomToolbar(props) {
  const { classes, close, confirm } = props;
  return (
    <Toolbar disableGutters variant="dense" classes={{ root: classes.root }}>
      <ActionButton
        icon={<CheckIcon />}
        kind="positive"
        onClick={confirm}
        classes={{ root: classes.margin }}
      >
        {t('yes')}
      </ActionButton>
      <ActionButton
        icon={<CloseIcon />}
        onClick={close}
        classes={{ root: classes.margin }}
        style={{ marginRight: 0 }}
      >
        {t('Cancellation')}
      </ActionButton>
    </Toolbar>
  );
}

const StyledToolbar = withStyles(styles)(CustomToolbar);

function ConfirmDialog(props) {
  const {
    confirm,
    close,
    isOpen,
    content = t('DoYouReallyWant'),
    header = t('ActionConfirmation'),
    ...restProps
  } = props;

  return (
    <Modal
      header={
        <span style={{ display: 'flex', justifyContent: 'space-between' }}>
          <span>{header}</span>
          <CloseButton onClick={close} />
        </span>
      }
      content={content}
      actions={<StyledToolbar confirm={confirm} close={close} />}
      isOpen={isOpen}
      onClose={close}
      {...restProps}
    />
  );
}

export { ConfirmDialog };
