import { createEffect, createStore, createEvent, merge, split } from 'effector';
import { createGate } from 'effector-react';
import { signout } from '@features/common';

type Row = {
  line: any[];
  link: string;
  url_device: string;
  url_devices: string;
};

type Column = {
  name: string;
  sort: boolean;
  title: string;
  type: string;
};

interface IApi {
  getList(payload: object, config: object): { meta: { total: number } };
}

interface IRaw {
  data: any[];
  meta: {
    total: number;
  };
  table?: {
    count: number;
    meta: Column[];
    data: Row[];
  };
}

interface IRawTable {
  filter: {
    main: Object[];
  };
  top: Object[];
  meta: {
    title: string;
  };
  table: {
    count: number;
    meta: Column[];
    data: Row[];
  };
}

interface IConfig {
  idAttribute: string;
  itemsAttribute: string;
  createdPath: string;
  updatedPath: string;
}

interface ITools {
  handleGetList: (
    state: IRaw,
    result: { [key: string]: any[] },
    itemsAttribute: string
  ) => { data: any[]; meta: { total: number } | any };
  handleGetById: (
    state: IRaw,
    result: { [key: string]: any },
    params: Object,
    config: IConfig
  ) => { data: any[]; meta: { total: number } };
  handleCreate: (
    state: IRaw,
    result: { [key: string]: Object },
    params: Object,
    config: { createdPath?: string }
  ) => { meta: { total: number }; data: any[] };
  handleUpdate: (
    state: IRaw,
    result: { [key: string]: { [index: string]: string } },
    params: Object,
    config: IConfig
  ) => { data: any[]; meta: { total: number } };
  handleDelete: (
    state: IRaw,
    id: string | number,
    idAttribute: string
  ) => { meta: { total: number }; data: any[] };
  normalize: (data: any, idAttribute?: string) => any;
}

const defaultTools: ITools = {
  handleGetList: formatData,
  handleGetById: updateItem,
  handleCreate: createItem,
  handleUpdate: updateItem,
  handleDelete: deleteItem,
  normalize: normalizeData,
};

const defaultConfig: IConfig = {
  idAttribute: 'id',
  itemsAttribute: 'data',
  createdPath: '',
  updatedPath: '',
};

function createPageBag(
  api: Object,
  customTools?: Object,
  config: IConfig = defaultConfig
) {
  const tools = { ...defaultTools, ...customTools };
  const defaultRaw: any = { data: [], meta: { total: 0 } };
  const PageGate: any = createGate();

  const pageUnmounted = PageGate.close;
  const pageMounted = PageGate.open;

  const deleteConfirmed = createEvent();
  const deleteClicked = createEvent();

  const {
    fxGetList,
    fxGetById,
    fxCreate,
    fxUpdate,
    fxDelete,

    $isLoading,
    $error,
    request,
    errorOccured,
    requestApi,
  } = createNetworkBag(api);

  const {
    $isDialogOpen: $isDeleteDialogOpen,
    dialogVisibilityChanged: deleteDialogVisibilityChanged,
  } = createDialogBag();

  const {
    $isDialogOpen: $isErrorDialogOpen,
    dialogVisibilityChanged: errorDialogVisibilityChanged,
  } = createDialogBag();

  const $raw = createStore(defaultRaw);
  const $rowCount = $raw.map(({ meta }) => Number(meta.total));
  const $normalized = $raw.map((state) => tools.normalize(state));

  $raw
    .on(fxGetList.done, (state, { result }) =>
      typeof tools.handleGetList === 'function'
        ? tools.handleGetList(state, result, config.itemsAttribute)
        : state
    )
    .on(fxGetById.done, (state, { result, params }) =>
      typeof tools.handleGetById === 'function'
        ? tools.handleGetById(state, result, params, config)
        : state
    )
    .on(fxCreate.done, (state, { result, params }) =>
      typeof tools.handleCreate === 'function'
        ? tools.handleCreate(state, result, params, config)
        : state
    )
    .on(fxUpdate.done, (state, { result, params }) =>
      typeof tools.handleUpdate === 'function'
        ? tools.handleUpdate(state, result, params, config)
        : state
    )
    .on(fxDelete.done, (state, { params: id }) =>
      typeof tools.handleDelete === 'function'
        ? tools.handleDelete(state, id, config.idAttribute)
        : state
    )
    .reset(signout);

  $error.on(requestApi.start, () => null);

  $isErrorDialogOpen
    .on(requestApi.start, () => false)
    .on(
      [fxGetList.fail, fxGetById.fail, fxCreate.fail, fxUpdate.fail, fxDelete.fail],
      () => true
    );

  $isDeleteDialogOpen.on(deleteConfirmed, () => false).on(deleteClicked, () => true);

  return {
    PageGate,
    pageUnmounted,
    pageMounted,
    deleteConfirmed,

    fxGetList,
    fxGetById,
    fxCreate,
    fxUpdate,
    fxDelete,

    $isLoading,
    $error,
    request,
    errorOccured,
    requestApi,

    $raw,
    $rowCount,
    $normalized,

    $isDeleteDialogOpen,
    deleteDialogVisibilityChanged,
    $isErrorDialogOpen,
    errorDialogVisibilityChanged,
    deleteClicked,
  };
}

export interface IUser {
  id: number;
  name: string;
  surname: string;
  age: number;
  gender: string;
  onlineStatus?: boolean;
}

export type IUserPayload = Promise<IUser>;

function createNetworkBag(serverApi = {}) {
  const defaultAsyncFn = () => Promise.resolve();
  const defaultApi = {
    getList: defaultAsyncFn,
    getById: defaultAsyncFn,
    create: defaultAsyncFn,
    update: defaultAsyncFn,
    delete: defaultAsyncFn,
  };

  const api = { ...defaultApi, ...serverApi };

  const fxGetList = createEffect<any, any, Error>();
  const fxGetById = createEffect<any, any, Error>();
  const fxCreate = createEffect<any, any, Error>();
  const fxUpdate = createEffect<any, any, Error>();
  const fxDelete = createEffect<any, any, Error>();

  const $isLoading = createStore(false);
  const $error = createStore(null);

  fxGetList.use(api.getList);
  fxGetById.use(api.getById);
  fxCreate.use(api.create);
  fxUpdate.use(api.update);
  fxDelete.use(api.delete);

  const request = merge([
    fxGetList.pending,
    fxGetById.pending,
    fxCreate.pending,
    fxUpdate.pending,
    fxDelete.pending,
  ]);

  const errorOccured = merge([
    fxGetList.fail,
    fxGetById.fail,
    fxCreate.fail,
    fxUpdate.fail,
    fxDelete.fail,
  ]);

  const requestApi = split(request, {
    start: (isLoading) => isLoading,
    finish: (isLoading) => !isLoading,
  });

  $isLoading.on(request, (state, pending) => pending).reset(signout);

  $error
    .on(
      [fxGetList.fail, fxGetById.fail, fxCreate.fail, fxUpdate.fail, fxDelete.fail],
      (state, { error }: { error: any }) => error
    )
    .on(requestApi.start, () => null)
    .reset(signout);

  return {
    $isLoading,
    $error,

    fxGetList,
    fxGetById,
    fxCreate,
    fxUpdate,
    fxDelete,
    request,
    errorOccured,
    requestApi,
  };
}

function createDialogBag() {
  const dialogVisibilityChanged = createEvent<boolean>();
  const $isDialogOpen = createStore<boolean>(false);

  $isDialogOpen
    .on(dialogVisibilityChanged, (state, visibility) => visibility)
    .reset(signout);

  return {
    $isDialogOpen,
    dialogVisibilityChanged,
  };
}

function formatData(
  state: IRaw,
  result: { [key: string]: any[] } = {},
  itemsAttribute: string = 'data'
) {
  if (Object.prototype.hasOwnProperty.call(result, 'meta')) {
    return { meta: result.meta, data: result[itemsAttribute] };
  }

  const data = Object.prototype.hasOwnProperty.call(result, itemsAttribute)
    ? result[itemsAttribute]
    : [];
  return { data, meta: { total: result.count || data.length } };
}

function createItem(
  state: IRaw,
  result: { [key: string]: Object },
  params: Object = {},
  config: { createdPath?: string } = {}
) {
  const meta = { ...state.meta, total: state.meta.total + 1 };

  const data = config.createdPath
    ? state.data.concat(result[config.createdPath])
    : state.data.concat(result);

  return { meta, data };
}

function updateItem(
  state: IRaw,
  result: { [key: string]: { [index: string]: string } } = {},
  params: Object = {},
  config: IConfig = defaultConfig
) {
  const meta = { ...state.meta };
  let data = [...state.data];

  const resultIdx = data.findIndex((item) => {
    if (config.updatedPath) {
      return (
        String(item[config.idAttribute]) ===
        String(result[config.updatedPath][config.idAttribute])
      );
    }
    return String(item[config.idAttribute]) === String(result[config.idAttribute]);
  });

  if (resultIdx > -1) {
    data = data
      .slice(0, resultIdx)
      .concat(config.updatedPath ? result[config.updatedPath] : result)
      .concat(data.slice(resultIdx + 1));
  }

  return {
    meta,
    data,
  };
}

function deleteItem(state: IRaw, id: string | number, idAttribute: string) {
  const meta = { ...state.meta, total: state.meta.total - 1 };
  const data = state.data.filter((item) => String(item[idAttribute]) !== String(id));

  return { meta, data };
}

function normalizeData(
  { data = [] }: { data: { [idAttribute: string]: any }[] },
  idAttribute: string = 'id'
) {
  return data.reduce((acc, item) => ({ ...acc, [item[idAttribute]]: item }), {});
}

export { createPageBag, createDialogBag, createNetworkBag };
