import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    // MUI 5 баг с notched=true
    '& legend': {
      width: 0,
    },
    '& .MuiOutlinedInput-root': {
      borderRadius: 100,
      '& fieldset': {
        borderColor: '#E7E7EC',
      },
    },
    '& .MuiOutlinedInput-input': {
      padding: '0 12px',
      color: '#65657B',
      fontWeight: 500,
      fontSize: 14,
      height: 32,
    },
    '& *::-webkit-calendar-picker-indicator': {
      filter: 'invert(0.6)',
    },
  },
}));

export const FilterDatepicker = ({
  form,
  field: { value, name },
  onChange,
  label,
  list,
  ...props
}) => {
  const classes = useStyles();

  return (
    <FormControl className={classes.field}>
      <span className={classes.field_title}>{label}</span>
      <TextField
        classes={{ root: classes.textField }}
        type="date"
        sx={{ width: 150 }}
        onChange={(e) => onChange(e.target.value)}
        InputLabelProps={{
          shrink: true,
        }}
        value={value}
        notched
        {...props}
      />
    </FormControl>
  );
};
