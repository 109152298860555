function getFormData(formData, data, previousKey) {
  if (data instanceof Object) {
    Object.keys(data).forEach((key) => {
      const value = data[key];
      let tempKey = key;

      if (value instanceof File) {
        if (previousKey) {
          //для отправки массива файлов
          formData.append(`${previousKey}[${tempKey}]`, value);
        } else {
          formData.append(tempKey, value);
        }
      }

      if (previousKey) {
        tempKey = `${previousKey}[${tempKey}]`;
      }

      if (value instanceof Object && !Array.isArray(value)) {
        return getFormData(formData, value, tempKey);
      }

      if (Array.isArray(value)) {
        value.forEach((value_, index) => {
          if (typeof value_ === 'object') {
            getFormData(formData, value_, `${tempKey}[${index}]`);
          } else {
            formData.append(`${tempKey}[]`, value_);
          }
        });
      } else {
        formData.append(tempKey, value);
      }

      return undefined;
    });
  }
}

export default getFormData;
