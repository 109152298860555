import { Children, cloneElement } from 'react';
import { components } from 'react-select';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => {
  return {
    input: {
      '& > input::placeholder': {
        color: 'rgba(101,101,123,.5)',
      },
    },
  };
});

function MultiSelectValueContainer({ children, ...props }) {
  const { input } = useStyles();

  const newChildren = Children.map(children, (child) => {
    return child && child.type === components.Input
      ? cloneElement(child, {
          placeholder: props.selectProps.placeholder,
          className: input,
        })
      : null;
  });

  return <components.ValueContainer {...props}>{newChildren}</components.ValueContainer>;
}

export { MultiSelectValueContainer };
