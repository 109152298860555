import { useState, useEffect } from 'react';
import { Typography, Grid, IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';
import withStyles from '@mui/styles/withStyles';
import i18n from '@shared/config/i18n';

const { t } = i18n;

const styles = {
  errorContainer: {
    background: 'rgba(255,255,255, 0.9)',
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 11,
    padding: 24,
  },
  errorHeader: {
    fontSize: '24px',
    color: '#3B3B50',
    fontWeight: 900,
  },
  errorContent: {
    fontSize: '18px',
    color: '#3B3B50',
  },
  closeButton: {
    position: 'absolute',
    top: 12,
    right: 12,
  },
};

function ErrorMessage(props) {
  const { header = t('AnErrorHasOccurred'), error, content, classes, onClose } = props;
  const [isOpen, setOpen] = useState(false);

  useEffect(() => {
    if (error || content) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [error, content]);

  return isOpen ? (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      className={classes.errorContainer}
    >
      <IconButton
        onClick={() => {
          setOpen(false);
          if (typeof onClose === 'function') {
            onClose();
          }
        }}
        classes={{ root: classes.closeButton }}
        size="large"
      >
        <Close fontSize="large" style={{ color: '#3B3B50' }} />
      </IconButton>
      <Grid item>
        <Typography classes={{ root: classes.errorHeader }}>{header}</Typography>
      </Grid>
      {error && (
        <Grid item>
          <Typography classes={{ root: classes.errorContent }}>
            {error instanceof Error ? parseErrorMessage(error.message) : error}
          </Typography>
        </Grid>
      )}
      {content || null}
    </Grid>
  ) : null;
}

const StyledError = withStyles(styles)(ErrorMessage);
export { StyledError as ErrorMsg };

function parseErrorMessage(error) {
  const { message, request_uuid } = JSON.parse(error);

  return `${message || null}\n\n${'request_uuid: ' + request_uuid || null}`;
}
