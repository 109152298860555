import i18n from '@shared/config/i18n';

const { t } = i18n;

export const data = [
  {
    id: 0,
    label: t('NoPayment'),
  },
  {
    id: 1,
    label: t('PaymentExists'),
  },
];
