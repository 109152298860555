import MTab from '@mui/material/Tab';
import withStyles from '@mui/styles/withStyles';

const styles = {
  root: {
    fontWeight: '700',
    fontSize: '14px',
  },
};

export const Tab = withStyles(styles)((props) => {
  const { label, value, disabled, classes, ...restProps } = props;
  const { root } = classes;

  return (
    <MTab
      disableRipple
      label={label}
      disabled={disabled}
      classes={{ root }}
      value={value}
      {...restProps}
    />
  );
});
