import { FC } from 'react';
import { Typography } from '@mui/material';
import {
  GroupContainer,
  GroupContent,
  GroupHeader,
} from '@features/effector-form/controls/group/styled';

export const GroupControl: FC = ({ children, label, ...props }) => {
  return (
    <GroupContainer>
      <GroupHeader>
        <Typography variant="h6">{label}</Typography>
      </GroupHeader>

      <GroupContent>{children}</GroupContent>
    </GroupContainer>
  );
};
