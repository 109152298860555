import PropTypes from 'prop-types';
import { FormControlContext } from './form-control-context';

const FormControl = (props) => {
  const {
    children,
    component: Component = 'div',
    disabled,
    error,
    mode,
    readOnly,
    required,
  } = props;

  const isReadOnly = mode === 'view' || readOnly;

  const childContext = {
    disabled,
    error,
    readOnly: isReadOnly,
    required,
  };

  return (
    <FormControlContext.Provider value={childContext}>
      <Component>{children}</Component>
    </FormControlContext.Provider>
  );
};

FormControl.propTypes = {
  mode: PropTypes.oneOf(['view', 'edit']),
  readOnly: PropTypes.bool,
  error: PropTypes.bool,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  children: PropTypes.node,
};

FormControl.defaultProps = {
  mode: 'edit',
  readOnly: false,
  error: false,
  disabled: false,
  required: false,
};

export { FormControl, FormControlContext };
export { useFormControl } from './use-form-control';
export { formControlState } from './form-control-state';
