import { Children } from 'react';
import { Grid } from '@mui/material';

const ColumnsLayout = (props) => {
  const { children } = props;
  const columns = Children.map(children, (child) => {
    const defaultGreed = 0;
    const greed = child && child.props.greed;

    return child ? (
      <Grid item style={{ flexGrow: greed ? greed : defaultGreed }}>
        {child}
      </Grid>
    ) : null;
  });

  return (
    <Grid container spacing={6} wrap="nowrap" style={{ padding: 24 }}>
      {columns}
    </Grid>
  );
};

export { ColumnsLayout };
