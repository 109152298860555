import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Divider as MuiDivider } from '@mui/material';
import withStyles from '@mui/styles/withStyles';

const styles = () => {
  return {
    root: {},
  };
};

const Divider = (props) => {
  const { isVisible, gap, classes, ...rest } = props;
  const { root } = classes;

  const rootClassName = classNames(root, {});

  return isVisible ? (
    <MuiDivider classes={{ root: rootClassName }} style={{ margin: gap }} {...rest} />
  ) : null;
};

const StyledDivider = withStyles(styles)(Divider);

Divider.propTypes = {
  gap: PropTypes.string,
  isVisible: PropTypes.bool,
};

Divider.defaultProps = {
  gap: '10px 0px 15px 0px',
  isVisible: true,
};

export { StyledDivider as Divider };
