import { Check, Clear } from '@mui/icons-material';
import i18n from '@shared/config/i18n';
import { ActionButton } from '../../ui';

const { t } = i18n;

const FilterFooter = (props) => {
  const { onSubmit = null, onReset = null, disabled = false } = props;
  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          flexWrap: 'nowrap',
          width: '100%',
        }}
      >
        <ActionButton onClick={onReset} disabled={disabled} kind="negative">
          <Clear />
          {t('Reset')}
        </ActionButton>
        <ActionButton onClick={onSubmit} kind="positive">
          <Check />
          {t('Apply')}
        </ActionButton>
      </div>
    </>
  );
};

export default FilterFooter;
