import {
  createEffect,
  createEvent,
  createStore,
  sample,
  attach,
  forward,
  restore,
  split,
  guard,
} from 'effector';
import addHours from 'date-fns/addHours';
import format from 'date-fns/format';
import io from 'socket.io-client';
import {
  MailOutline,
  LockOpen,
  Domain,
  AccessibilityNew,
  Business,
  ArchiveOutlined,
  DriveEta,
  Notifications,
  LocalFireDepartment,
  RateReviewOutlined,
} from '@mui/icons-material';
import { getPathOr } from '../../../tools/path';
import { notificationApi } from '../api';
import { push } from './push';
import { $token, loggedIn, signout } from './user';

// Актуальный список типов
// https://api-product.mysmartflat.ru/api/v1/notifications/events/?app=crm&token=ust-700695-657b2ad262b17b91fe232cedc7db1781
const TICKET_UPDATED = 'ticket-updated';
const TICKET_UPDATED_CHAT = 'ticket-updated-chat';
const NEW_ALARM = 'new-alarm';
const NEW_MESSAGE = 'new-message';
const NEW_ENTERPRISE_ON_VERIFICATION = 'new-enterprise-on-verification';
const NEW_EMPLOYEE_ON_VERIFICATION = 'new-employee-on-verification';
const ENTERPRISE_CREATED_BY_OWNER = 'enterprise-created-by-owner';
const STUFF_PASS_STATUS_CHANGED = 'stuff-pass-status-changed';
const USER_VEHICLE_ADD = 'user-vehicle-add';
const RENT_CREATED = 'rent-created';
const NEW_TICKET_MESSAGE = 'new-ticket-message';
const DEBTORS_BUILDINGS_UPDATE_REQUIRED = 'debtors-buildings-update-required';

const connected = createEvent();
const disconnected = createEvent();
const reconnected = createEvent();
const subscribeChanged = createEvent();
const receivedNotification = createEvent();
const readNotifications = createEvent();

export const expectedEvents = [
  (() => {
    return {
      eventName: DEBTORS_BUILDINGS_UPDATE_REQUIRED,
      Icon: RateReviewOutlined,
      relatedSection: 'debtors-settings',
    };
  })(),

  (() => {
    const getEntityId = (event) => getPathOr('data.ticket_id', event, null);

    return {
      eventName: TICKET_UPDATED_CHAT,
      getEntityId,
      Icon: RateReviewOutlined,
      relatedSection: 'tickets',
      nestedRouting: [getEntityId],
    };
  })(),
  (() => {
    const getEntityId = (event) => getPathOr('data.ticket_id', event, null);

    return {
      eventName: TICKET_UPDATED,
      getEntityId,
      Icon: RateReviewOutlined,
      relatedSection: 'tickets',
      nestedRouting: [getEntityId],
    };
  })(),
  {
    eventName: NEW_ALARM,
    getEntityId: (event) => getPathOr('data.alarm_id', event, null),
    Icon: LocalFireDepartment,
    color: '#EB5757',
    relatedSection: 'alarms',
    nestedRouting: null,
  },
  (() => {
    const getEntityId = (event) => getPathOr('data.channel_id', event, null);

    return {
      eventName: NEW_MESSAGE,
      getEntityId,
      Icon: MailOutline,
      relatedSection: 'messages',
      nestedRouting: [getEntityId],
    };
  })(),
  {
    eventName: NEW_ENTERPRISE_ON_VERIFICATION,
    getEntityId: (event) => getPathOr('data.enterprise_id', event, null),
    Icon: Domain,
    relatedSection: 'enterprises',
    nestedRouting: null,
  },
  {
    eventName: NEW_EMPLOYEE_ON_VERIFICATION,
    getEntityId: (event) => getPathOr('data.id', event, null),
    Icon: AccessibilityNew,
    relatedSection: 'enterprises-employee',
    nestedRouting: null,
  },
  {
    eventName: ENTERPRISE_CREATED_BY_OWNER,
    getEntityId: (event) => getPathOr('data.id', event, null),
    Icon: Business,
    relatedSection: 'enterprises',
    nestedRouting: null,
  },
  {
    eventName: STUFF_PASS_STATUS_CHANGED,
    getEntityId: (event) => getPathOr('data.stuff_pass_id', event, null),
    Icon: ArchiveOutlined,
    relatedSection: 'stuff',
    nestedRouting: null,
  },
  {
    eventName: USER_VEHICLE_ADD,
    getEntityId: (event) => getPathOr('data.user.id', event, null),
    Icon: DriveEta,
    relatedSection: 'enterprises-employee',
    nestedRouting: null,
  },
  {
    eventName: RENT_CREATED,
    getEntityId: (event) => getPathOr('data.property_id', event, null),
    Icon: Notifications,
    relatedSection: 'rent-objects',
    nestedRouting: null,
  },
  (() => {
    const getEntityId = (event) => getPathOr('data.ticket_id', event, null);

    return {
      eventName: NEW_TICKET_MESSAGE,
      getEntityId,
      Icon: RateReviewOutlined,
      relatedSection: 'tickets',
      nestedRouting: [getEntityId],
    };
  })(),
];

export const mapEventNameToGetEntityIdFn = expectedEvents.reduce(
  (acc, { eventName, getEntityId }) => ({ ...acc, [eventName]: getEntityId }),
  {}
);

const splitSchema = expectedEvents.reduce(
  (acc, { eventName }) => ({
    ...acc,
    [eventName]: ({ event }) => event === eventName,
  }),
  {}
);

const receivedNotificationAbout = split(receivedNotification, splitSchema);
const readNotificationsFor = split(readNotifications, splitSchema);

const eventsPreparedForPushNotification = expectedEvents.map(
  ({ eventName, Icon, color }) =>
    receivedNotificationAbout[eventName].map(({ title, body, event }) => ({
      header: title,
      content: body,
      event,
      Icon,
      color,
    }))
);

forward({
  from: eventsPreparedForPushNotification,
  to: push,
});

const fxGetUnreadNotifications = createEffect().use(notificationApi.getList);
const fxSetAsReadNotifications = createEffect().use(notificationApi.markAsRead);
const fxSetAllAsReadNotifications = createEffect().use(notificationApi.markAllAsRead);

export const fxOpenSocket = createEffect().use((socketUrl) => {
  if (!socketUrl) {
    return null;
  }

  const socket = io(socketUrl, {
    reconnection: true,
    reconnectionDelay: 1000,
    reconnectionDelayMax: 5000,
    reconnectionAttempts: Number.POSITIVE_INFINITY,
  });

  socket.addEventListener('connect', connected);
  socket.addEventListener('disconnect', disconnected);
  socket.addEventListener('reconnect', reconnected);

  return socket;
});

sample({
  source: fxOpenSocket.doneData,
  clock: [fxOpenSocket, signout],
}).watch((socket) => {
  if (socket) {
    const channel = $token.getState() + '-notifications';

    socket.removeAllListeners();
    socket.close();
    socket.emit('unsubscribe', { channels: [channel] });
  }
});

const notificationSocketUrl = process.env.NOTIFICATION_URL;

const timezoneOffset = (new Date().getTimezoneOffset() / 60) * -1;

const fxOpenNotificationSocket = attach({
  effect: fxOpenSocket,
  mapParams: () => notificationSocketUrl,
});

const $socket = restore(fxOpenNotificationSocket.doneData, null);

guard({
  source: sample($socket, loggedIn),
  filter: (socket) => !socket,
  target: fxOpenNotificationSocket,
});

const $notifications = createStore([])
  .on(receivedNotification, (state, data) => [
    {
      ...data,
      read_at: null,
      sent_at: format(new Date(), 'dd.MM.yyyy HH:mm'),
    },
    ...state,
  ])
  .on(fxGetUnreadNotifications.doneData, (state, data) =>
    data.notifications.map((i) => ({
      ...i,
      sent_at: format(addHours(new Date(i.sent_at), timezoneOffset), 'dd.MM.yyyy HH:mm'),
      related_section: getRelatedSectionOrNull(i),
    }))
  )
  .on(fxSetAsReadNotifications.done, (state, { params }) => {
    return state.map((i) =>
      params.includes(i.id)
        ? { ...i, read_at: format(new Date(), 'yyyy-MM-dd HH:mm:ss') }
        : i
    );
  })
  .on(fxSetAllAsReadNotifications.done, (state) => {
    return state.map((i) => ({
      ...i,
      read_at: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
    }));
  })
  .reset(signout);

const $isNotificationsLoading = createStore(false)
  .on(fxGetUnreadNotifications.pending, (_, pending) => pending)
  .reset(fxGetUnreadNotifications.doneData);

guard({
  source: sample($notifications, loggedIn),
  filter: (notifications) => Array.isArray(notifications) && !notifications.length,
  target: fxGetUnreadNotifications.prepend(() => ({
    events: [
      TICKET_UPDATED,
      TICKET_UPDATED_CHAT,
      NEW_ALARM,
      NEW_MESSAGE,
      NEW_ENTERPRISE_ON_VERIFICATION,
      NEW_EMPLOYEE_ON_VERIFICATION,
      ENTERPRISE_CREATED_BY_OWNER,
      STUFF_PASS_STATUS_CHANGED,
      USER_VEHICLE_ADD,
      RENT_CREATED,
      NEW_TICKET_MESSAGE,
      DEBTORS_BUILDINGS_UPDATE_REQUIRED,
    ],
  })),
});

const $unreadNotifications = $notifications.map((notifications) =>
  notifications.filter((i) => i.read_at === null)
);

const $unreadCount = $unreadNotifications.map((notifications) => notifications.length);

expectedEvents.forEach(({ eventName, getEntityId }) => {
  guard({
    source: sample(
      $notifications,
      readNotificationsFor[eventName],
      (notifications, { id }) =>
        notifications
          .filter((i) => String(getEntityId(i)) === String(id))
          .map((i) => i.id)
    ),
    filter: (notifications) => notifications.length,
    target: fxSetAsReadNotifications,
  });
});

$socket.watch((socket) => {
  const channel = $token.getState() + '-notifications';

  if (socket && channel) {
    socket.emit('subscribe', { channels: [channel] }, (data) => {
      if (data.error) {
        console.error('Notification subscribe failed', data.error);
      } else {
        subscribeChanged(true);
        socket.on(channel, receivedNotification);
      }
    });
  }
});

export {
  TICKET_UPDATED,
  NEW_ALARM,
  NEW_MESSAGE,
  NEW_ENTERPRISE_ON_VERIFICATION,
  NEW_EMPLOYEE_ON_VERIFICATION,
  ENTERPRISE_CREATED_BY_OWNER,
  STUFF_PASS_STATUS_CHANGED,
  USER_VEHICLE_ADD,
  RENT_CREATED,
  $notifications,
  $unreadCount,
  readNotifications,
  receivedNotification,
  receivedNotificationAbout,
  fxSetAllAsReadNotifications,
  $isNotificationsLoading,
  fxSetAsReadNotifications,
  TICKET_UPDATED_CHAT,
  NEW_TICKET_MESSAGE,
  DEBTORS_BUILDINGS_UPDATE_REQUIRED,
};

function getRelatedSectionOrNull({ event = '' }) {
  const expectedEvent = expectedEvents.find((expected) => expected.eventName === event);
  return expectedEvent && expectedEvent.relatedSection
    ? expectedEvent.relatedSection
    : null;
}
