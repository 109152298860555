import { createAction } from 'redux-actions';
import format from 'date-fns/format';

import makeRequest from '../../tools/makeRequest';
import {
  detailActions,
  tableActions,
  filterActions,
  dialogActions,
  detailEntrancesActions,
  requestVariantsActions,
  entrancesActions,
} from '../symbiotes/objects';

import { stringToHtml, saveQrPdf } from '../../components/pages/Objects/lib/qr-code';

import { requestAC, requestSuccessAC, requestFailAC } from './commonActionCreators';

import {
  fetchComplexes,
  createComplex,
  updateComplex,
  deleteComplex,
} from './complexesActionGenerator';

import {
  fetchHouses,
  createBuilding,
  updateBuilding,
  deleteBuilding,
} from './housesActionGenerator';

import { fetchFlats, saveFlat } from './flatsActionGenerator';

import { fetchComplexesRegion } from './complexes_region';

export { fetchFlats, saveFlat };
export const changeTableKind = createAction('OBJECTS_TABLE_CHANGE_KIND');
export const changeQrModalVisibility = createAction('CHANGE_QR_MODAL_VISIBILITY');

export const changeReprintStatus = createAction('CHANGE_REPRINT_STATUS');

export const { detailChangeVisibility, openCloseEntity, detailChangeMode, changeTab } =
  detailActions;

export const {
  changeColumnWidths,
  changeColumnsOrder,
  hideColumns,
  changePageSize,
  changeCurrentPage,
  search,
  selectRows,
  sortBy,
} = tableActions;

export const { filterChangeVisibility, filterData } = filterActions;

export const { dialogChangeVisibility } = dialogActions;
export const { setDetailEntrances } = detailEntrancesActions;
export const { setRequestVariants } = requestVariantsActions;
export const { setEntrances } = entrancesActions;

const name = 'OBJECTS';

export const request = requestAC(`${name}`);
export const requestSuccess = requestSuccessAC(`${name}`);
export const requestFail = requestFailAC(`${name}`);

export const createPdf = requestAC('CREATE_PDF');
export const createPdfSuccess = requestAC('CREATE_PDF_SUCCESS');
export const createPdfFail = requestAC('CREATE_PDF_FAIL');

const actions = (dispatch) => ({
  request: () => dispatch(request()),
  fail: (error) => {
    dispatch(requestFail(error));
    dispatch(dialogChangeVisibility({ isOpen: true, kind: 'error' }));
  },
});

export default function fetchObjects() {
  return async (dispatch) => {
    try {
      return await Promise.all([
        dispatch(fetchComplexesRegion()),
        dispatch(fetchComplexes()),
        dispatch(fetchHouses()),
        dispatch(fetchFlats()),
      ]);
    } catch (error) {
      dispatch(requestFail(error.message || error));
      dispatch(dialogChangeVisibility({ isOpen: true, kind: 'error' }));
      return null;
    }
  };
}

export function deleteObject() {
  return async (dispatch, getState) => {
    const { objects } = getState();

    const data = {
      id: objects.detail.open,
    };

    try {
      dispatch(detailChangeVisibility(false));
      dispatch(detailChangeMode('view'));
      dispatch(openCloseEntity(null));

      dispatch(dialogChangeVisibility({ isOpen: false, kind: null }));

      if (objects.kind === 'buildings') {
        dispatch(deleteBuilding(data));
      } else {
        dispatch(deleteComplex(data));
      }
    } catch (error) {
      console.error(error);
    }
  };
}

export function updateCreateObject(payload) {
  return (dispatch, getState) => {
    const {
      complex,
      region,
      alias,
      ownershipsList,
      address,
      handle_address,
      city,
      street,
      number,
      fias,
      resident_request_variant,
      meters,
      sell,
      buildings_properties_rent_available,
      security_number,
      start_work_time,
      end_work_time,
      apartment_plan_default,
      guest_scud_pass_limit,
      paidTicketsAllowed,
    } = payload;

    const buildingData = {
      fias: fias && fias !== 'не задан' ? fias : '',
      complex_id: complex.id,
      alias: alias || '',
      security_number: security_number || '',
      sell: {
        enabled: sell && sell.enabled ? 1 : 0,
        emails: sell && Array.isArray(sell.emails) ? sell.emails : [],
      },
      buildings_properties_rent_available: buildings_properties_rent_available ? 1 : 0,
      ownershipsList,
      resident_request_variant: resident_request_variant || '',
      meters:
        meters.mode === 'manual'
          ? {
              mode: meters.mode,
              'manual-update': {
                notification_message: meters['manual-update'].notification_message,
              },
            }
          : { mode: 'automatic' },
      start_work_time: start_work_time ? format(start_work_time, 'HH:mm') : '',
      end_work_time: end_work_time ? format(end_work_time, 'HH:mm') : '',
      guest_scud_pass_limit: guest_scud_pass_limit || 0,
      paid_tickets_allowed: Number(Boolean(paidTicketsAllowed)),
    };

    if (meters.mode === 'manual' && meters['manual-update'].push_day_of_month) {
      buildingData.meters['manual-update'].push_day_of_month = format(
        new Date(meters['manual-update'].push_day_of_month),
        'dd'
      );
    }

    if (meters.mode === 'manual' && meters['manual-update'].from_day_of_month) {
      buildingData.meters['manual-update'].from_day_of_month = format(
        new Date(meters['manual-update'].from_day_of_month),
        'dd'
      );
    }

    if (meters.mode === 'manual' && meters['manual-update'].until_day_of_month) {
      buildingData.meters['manual-update'].until_day_of_month = format(
        new Date(meters['manual-update'].until_day_of_month),
        'dd'
      );
    }

    if (apartment_plan_default) {
      buildingData.apartment_plan_default_image_id = apartment_plan_default.id;
    }

    if (handle_address) {
      buildingData.address = {
        city: city || 'не указан',
        street: street || 'не указана',
        number: number || 'не указан',
      };
    } else {
      buildingData.full_address = address ? address.label : '';
    }

    const complexesData = {
      title: complex,
      region_id: region.id,
      paid_tickets_allowed: Number(Boolean(paidTicketsAllowed)),
    };

    if (getState().objects.detail.open !== null) {
      complexesData.id = getState().objects.detail.open;
      buildingData.id = getState().objects.detail.open;
    }

    const { objects } = getState();

    async function create() {
      const result =
        getState().objects.kind === 'buildings'
          ? dispatch(createBuilding(buildingData))
          : dispatch(createComplex(complexesData));

      try {
        const {
          data: { data, error, message },
        } = await result;

        if (error) {
          throw new Error(message);
        }

        dispatch(openCloseEntity(data.id));
        dispatch(detailChangeMode('view'));
      } catch (error) {
        console.error(error);
      }
    }

    async function update() {
      const result =
        getState().objects.kind === 'buildings'
          ? dispatch(updateBuilding(buildingData))
          : dispatch(updateComplex(complexesData));

      try {
        const {
          data: { error, message },
        } = await result;

        if (error) {
          throw new Error(message);
        }

        dispatch(detailChangeMode('view'));
        dispatch(fetchHouses(false));
      } catch (error) {
        console.error(error);
      }
    }

    return objects.detail.open === null ? create() : update();
  };
}

export const clickRow = (id) => (dispatch, getState) => {
  const { detail } = getState()[name.toLowerCase()];

  if (detail.open !== id) {
    dispatch(openCloseEntity(id));
  }

  if (!detail.isOpen) {
    dispatch(detailChangeVisibility(true));
  }

  if (detail.mode !== 'view') {
    dispatch(detailChangeMode('view'));
  }
};

export function fetchEntrances(id) {
  return (dispatch) =>
    makeRequest(
      '/v1/buildings/crm/get-details/',
      { id },
      {
        actions: {
          ...actions(dispatch),
          success: ({ data }) => {
            dispatch(setDetailEntrances(data.building.entrances));
            dispatch(requestSuccess());
          },
        },
        method: 'GET',
      }
    );
}

export function fetchRequestVariants() {
  return (dispatch) =>
    makeRequest(
      '/v1/buildings/resident-request-variants/',
      {},
      {
        actions: {
          ...actions(dispatch),
          success: ({ data }) => {
            dispatch(setRequestVariants(data));
            dispatch(requestSuccess());
          },
        },
        method: 'GET',
      }
    );
}

export function addEntrance({ entrance_number, floors }) {
  return async (dispatch, getState) => {
    const building_id = getState().objects.detail.open;
    const payload = {
      building_id,
      entrance_number,
      floors: floors.map((item) => {
        const [first_flat, last_flat] = item.range.split('-');
        return { floor_number: item.number, first_flat, last_flat };
      }),
    };

    return makeRequest('v1/buildings/crm/add-entrance', payload, {
      actions: {
        ...actions(dispatch),
        success: ({ data }) => {
          dispatch(setDetailEntrances(data.building.entrances));
          dispatch(requestSuccess());
          dispatch(detailChangeMode('view'));
          dispatch(fetchHouses(false));
        },
      },
      method: 'POST',
    });
  };
}

export const createQRPDF =
  ({ title, text }) =>
  (dispatch, getState) => {
    const { objects, flats } = getState();

    const { selection } = objects.table;
    const { isReprint } = objects;
    const { data } = flats;
    const pdf_text = stringToHtml(text);

    const isMultipleBuildingIds =
      [
        ...new Set(
          selection.map((selected) => {
            const flat = data[selected];

            return flat.building_id;
          })
        ),
      ].length > 1;

    const buildingIdData = isMultipleBuildingIds
      ? {}
      : {
          building_id: data[selection[0]].building_id,
        };

    const QRData = {
      apartments: [...selection],
      pdf_title: title,
      pdf_text,
      is_reprint: Number(isReprint),
      ...buildingIdData,
    };

    return makeRequest('v1/apartment/bind-by-qr/create-pdf', QRData, {
      actions: {
        request: () => {
          dispatch(changeQrModalVisibility(false));
          dispatch(createPdf());
        },
        success: (data) => {
          saveQrPdf(data);
          dispatch(createPdfSuccess());
          dispatch(fetchObjects());
        },
        fail: () => {
          dispatch(createPdfFail());
        },
      },
      config: {
        responseType: 'arraybuffer',
      },
    });
  };

export function getEntrance(id) {
  return async (dispatch) =>
    makeRequest(
      '/v1/buildings/crm/get-entrances',
      { building_id: id },
      {
        actions: {
          ...actions(dispatch),
          success: ({ data }) => {
            dispatch(setEntrances(data.entrances));
            dispatch(requestSuccess());
          },
        },
        method: 'GET',
      }
    );
}
