import { createEffect, createStore } from 'effector';

const fxGetList = createEffect();

const $isLoading = createStore<boolean>(false);
const $error = createStore<string | null>(null);
const $list = createStore<{
  [key: string]: { [key: number]: number; title: string }[];
}>({});

export { fxGetList, $isLoading, $error, $list };
