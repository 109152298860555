import { sample } from 'effector';
import { t } from 'i18next';
import { fxGetUser, push } from '@features/common';
import { profileApi } from '@features/profile/api';
import { errorOccured } from '../root/root.model';
import {
  fxUpdateAuth,
  fxUpdateAvatar,
  fxUpdateUser,
  updateAuth,
  updateAvatar,
  updateUser,
} from './user.model';

fxUpdateUser.use(profileApi.updateUser);
fxUpdateAuth.use(profileApi.updateAuth);
fxUpdateAvatar.use(profileApi.updateAvatar);

/** Обновление личных данных */
sample({
  clock: updateUser,
  target: fxUpdateUser,
});

/** Триггер на обновление глобального стора с юзером */
sample({
  clock: fxUpdateUser.done,
  fn: () => ({}),
  target: fxGetUser,
});

/** Обновление данных для входа */
sample({
  clock: updateAuth,
  target: fxUpdateAuth,
});

/** Обновление аватара */
sample({
  clock: updateAvatar,
  target: fxUpdateAvatar,
});

/** Вызов пуша */
sample({
  clock: [fxUpdateUser.done, fxUpdateAuth.done, fxUpdateAvatar.done],
  fn: () => {
    //@ts-ignore
    push({
      header: t('ProfilePage.SuccessUpdate'),
    });
  },
});

/** Обработка ошибок */
sample({
  clock: [fxUpdateUser.fail, fxUpdateAuth.fail, fxUpdateAvatar.fail],
  target: errorOccured,
});
