import { Grid } from '@mui/material';

const Dumb = (props) => {
  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      style={{ height: '100%', width: '100%' }}
    >
      <Grid item style={{ textAlign: 'center' }}>
        {props.children}
      </Grid>
    </Grid>
  );
};

export { Dumb };
