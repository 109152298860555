import { useGate, useUnit } from 'effector-react';
import {
  Autocomplete,
  AutocompleteProps,
  FormControl,
  FormHelperText,
  FormLabel,
  CircularProgress,
} from '@mui/material';
import TextField from '@mui/material/TextField';
import { Listbox } from '@features/effector-form/components/listbox';
import { withControl } from '@features/effector-form/hoc/withControl';
import { getAutocompleteValue } from '@features/effector-form/lib/getAutocompleteValue';
import { PersonalAccountControlGate, personalAccountQuery } from './model';
import { PersonalAccount } from './types';

export const PersonalAccountControl = withControl<
  Partial<AutocompleteProps<any, any, any, any>>
>(
  ({
    label,
    value,
    setValue,
    error,
    isError,
    required,
    readOnly,
    disabled,
    multiple,
    ...props
  }) => {
    useGate(PersonalAccountControlGate);

    const { data: options, pending } = useUnit(personalAccountQuery);

    const handleChange = (event: any, newValue: any) => {
      if (multiple) {
        setValue(newValue.map((item: PersonalAccount) => item.id) || null);
      } else {
        setValue(newValue?.id || null);
      }
    };

    return (
      <FormControl>
        {label && <FormLabel required={required}>{label}</FormLabel>}

        <Autocomplete
          {...props}
          loading={pending}
          value={getAutocompleteValue(options, value, multiple)}
          options={!options ? [] : options}
          onChange={handleChange}
          ListboxComponent={Listbox}
          renderInput={(params) => (
            <TextField
              {...params}
              InputProps={{
                ...params.InputProps,
                error: isError,
                required,
                readOnly,
                disabled,
                endAdornment:
                  pending && !readOnly ? (
                    <CircularProgress size={20} color="inherit" />
                  ) : (
                    params.InputProps.endAdornment
                  ),
              }}
            />
          )}
          readOnly={readOnly}
          disabled={disabled}
          multiple={multiple}
        />

        {error && <FormHelperText error>{error}</FormHelperText>}
      </FormControl>
    );
  }
);
