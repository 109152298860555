import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined';
import UnarchiveOutlinedIcon from '@mui/icons-material/UnarchiveOutlined';
import i18n from '@shared/config/i18n';
import { ActionIconButton } from '../..';

const { t } = i18n;

function ArchiveButton(props) {
  const { isArchived = false, titleAccess, ...restProps } = props;

  return (
    <ActionIconButton data-test-id="archive-btn" {...restProps}>
      {isArchived ? (
        <UnarchiveOutlinedIcon titleAccess={titleAccess || t('RestoreFromArchive')} />
      ) : (
        <ArchiveOutlinedIcon titleAccess={titleAccess || t('Archive')} />
      )}
    </ActionIconButton>
  );
}

export { ArchiveButton };
