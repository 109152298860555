import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => {
  return {
    container: {
      display: 'grid',
      gridTemplateRows: '1fr',
      gridTemplateColumns: (props) => (props.inverse ? '1fr 500px' : '500px 1fr'),
      padding: 24,
      paddingTop: 12,
      height: '100%',
      gridGap: 24,
    },
    left: {
      position: 'relative',
      gridRow: '1 / 2',
      gridColumn: (props) =>
        props.inverse
          ? !props.right
            ? '1 / 3'
            : '2 / 3'
          : !props.right
          ? '1 / 3'
          : '1 / 2',
    },
    right: {
      position: 'relative',
      gridRow: '1 / 2',
      gridColumn: (props) =>
        props.inverse
          ? !props.left
            ? '1 / 3'
            : '1 / 2'
          : !props.left
          ? '1 / 3'
          : '2 / 3',
    },
  };
});

const TwoColumnLayout = (props) => {
  const { left = null, right = null, inverse = false } = props;
  const classes = useStyles({ inverse, left, right });

  return (
    <div className={classes.container}>
      {right && <div className={classes.right}>{right}</div>}
      {left && <div className={classes.left}>{left}</div>}
    </div>
  );
};

export { TwoColumnLayout };
