import { createEffect, sample } from 'effector';
import { createGate } from 'effector-react';
import { cache, createQuery } from '@farfetched/core';
import { TicketTypesResponse } from '@features/effector-form/controls/ticketType/types';
import { getTicketTypes } from './api';

export const TicketTypeControlGate = createGate();

const fxGetTicketTypes = createEffect<void, TicketTypesResponse, Error>().use(
  getTicketTypes
);

export const ticketTypesQuery = createQuery({
  name: 'ticketTypes',
  effect: fxGetTicketTypes,
  mapData: ({ result }) =>
    result.types.map((type) => ({
      id: type.id,
      title: type.title,
      label: type.title,
      level: type.level,
      parent_id: type.parent_id,
      children: type.children,
      planned_duration_minutes: type.planned_duration_minutes,
      archived: type.archived,
      paid: type.paid,
    })),
});

cache(ticketTypesQuery);

sample({
  clock: TicketTypeControlGate.status,
  target: ticketTypesQuery.start,
});
