import { styled } from '@mui/material/styles';
import UjinLogo from '@img/ujin_white.svg';

export const Container = styled('div')(() => ({
  display: 'grid',
  height: '100vh',
  width: '100%',
  backgroundColor: '#585f78',
}));

export const Content = styled('div')(() => ({
  display: 'grid',
  gap: 15,
  alignContent: 'center',
  justifyContent: 'center',
  justifyItems: 'center',
}));

export const Logo = styled(UjinLogo)(() => ({
  transform: 'scale(1.5)',
  marginBottom: 15,
}));
