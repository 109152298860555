import { useState, FC } from 'react';
import { Paper, Popper, Popover as MuiPopover, ClickAwayListener } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
  content: {
    display: 'grid',
    alignContent: 'center',
    alignItems: 'center',
    borderRadius: 4,
    boxShadow:
      '0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12)',
    padding: 5,
    overflow: 'hidden',
    minWidth: 184,
  },
  popper: {
    zIndex: 2000,
  },
}));

export const Popover: FC<any> = ({
  trigger,
  items,
  children,
  disabled = false,
  ...props
}) => {
  const classes = useStyles();
  const [anchorElement, setAnchorElement] = useState(null);

  const handleOpen = (event: any) => {
    if (!disabled) {
      event.stopPropagation();
      setAnchorElement(anchorElement ? null : event.currentTarget);
    }
  };

  const handleClose = (event: any) => {
    event.stopPropagation();
    setAnchorElement(null);
  };

  const open = Boolean(anchorElement);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div>
      <div aria-describedby={id} onClick={handleOpen}>
        {trigger}
      </div>

      <MuiPopover
        id={id}
        open={open}
        anchorEl={anchorElement}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onClose={handleClose}
        onClick={handleClose}
        className={classes.popper}
        {...props}
      >
        <div className={classes.content}>{children}</div>
      </MuiPopover>
    </div>
  );
};
