import axios from 'axios';
import qs from 'qs';
import { getCookie } from './cookie';
import getFormData from './getFormatData';

const API_URL = process.env.API_URL;

const makeRequest = (url, params = {}, options = {}) => {
  const { actions = {}, method = 'post', config = {} } = options;
  const hasConfig = Object.keys(config).length > 0;

  const dispatchError = (error) => {
    if ('fail' in actions) actions.fail(error.message);
  };

  const token = getCookie('bms_token');

  const payload = {
    app: 'crm',
    ...params,
  };

  if (token) payload.token = token;

  if ('request' in actions) actions.request();

  const baseURL = /http?s:\/\//.test(url) ? '' : API_URL;

  const defaultPostConfig = {
    method,
    baseURL,
    url,
    data: payload,
    transformRequest: [
      (data) => {
        if (method.toLowerCase() === 'post') {
          const fd = new FormData();
          getFormData(fd, data);

          return fd;
        }

        return undefined;
      },
    ],
  };

  const defaultGetConfig = {
    method,
    baseURL,
    url: `${url}?${qs.stringify(payload)}`,
  };

  return axios(
    !hasConfig
      ? method.toLowerCase() === 'post'
        ? defaultPostConfig
        : defaultGetConfig
      : {
          ...(method.toLowerCase() === 'post' ? defaultPostConfig : defaultGetConfig),
          ...config,
        }
  )
    .then((response) => {
      if (response.data.error) {
        throw new Error(response.data.message);
      }

      if (response.status < 200 || response.status > 299) {
        throw new Error(response.statusText);
      }

      if ('success' in actions) actions.success(response.data);

      return response;
    })
    .catch((error) => {
      dispatchError(error);
    });
};

export default makeRequest;
