import { Children } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { FormHelperText } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { useFormControl, formControlState } from '../..';

const styles = () => {
  return {
    root: {},
    error: { color: 'rgba(0,0,0, 0.54) !important' },
    readOnly: {
      display: 'none',
    },
  };
};

const BaseInputHelperText = (props) => {
  const { classes, children, ...rest } = props;
  const { root, readOnly: ro, error } = classes;

  const contextProps = useFormControl();

  const fcs = formControlState({
    props,
    ctxProps: contextProps,
    states: ['readOnly', 'error', 'required', 'disabled'],
  });

  const rootClassName = classNames(root, { [ro]: fcs.readOnly });
  const hasHelperText = Children.count(children) > 0;

  return !fcs.readOnly && hasHelperText ? (
    <FormHelperText classes={{ root: rootClassName, error }} {...fcs} {...rest}>
      {children}
    </FormHelperText>
  ) : null;
};

const StyledBaseInputHelperText = withStyles(styles)(BaseInputHelperText);

BaseInputHelperText.propTypes = {
  error: PropTypes.bool,
  readOnly: PropTypes.bool,
};

export { StyledBaseInputHelperText as BaseInputHelperText };
