import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => {
  return {
    content: {
      borderRadius: 8,
      padding: '16px !important',
      position: 'relative',
      maxWidth: '100%',
      wordWrap: 'break-word',
      color: '#65657b',
    },

    triangle: {
      border: '40px solid transparent',
      borderBottom: '20px solid',
      position: 'absolute',
      bottom: 0,
      left: '-20px',
    },

    triangleMine: {
      left: 10,
      right: -20,
    },
  };
});

const MessageContent = (props) => {
  const { children, styles, isMine } = props;
  const { content, triangle, triangleMine } = useStyles();

  return (
    <Grid className={content} item style={styles.container}>
      <span
        className={classNames(triangle, { [triangleMine]: isMine })}
        style={styles.triangle}
      />
      <Grid container direction="column">
        {children}
      </Grid>
    </Grid>
  );
};

MessageContent.propTypes = {
  children: PropTypes.arrayOf(PropTypes.node).isRequired,
  styles: PropTypes.object,
  isMine: PropTypes.bool.isRequired,
};

export { MessageContent };
