import { Components } from '@mui/material';

export const CircularProgress: Components['MuiCircularProgress'] = {
  styleOverrides: {
    circle: {
      strokeLinecap: 'round',
      strokeWidth: 3,
    },
  },
};
