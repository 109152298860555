import { Components } from '@mui/material';

export const OutlinedInput: Components['MuiOutlinedInput'] = {
  styleOverrides: {
    root: {
      background: 'white',
    },
    input: {
      padding: 10,

      // '&.Mui-disabled': {
      //   color: 'black',
      //   opacity: 1,
      //   '-webkit-text-fill-color': 'unset',
      // },

      '&.MuiInputBase-readOnly': {
        color: 'black',
        opacity: 1,
        '-webkit-text-fill-color': 'unset',
      },

      '&.MuiInputBase-readOnly ~ fieldset': {
        border: 'none',
      },
    },
  },
};
