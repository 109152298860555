import { createSymbiote } from 'redux-symbiote';
import { memorize, remember } from '../../tools/userSettings';
import { signOut } from '../actions/userActionGenerator';

const visibilityReducer = (state, payload) => {
  return typeof payload === 'object' && payload !== null
    ? {
        ...state,
        ...payload,
        isOpen:
          Object.prototype.hasOwnProperty.call(payload, 'isOpen') &&
          payload.isOpen !== undefined
            ? payload.isOpen
            : !state.isOpen,
      }
    : typeof payload === 'boolean'
    ? { ...state, isOpen: payload }
    : { ...state, isOpen: !state.isOpen };
};

const createConfig = (name, initialState) => {
  return {
    namespace:
      typeof name === 'string' ? name : `entity-${Number(Date.now()).toString(36)}`,
    defaultReducer: (prevState, action) => {
      if (action.type === signOut().type) {
        return initialState;
      }
      return prevState;
    },
    separator: ' ---> ',
  };
};

// get saved values of column widths and extend them if was created new columns
const getInitialColumnWidths = (name, initialState = []) => {
  const memorizedWidths = remember(undefined, `${name.toLowerCase()}.columnWidths`) || [];
  const initialColumnWidths =
    memorizedWidths && memorizedWidths.length > 0
      ? [
          ...memorizedWidths,
          ...initialState.filter((element) =>
            memorizedWidths.every((mel) => mel.columnName !== element.columnName)
          ),
        ]
      : initialState;

  return initialColumnWidths;
};

// get saved values of columns order
const getInitialColumnsOrder = (name, initState = []) => {
  return initState;
};

// get saved values of hidden columns
const getHiddenColumns = (name, initState = []) => {
  return remember(undefined, `${name.toLowerCase()}.hiddenColumns`) || initState;
};

// get saved filter values
const getSavedFilters = (name, initState = {}) => {
  return remember(undefined, `${name.toLowerCase()}.filters`) || initState;
};

export const createTableSymbiotes = (name, initState = {}) => {
  const initialColumnWidths = getInitialColumnWidths(name, initState.columnWidths);
  const initialColumnsOrder = getInitialColumnsOrder(name, initState.columnsOrder);
  const initialHiddenColumns = getHiddenColumns(name, initState.columnsHidden);

  const initialState = {
    columnWidths: initialColumnWidths,
    columnsOrder: initialColumnsOrder,
    hidden: initialHiddenColumns,
    paging: {
      pageSize: 10,
      pageNum: 0,
    },
    search: '',
    selection: [],
    sort: [],
  };

  const config = createConfig(name, initialState);

  const symbiotes = {
    changeColumnWidths: (state, payload) => {
      memorize(undefined, `${name.toLowerCase()}.columnWidths`, payload);
      return { ...state, columnWidths: payload };
    },
    changeColumnsOrder: (state, payload) => {
      memorize(undefined, `${name.toLowerCase()}.columnsOrder`, payload);
      return { ...state, columnsOrder: payload };
    },
    hideColumns: (state, payload) => {
      memorize(undefined, `${name.toLowerCase()}.hiddenColumns`, payload);
      return { ...state, hidden: payload };
    },
    changePageSize: (state, payload) => ({
      ...state,
      paging: { ...state.paging, pageSize: payload },
    }),
    changeCurrentPage: (state, payload) => ({
      ...state,
      paging: { ...state.paging, pageNum: payload },
    }),
    search: (state, payload) => ({ ...state, search: payload }),
    selectRows: (state, payload) => ({ ...state, selection: payload }),
    sortBy: (state, payload) => ({ ...state, sort: payload }),
  };

  return createSymbiote(initialState, symbiotes, config);
};

export const createDetailSymbiotes = (name) => {
  const initialState = {
    isOpen: false,
    open: null,
    mode: 'view',
    tab: 'entity',
  };

  const config = createConfig(name, initialState);

  const symbiotes = {
    detailChangeVisibility: visibilityReducer,
    openCloseEntity: (state, payload) => ({ ...state, open: payload }),
    detailChangeMode: (state, payload) => ({ ...state, mode: payload }),
    changeTab: (state, payload) => ({ ...state, tab: payload }),
  };

  return createSymbiote(initialState, symbiotes, config);
};

export const createFilterSymbiotes = (name, initState = {}) => {
  const initialFilters = getSavedFilters(name, initState.filters);

  const initialState = {
    isOpen: false,
    filters: initialFilters,
  };

  const config = createConfig(name, initialState);

  const symbiotes = {
    filterChangeVisibility: visibilityReducer,
    filterData: (state, payload) => {
      // memorize( undefined, `${name.toLowerCase()}.filters`, payload );
      return { ...state, filters: payload };
    },
  };

  return createSymbiote(initialState, symbiotes, config);
};

export const createDialogSymbiotes = (name) => {
  const initialState = {
    isOpen: false,
    kind: null,
  };

  const config = createConfig(name, initialState);

  const symbiotes = {
    dialogChangeVisibility: visibilityReducer,
  };

  return createSymbiote(initialState, symbiotes, config);
};
