import { FC } from 'react';
import InputMask from 'react-input-mask';
import { FormControl, FormHelperText, FormLabel, OutlinedInput } from '@mui/material';
import { withControl } from '@features/effector-form/hoc/withControl';
import i18n from '@shared/config/i18n';

const { t } = i18n;

const mask = `99 ${t('ShortDay')} 99 ${t('ShortHour')} 99 ${t('Min')}`;

const DurationMaskedInput: FC = (props) => (
  <InputMask mask={mask} alwaysShowMask {...props} />
);

export const TicketTypeDurationControl = withControl(
  ({ value, setValue, error, label, required, readOnly, disabled }) => (
    <FormControl required={required} disabled={disabled}>
      <FormLabel>{label}</FormLabel>
      <OutlinedInput
        value={value}
        readOnly={readOnly}
        disabled={disabled}
        inputComponent={DurationMaskedInput}
        onChange={(e) => setValue(e.target.value)}
      />
      <FormHelperText error>{error}</FormHelperText>
    </FormControl>
  )
);
