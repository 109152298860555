import { SelectControl, SelectField } from '@ui/index';

export const timeZones = [
  { id: -12, title: 'UTC-12' },
  { id: -11, title: 'UTC-11' },
  { id: -10, title: 'UTC-10' },
  { id: -9, title: 'UTC-9' },
  { id: -8, title: 'UTC-8' },
  { id: -7, title: 'UTC-7' },
  { id: -6, title: 'UTC-6' },
  { id: -5, title: 'UTC-5' },
  { id: -4, title: 'UTC-4' },
  { id: -3, title: 'UTC-3' },
  { id: -2, title: 'UTC-2' },
  { id: -1, title: 'UTC-1' },
  { id: 0, title: 'UTC 0' },
  { id: 1, title: 'UTC+1' },
  { id: 2, title: 'UTC+2' },
  { id: 3, title: 'UTC+3' },
  { id: 4, title: 'UTC+4' },
  { id: 5, title: 'UTC+5' },
  { id: 6, title: 'UTC+6' },
  { id: 7, title: 'UTC+7' },
  { id: 8, title: 'UTC+8' },
  { id: 9, title: 'UTC+9' },
  { id: 10, title: 'UTC+10' },
  { id: 11, title: 'UTC+11' },
  { id: 12, title: 'UTC+12' },
  { id: 13, title: 'UTC+13' },
  { id: 14, title: 'UTC+14' },
];

const TimeZoneExtendedSelect = (props) => (
  <SelectControl options={timeZones} {...props} />
);

const TimeZoneExtendedSelectField = (props) => {
  const onChange = (value) => props.form.setFieldValue(props.field.name, value.id);

  return (
    <SelectField component={<TimeZoneExtendedSelect />} onChange={onChange} {...props} />
  );
};

export { TimeZoneExtendedSelect, TimeZoneExtendedSelectField };
