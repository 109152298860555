import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Checkbox, Grid, Radio, Switch, Tooltip } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { CheckBoxOutlineBlank } from '@mui/icons-material';

import {
  BaseInputErrorText,
  BaseInputHelperText,
  BaseInputLabel,
  Divider,
  FormControl,
  FormControlContext,
} from '../..';

const styles = () => {
  return {
    item: {},
    fullWidth: {
      width: '100%',
    },
    labelStart: {
      marginRight: 10,
      whiteSpace: 'break-spaces',
    },
    labelEnd: {
      marginLeft: 10,
      whiteSpace: 'break-spaces',
    },
  };
};

const SwitchControl = (props) => {
  const {
    classes,
    divider,
    fullWidth,
    labelPlacement: lp,
    name,
    onChange,
    value,
    helpText,
    label,
    errors,
    kind,
    disabled = false,
    indeterminate = false,
    indeterminateIcon = <CheckBoxOutlineBlank style={{ color: '#0288d1' }} />,
    viewCheckedIcon,
    viewUncheckedIcon,
    mode,
    customCheckedIcon,
    customUncheckedIcon,
    disableRipple,
    showTooltip,
    tooltipTitle,
    ...rest
  } = props;

  const inputId = `${name}-switch`;
  const { item, fullWidth: fw, labelStart, labelEnd } = classes;

  const mapDirectionToLabelPlacement = {
    bottom: 'column-reverse',
    end: 'row-reverse',
    start: 'row',
    top: 'column',
  };

  const direction = mapDirectionToLabelPlacement[lp];
  const rootClassName = classNames(item, { [fw]: fullWidth });
  const labelClassName = classNames({
    [labelStart]: lp === 'start',
    [labelEnd]: lp === 'end',
  });
  const hasHelpText = Boolean(helpText);

  const viewIcon = (value && viewCheckedIcon) || (!value && viewUncheckedIcon) || null;

  const controlProps = {
    inputProps: { id: inputId },
    checked: value,
    disabled,
    onChange,
    name,
  };

  const customCheckbox =
    customCheckedIcon && customUncheckedIcon
      ? { icon: customUncheckedIcon, checkedIcon: customCheckedIcon }
      : {};

  const CheckToolTip = ({ show, title, children }) =>
    show ? <Tooltip title={title}>{children}</Tooltip> : <div>{children}</div>;

  const mapKindToControl = {
    checkbox: (
      <Checkbox
        onClick={(e) => {
          e.stopPropagation();
        }}
        indeterminate={indeterminate}
        indeterminateIcon={indeterminateIcon}
        disableRipple={disableRipple}
        {...controlProps}
        {...customCheckbox}
      />
    ),
    switch: <Switch {...controlProps} />,
    radio: <Radio {...controlProps} />,
  };

  const Control = mapKindToControl[kind];

  return (
    <FormControl {...rest}>
      <Grid container direction="column">
        <Grid item classes={{ item: rootClassName }}>
          <Grid
            container
            direction={direction}
            alignItems="center"
            justifyContent="space-between"
            wrap="nowrap"
          >
            <Grid item classes={{ item: rootClassName }}>
              <FormControlContext.Consumer>
                {(contextProps) => (
                  <BaseInputLabel
                    {...contextProps}
                    classes={{
                      root: labelClassName,
                    }}
                    htmlFor={inputId}
                  >
                    {label}
                  </BaseInputLabel>
                )}
              </FormControlContext.Consumer>
            </Grid>

            <Grid item>
              <FormControlContext.Consumer>
                {() =>
                  (mode === 'view' && viewIcon) || (
                    <CheckToolTip show={showTooltip} title={tooltipTitle}>
                      {Control}
                    </CheckToolTip>
                  )
                }
              </FormControlContext.Consumer>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <FormControlContext.Consumer>
            {(contextProps) => <BaseInputErrorText errors={errors} {...contextProps} />}
          </FormControlContext.Consumer>
        </Grid>

        <Grid item>
          <FormControlContext.Consumer>
            {(contextProps) => (
              <BaseInputHelperText {...contextProps}>
                {hasHelpText ? helpText : null}
              </BaseInputHelperText>
            )}
          </FormControlContext.Consumer>
        </Grid>
      </Grid>

      <Divider isVisible={divider} />
    </FormControl>
  );
};

const StyledSwitchControl = withStyles(styles)(SwitchControl);

SwitchControl.propTypes = {
  name: PropTypes.string,
  divider: PropTypes.bool,
  fullWidth: PropTypes.bool,
  kind: PropTypes.oneOf(['checkbox', 'radio', 'switch']),
  label: PropTypes.node,
  helpText: PropTypes.node,
  onChange: PropTypes.func,
  labelPlacement: PropTypes.oneOf(['start', 'end', 'top', 'bottom']),
  errors: PropTypes.arrayOf(PropTypes.string),
};

SwitchControl.defaultProps = {
  name: Math.random().toString(36).slice(2, 11),
  divider: true,
  fullWidth: true,
  kind: 'checkbox',
  helpText: '',
  label: 'Some label',
  onChange: () => null,
  labelPlacement: 'top',
  errors: [],
};

export { StyledSwitchControl as SwitchControl };
