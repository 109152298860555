import { Dialog, DialogTitle } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { ModalCardContent, ModalCardActions } from '../..';

const titleUseStyles = makeStyles({
  root: {
    color: '#3B3B50',
    fontWeight: '700',
    minWidth: '25%',
    minHeight: '30%',
    fontSize: (props) => (props.titleFontSize ? props.titleFontSize : '1.2em'),
    padding: 0,
    lineHeight: '28px',
  },
});

const cardUseStyles = makeStyles({
  paper: {
    borderRadius: '15px',
    boxShadow: '0px 4px 15px #6A6A6E',
    width: '27.55vw',
    padding: (props) => (props.padding || props.padding === 0 ? props.padding : 24),
    minWidth: (props) => (props.minWidth ? props.minWidth : 600),
    maxWidth: (props) => (props.maxWidth ? props.maxWidth : null),
    overflow: (props) => props.disableOverflow && 'hidden',
  },
});

const ModalCard = ({ maxWidth, minWidth, padding, disableOverflow, ...props }) => {
  const { paper } = cardUseStyles({ maxWidth, minWidth, padding, disableOverflow });
  return <Dialog {...props} classes={{ paper }} />;
};

const ModalCardTitle = ({ titleFontSize, ...props }) => {
  const { root } = titleUseStyles({ titleFontSize });
  return <DialogTitle {...props} classes={{ root }} />;
};

function CustomModal(props) {
  const { isOpen, header, content, actions, titleFontSize, actionsClasses, ...rest } =
    props;

  return isOpen ? (
    <ModalCard open={isOpen} {...rest}>
      <ModalCardTitle titleFontSize={titleFontSize} disableTypography>
        {header}
      </ModalCardTitle>
      {content && <ModalCardContent>{content}</ModalCardContent>}
      {actions && <ModalCardActions classes={actionsClasses}>{actions}</ModalCardActions>}
    </ModalCard>
  ) : null;
}

export { CustomModal };
