import MaskedInput from 'react-text-mask';

const asterisk = '*';
const dash = '-';
const allDashSymbolsRegExp = /-/g;
const caretTrap = '[]';
const space = ' ';
const g = 'g';
const anyNonSymbolRegExp = /[^\D]/;
const allWhitespaceRegExp = /\s/g;
const emptyString = '';

function flatsMask(rawValue, config) {
  const tempRawValue = rawValue.replace(allWhitespaceRegExp, emptyString);

  const { placeholderChar } = config;
  const indexOfDashSymbol = tempRawValue.indexOf(dash);

  const fromToConnector = getConnector(tempRawValue, indexOfDashSymbol, dash);

  let fromPart = getPart(tempRawValue, indexOfDashSymbol, 'from', placeholderChar);
  fromPart = convertToMask(fromPart);

  let toPart = getPart(tempRawValue, indexOfDashSymbol, 'to', placeholderChar);
  toPart = convertToMask(toPart);

  const mask = fromPart.concat(fromToConnector).concat(toPart);

  return mask;
}

function getConnector(rawValue, indexOfConnection, connectionSymbol) {
  const connector = [];

  if (rawValue[indexOfConnection] === connectionSymbol) {
    connector.push(connectionSymbol);
  } else {
    connector.push(caretTrap, connectionSymbol);
  }

  connector.push(caretTrap);

  return connector;
}

function getPart(rawValue, indexOfDashSymbol, partName, placeholderChar) {
  let part = emptyString;

  if (indexOfDashSymbol === -1) {
    part = partName === 'from' ? rawValue : '';
  } else {
    part =
      partName === 'from'
        ? rawValue.slice(0, indexOfDashSymbol)
        : rawValue.slice(indexOfDashSymbol + 1, rawValue.length);
  }

  part = part.replace(new RegExp(`[^\\d-]`, g), emptyString);

  if (part === dash) {
    return asterisk;
  }
  if (part.length < 1) {
    return space;
  }
  return part;
}

function convertToMask(string) {
  return string
    .split(emptyString)
    .map((char) => (char === space || char === dash ? char : anyNonSymbolRegExp));
}

function fromToPipe(conformedValue, config) {
  const { rawValue, placeholderChar } = config;

  let value = conformedValue;
  value = removeAllDashSymbolsButFirst(value);

  const isEmpty = rawValue.match(new RegExp(`[^-\\s.${placeholderChar}]`)) === null;

  if (isEmpty) {
    return emptyString;
  }

  return value;
}

function removeAllDashSymbolsButFirst(string) {
  let dashSymbolCount = 0;

  return string.replace(allDashSymbolsRegExp, () => {
    dashSymbolCount++;

    return dashSymbolCount === 1 ? dash : emptyString;
  });
}
const FlatsMask = { mask: flatsMask, pipe: fromToPipe };

function FlatsMaskedInput(props) {
  return <MaskedInput {...props} mask={FlatsMask} />;
}

export default FlatsMaskedInput;
