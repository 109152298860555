import { combineReducers } from 'redux';
import { request, getSuccess, requestFail } from '../actions/rizersActionGenerator';
import { fetchingReducer, errorReducer, dataReducer } from './commonReducers';

const isFetching = fetchingReducer([request], [getSuccess, requestFail], false);
const error = errorReducer([requestFail], [request], null);

const format = (data) => {
  return {
    [`${data.building_id}`]: {
      ...data,
      building_id: `${data.building_id}`,
      rizers: data.rizers.reduce((acc, item) => {
        return [
          ...acc,
          { value: item, title: item === 0 ? 'Калитка' : `Подъезд №${item}` },
        ];
      }, []),
    },
  };
};

const data = dataReducer(
  {
    create: 'NO_ACTION',
    update: 'NO_ACTION',
    delete: 'NO_ACTION',
    get: getSuccess,
    getReducer: (state, action) => ({ ...state, ...format(action.payload) }),
  },
  {}
);

const rizers = combineReducers({
  isFetching,
  error,
  data,
});

export default rizers;
