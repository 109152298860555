import { useState } from 'react';
import { Tooltip } from '@mui/material';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import makeStyles from '@mui/styles/makeStyles';
import i18n from '@shared/config/i18n';
import { ActionIconButton } from '../..';

const { t } = i18n;

const useStyles = makeStyles(() => {
  return {
    buttonCopy: {
      position: 'relative',
      left: '10px',
      width: '10px',
      height: '10px',
      minWidth: '24px',
      minHeight: '24px',
      boxShadow: 'none',
    },
    cursorPointer: {
      cursor: 'pointer',
    },
    iconSize: {
      fontSize: '1rem',
    },
  };
});

const RenderMessageContent = ({ message, request_uuid }) => {
  const { buttonCopy, cursorPointer, iconSize } = useStyles();
  const [isCopy, setCopy] = useState(false);

  const handleResetCopy = () => setCopy(false);
  const handleCopy = () => {
    navigator.clipboard.writeText(request_uuid);
    setCopy(true);
  };

  const tooltipTitle = isCopy ? t('Copied') : t('Copy');

  return (
    <div>
      <div>{message}</div>
      <div onMouseLeave={handleResetCopy}>
        <Tooltip title={tooltipTitle} placement="top">
          <span onClick={handleCopy} className={cursorPointer}>
            {request_uuid}
          </span>
        </Tooltip>
        <Tooltip title={tooltipTitle} placement="top">
          <ActionIconButton onClick={handleCopy} className={buttonCopy}>
            <FileCopyIcon className={iconSize} />
          </ActionIconButton>
        </Tooltip>
      </div>
    </div>
  );
};

export default RenderMessageContent;
