import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useStore } from 'effector-react';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import { $user } from '@features/common';
import { $isEditMode, toggleEditMode, updateUser } from '@features/profile/models';
import {
  CancelButton,
  EditButton,
  EmailMaskedInput,
  PhoneMaskedInput,
  SaveButton,
} from '@ui/atoms';
import { InputField } from '@ui/molecules';
import { useStyles } from '../../organisms/profile-content/styles';

export const Personal: FC = () => {
  const { t } = useTranslation();
  const user = useStore($user);
  const isEditMode = useStore($isEditMode);
  const editMode = isEditMode ? 'edit' : 'view';
  const classes = useStyles();

  const validationSchema = Yup.object().shape({
    user_fullname: Yup.string().required(t('thisIsRequiredField')),
    user_mail: Yup.string().required(t('thisIsRequiredField')),
    user_phone: Yup.string().required(t('thisIsRequiredField')),
  });

  const defaultValues = {
    user_fullname: user.user_fullname,
    user_mail: user.user_mail,
    user_phone: `+${user.user_phone}`,
  };

  return (
    <Formik
      initialValues={defaultValues}
      validationSchema={validationSchema}
      enableReinitialize
      onSubmit={(values: any) => {
        updateUser(values);
      }}
      render={({ handleSubmit, handleReset, isValid }) => (
        <>
          <Field
            component={InputField}
            name="user_fullname"
            label={t('ProfilePage.FIO')}
            mode={editMode}
            divider={false}
          />

          <Field
            component={InputField}
            inputComponent={EmailMaskedInput}
            name="user_mail"
            label={t('ProfilePage.Email')}
            mode={editMode}
            divider={false}
          />

          <Field
            component={InputField}
            inputComponent={PhoneMaskedInput}
            name="user_phone"
            label={t('ProfilePage.Phone')}
            mode={editMode}
            divider={false}
          />

          <div className={classes.profileContent__actions}>
            {isEditMode ? (
              <>
                <SaveButton
                  disabled={!isValid}
                  onClick={() => {
                    handleSubmit();
                    toggleEditMode(false);
                  }}
                />
                <CancelButton
                  onClick={() => {
                    handleReset();
                    toggleEditMode(false);
                  }}
                />
              </>
            ) : (
              <EditButton
                onClick={() => {
                  toggleEditMode(true);
                }}
              />
            )}
          </div>
        </>
      )}
    />
  );
};
