import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useStore } from 'effector-react';
import {
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Typography,
} from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import SettingsIcon from '@mui/icons-material/Settings';
import SupportIcon from '@mui/icons-material/Support';
import VpnKeyOutlinedIcon from '@mui/icons-material/VpnKeyOutlined';
import { $user, $userRole } from '@features/common';
import { $currentPage, changeCurrentPage } from '@features/profile/models';
import { ProfileAvatar } from '../profile-avatar';
import { useStyles } from './styles';

export const ProfileCard: FC = () => {
  const { t } = useTranslation();
  const user = useStore($user);
  const userRole = useStore($userRole);
  const currentPage = useStore($currentPage);
  const classes = useStyles();

  const handleMenuItemClick = (type: ProfileCurrentPage) => {
    changeCurrentPage(type);
  };

  return (
    <div className={classes.profile__card}>
      <div className={classes.profile__content}>
        <ProfileAvatar />

        <div className={classes.profile__personalInfo}>
          <span className={classes.profile__name}>{user.user_fullname}</span>
          <span className={classes.profile__role}>{userRole.title}</span>
        </div>
      </div>

      <div className={classes.profile__menu}>
        <MenuList className={classes.profile__menuList}>
          <MenuItem
            selected={currentPage === 'personal'}
            onClick={() => handleMenuItemClick('personal')}
          >
            <ListItemIcon>
              <PersonIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>{t('ProfilePage.PersonalData')}</ListItemText>
          </MenuItem>

          <MenuItem
            selected={currentPage === 'password'}
            onClick={() => handleMenuItemClick('password')}
          >
            <ListItemIcon>
              <VpnKeyOutlinedIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>{t('ProfilePage.ChangePassword')}</ListItemText>
          </MenuItem>

          <MenuItem disabled>
            <ListItemIcon>
              <SettingsIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>{t('ProfilePage.Settings')}</ListItemText>
            <Typography variant="body2" color="text.secondary">
              {t('ProfilePage.Soon')}
            </Typography>
          </MenuItem>

          <MenuItem disabled>
            <ListItemIcon>
              <SupportIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>{t('ProfilePage.Support')}</ListItemText>
            <Typography variant="body2" color="text.secondary">
              {t('ProfilePage.Soon')}
            </Typography>
          </MenuItem>
        </MenuList>
      </div>
    </div>
  );
};
