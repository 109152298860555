import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import * as Zod from 'zod';
import { zodI18nMap } from 'zod-i18n-map';
import translation from 'zod-i18n-map/locales/ru/zod.json';
import translationEN from '../locales/en/translation.json';
import translationRU from '../locales/ru/translation.json';

const resources = {
  en: {
    translation: translationEN,
  },
  ru: {
    translation: translationRU,
    zod: translation,
  },
};

const lng = localStorage.getItem('lang') || 'en';

i18n.use(initReactI18next).init({
  resources,
  lng,
  interpolation: {
    escapeValue: false,
  },
});

if (lng === 'ru') {
  Zod.setErrorMap(zodI18nMap);
}

export default i18n;
