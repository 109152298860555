import { useIMask } from 'react-imask';
import { FormControl, FormHelperText, FormLabel, OutlinedInput } from '@mui/material';
import { withControl } from '@features/effector-form/hoc/withControl';

export const NumberControl = withControl(
  ({ value, setValue, error, label, required, readOnly, disabled }) => {
    const { ref } = useIMask(
      { mask: /^\d+$/ },
      {
        onComplete: (data) => {
          if (data !== '') {
            setValue(Number(data));
            return;
          }

          setValue(data);
        },
      }
    );

    return (
      <FormControl required={required} disabled={disabled}>
        {label && <FormLabel>{label}</FormLabel>}

        <OutlinedInput
          inputRef={ref}
          value={value}
          readOnly={readOnly}
          disabled={disabled}
        />

        {error && <FormHelperText error>{error}</FormHelperText>}
      </FormControl>
    );
  }
);
