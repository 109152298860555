import PropTypes from 'prop-types';
import { Typography, Grid } from '@mui/material';

export const MessageText = (props) => {
  const { text } = props;

  return text.length ? (
    <Grid {...props} item>
      <Typography sx={{ wordBreak: 'break-all' }}>{text}</Typography>
    </Grid>
  ) : null;
};

MessageText.propTypes = {
  text: PropTypes.string.isRequired,
};
