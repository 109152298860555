import { LabeledContent } from '@ui';
import makeStyles from '@mui/styles/makeStyles';
import i18n from '@shared/config/i18n';
import { formatPhone } from '@tools/formatPhone';

const { t } = i18n;

const useStyles = makeStyles({
  listOuter: {
    padding: '0 12px 0 24px',
    height: '100%',
  },
  listInner: {
    paddingRight: 12,
    '& > *:last-child': {
      marginBottom: 0,
    },
  },
  card: {
    padding: 24,
    background: '#EDF6FF',
    borderRadius: 16,
    marginBottom: 20,
    '& > *:last-child': {
      marginBottom: 0,
    },
  },
  part: {
    marginBottom: 20,
  },
  text: {
    color: '#65657B',
    fontSize: '13px',
    fontWeight: 500,
  },
});

export const EmployeeCard = ({ employee, position }) => {
  const { fullname, phone, email } = employee;
  const { part, card, text } = useStyles();
  const formattedEmployee = [
    {
      label: position,
      value: fullname,
    },
    {
      label: t('Label.phone'),
      value: phone.startsWith('+') ? phone : `+${phone}`,
    },
    {
      label: t('Label.email'),
      value: email,
    },
  ];

  return (
    <div className={card}>
      {formattedEmployee.map((field, idx) => (
        <LabeledContent key={idx} className={part} label={field.label}>
          <span className={text}>{field.value || t('Unknown').toLowerCase()}</span>
        </LabeledContent>
      ))}
    </div>
  );
};
