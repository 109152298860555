import PropTypes from 'prop-types';
import { Grid, Typography } from '@mui/material';
import { VisibilityOffOutlined } from '@mui/icons-material';
import i18n from '@shared/config/i18n';

const { t } = i18n;

export const MessageHidden = (props) => {
  const { isHidden } = props;
  return !isHidden ? null : (
    <Grid item>
      <Grid container alignItems="center" justifyContent="space-between" wrap="nowrap">
        <Typography noWrap component="i" variant="caption" style={{ marginRight: 8 }}>
          {t('TheMessageIsHiddenFromTheResident')}
        </Typography>
        <VisibilityOffOutlined />
      </Grid>
    </Grid>
  );
};

MessageHidden.propTypes = {
  isHidden: PropTypes.bool.isRequired,
};
