import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles({
  text: {
    fontWeight: 600,
    color: 'inherit',
    '&:hover': {
      color: 'inherit',
    },
  },
});
