import { combine, guard, createStore, forward, sample } from 'effector';
import { spread } from 'patronum';
import { Warning } from '@mui/icons-material';
import { theme } from '@configs/theme';
import { $isAuthenticated, push } from '@features/common';
import i18n from '@shared/config/i18n';
import { mergeColumnWidths } from '@tools/merge-column-widths';

const { t } = i18n;

export function createSavedUserSettings({ section, params, pageMounted, storage }) {
  const { table, $filters } = params;
  const { fxGetFromStorage, fxSaveInStorage } = storage;
  const {
    $columnOrder,
    $columnWidths,
    $hiddenColumnNames,
    $pageSize,
    $columns,
    $pinnedColumns,
  } = table;

  const $settingsInitialized = createStore(false);
  const $isGettingSettingsFromStorage = createStore(false);

  function getStores({ withSettings }) {
    const base = {
      columnOrder: $columnOrder || [],
      columnWidths: $columnWidths || [],
      hiddenColumnNames: $hiddenColumnNames,
      pageSize: $pageSize,
      columns: $columns || [],
      pinnedColumns: $pinnedColumns || [],
    };

    if ($filters) {
      // base.filters = $filters;
    }

    if (withSettings) {
      base.settingsInitialized = $settingsInitialized;
    }

    return base;
  }

  const $params = combine(getStores({ withSettings: false }));

  const $saved = sample({
    source: $params,
    clock: [fxGetFromStorage.doneData, fxGetFromStorage.fail],
    fn: mappedSavedData,
  });

  spread({
    source: $saved,
    targets: getStores({ withSettings: true }),
  });

  guard({
    source: [$params, $isAuthenticated, $settingsInitialized],
    clock: $params,
    filter: ([_, isAuthenticated, settingsInitialized]) =>
      isAuthenticated && settingsInitialized,
    target: fxSaveInStorage.prepend(([data]) => ({
      data,
      storage_key: section,
    })),
  });

  forward({
    from: pageMounted,
    to: fxGetFromStorage.prepend(() => ({ storage_key: section })),
  });

  forward({
    from: fxGetFromStorage.pending,
    to: $isGettingSettingsFromStorage,
  });

  forward({
    from: fxGetFromStorage.fail,
    to: push.prepend(() => ({
      Icon: Warning,
      color: theme.palette.warning.light,
      header: t('DataLoadingError'),
      content: t('UserDataHasNotBeenLoaded'),
      timeout: 3000,
    })),
  });

  return [$settingsInitialized, $isGettingSettingsFromStorage];
}

function mappedSavedData(params, saved) {
  return {
    pageSize: getParam(params, saved, 'pageSize'),
    filters: getParam(params, saved, 'filters'),
    hiddenColumnNames: getParam(params, saved, 'hiddenColumnNames'),
    columnWidths: mergeColumnWidths(params.columnWidths, saved?.columnWidths),
    columnOrder: saved?.columnOrder
      ? Array.from(new Set([...saved.columnOrder, ...params.columnOrder]))
      : params.columnOrder,
    columns: getParam(params, saved, 'columns'),
    pinnedColumns: getParam(params, saved, 'pinnedColumns'),
    settingsInitialized: true,
  };
}

function getParam(params, saved, paramName) {
  return saved?.[paramName] || params?.[paramName];
}
