import { Components } from '@mui/material';

export const FormLabel: Components['MuiFormLabel'] = {
  defaultProps: {
    focused: false,
  },
  styleOverrides: {
    asterisk: {
      color: 'red',
    },
  },
};
