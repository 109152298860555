import Checkbox from './Checkbox';
import Divider from './Divider';
import FormControl from './FormControl';

function CheckboxControl(props) {
  const {
    form = {},
    field = {},
    onChange,
    value,
    label,
    labelPlacement = 'start',
    divider,
  } = props;

  let error = false;
  let errorMessage = '';
  let name = '';

  if (Object.keys(form).length !== 0 && Object.keys(form.errors).length > 0) {
    name = name.length === 0 ? field.name : name;

    const { errors } = form;

    error =
      Object.prototype.hasOwnProperty.call(errors, name) && errors[name].length !== 0;
    errorMessage = errors[name];
  }

  return (
    <>
      <FormControl
        style={{ justifyContent: 'flex-end', margin: 0 }}
        control={<Checkbox onChange={onChange} value={value} {...props} />}
        labelPlacement={labelPlacement}
        label={label}
      />
      {error ? <div style={{ color: '#EB5757' }}>{errorMessage}</div> : null}
      {divider ? <Divider marginTop="19.5px" marginBottom="22px" /> : null}
    </>
  );
}

export default CheckboxControl;
