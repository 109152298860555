import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import i18n from '@shared/config/i18n';

const { t } = i18n;

export const PollFooter = ({ votesNumber }) => (
  <Typography align="center">{`${t('VotedIt')} ${votesNumber}`}</Typography>
);

PollFooter.propTypes = {
  votesNumber: PropTypes.number.isRequired,
};
