import CloseIcon from '@mui/icons-material/Close';

function CloseButton(props) {
  const { style, ...rest } = props;
  return (
    <CloseIcon
      data-test-id="close-btn"
      style={{ cursor: 'pointer', ...style }}
      {...rest}
    />
  );
}

export { CloseButton };
