export default (props) => (
  <svg
    width="13"
    height="12"
    viewBox="0 0 13 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.86265 8.77088L5.48598 10.1102C5.24732 10.3422 4.84798 10.2395 4.75132 9.92088L3.83398 6.90088"
      stroke="#6C6C7C"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M8.40255 4.78482C8.40255 4.78482 6.67655 6.34216 5.79122 7.14149C5.52655 7.38016 5.54855 7.80016 5.83522 8.01149L9.42055 10.6602C9.77322 10.9208 10.2772 10.7295 10.3686 10.3002L12.1292 2.00216C12.2146 1.60082 11.8206 1.26549 11.4379 1.41282L1.09455 5.40216C0.789887 5.51949 0.804554 5.95482 1.11589 6.05216L3.83389 6.90016"
      stroke="#6C6C7C"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
