import Chip from '@mui/material/Chip';
import Close from '@mui/icons-material/Close';
import withStyles from '@mui/styles/withStyles';

const styles = {
  root: {
    width: 'inherit',
    justifyContent: 'center',
    margin: '5px',
  },
  label: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: 'block',
    padding: '0 5px',
    margin: '0 auto',
  },
};

function MultiValue(props) {
  const { selectProps, classes } = props;

  return (
    <Chip
      classes={{
        root: classes.root,
        label: classes.label,
      }}
      data-test-id="address-chip"
      tabIndex={-1}
      label={props.children}
      onDelete={props.removeProps.onClick}
      deleteIcon={
        <Close
          data-test-id="address-chip-remove"
          {...props.removeProps}
          style={{
            fontSize: '1em',
            margin: '0 5px 0 0',
            display: selectProps.mode === 'view' ? 'none' : 'block',
          }}
        />
      }
    />
  );
}

export default withStyles(styles)(MultiValue);
