import { Tooltip } from '@mui/material';
import TemplateIcon from '@mui/icons-material/DescriptionOutlined';
import i18n from '@shared/config/i18n';
import { ActionIconButton } from '../action-icon-button';

const { t } = i18n;

const DownloadTemplateButton = (props) => {
  return (
    <Tooltip title={t('DownloadTemplate')}>
      <ActionIconButton {...props} data-testid="download-template">
        <TemplateIcon />
      </ActionIconButton>
    </Tooltip>
  );
};

export { DownloadTemplateButton };
