import { components } from 'react-select';
import { MultiSelectValue as MultiValue } from '../..';

const MultiSelectContainer = (props) => {
  const { getValue } = props;

  return (
    <>
      <components.SelectContainer {...props} />
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          paddingTop: 8,
          gap: 6,
        }}
      >
        {getValue().map((options, idx) => (
          <MultiValue
            key={idx}
            {...props}
            components={{
              Container: components.MultiValueContainer,
              Label: components.MultiValueLabel,
              Remove: components.MultiValueRemove,
            }}
            removeProps={{
              onClick: () => props.selectOption(options),
              onTouchEnd: () => props.selectOption(options),
              onMouseDown: (e) => {
                e.preventDefault();
                e.stopPropagation();
              },
            }}
            isFocused={false}
          >
            {props.selectProps.getOptionLabel(options)}
          </MultiValue>
        ))}
      </div>
    </>
  );
};

export { MultiSelectContainer };
