import { Check, Clear } from '@mui/icons-material';
import i18n from '@shared/config/i18n';
import { ActionButton } from '../..';

const { t } = i18n;

const FilterFooter = (props) => {
  const {
    isResettable = false,
    hide = null,
    submitLabel = t('Apply'),
    cancelLabel = t('Reset'),
  } = props;

  const isDisabled = !isResettable;

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        flexWrap: 'nowrap',
        zIndex: 1,
      }}
    >
      <ActionButton disabled={isDisabled} kind="negative" type="reset">
        <Clear />
        {cancelLabel}
      </ActionButton>
      {hide ? <ActionButton onClick={hide}>{t('Hide')}</ActionButton> : null}
      <ActionButton kind="positive" type="submit">
        <Check />
        {submitLabel}
      </ActionButton>
    </div>
  );
};

export { FilterFooter };
