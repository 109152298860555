import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import { updateAuth } from '@features/profile/models';
import { SaveButton } from '@ui/atoms';
import { InputField } from '@ui/molecules';
import { useStyles } from '../../organisms/profile-content/styles';

export const Password: FC = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  const validationSchema = Yup.object().shape({
    oldpass: Yup.string().required(t('thisIsRequiredField')),
    newpass: Yup.string().required(t('thisIsRequiredField')),
    newpass_confirm: Yup.string()
      .required(t('thisIsRequiredField'))
      .oneOf([Yup.ref('newpass'), null], t('ProfilePage.PasswordsNotEqual')),
  });

  const defaultValues = {
    oldpass: '',
    newpass: '',
    newpass_confirm: '',
  };

  return (
    <Formik
      initialValues={defaultValues}
      validationSchema={validationSchema}
      enableReinitialize
      onSubmit={(values: any) => {
        updateAuth({
          oldpass: values.oldpass,
          newpass: values.newpass,
        });
      }}
      render={({ handleSubmit, handleReset, isValid }) => (
        <>
          <Field
            component={InputField}
            name="oldpass"
            type="password"
            label={t('ProfilePage.OldPassword')}
            placeholder=""
            mode="edit"
            autocomplete="new-password"
            divider={false}
          />

          <Field
            component={InputField}
            name="newpass"
            type="password"
            label={t('ProfilePage.NewPassword')}
            placeholder=""
            mode="edit"
            autocomplete="new-password"
            divider={false}
          />

          <Field
            component={InputField}
            name="newpass_confirm"
            type="password"
            label={t('ProfilePage.ConfirmPassword')}
            placeholder=""
            mode="edit"
            autocomplete="new-password"
            divider={false}
          />

          <div className={classes.profileContent__actions}>
            <SaveButton
              disabled={!isValid}
              onClick={() => {
                handleSubmit();
                setTimeout(() => {
                  handleReset();
                }, 1);
              }}
            />
          </div>
        </>
      )}
    />
  );
};
