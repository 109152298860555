import { forwardRef } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import {
  MenuItem,
  ListItem,
  ListItemText,
  ListItemIcon,
  Typography,
} from '@mui/material';
import { Error } from '@mui/icons-material';
import makeStyles from '@mui/styles/makeStyles';
import { expectedEvents, fxSetAsReadNotifications } from '@features/common';
import i18n from '@shared/config/i18n';

const useStyles = makeStyles({
  menu_item: {
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    alignItems: 'center',
    gap: 10,
    width: '100%',
    maxWidth: 500,
    borderRadius: 4,
  },
  itemTitle: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    alignItems: 'center',
    justifyItems: 'space-between',
    gap: 15,
    whiteSpace: 'break-spaces',
    display: 'flex',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
  },
  itemDescription: {
    whiteSpace: 'break-spaces',
    display: 'flex',
    flexDirection: 'column',
  },
  statusText: {
    fontSize: 13,
    fontWeight: 500,
    whiteSpace: 'nowrap',
  },
});

const ListItemLink = forwardRef((props, ref) => {
  const { to = '/', ...rest } = props;
  return <ListItem ref={ref} component={Link} to={to} {...rest} />;
});

const { t } = i18n;

const NotificationsItem = ({ title, description, event, datetime, data, isRead, id }) => {
  const classes = useStyles();

  const unknownEventPath = { pathname: '', getNestedPaths: null };

  const mapEventToIcon = expectedEvents.reduce(
    (acc, { eventName, Icon }) => ({ ...acc, [eventName]: Icon }),
    {}
  );

  const mapEventToPath = expectedEvents.reduce(
    (acc, { eventName, relatedSection, nestedRouting }) => ({
      ...acc,
      [eventName]: {
        pathname: relatedSection ? `/${relatedSection}` : '',
        getNestedPaths: (data) =>
          Array.isArray(nestedRouting)
            ? nestedRouting.map((fn) => fn({ data })).join('/')
            : null,
      },
    }),
    {}
  );

  const Icon = mapEventToIcon[event];
  const { pathname, getNestedPaths } = mapEventToPath[event] || unknownEventPath;

  const nestedPaths =
    (typeof getNestedPaths === 'function' && getNestedPaths(data)) || null;

  const path = pathname && nestedPaths ? `${pathname}/${nestedPaths}` : pathname;

  const icon = Icon ? (
    <ListItemIcon>
      <Icon />
    </ListItemIcon>
  ) : (
    <ListItemIcon>
      <Error />
    </ListItemIcon>
  );

  const SHORT_MESSAGE_LENGTH = 100;

  const formatMessage = (mssg) => {
    if (Boolean(mssg) && typeof mssg === 'string') {
      return mssg.length > SHORT_MESSAGE_LENGTH
        ? `${mssg.slice(0, SHORT_MESSAGE_LENGTH)}...`
        : mssg;
    }

    return null;
  };

  const renderStatus = (isRead) => {
    if (isRead) {
      return (
        <span className={classes.statusText} style={{ color: '#1BB169' }}>
          {t('Read')}
        </span>
      );
    }

    return (
      <span className={classes.statusText} style={{ color: '#EB5757' }}>
        {t('Unread')}
      </span>
    );
  };

  const onClick = () => fxSetAsReadNotifications([id]);

  return (
    <MenuItem
      component={path ? ListItemLink : ListItem}
      to={path}
      classes={{ root: classes.menu_item }}
      onClick={onClick}
    >
      {icon}
      <ListItemText
        classes={{
          secondary: classes.itemDescription,
          primary: classes.itemTitle,
        }}
        primary={
          <>
            <span>{title}</span>
            {renderStatus(isRead)}
          </>
        }
        secondary={
          <>
            {formatMessage(description)}
            <Typography align="right" variant="caption">
              {datetime}
            </Typography>
          </>
        }
      />
    </MenuItem>
  );
};

export default connect(null, null)(withRouter(NotificationsItem));
