import addMinutes from 'date-fns/addMinutes';
import format from 'date-fns/format';

export const convertTimestampToLocal = (timestamp, form) => {
  const date = new Date(timestamp * 1000).toLocaleString();

  return date.substring(0, date.length - 3);
};

export const formatDateWithZone = (timestamp, form) => {
  if (typeof timestamp === 'number') {
    const date = new Date(timestamp * 1000);

    return format(addMinutes(date, date.getTimezoneOffset()), form);
  }

  return new Date();
};

export const convertUTCDateToLocalDate = (date) => {
  const ISODateString = format(date, 'yyyy-MM-dd HH:mm:ss')
    .split(' ')
    .join('T')
    .concat('Z');

  return new Date(ISODateString);
};
