import { createEffect, sample } from 'effector';
import { createGate } from 'effector-react';
import { createQuery } from '@farfetched/core';
import { personalAccountApi } from './api';
import { PersonalAccountResponse } from './types';

export const PersonalAccountControlGate = createGate();

const fxGetPersonalAccounts = createEffect<void, PersonalAccountResponse, Error>().use(
  personalAccountApi.getAccountTypes
);

export const personalAccountQuery = createQuery({
  name: 'personalAccounts',
  effect: fxGetPersonalAccounts,
  mapData: ({ result, params }) =>
    result.data.items.map((personalAccount) => ({
      id: personalAccount.id,
      label: personalAccount.title,
    })),
});

// cache(personalAccountQuery, {
//   adapter: localStorageCache({ maxAge: '5m' }),
// });

sample({
  clock: PersonalAccountControlGate.open,
  target: personalAccountQuery.start,
});
