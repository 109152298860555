import { useState, forwardRef, FC } from 'react';
import { Button, Tooltip } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useHover } from '@ui/index';

const useStyles = makeStyles((theme) => {
  return {
    iconButton: {
      cursor: 'pointer',
      margin: '0 5px',
      minWidth: 'unset',
      color: 'inherit',
      width: 30,
      height: 30,
    },
  };
});

export const IconButton: FC<any> = forwardRef(
  ({ onClick = () => {}, title, children, ...rest }, ref) => {
    const { iconButton } = useStyles();
    const { hover, handleMouseIn, handleMouseOut, close } = useHover('div');

    const renderButton = () => (
      <Button ref={ref} size="small" {...rest} className={iconButton}>
        {children}
      </Button>
    );

    return (
      <div onMouseOver={handleMouseIn} onMouseOut={handleMouseOut} onClick={onClick}>
        {title ? (
          <Tooltip title={title}>{renderButton()}</Tooltip>
        ) : (
          <>{renderButton()}</>
        )}
      </div>
    );
  }
);
