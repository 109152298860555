import { createEffect, sample } from 'effector';
import { createGate } from 'effector-react';
import { cache, createQuery } from '@farfetched/core';
import { TicketClassesResponse } from '@features/effector-form/controls/ticketClass/types';
import { getTicketClasses } from './api';

export const TicketClassControlGate = createGate();

const fxGetTicketClasses = createEffect<void, TicketClassesResponse, Error>().use(
  getTicketClasses
);

export const ticketClassesQuery = createQuery({
  name: 'ticketClasses',
  effect: fxGetTicketClasses,
  mapData: ({ result, params }) =>
    result.classes.map((classData) => ({
      id: classData.name,
      label: classData.title,
    })),
});

cache(ticketClassesQuery);

sample({
  clock: TicketClassControlGate.status,
  target: ticketClassesQuery.start,
});
