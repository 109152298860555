import SaveIcon from '@mui/icons-material/Save';
import i18n from '@shared/config/i18n';
import { ActionIconButton } from '../..';

const { t } = i18n;

function SaveButton({ isHideTitleAccess = false, ...props }) {
  return (
    <ActionIconButton data-test-id="save-btn" kind="positive" {...props}>
      <SaveIcon titleAccess={!isHideTitleAccess ? t('Save') : null} />
    </ActionIconButton>
  );
}

export { SaveButton };
