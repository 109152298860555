import { Tooltip } from '@mui/material';
import { QrCode } from '@mui/icons-material';
import makeStyles from '@mui/styles/makeStyles';
import i18n from '@shared/config/i18n';
import { ActionIconButton } from '../../index';

const { t } = i18n;

const useStyles = makeStyles({
  qr: {
    display: 'flex',
    alignItems: 'center',
    width: 30,
    height: 30,
    borderRadius: '50%',
    background: ({ color }) => color,
    '& svg': {
      fontSize: 24,
      fill: '#fff',
      margin: 'auto',
    },
  },
});

export const QrCodeButton = ({
  title = t('GenerateQrCodes'),
  clickHandler = () => {},
  ...rest
}) => {
  const classes = useStyles();
  return (
    <Tooltip title={title}>
      <span>
        <ActionIconButton onClick={clickHandler} {...rest}>
          <div className={classes.qr}>
            <QrCode />
          </div>
        </ActionIconButton>
      </span>
    </Tooltip>
  );
};
