import { cloneElement } from 'react';
import Grid from '@mui/material/Grid';

export const FilesList = (props) => {
  const { files, children: element } = props;

  return files.length ? (
    <Grid container component="ul" style={{ padding: 0 }}>
      {files.map(({ meta = {}, ...rest }, index) => {
        return cloneElement(element, { key: index, ...rest, ...meta });
      })}
    </Grid>
  ) : null;
};
